
import { DayPicker } from "react-day-picker"
import "react-day-picker/style.css";

import { cn } from "../../utils"

export const Calendar = ({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}) => {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
          months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
          month: "space-y-4",
          month_caption: "flex justify-center pt-1 relative items-center",
          caption_label: "text-sm font-medium",
          nav: "flex absolute items-center w-full justify-between",
          chevron: "color-black",
          month_grid: "w-full border-collapse space-y-1",
          weekdays: "flex",
          weekday: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
          week: "flex w-full mt-2",
          range_start: "rounded-l-full bg-black",
          range_end: "rounded-r-full bg-black",
          range_middle: "",
          day: "h-9 w-9 rounded-full text-[#9CA3AF] hover:bg-[#9CA3AF] hover:text-white text-center text-sm p-0",
          day_button: "h-full w-full p-0",
          selected: "rounded-none bg-[#9CA3AF] text-white",
          today: "text-gray-700",
          outside: "opacity-60",
          disabled: "opacity-50",
          hidden: "invisible",
          ...classNames,
      }}
      // components={{
      //     IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
      //     IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
      // }}
      {...props}
    />
  )
}
