import React from 'react';

function SvgProfileModelContactWeb({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-5" } `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99984 18.9584C5.05817 18.9584 1.0415 14.9417 1.0415 10.0001C1.0415 5.05841 5.05817 1.04175 9.99984 1.04175C14.9415 1.04175 18.9582 5.05841 18.9582 10.0001C18.9582 14.9417 14.9415 18.9584 9.99984 18.9584ZM9.99984 2.29175C5.74984 2.29175 2.2915 5.75008 2.2915 10.0001C2.2915 14.2501 5.74984 17.7084 9.99984 17.7084C14.2498 17.7084 17.7082 14.2501 17.7082 10.0001C17.7082 5.75008 14.2498 2.29175 9.99984 2.29175Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M7.5002 18.125H6.66686C6.3252 18.125 6.04186 17.8417 6.04186 17.5C6.04186 17.1583 6.30853 16.8833 6.6502 16.875C5.34186 12.4083 5.34186 7.59167 6.6502 3.125C6.30853 3.11667 6.04186 2.84167 6.04186 2.5C6.04186 2.15833 6.3252 1.875 6.66686 1.875H7.5002C7.7002 1.875 7.89186 1.975 8.00853 2.13333C8.1252 2.3 8.15853 2.50833 8.09186 2.7C6.5252 7.40833 6.5252 12.5917 8.09186 17.3083C8.15853 17.5 8.1252 17.7083 8.00853 17.875C7.89186 18.025 7.7002 18.125 7.5002 18.125Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M12.5 18.125C12.4333 18.125 12.3667 18.1167 12.3 18.0917C11.975 17.9833 11.7917 17.625 11.9083 17.3C13.475 12.5917 13.475 7.40832 11.9083 2.69165C11.8 2.36665 11.975 2.00832 12.3 1.89999C12.6333 1.79165 12.9833 1.96665 13.0917 2.29165C14.75 7.25832 14.75 12.725 13.0917 17.6833C13.0083 17.9583 12.7583 18.125 12.5 18.125Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M10 14.3332C7.675 14.3332 5.35833 14.0082 3.125 13.3499C3.11667 13.6832 2.84167 13.9582 2.5 13.9582C2.15833 13.9582 1.875 13.6749 1.875 13.3332V12.4999C1.875 12.2999 1.975 12.1082 2.13333 11.9916C2.3 11.8749 2.50833 11.8416 2.7 11.9082C7.40833 13.4749 12.6 13.4749 17.3083 11.9082C17.5 11.8416 17.7083 11.8749 17.875 11.9916C18.0417 12.1082 18.1333 12.2999 18.1333 12.4999V13.3332C18.1333 13.6749 17.85 13.9582 17.5083 13.9582C17.1667 13.9582 16.8917 13.6916 16.8833 13.3499C14.6417 14.0082 12.325 14.3332 10 14.3332Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M17.5001 8.12497C17.4335 8.12497 17.3668 8.11663 17.3001 8.09163C12.5918 6.52497 7.40015 6.52497 2.69181 8.09163C2.35848 8.19997 2.00848 8.02497 1.90015 7.69997C1.80015 7.36663 1.97515 7.01663 2.30015 6.9083C7.26681 5.24997 12.7335 5.24997 17.6918 6.9083C18.0168 7.01663 18.2001 7.37497 18.0835 7.69997C18.0085 7.9583 17.7585 8.12497 17.5001 8.12497Z" fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgProfileModelContactWeb;