import React from 'react';
import { Icons } from '../../assets/Svgs';
import ButtonMobile from "../../components/Buttons/ButtonMobile";

const WidgetSection = (
    {
        title,
        displayEditAction,
        onEdit,
        children,
        className,
        hasShadow,
    }) => {

    return (
        <div className={`${!!hasShadow ? "shadow-lg p-2 m-px" : ""} flex flex-col gap-2 rounded-lg`}>
            <div className="flex flex-col gap-2 rounded-lg">
                <div className="flex justify-between items-center">
                    <div className="font-semibold text-[1rem] leading-6 truncate w-56 text-[#111827]">
                        {!!title ? title : null}
                    </div>
                    {
                        !!displayEditAction
                            ? <ButtonMobile textClass="text-[1rem] leading-6 font-normal text-[white]" onClick={onEdit} className="px-2.5 py-1" label="Edit" icon={<Icons.SvgProfileSectionEdit />} />
                            : null
                    }
                    {/* Can add a delete here if needed */}
                </div>
            </div>
            <div className={`${!!className ? className : "flex flex-col" }`}>
                {
                    !!children ? children : null
                }
            </div>
        </div>
    );
}

export default WidgetSection;