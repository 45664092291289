import React from 'react';

function SvgConnectionFilterSticker({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-12" } `} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" strokeWidth="8"/>
            <path
                d="M27.1082 36.9584C26.7082 36.9584 26.3082 36.8584 25.9415 36.6584C25.1999 36.2417 24.7581 35.5 24.7581 34.6583V30.2C24.7581 29.775 24.4831 29.15 24.2248 28.825L21.0581 25.5C20.5331 24.975 20.1248 24.0584 20.1248 23.3834V21.45C20.1248 20.1084 21.1415 19.0583 22.4332 19.0583H33.5498C34.8248 19.0583 35.8582 20.0917 35.8582 21.3667V23.2167C35.8582 24.0917 35.3332 25.1 34.8332 25.5917C34.5915 25.8333 34.1915 25.8333 33.9498 25.5917C33.7082 25.35 33.7082 24.95 33.9498 24.7083C34.2582 24.4 34.6082 23.7084 34.6082 23.2167V21.3667C34.6082 20.7834 34.1331 20.3083 33.5498 20.3083H22.4332C21.8415 20.3083 21.3748 20.8084 21.3748 21.45V23.3834C21.3748 23.6917 21.6248 24.3 21.9498 24.625L25.1582 28C25.5832 28.525 25.9998 29.4084 25.9998 30.2V34.6583C25.9998 35.2084 26.3748 35.475 26.5415 35.5667C26.8998 35.7667 27.3248 35.7584 27.6582 35.5584L28.8248 34.8084C29.0665 34.6667 29.2998 34.2083 29.2998 33.9C29.2998 33.5583 29.5832 33.275 29.9248 33.275C30.2665 33.275 30.5498 33.5583 30.5498 33.9C30.5498 34.65 30.0831 35.5084 29.4915 35.8667L28.3332 36.6167C27.9582 36.8417 27.5332 36.9584 27.1082 36.9584Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M31.3918 32.3917C29.5751 32.3917 28.1001 30.9167 28.1001 29.1C28.1001 27.2834 29.5751 25.8083 31.3918 25.8083C33.2084 25.8083 34.6835 27.2834 34.6835 29.1C34.6835 30.9167 33.2084 32.3917 31.3918 32.3917ZM31.3918 27.0583C30.2668 27.0583 29.3501 27.975 29.3501 29.1C29.3501 30.225 30.2668 31.1417 31.3918 31.1417C32.5168 31.1417 33.4335 30.225 33.4335 29.1C33.4335 27.975 32.5168 27.0583 31.3918 27.0583Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M34.5583 32.8917C34.4 32.8917 34.2417 32.8333 34.1167 32.7083L33.2834 31.875C33.0417 31.6333 33.0417 31.2333 33.2834 30.9917C33.525 30.75 33.925 30.75 34.1667 30.9917L35 31.825C35.2417 32.0667 35.2417 32.4667 35 32.7083C34.8834 32.825 34.7167 32.8917 34.5583 32.8917Z"
                fill={!!color ? color : "#0400BD"}/>
        </svg>
    );
}

export default SvgConnectionFilterSticker;