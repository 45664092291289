import { Reorder } from "framer-motion";
import React, { useImperativeHandle, useRef, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Icons } from "../../../assets/Svgs";
import TemplateReorderItem from "../../../pages/Admin/Templates/TemplateReorderItem";
import FileService from "../../../services/FileService";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import ButtonBase from "../../Buttons/ButtonBase";
import InputGroup from "../../Layouts/InputGroup";
import PopupItemDelete from "../../../pages/Mobile/Popups/PopupItemDelete";
import WidgetService from "../../../services/Widget/WidgetService";

const WidgetPictureEditSteps = {
    PICTURE_LIST: "PICTURE_LIST",
    PICTURE_DETAIL: "PICTURE_DETAIL",
}

const WidgetPictureEdit = React.forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
) => {
    useImperativeHandle(ref, () => ({
        handleSave,
        handleCreate
    }));

    /* Hooks */
    const { widgetId } = useParams();
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();

    /* States */
    const [data, setData] = useState({
        title: widget?.data?.title || "",
        pictures: (widget?.data?.pictures || []).map((item, index) => ({ ...item, id: index })),
    });
    const [step, setStep] = useState(WidgetPictureEditSteps.PICTURE_LIST);
    const [editedPicture, setEditedPicture] = useState(null);

    /* Utils */
    const buildPayload = (widgetData) => {
        return {
            widget: {
                ...widget,
                data: {
                    title: widgetData.title,
                    pictures: widgetData.pictures.map((item) => ({
                        label: item.label,
                        order: item.order,
                        discover_url: item.discover_url,
                        widget_picture_id: item.file_content ? ("l_" + item.id) : item.widget_picture_id,
                    })),
                }
            },
            picturesToAdd: widgetData.pictures.reduce((acc, item) => {
                if (!item.file_content) return acc;
                return { ...acc, ["l_" + item.id]: item.file_content };
            }, {}),
        }
    }

    const deleteUnusedPictures = (widget, pictures) => {
        pictures.forEach(picture => {
            if (!widget.data.pictures.find(item => item.widget_picture_id === picture.id.toString())) {
                WidgetService.deleteWidgetPicture(picture.id);
            }
        });
    }

    /* Handlers */
    const handleSave = () => {
        const payload = buildPayload(data);
        deleteUnusedPictures(payload.widget, payload.widget.pictures ?? []);
        WidgetService.updateWidgetWithBlobs(payload.widget, null, payload.picturesToAdd, (savedWidget, _files, pictures) => {
            savedWidget.data.pictures = savedWidget.data.pictures.map((item) => ({
                ...item,
                widget_picture_id: item.widget_picture_id?.startsWith("l_")
                    ? pictures[item.widget_picture_id].id.toString()
                    : item.widget_picture_id,
            }));
            return savedWidget;
        })
            .then((savedWidget) => {
                onSave(savedWidget);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleCreate = () => {
        const payload = buildPayload(data);
        deleteUnusedPictures(payload.widget, payload.widget.pictures ?? []);
        WidgetService.createWidgetWithBlobs(payload.widget, null, payload.picturesToAdd, (savedWidget, _files, pictures) => {
            savedWidget.data.pictures = savedWidget.data.pictures.map((item) => ({
                ...item,
                widget_picture_id: item.widget_picture_id?.startsWith("l_")
                    ? pictures[item.widget_picture_id].id.toString()
                    : item.widget_picture_id,
            }));
            return savedWidget;
        })
            .then((savedWidget) => {
                onSave(savedWidget);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleChange = (updatedData) => {
        setData(updatedData);
        onChange({ ...widget, data: updatedData });
    };

    const handleReorder = (orderedItems) => {
        handleChange({
            ...data,
            pictures: orderedItems.map((item, index) => ({ ...item, order: index })),
        });
    };

    const handlePictureChange = (updatedPicture) => {
        console.log(updatedPicture);
        updatedPicture.id = editedPicture == null
            ? data.pictures.reduce((maxId, item) => Math.max(maxId, item.id), 0) + 1
            : editedPicture.id;
        if (editedPicture === null) {
            handleReorder([
                ...data.pictures,
                updatedPicture
            ]);
        } else {
            handleReorder(
                data.pictures.map((item) => (item.id === updatedPicture.id ? updatedPicture : item)),
            );
        }
        setEditedPicture(updatedPicture);
    };

    const handlePictureDelete = (id) => {
        handleReorder(data.pictures.filter(item => item.id !== id));
    }

    /* Renders */
    const renderAddPictureButton = () => {
        return <ButtonBase
            className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full w-40"
            iconColor="white"
            icon={<Icons.SvgTemplateAddNewSection color="white" className="w-3" />}
            onClick={() => { setStep(WidgetPictureEditSteps.PICTURE_DETAIL); }}
        >
            {_("Add a new picture")}
        </ButtonBase>
    }

    const renderPreviousButton = () => {
        return <ButtonBase
            className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full w-40 self-end"
            iconColor="white"
            icon={<Icons.SvgArrowReturnOutline color="white" className="w-3" />}
            onClick={() => { 
                setStep(WidgetPictureEditSteps.PICTURE_LIST);
                setEditedPicture(null);
            }}
        >
            {_("Previous")}
        </ButtonBase>
    }

    return (
        <div className="flex flex-col w-full h-full gap-7">
            {
                {
                    [WidgetPictureEditSteps.PICTURE_LIST]: renderAddPictureButton,
                    [WidgetPictureEditSteps.PICTURE_DETAIL]: renderPreviousButton,
                }[step]()
            }

            {
                {
                    [WidgetPictureEditSteps.PICTURE_LIST]: (
                        <div className="flex flex-col h-full gap-7 overflow-hidden">
                            <InputGroup
                                label={_("Section Title")}
                                classGapLabelToField="gap-3.5"
                                name="title"
                                widthInputClass="w-4/6"
                                value={data.title}
                                onChange={(e) => handleChange({ ...data, title: e.target.value })}
                                fontClass="text-black text-[1rem] leading-6 font-normal"
                                required={true}
                                backgroundClass="bg-[#F6F7F8]"
                            />

                            <Reorder.Group className="flex flex-col h-full gap-4 overflow-y-auto" axis="y" onReorder={handleReorder} values={data.pictures}>
                                {
                                    data.pictures.map((item) => (
                                        <Reorder.Item key={item.id} value={item}>
                                            <div className="bg-[#F6F7F8] rounded-[0.625rem] flex justify-between px-5 py-2.5">
                                                <div className="flex justify-center items-center align-center gap-2.5">
                                                    <Icons.SvgTemplateSectionListDraggable />
                                                    <img src={
                                                        item.file_content
                                                            ? item.file_content
                                                            : widget.pictures?.find(p => p.id === parseInt(item.widget_picture_id))?.picture
                                                    } alt={item.label} className="h-28 w-28 object-contain rounded" />
                                                    <div className="flex flex-col gap-1">
                                                        { item.label && <p className="text-sm">{ item.label }</p> }
                                                        { item.discover_url && <p className="text-sm text-[#9CA3AF]">{ item.discover_url }</p> }
                                                    </div>
                                                </div>

                                                <div className="flex justify-center items-center align-center gap-5">
                                                    <ButtonBase
                                                        onClick={() => addPopup(<PopupItemDelete handleDelete={() => handlePictureDelete(item.id)} />)}
                                                        className="bg-white text-[#FF0000] gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-2"
                                                    >
                                                        <Icons.SvgTrashOutline className="h-2.5" color="#FF0000" />
                                                        {_("Delete")}
                                                    </ButtonBase>

                                                    <ButtonBase className="bg-black text-white gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-1.5"
                                                        onClick={() => {
                                                            setEditedPicture(item);
                                                            setStep(WidgetPictureEditSteps.PICTURE_DETAIL);
                                                        }}
                                                    >
                                                        {_("Edit")}
                                                    </ButtonBase>

                                                    {/* <label className="relative inline-flex items-center cursor-pointer">
                                                        <input type="checkbox" className="sr-only peer" readOnly />
                                                        <div
                                                            className={`
                                                            ${!false
                                                                ? "bg-[#EEEFF2] peer-checked:after:-translate-x-5 peer-checked:bg-[#EEEFF2]"
                                                                : "bg-black peer-checked:after:translate-x-5 peer-checked:bg-black"
                                                            }
                                                                group peer ring-0 rounded-full outline-none duration-300 after:duration-300 w-11 h-6 peer-focus:outline-none after:rounded-full after:absolute after:bg-gray-50 after:outline-none after:h-6 after:w-6 after:flex after:justify-center after:items-center peer-hover:after:scale-90`
                                                            }
                                                        />
                                                    </label> */}
                                                </div>
                                            </div>
                                        </Reorder.Item>
                                    ))
                                }
                            </Reorder.Group>
                        </div>
                    ),
                    [WidgetPictureEditSteps.PICTURE_DETAIL]: <PictureDetailEdit picture={editedPicture} onChange={handlePictureChange} />,
                }[step]
            }
        </div>
    );
})

const PictureDetailEdit = ({ picture, onChange }) => {
    /* Hooks */
    const { t: _ } = useTranslation();
    const pictureSelectRef = useRef(null);
    
    /* States */
    const [data, setData] = useState({
        ...picture,
        label: picture?.label || "",
        file_content: picture?.file_content || null,
        file_name: picture?.file_name || null,
        discover_url: picture?.discover_url || "",
    });

    /* Effects */
    useEffect(() => {
        onChange(data);
    }, [data]);

    /* Handlers */
    const handleDataChange = (key, value) => {
        setData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }

    const handleSelectFile = (e) => {
        const file = e.target.files[0];
        FileService.loadFileBase64(file)
            .then(fileContent => {
                handleDataChange("file_content", fileContent);
                handleDataChange("file_name", file.name);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleRemoveFile = () => {
        handleDataChange("file_content", null);
        handleDataChange("file_name", null);
    }

    return (
        <div className="w-full h-full gap-7 flex flex-col">
            <InputGroup
                fontClass="text-sm font-medium flex"
                classGapLabelToField="gap-3.5"
                widthInputClass="w-full"
                name="label"
                placeholder="Picture Label"
                value={data.label}
                label={_("Picture Label")}
                required={true}
                onChange={(e) => handleDataChange("label", e.target.value)}
            />

            <label className="flex flex-col">
                <input
                    ref={pictureSelectRef}
                    type="file"
                    className="hidden"
                    onChange={handleSelectFile}
                    accept="image/*"
                />
                <div className="flex flex-col gap-7">
                    <div className={`flex flex-col align-center gap-1.5`}>
                        <p className="text-[0.688rem] font-medium text-[#9CA3AF]">
                            {data.file_name || _("No file selected")}
                        </p>
                        <ButtonBase
                            onClick={() => { pictureSelectRef.current?.click() }}
                            className={`bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full`}
                        >
                            {_("Select a picture")}
                        </ButtonBase>
                    </div>
                </div>
            </label>
            {
                data.file_content && (
                    <div className="flex gap-7 justify-center items-center">
                        <p className="text-sm">{data.file_name}</p>
                        <ButtonBase
                            onClick={handleRemoveFile}
                            className={`bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full`}
                        >
                            {_("Remove this picture")}
                        </ButtonBase>
                    </div>
                )
            }

            <InputGroup
                label={_("Picture “Discover” Link")}
                classGapLabelToField="gap-3.5"
                widthInputClass="w-full"
                name="discover_url"
                placeholder="Discover URL"
                value={data.discover_url}
                onChange={(e) => handleDataChange("discover_url", e.target.value)}
                fontClass="text-black text-[1rem] leading-6 font-normal"
                required={false}
                backgroundClass="bg-[#F6F7F8]"
            />
        </div>
    );
}

export default WidgetPictureEdit;