import React from 'react';

function SvgConnectionFlag({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-5" } `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.29175 18.9584C3.95008 18.9584 3.66675 18.675 3.66675 18.3334V1.66669C3.66675 1.32502 3.95008 1.04169 4.29175 1.04169C4.63341 1.04169 4.91675 1.32502 4.91675 1.66669V18.3334C4.91675 18.675 4.63341 18.9584 4.29175 18.9584Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M13.6251 13.9583H4.29175C3.95008 13.9583 3.66675 13.675 3.66675 13.3333C3.66675 12.9916 3.95008 12.7083 4.29175 12.7083H13.6251C14.5334 12.7083 14.9584 12.4666 15.0417 12.2583C15.1251 12.05 15.0001 11.5833 14.3501 10.9416L13.3501 9.94165C12.9417 9.58331 12.6917 9.04165 12.6667 8.44165C12.6417 7.80831 12.8917 7.18331 13.3501 6.72498L14.3501 5.72498C14.9667 5.10831 15.1584 4.60831 15.0667 4.39165C14.9751 4.17498 14.5001 3.95831 13.6251 3.95831H4.29175C3.94175 3.95831 3.66675 3.67498 3.66675 3.33331C3.66675 2.99165 3.95008 2.70831 4.29175 2.70831H13.6251C15.4501 2.70831 16.0334 3.46665 16.2251 3.91665C16.4084 4.36665 16.5334 5.31665 15.2334 6.61665L14.2334 7.61665C14.0251 7.82498 13.9084 8.11665 13.9167 8.40831C13.9251 8.65831 14.0251 8.88331 14.2001 9.04165L15.2334 10.0666C16.5084 11.3416 16.3834 12.2916 16.2001 12.75C16.0084 13.1916 15.4167 13.9583 13.6251 13.9583Z"
                fill={!!color ? color : "white"}/>
        </svg>
    );
}

export default SvgConnectionFlag;