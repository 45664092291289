import React, {useState} from 'react';
import PopupBase from "../PopupBase";
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import InputField from "../../Layouts/InputField";

function PopupCreateNewTag() {
    const { clearAllPopups, removePopup } = usePopup();
    const { t: _ } = useTranslation();
    const [values, setValues] = useState({
        tagName: "",
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <PopupBase
            title={_("Create a new tag:")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: clearAllPopups,
                    },
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: removePopup,
                    },
                ]
            }>

                <div className="w-full flex flex-col items-center" >
                    <div className="grid w-full items-center">
                        <div className="grid gap-7 px-48">
                            <InputField
                                label="Tag Name"
                                onChange={ onChange }
                                value={ values["tagName"] }
                                placeholder="Enter the tag name"
                                backgroundClass="bg-white"
                            />
                        </div>
                    </div>
                </div>
        </PopupBase>
    );
}

export default PopupCreateNewTag;