import React from 'react';

function SvgLeadsMailOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 4.25C1.5 2.5 2.5 1.75 4 1.75H9C10.5 1.75 11.5 2.5 11.5 4.25V7.75C11.5 9.5 10.5 10.25 9 10.25H4" stroke={props.color || 'black'} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 4.5L7.435 5.75C6.92 6.16 6.075 6.16 5.56 5.75L4 4.5" stroke={props.color || 'black'} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.5 8.25H4.5" stroke={props.color || 'black'} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.5 6.25H3" stroke={props.color || 'black'} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgLeadsMailOutline;