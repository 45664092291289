import APIService from "../APIService";
import TokenService from "./TokenService";

// const signUp = async(payload) => {
//     return new Promise((resolve, reject) => {
//         return APIService.apiCall("signup", "POST", payload, {}, { headers: { Authorization: "token" } })
//             .then((response) => {
//                 resolve(response.data);
//             })
//             .catch(error => {
//                 reject(error);
//             })
//     });
// }

const isLoggedIn = async() => {
    const token = await TokenService.getAccessToken(APIService.apiCall);
    return !!token;
};

const signIn = async(login, password) => {
    return new Promise((resolve, reject) => {
        return APIService.apiCall("token/", "POST", {
            email: login,
            password: password
        }, {}, {}, null)
        .then((response) => {
            response.json().then((data) => {
                localStorage.setItem("access-token", data.access);
                localStorage.setItem("refresh-token", data.refresh);
                resolve();
            })
            .catch((error) => {
                reject(error);
            })
        })
        .catch(error => {
            reject(error);
        })
    });
}

const signOut = async() => {
    return new Promise((resolve, reject) => {
        try {
            localStorage.removeItem("access-token");
            localStorage.removeItem("refresh-token");
            resolve();
        } catch (error) {
            reject(error);
        }
    });
}

const AuthService = {
    signIn,
    // signUp,
    signOut,
    isLoggedIn,
};

export default AuthService;
