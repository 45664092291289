import React, { useState } from 'react';
import { usePopup } from "../../../services/PopupService";
import { useTranslation } from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";
import InputSearch from "../../Layouts/InputSearch";
import Table from "../../Layouts/Table";
import { Icons } from "../../../assets/Svgs";
import PopupToReassignConfirm from "./PopupToReassignConfirm";

const onSearch = (listToSearch, keyword) => {
    if (!keyword) return listToSearch;
    return listToSearch.filter(item => {
        const fullNameMatch = item.full_name?.toLowerCase().includes(keyword.toLowerCase());
        const emailMatch = item.email?.toLowerCase().includes(keyword.toLowerCase());
        return fullNameMatch || emailMatch;
    });
}

function PopupSelectMemberToReassign({ members }) {
    const { clearAllPopups, addPopup } = usePopup();
    const { t: _ } = useTranslation();
    const [value, setValue] = useState("");
    const [connections, setConnections] = useState(members ?? []);
    const [connectionsOriginal] = useState(members ?? []);

    const handleSearch = (keyword) => {
        const filteredConnections = onSearch(connectionsOriginal, keyword);
        setConnections(filteredConnections);
    };

    return (
        <PopupBase
            title={_("Select the new team member to reassign this lead :")}
            footerButtons={[
                {
                    label: _("Cancel"),
                    background: "bg-white",
                    color: "text-black",
                    className: "w-[46.375rem]",
                    onClick: clearAllPopups,
                },
            ]}
        >
            <div className="w-2/4 flex flex-col justify-center align-center gap-7">
                <InputSearch
                    onSearch={handleSearch}
                    value={value}
                    setValue={setValue}
                    setList={setConnections}
                    listToSearchOriginal={connectionsOriginal}
                    icon={<Icons.SvgFilterUserSearch color="#D1D5DB" className="absolute top-1/2 left-3 -translate-y-1/2 h-4" />}
                    placeholder={_("Search name, email or template ")}
                    isFlexible={false}
                />

                <div className="h-40 overflow-auto">
                    {
                        connections?.length > 0 ? (
                            <Table
                                roundedClass="rounded-[0.438rem]"
                                columns={[
                                    {
                                        label: _("Leads"),
                                        sortable: false,
                                    },
                                ]}
                                rows={connections.map((member, index) => ({
                                    id: index,
                                    data: [
                                        {
                                            value: (
                                                <div
                                                    onClick={() => addPopup(<PopupToReassignConfirm member={member} />)}
                                                    className="flex gap-[1.5rem]"
                                                >
                                                    <img src={member.avatar} className="rounded-full h-8 w-8" alt="avatar" />
                                                    <span className="flex items-center">{member.full_name}</span>
                                                </div>
                                            ),
                                        },
                                    ],
                                }))}
                            />
                        ) : (
                            <p className="text-center">{_("No members found")}</p>
                        )
                    }
                </div>
            </div>
        </PopupBase>
    );
}

export default PopupSelectMemberToReassign;