import React from 'react';
import WidgetSection from "../../Layouts/WidgetSection";
import { SOCIAL_MEDIA_TYPES } from "../../../constants/SocialMediasTypes";
import {Icons} from "../../../assets/Svgs";

const iconOf = (type) => {
    const icons = {
        [SOCIAL_MEDIA_TYPES.WHATSAPP]: <Icons.SvgMediaWhatsappFill className="h-8 lg:h-6" />,
        [SOCIAL_MEDIA_TYPES.LINKEDIN]: <Icons.SvgMediaLinkedinFill className="h-8 lg:h-6" />,
        [SOCIAL_MEDIA_TYPES.YOUTUBE]: <Icons.SvgMediaYoutubeFill className="h-8 lg:h-6" />,
        [SOCIAL_MEDIA_TYPES.FACEBOOK]: <Icons.SvgMediaFacebookFill className="h-8 lg:h-6" />,
        [SOCIAL_MEDIA_TYPES.TIKTOK]: <Icons.SvgMediaTiktokFill className="h-8 lg:h-6" />,
        [SOCIAL_MEDIA_TYPES.INSTAGRAM]: <Icons.SvgMediaInstagram className="h-8 lg:h-6" />,
        [SOCIAL_MEDIA_TYPES.TWITTER]: <Icons.SvgMediaTwitterFill className="h-8 lg:h-6" />,
    };
    return icons[type];
}

const WidgetSocialMediaView = ({
        widget,
        displayEditAction,
        onEdit,
}) => {

    /* Utils */
    const widgetData = widget.data;
    const widgetTitle = widgetData?.title;

    const handleSocialMediaClick = (media) => {
        const defaultHandler = (media) => {
            window.open(media.details?.link, "_blank");
        }

        const socialMediaHandlers = {
            [SOCIAL_MEDIA_TYPES.WHATSAPP]: (media) => {
                if (media.details.enabled) {
                    window.open(`https://api.whatsapp.com/send?phone=${media.details?.tel}&text=${media.details?.whatsappNote}`, "_blank");
                } else {
                    window.open(`https://api.whatsapp.com/send?phone=${media.details?.tel}`, "_blank");
                }
            },
        };

        const socialMediaHandler = socialMediaHandlers[media.type] || defaultHandler;
        socialMediaHandler(media);
    }

    return (
        <WidgetSection
            title={widgetTitle}
            displayEditAction={displayEditAction}
            onEdit={onEdit}
            className="flex gap-1.5 wrapper-social-media-model w-auto flex-wrap"
        >
            {
                widgetData?.socialMedias?.sort((a, b) => (a.order > b.order) ? 1 : -1).map((media, index) => (
                    <div key={index} onClick={() => {handleSocialMediaClick(media)}} className="flex item-social-media-model h-fit w-auto justify-center items-center rounded-lg shadow-lg m-px">
                        { iconOf(media.type) }
                    </div>
                ))
            }
        </WidgetSection>
    );
}

export default WidgetSocialMediaView;