import React from 'react';

function SvgSidebarContracts(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.32812 9.31233C6.32812 9.95899 6.82813 10.479 7.44147 10.479H8.69478C9.22812 10.479 9.66146 10.0257 9.66146 9.45899C9.66146 8.85233 9.3948 8.63233 9.00146 8.49233L6.99479 7.79233C6.60146 7.65233 6.3348 7.439 6.3348 6.82566C6.3348 6.26566 6.76812 5.80566 7.30145 5.80566H8.55479C9.16812 5.80566 9.66813 6.32566 9.66813 6.97233" stroke={props.color || 'white'} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.99219 5.14551V11.1455" stroke={props.color || 'white'} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.6693 8.14469C14.6693 11.8247 11.6826 14.8114 8.0026 14.8114C4.3226 14.8114 1.33594 11.8247 1.33594 8.14469C1.33594 4.46469 4.3226 1.47803 8.0026 1.47803" stroke={props.color || 'white'} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.3359 2.14551V4.81217H14.0026" stroke={props.color || 'white'} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.6693 1.47803L11.3359 4.81136" stroke={props.color || 'white'} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgSidebarContracts;