import { TOP_COMPLETE_WEBSITE_CLICKS } from "../../constants/DatasConstants";

let topCompleteClicksWebsite = null;

export const getTopClicksWebsite = () => {
    return new Promise((resolve, reject) => {
        if (topCompleteClicksWebsite === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            //data template
            topCompleteClicksWebsite = TOP_COMPLETE_WEBSITE_CLICKS;
            resolve(topCompleteClicksWebsite);
        } else {
            resolve(topCompleteClicksWebsite);
        }
    });
}

export default {
    getTopClicksWebsite,
};