import React, { useState } from 'react';
import { Icons } from "../assets/Svgs";
import InputGroup from "../components/Layouts/InputGroup";
import { getFullPath } from "../router/Router";
import { useTranslation } from "../services/i18n/i18nService";
import { useLocation, useNavigate } from "react-router-dom";
import Transition from "../transition";
import AuthService from '../services/Auth/AuthService';
import {useToast} from "../contexts/ToastContext";

function Login() {
    const { t: _ } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { showToast } = useToast();
    const searchParams = new URLSearchParams(location.search);
    const transitionType = searchParams.get('transition');

    const inputs = [
        {
            id: 1,
            name: "email",
            type: "text",
            placeholder: "Enter your mail",
            label: "",
            errorMsg: "",
            pattern: "",
            icon: <Icons.SvgProfileEditPersoInfosName />,
            required: false,
        },
        {
            id: 2,
            name: "password",
            type: "password",
            placeholder: "Enter your password",
            label: "",
            errorMsg: "",
            pattern: "",
            icon: <Icons.SvgLoginPasswd />,
            required: false,
        },
    ];

    const [values, setValues] = useState({
        email: "",
        password: "",
    });

    const onChange = (e) => {
        setValues(v => { return { ...v, [e.target.name]: e.target.value } });
    };

    const handleLogin = () => {
        AuthService.signIn(values.email, values.password)
            .then(res => {
                showToast('Logged in successfully!', 'success');
                navigate(getFullPath(["ROOT", "HOME"]));
            })
            .catch(err => {
                showToast('Login failed. Please try again.', 'error');
                console.log(err);
            });
    };

    return (
        <Transition transitionType={transitionType}>
            <div className="flex items-center justify-center w-full h-full bg-white overflow-y-hidden">
                <div className="xxs:w-full xxs:absolute ss:w-0">
                    <img src={Icons.LoginBg} alt="img-login" />
                </div>

                <div className="flex ss:w-3/6 xxs:w-full xxs:z-40 xxs:gap-7 items-center justify-center">
                    <div className="ss:p-14 ss:border-0 xxs:px-6 xxs:py-11 bg-white xxs:rounded-[0.914rem] gap-7 flex flex-col align-center items-center justify-center">
                        <div className="w-full flex flex-col gap-6">
                            <div className="text-[#111827] font-bold ss:text-[2.5rem] xxs:text-[1.5rem] leading-6">{_("Welcome back !")}</div>
                            <div className="w-4/6 text-[#9CA3AF] font-normal leading-6 xxs:text-[0.875rem]">{_("Please log in to access to your own dashboard.")}</div>
                        </div>

                        <div className="flex flex-col w-full gap-5">
                            <div className="flex w-full flex-col gap-2.5">
                                <form className="flex flex-col gap-2.5">
                                    {
                                        inputs.map((input) => (
                                            <InputGroup
                                                widthInputClass="w-full"
                                                key={input.id}
                                                {...input}
                                                value={values[input.name]}
                                                onChange={onChange}
                                                icon={input.icon}
                                            />
                                        ))
                                    }
                                </form>

                                <div className="flex justify-center w-full px-2.5">
                                    <div className="flex w-full gap-2.5 text-sm justify-between ">
                                        <div className="flex gap-2.5">
                                            <input type="checkbox" />
                                            <div className="text-[#9CA3AF] hover:underline hover:select-all">{_("Remember me")}</div>
                                        </div>

                                        <div className="text-[#0400BD] hover:underline hover:select-all">{_("Forgot Password?")}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col w-full gap-3">
                                <div
                                    onClick={handleLogin}
                                    className={
                                        `flex flex-row items-center hover:no-underline gap-3 px-4 py-3 rounded-[0.625rem] text-base font-semibold 
                                        text-white hover:bg-blue-gradient bg-blue-gradient justify-center cursor-pointer`
                                    }
                                >
                                    {_("Log in")}
                                </div>

                                <div className="flex justify-center w-full">
                                    <span className="text-[#9CA3AF] font-medium leading-6 xxs:text-[0.688rem]">{_("Don’t have account")}?&nbsp;</span>
                                    <span className="text-[#0400BD] font-bold leading-6 xxs:text-[0.688rem] hover:underline hover:select-all" onClick={() => {
                                        navigate(getFullPath(["ROOT", "PERSO_INFOS"]) + "?transition=turn-left")
                                    }}>{_("Create an account")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ss:w-3/6 xxs:w-0">
                    <img className="w-full" src={Icons.LoginBg} alt="img-login" />
                </div>
            </div>
        </Transition>
    );
}

export default Login;