import React, {useEffect, useState} from 'react';
import {Icons} from "../../../assets/Svgs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import { useTranslation } from "../../../services/i18n/i18nService";
import LeadsService from "../../../services/Leads/LeadsService";
import { format } from "date-fns";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import InputField from "../../../components/Layouts/InputField";
import FieldCopy from "../../../components/Layouts/FieldCopy";
import InputTextarea from "../../../components/Layouts/InputTextarea";
import PopupSaveModification from "../../../components/Popups/SaveModification/PopupSaveModification";
import {usePopup} from "../../../services/PopupService";
import PopupGoBackQuestion from "../../../components/Popups/SaveModification/PopupGoBackQuestion";

function LeadEdit() {
    const { t: _ } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(true);
    const [ lead, setLead ] = useState({});
    const { addPopup, clearAllPopups } = usePopup();

    const [values, setValues] = useState({});

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        setLoading(true);
        LeadsService.getLeadById(parseInt(id))
            .then(resLead => {
                setLead(resLead);
                const newValues = resLead.data.reduce((acc, field) => {
                    acc[field.key] = field.value;
                    return acc;
                }, {});
                newValues.note = resLead.note;
                setValues(newValues);
            })
            .catch(err => {
                console.error(err);
                navigate('../', { replace: true });
            })
            .finally(() => {
                setLoading(false);
            })
    }, [id, navigate]);

    return (
        <form onSubmit={ (e) => {e.preventDefault()} } className="p-7 flex flex-col gap-7">
            <header className=" flex justify-between items-center border-gray-200">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={ getFullPath(["ROOT", "LEADS"]) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Leads")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    <Link to={ getFullPath(["ROOT", "LEADS", "VIEW_LEAD"]).replace(":id", id) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("View")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("Edit")}
                </div>

                <div className="flex items-center gap-7">
                    <ButtonBase
                        onClick={ () => { addPopup(
                            <PopupGoBackQuestion
                                id={id}
                                backPrev={ () => { navigate('../view/' + id, { replace: true }); clearAllPopups() }}
                                save={ () => { navigate('../view/' + id, { replace: true }); clearAllPopups() }}
                            />) }}
                        className="bg-white text-black text-sm py-3.5 px-6 rounded-full" iconColor="black" icon={ <Icons.SvgArrowReturnOutline className="h-3" /> }>
                        {_("Back")}
                    </ButtonBase>

                    <ButtonBase
                        onClick={() => { addPopup(
                            <PopupSaveModification
                                onHandleRedirectToPrev={() => { navigate('../view/' + id, { replace: true }); clearAllPopups() }}
                            />
                        ) }}
                        className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={ <Icons.SvgUserAddOutline className="h-3" /> } >
                        {_("Save the modifications")}
                    </ButtonBase>
                </div>
            </header>

            <div className="flex-1 min-h-0 overflow-auto">
                <div className="flex gap-7 flex-col overflow-x-scroll">
                    <div className="md:flex md:flex-row xs:grid gap-7 p-7 rounded-[1.25rem] bg-white">

                        <div className="w-full bg-white grid grid-cols-2 gap-7">
                            <div className="flex flex-col gap-7">
                                {
                                    lead?.data?.map((field, index) => (
                                        <InputField
                                            label={field.key}
                                            widthInputClass="w-4/6"
                                            name={field.key}
                                            key={index}
                                            value={values[field.key] || ''}
                                            onChange={ onChange }
                                        />
                                    ))
                                }
                            </div>

                            <div className="flex flex-col gap-7">
                                <InputTextarea
                                    gapClass="gap-3.5"
                                    rows={3}
                                    name="note"
                                    widthInputClass="w-4/6"
                                    value={ values["note"] || '' }
                                    onChange={ onChange }
                                    label="Note"
                                    required={false}
                                />

                                <FieldCopy label="Tag" canCopy={false} >
                                    <ButtonBase className="text-sm leading-3 bg-[#D1D5DB] text-[#4B5563] px-4 py-1.5 gap-[0.438rem] shadow-sm flex rounded-[0.625rem] cursor-pointer justify-between">
                                        { lead?.template }
                                        <Icons.SvgLeadsTagClose />
                                    </ButtonBase>
                                </FieldCopy>

                                {
                                    lead?.update_at
                                        ? <FieldCopy label="Connection date" canCopy={false} >
                                            { format(new Date(lead.update_at), 'dd/MM/yyyy') } at  { format(new Date(lead.update_at), 'hh:mm') }
                                        </FieldCopy>
                                        : null
                                }

                                <FieldCopy label="Connected with" canCopy={false}>
                                    <div className="flex gap-6 items-center">
                                        <img src={lead?.avatar} className="rounded-full h-8 w-8" alt="avatar" />
                                        <span>Edwin Bossy</span>
                                    </div>
                                </FieldCopy>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default LeadEdit;