import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {Icons} from "../../../assets/Svgs";
import InputGroup from "../../Layouts/InputGroup";
import InputTextarea from "../../Layouts/InputTextarea";
import WidgetService from "../../../services/Widget/WidgetService";
import {useParams} from "react-router-dom";

const WidgetVideoEdit = React.forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
) => {
    useImperativeHandle(ref, () => ({
        handleSave,
        handleCreate
    }));

    const { t: _ } = useTranslation();
    const { idType } = useParams();

    const [data, setData] = useState({
        title: widget?.data?.title || "",
        link: widget?.data?.link || "",
        description: widget?.data?.description || "",
    });

    const handleSave = () => {
        const updatedWidget = { ...widget, data };

        WidgetService.updateWidget(widget.id, widget)
            .then(() => {
                onSave(updatedWidget);
            })
            .catch((error) => {
                console.log('Failed to save widget');
                throw error;
            });
    }

    const handleCreate = () => {
        const updatedWidget = {
            portfolio: 2, // fix
            section: 1, // fix
            widget_type: idType,
            order: 27, // fix
            data
        };

        WidgetService.createWidget(updatedWidget)
            .then((newWidget) => {
                onSave(newWidget); // Pass the new widget to the onSave callback
            })
            .catch((error) => {
                console.log('Failed to create widget');
                throw error;
            });
    }

    const handleChanges = (data) => {
        setData(data);
        const updatedWidget = { ...widget, data };
        onChange(updatedWidget);
    }

    const extractVideoId = (url) => {
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/);
        return match ? match[1] : null;
    };

    const handleLinkChange = (e) => {
        const inputLink = e.target.value;
        const videoId = extractVideoId(inputLink);
        const embedLink = `https://www.youtube.com/embed/${videoId}`;
        handleChanges({ ...data, link: embedLink })
    };

    useEffect(() => {
        if (widget?.data) {
            setData({
                title: widget.data.title || "",
                link: widget.data.link || "",
                description: widget.data.description || ""
            });
        }
    }, [widget]);

    const isTemplate = !!window.location.href.includes("templates");

    return (
        <div className="grid w-full gap-7">
            <InputGroup
                name="title"
                widthInputClass={isTemplate ? "w-3/6" : "w-full"}
                value={ data.title }
                classFontLabel={isTemplate ? "text-black text-sm font-medium" : "text-[#111827] font-medium text-[1.25rem] leading-7 w-4/6"}
                label={isTemplate ? _("Video Title") : _("Modify the section title") }
                onChange={(e) => handleChanges({ ...data, title: e.target.value })}
                backgroundClass={ isTemplate ? "bg-[#F6F7F8]" : "border text-[#6B7280]" }
                required={isTemplate}
                classGapLabelToField="grid gap-4"
                fontClass={isTemplate ? "" : "text-[#6B7280] text-[1rem] leading-6 font-normal"}
                icon={ isTemplate ? null : <Icons.SvgProfileEditText className="absolute top-1/2 left-4 -translate-y-1/2 h-5" /> }
            />

            <InputGroup
                name="link"
                widthInputClass={ isTemplate ? "w-3/6" : "w-full" }
                value={ data.link }
                classFontLabel={ isTemplate ? "text-black text-sm font-medium" : "text-[#111827] font-medium text-[1.25rem] leading-7 w-4/6" }
                label={ isTemplate ? _("Video Link") : _("Modify the YouTube video link") }
                onChange={ handleLinkChange }
                backgroundClass={ isTemplate ? "bg-[#F6F7F8]" : "border text-[#6B7280]" }
                required={isTemplate}
                classGapLabelToField="grid gap-4"
                fontClass={ isTemplate ? "" : "text-[#6B7280] text-[1rem] leading-6 font-normal" }
                icon={ isTemplate ? null : <Icons.SvgProfilePopupMediaLink className="absolute top-1/2 left-4 -translate-y-1/2 h-5" /> }
            />

            <InputTextarea
                name="description"
                widthInputClass={ isTemplate ? "w-3/6" : "w-full" }
                fontClass={ isTemplate ? "" : "text-[#6B7280] text-[1rem] leading-6 font-normal" }
                label={ isTemplate ? _("Free Description Text") : _("Modify the video description") }
                backgroundClass={ isTemplate ? "bg-[#F6F7F8]" : "bg-white border" }
                gapClass="gap-4"
                value={ data.description }
                classFontLabel={ isTemplate ? "text-black text-sm font-medium" : "text-[#111827] font-medium text-[1.25rem] leading-7 w-4/6" }
                onChange={(e) => handleChanges({ ...data, description: e.target.value })}
            />
        </div>
    );
})

export default WidgetVideoEdit;