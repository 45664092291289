import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from "../../services/i18n/i18nService";
import { usePopup } from "../../services/PopupService";
import PopupUpdatedAvatar from "../Popups/AvatarProfile/PopupUpdatedAvatar";
import PopupGoBackQuestion from "../Popups/SaveModification/PopupGoBackQuestion";
import PopupUploadAvatar from "../Popups/AvatarProfile/PopupUploadAvatar";
import ButtonBase from "../Buttons/ButtonBase";
import IPhone from "./IPhone";
import PopupBase from "../Popups/PopupBase";
import {Icons} from "../../assets/Svgs";

function InputUploadImage(
    {
        isWebsite,
        isTeam,
        isTemplate,

        isPortfolioPictureEdit,
        isAdminPictureEdit,

        previewImageClass,
        displayJustifyCenter,
        values, // background picture
        setValues, // set background picture
        label,
        buttonInnerText,
        buttonStyleClass,
    }) {

    const hiddenFileInput = useRef(null);
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();

    const handleSelectImage = (event) => {
        const file = event.target.files[0];
        const fileReader = new FileReader();

        fileReader.addEventListener("load", () => {
            const uploadedImage = fileReader.result;
            if (typeof uploadedImage === "string") {
                if(!!isTemplate){
                    setValues((prevValues) => ({ ...prevValues, background: uploadedImage }));
                } else{
                    setValues((prevValues) => ({ ...prevValues, img: uploadedImage }));
                }
            }
        });

        fileReader.readAsDataURL(file);
    };

    const openPopupGoBackQuestion = () => {
        addPopup(
            <PopupGoBackQuestion
                openPopupEditAvatar={() => {
                    addPopup(
                        <PopupBase
                            footerButtons={
                                [
                                    {
                                        label: _("Cancel"),
                                        background: "bg-white",
                                        color: "text-black",
                                        onClick: openPopupGoBackQuestion,
                                    },
                                    {
                                        label: _("Modify the picture"),
                                        background: "bg-white",
                                        color: "text-black",
                                        onClick: () => hiddenFileInput.current.click(),
                                    },
                                    {
                                        label: _("Save the modifications"),
                                        background: "bg-black",
                                        color: "text-white",
                                        onClick: () => addPopup(<PopupUpdatedAvatar />),
                                    }
                                ]
                            }>
                            <IPhone isHalfPhone={true} backgroundValue={values["background"]} />
                        </PopupBase>
                    )
                }}
                save={() => addPopup(<PopupUpdatedAvatar />)}
            />
        )
    }

    const handlePopup = () => {
        // if(!!isTeam){
        //     !id ? openPopupCreateAvatar() : null // error
        // }else{
        //     if (hiddenFileInput.current) {
        //         hiddenFileInput.current.click();
        //     } else {
        //         console.error("hiddenFileInput is null");
        //     }
        // }
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        } else {
            console.error("hiddenFileInput is null");
        }
    }

    return (
        <div className="flex flex-col w-full gap-7 overflow-y-scroll">
            <label className="flex flex-col gap-3.5 w-full">
                {
                    !!label && (
                        <span className="block text-sm font-medium after:content-['*'] after:ml-0 after:text-red-500">
                            {_(label)}
                        </span>
                    )
                }

                <input
                    type="file"
                    className="hidden"
                    ref={ hiddenFileInput }
                    onChange={ handleSelectImage }
                    accept="image/*"
                />

                <div className="flex flex-col gap-7">
                    <div className={`${!!displayJustifyCenter ? "justify-center" : "" } flex align-center`}>

                        {
                            (isWebsite === true) && (
                                <div className={`flex flex-col gap-7 ${!!displayJustifyCenter && displayJustifyCenter === true ? "" : "w-full" }`}>
                                    <img className={`${!!previewImageClass ? previewImageClass : "w-[10rem] h-[10rem] md:w-[12.5rem] md:h-[12.5rem] lg:w-[14.5rem] lg:h-[14.5rem] rounded-full"} `} src={ !!values["img"] ? values["img"] : Icons.ProfileModelWebLogo } alt="website logo" />
                                    <ButtonBase
                                        onClick={ handlePopup }
                                        className={`${!!buttonStyleClass ? buttonStyleClass : ""}`}
                                        // bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full
                                    >
                                        {_(!!buttonInnerText ? buttonInnerText : "")}
                                    </ButtonBase>
                                </div>
                            )
                        }

                        {/*Portfolio Picture Add*/}
                        {
                            (isPortfolioPictureEdit === false) && (!isAdminPictureEdit) && (
                                <div onClick={ handlePopup } className="flex flex-col w-full bg-[#0400BD] rounded-lg p-2.5 items-center justify-center gap-2.5">
                                    <Icons.SvgProfileAddPicture className="h-8" />
                                    <div className="text-[1rem] font-medium leading-7 text-white">{_("Upload a picture")}</div>
                                </div>
                            )
                        }

                        {/*Portfolio Picture Edit*/}
                        {
                            (isPortfolioPictureEdit === true) && (
                                <div className={`flex flex-col gap-7 ${!!displayJustifyCenter && displayJustifyCenter === true ? "" : "w-full" }`}>
                                    <img className={`${!!previewImageClass ? previewImageClass : "w-[10rem] h-[10rem] md:w-[12.5rem] md:h-[12.5rem] lg:w-[14.5rem] lg:h-[14.5rem] rounded-full"} `} src={values["img"]} alt="uploaded-img"/>
                                    <ButtonBase onClick={ handlePopup } className="bg-[#0400BD] text-white w-full rounded-lg py-2.5 px-[1.125rem]" >
                                        {_(!!buttonInnerText ? buttonInnerText : "")}
                                    </ButtonBase>
                                </div>
                            )
                        }

                        {/*Admin Template Picture Edit*/}
                        {
                            (isPortfolioPictureEdit === false) && (!!isAdminPictureEdit) && (
                                <div className={`flex flex-col gap-7 ${!!displayJustifyCenter && displayJustifyCenter === true ? "" : "w-full" }`}>
                                    {
                                        values ? <img className={`${!!previewImageClass ? previewImageClass : "w-[10rem] h-[10rem] md:w-[12.5rem] md:h-[12.5rem] lg:w-[14.5rem] lg:h-[14.5rem] rounded-full"} `} src={values["img"]} alt="uploaded-img"/> : null
                                    }

                                    <ButtonBase onClick={ handlePopup } className="bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full" >
                                        {_(!!buttonInnerText ? buttonInnerText : "")}
                                    </ButtonBase>
                                </div>
                            )
                        }

                        {
                            // Admin TemplateEdit background picture
                            !!isTemplate && (
                                <div>
                                    <IPhone isHalfPhone={true} isEnlarged={true} backgroundValue={values["background"]} />
                                    <div className="flex justify-center item-center">
                                        <ButtonBase onClick={
                                            () => addPopup(
                                                <PopupUploadAvatar
                                                    title="Do you want to upload a background picture for your template?"
                                                    onEditPicture={() => {
                                                        addPopup(
                                                            <PopupBase
                                                                footerButtons={
                                                                    [
                                                                        {
                                                                            label: _("Cancel"),
                                                                            background: "bg-white",
                                                                            color: "text-black",
                                                                            onClick: openPopupGoBackQuestion,
                                                                        },
                                                                        {
                                                                            label: _("Modify the picture"),
                                                                            background: "bg-white",
                                                                            color: "text-black",
                                                                            onClick: () => hiddenFileInput.current.click(),
                                                                        },
                                                                        {
                                                                            label: _("Save the modifications?"),
                                                                            background: "bg-black",
                                                                            color: "text-white",
                                                                            onClick: () => addPopup(<PopupUpdatedAvatar />),
                                                                        }
                                                                    ]
                                                                }>
                                                                <IPhone isHalfPhone={true} backgroundValue={values["background"]} />
                                                            </PopupBase>
                                                        )
                                                    }}
                                                />
                                            )
                                        } className="bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full" >
                                            {_(!!buttonInnerText ? buttonInnerText : "")}
                                        </ButtonBase>
                                    </div>
                                </div>
                            )
                        }

                        {
                            !!isTeam
                                ? <div className={`flex flex-col gap-7 ${!!displayJustifyCenter && displayJustifyCenter === true ? "" : "w-full" }`}>
                                    {
                                        !!values["img"]
                                            ? <img className={`${!!previewImageClass ? previewImageClass : "w-[10rem] h-[10rem] md:w-[12.5rem] md:h-[12.5rem] lg:w-[14.5rem] lg:h-[14.5rem] rounded-full"} `} src={values["img"]} alt="uploaded-img"/>
                                            : <div className={`${!!previewImageClass ? previewImageClass : "w-[10rem] h-[10rem] md:w-[12.5rem] md:h-[12.5rem] lg:w-[14.5rem] lg:h-[14.5rem] bg-[#EEEFF2] rounded-full"} `} />
                                    }

                                    <ButtonBase
                                        onClick={ handlePopup }
                                        className={`${!!buttonStyleClass ? buttonStyleClass : ""}`}
                                        // bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full
                                    >
                                        {_(!!buttonInnerText ? buttonInnerText : "")}
                                    </ButtonBase>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </label>

        </div>
    );
}

export default InputUploadImage;