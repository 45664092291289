import React, {useState} from 'react';
import InputField from "../../../components/Layouts/InputField";
import InputTextarea from "../../../components/Layouts/InputTextarea";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import {useTranslation} from "../../../services/i18n/i18nService";
import {usePopup} from "../../../services/PopupService";
import PopupBase from "../../../components/Popups/PopupBase";

function LayoutContactForm() {
    const { t: _ } = useTranslation();
    const { addPopup, removePopup } = usePopup();
    const [values, setValues] = useState({
        subject: "",
        message: "",
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <div className="w-full flex flex-col gap-7">
            <div className="w-full bg-white grid gap-7">
                <InputField
                    labelColorClass="text-black"
                    name="subject"
                    widthInputClass="w-2/6"
                    value={ values["subject"] }
                    onChange={ onChange }
                    label="Subject"
                    required={true}
                />

                <InputTextarea
                    backgroundClass="bg-[#F6F7F8]"
                    labelColorClass="text-black"
                    gapClass="gap-3.5"
                    rows={10}
                    name="message"
                    widthInputClass="w-full"
                    value={ values["message"] }
                    onChange={ onChange }
                    label="Message"
                    required={true}
                />
            </div>

            <div className="flex justify-center align-center h-fit">
                <ButtonBase
                    onClick={() => {
                        addPopup(
                            <PopupBase
                                title={_("Thank you! We’ll get back to you as soon as possible.")}
                                footerButtons={
                                    [
                                        {
                                            label: _("Confirm and close"),
                                            background: "bg-black",
                                            color: "text-white",
                                            onClick: removePopup,
                                        }
                                    ]
                                }>
                            </PopupBase>
                        )
                    }}
                    className="bg-black text-white text-sm py-3.5 px-6 rounded-full"
                    iconColor="white"
                >
                    {_("Send this message")}
                </ButtonBase>
            </div>
        </div>
    );
}

export default LayoutContactForm;