import React from 'react';
import {Icons} from "../../../assets/Svgs";

function SwitchTeamView({toggle, setToggle}) {
    return (
        <label className="relative inline-flex items-center cursor-pointer h-10">
            <input type="checkbox" checked={toggle} onChange={() => { setToggle((prev) => !prev)} } className="sr-only peer" />
            <div className={`group peer bg-[#EEEFF2] rounded-full outline-none duration-700 w-24 h-full shadow-sm
                  peer-checked:bg-[#EEEFF2] peer-focus:outline-none after:rounded-full after:absolute after:bg-gray-50
                  after:outline-none after:h-full after:w-[3rem] after:top-0 after:left-0 after:duration-300
                  peer-checked:after:translate-x-12`}>
                <Icons.SvgTeamPreviewCard color={toggle ? "#9CA3AF" : "black" } />
                <Icons.SvgTeamPreviewTable color={toggle ? "black" : "#9CA3AF"} />
            </div>
        </label>
    );
}

export default SwitchTeamView;