import React from 'react';
import ButtonBase from "./ButtonBase";
import {Icons} from "../../assets/Svgs";
import {useTranslation} from "../../services/i18n/i18nService";
import ButtonFlexible from "./ButtonFlexible";

function ButtonBack({isFlexible, onClick}) {
    const { t: _ } = useTranslation();

    return <>
        {
            !isFlexible
                ? <ButtonBase onClick={onClick} className="bg-white text-black cursor-pointer py-3.5 px-6 rounded-full text-sm" iconColor="black" icon={ <Icons.SvgArrowReturnOutline className="h-3" /> }>
                    {_("Back")}
                </ButtonBase>
                : <ButtonFlexible onClick={onClick} icon={<Icons.SvgArrowReturnOutline />}>
                    {_("Back")}
                </ButtonFlexible>
        }
        </>
}

export default ButtonBack;