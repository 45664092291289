import React from 'react';

function SvgFooterStatistic({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.5 28.4375H2.5C1.9875 28.4375 1.5625 28.0125 1.5625 27.5C1.5625 26.9875 1.9875 26.5625 2.5 26.5625H27.5C28.0125 26.5625 28.4375 26.9875 28.4375 27.5C28.4375 28.0125 28.0125 28.4375 27.5 28.4375Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M17.8125 28.4375H12.1875C11.675 28.4375 11.25 28.0125 11.25 27.5V5C11.25 2.85 12.4375 1.5625 14.4375 1.5625H15.5625C17.5625 1.5625 18.75 2.85 18.75 5V27.5C18.75 28.0125 18.325 28.4375 17.8125 28.4375ZM13.125 26.5625H16.875V5C16.875 3.5625 16.2 3.4375 15.5625 3.4375H14.4375C13.8 3.4375 13.125 3.5625 13.125 5V26.5625Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M8.75 28.4375H3.75C3.2375 28.4375 2.8125 28.0125 2.8125 27.5V12.5C2.8125 10.35 3.9125 9.0625 5.75 9.0625H6.75C8.5875 9.0625 9.6875 10.35 9.6875 12.5V27.5C9.6875 28.0125 9.2625 28.4375 8.75 28.4375ZM4.6875 26.5625H7.8125V12.5C7.8125 10.9375 7.125 10.9375 6.75 10.9375H5.75C5.375 10.9375 4.6875 10.9375 4.6875 12.5V26.5625Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M26.25 28.4375H21.25C20.7375 28.4375 20.3125 28.0125 20.3125 27.5V18.75C20.3125 16.6 21.4125 15.3125 23.25 15.3125H24.25C26.0875 15.3125 27.1875 16.6 27.1875 18.75V27.5C27.1875 28.0125 26.7625 28.4375 26.25 28.4375ZM22.1875 26.5625H25.3125V18.75C25.3125 17.1875 24.625 17.1875 24.25 17.1875H23.25C22.875 17.1875 22.1875 17.1875 22.1875 18.75V26.5625Z" fill={!!color ? color : "#9CA3AF"}/>
        </svg>

    );
}

export default SvgFooterStatistic;