import React, {useState} from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupMobile from "../../../components/Popups/PopupMobile";
import {Icons} from "../../../assets/Svgs";
import PopupItemDelete from "./PopupItemDelete";
import WidgetWebsite from "../../../components/Widgets/WidgetWebsite";

function PopupWebsite({ mode, itemSelected, list, setList }) {
    const { clearAllPopups, addPopup } = usePopup();
    const { t: _ } = useTranslation();

    const initialValues = mode === 'edit'
        ? { ...itemSelected }
        : { id: 0, img: "", title: "", link: "", displayButtonDelete: false, isDisabled: false, order: 0 };

    const [websiteValues, setWebsiteValues] = useState(initialValues);

    const onChange = (e) => {
        setWebsiteValues({ ...websiteValues, [e.target.name]: e.target.value });
    }

    const handleSave = () => {
        if (mode === 'edit') {
            let pictureToUpdate = list.datas.find((data) => data.id === itemSelected.id)
            pictureToUpdate.title = websiteValues["title"]
            pictureToUpdate.link = websiteValues["link"]
            setList({...list, ["datas"]: list.datas})
        } else {
            let orderMax = Math.max(...list.datas.map(data => data.order), -1);

            list.datas.push({
                id: orderMax + 1,
                img: "",
                title: websiteValues["title"],
                link: websiteValues["link"],
                displayButtonDelete: false,
                isDisabled: false,
                order: orderMax + 1,
            })
            setList({...list, ["datas"]: list.datas})
        }
        clearAllPopups();
    }

    return (
        <PopupMobile
            cols
            hasBtnClose={true}
            icon={<Icons.SvgProfilePopupWeb />}
            title={mode === 'edit' ? _("Do you want to modify or delete this website ?") : _("Do you want to add a new website ?")}
            footerButtons={
                [
                    ...(mode === 'edit' ? [
                        {
                            label: _("Delete this website"),
                            background: "bg-[#EC0000]",
                            color: "text-white",
                            onClick: () => {
                                addPopup(<PopupItemDelete />)
                            }
                        }
                    ] : []),
                    {
                        label: mode === 'edit' ? _("Save the modifications") : _("Add this website"),
                        background: "bg-[#0400BD]",
                        color: "text-white",
                        onClick: handleSave,
                    }
                ]
            }>
            <WidgetWebsite
                classContainerList="flex flex-col gap-4 w-full"
                fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                widthInputClass="w-full"
                hasIcon={true}
                onChange={onChange}
                websiteValues={websiteValues}
                setWebsiteValues={setWebsiteValues}
            />
        </PopupMobile>
    );
}

export default PopupWebsite;