import React, {createContext, useContext, useState} from 'react';

export const LocaleContext = createContext(null);

export const LocaleProvider = ({children}) => {
    const [locale, setLocale] = useState(useContext(LocaleContext));

    return (
        <LocaleContext.Provider value={{locale, setLocale}}>
            {children}
        </LocaleContext.Provider>
    );
}

export default { LocaleProvider, LocaleContext };

