import { TOP_COMPLETE_MEDIAS_DATAS } from "../../constants/DatasConstants";

let topCompleteMedias = null;

export const getTopMedias = () => {
    return new Promise((resolve, reject) => {
        if (topCompleteMedias === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            //data template
            topCompleteMedias = TOP_COMPLETE_MEDIAS_DATAS;
            resolve(topCompleteMedias);
        } else {
            resolve(topCompleteMedias);
        }
    });
}

export default {
    getTopMedias,
};