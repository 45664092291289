import React from 'react';
import { Icons } from "../../assets/Svgs";

function TagMedias({social}, iconClassName) {
    switch (social) {
        case 'Twitter':
            return (
                <div className="flex items-center gap-[1.5rem]">
                    <Icons.SvgMediaTwitterFill className={`${!!iconClassName ? iconClassName : ""}`} />
                    <span className="truncate">{ social }</span>
                </div>
            )
        case 'YouTube':
            return (
                <div className="flex items-center gap-[1.5rem]">
                    <Icons.SvgMediaYoutubeFill className={`${!!iconClassName ? iconClassName : ""}`} />
                    <span className="truncate">{ social }</span>
                </div>
            )
        case 'LinkedIn':
            return (
                <div className="flex items-center gap-[1.5rem]">
                    <Icons.SvgMediaLinkedinFill className={`${!!iconClassName ? iconClassName : ""}`} />
                    <span className="truncate">{ social }</span>
                </div>
            )
        case 'WhatsApp':
            return (
                <div className="flex items-center gap-[1.5rem]">
                    <Icons.SvgMediaWhatsappFill className={`${!!iconClassName ? iconClassName : ""}`} />
                    <span className="truncate">{ social }</span>
                </div>
            )
        case 'Facebook':
            return (
                <div className="flex items-center gap-[1.5rem]">
                    <Icons.SvgMediaFacebookFill className={`${!!iconClassName ? iconClassName : ""}`} />
                    <span className="truncate">{ social }</span>
                </div>
            )
        default:
            return (
                <span className="capitalize py-1 px-2 rounded-[0.625rem] text-xs text-gray-600 bg-gray-100">
					{ social }
				</span>
            )
    }
}

export default TagMedias;