import APIService from "../APIService";

class WidgetService {

    static widgetTypes = null;
    static widgetTypesPromise = null;

    // Get all widget types
    static async getWidgetTypes() {
        if (!!this.widgetTypes) return this.widgetTypes;

        if (this.widgetTypesPromise) return this.widgetTypesPromise;

        this.widgetTypesPromise = APIService.apiCall("widgets/types/", "GET", null, true, {}, null)
            .then(async response => {
                if (!response.ok) throw new Error('Failed to fetch widget types');
                const data = await response.json();
                this.widgetTypes = data; // Cache data
                this.widgetTypesPromise = null; // Reset promise
                return data;
            })
            .catch(error => {
                this.widgetTypesPromise = null; // Reset promise in case of error
                throw error;
            });

        return this.widgetTypesPromise;
    }

    // Find a widget type by name
    static async findWidgetTypeByName(name) {
        const widgetTypes = await this.getWidgetTypes();
        return widgetTypes.find(wt => wt.name === name);
    }

    // Find a widget type by id
    static async findWidgetTypeById(id) {
        const widgetTypes = await this.getWidgetTypes();
        return widgetTypes.find(wt => wt.id === id);
    }

    // Get a single widget by ID
    static async getWidgetById(id) {
        const response = await APIService.apiCall(`widgets/${id}/`, "GET");
        if (!response.ok) throw new Error('Failed to fetch widget');
        const widget = await response.json();
        return widget;
    }

    // Create a new widget
    static async createWidget(widget) {
        const response = await APIService.apiCall("widgets/", "POST", widget, true, {});
        const data = await response.json();
        if (response.status !== 201) throw new Error('Failed to create widget');
        return data;
    }

    // Update an existing widget
    static async updateWidget(id, widget) {
        const response = await APIService.apiCall(
            `widgets/${id !== undefined ? id : widget.id}/`,
            id === undefined ? "PUT" : "PATCH",
            widget, true, {}
        );
        const data = await response.json();
        if (response.status !== 200) throw new Error('Failed to update widget');
        return data;
    }

    // Delete a widget
    static async deleteWidget(id) {
        const response = await APIService.apiCall(`widgets/${id}/`, "DELETE");
        if (response.status !== 204) throw new Error('Failed to delete widget');
    }

    static async createWidgetFile(blob, widgetId = null) {
        console.log(widgetId);
        const payload = widgetId
            ? { widget: widgetId, file: blob }
            : blob;
        const response = await APIService.apiCall("widgets/files/", "POST", payload);
        const data = await response.json();
        if (response.status !== 201) throw new Error('Failed to create widget file');
        return data;
    }

    static async deleteWidgetFile(id) {
        const response = await APIService.apiCall(`widgets/files/${id}/`, "DELETE");
        if (response.status !== 204) throw new Error('Failed to delete widget file');
    }

    static async createWidgetPicture(blob, widgetId = null) {
        const payload = widgetId
            ? { widget: widgetId, picture: blob }
            : blob;
        const response = await APIService.apiCall("widgets/pictures/", "POST", payload);
        const data = await response.json();
        if (response.status !== 201) throw new Error('Failed to create widget picture');
        return data;
    }

    static async deleteWidgetPicture(id) {
        const response = await APIService.apiCall(`widgets/pictures/${id}/`, "DELETE");
        if (response.status !== 204) throw new Error('Failed to delete widget picture');
    }

    static async defaultUpdateWidgetLambda(widget, _files, _pictures) {
        return widget;
    }

    static async createWidgetWithBlobs(widget, files = null, pictures = null, updateWidgetLambda = this.defaultUpdateWidgetLambda) {
        const widgetResponseData = await this.createWidget(widget);
        
        try {
            const filesLocalIds = Object.keys(files || {});
            const picturesLocalIds = Object.keys(pictures || {});

            const filesPromises = filesLocalIds.map((localId) => {
                return new Promise(async (resolve, reject) => {
                    const file = files[localId];
                    this.createWidgetFile(file, widgetResponseData.id)
                        .then((fileResponseData) => {
                            files[localId] = fileResponseData;
                            resolve();
                        })
                        .catch((error) => {
                            console.error(error);
                            reject(error);
                        });
                });
            });

            const picturesPromises = picturesLocalIds.map((localId) => {
                return new Promise(async (resolve, reject) => {
                    const picture = pictures[localId];
                    this.createWidgetPicture(picture, widgetResponseData.id)
                        .then((pictureResponseData) => {
                            pictures[localId] = pictureResponseData;
                            resolve();
                        })
                        .catch((error) => {
                            console.error(error);
                            reject(error);
                        });
                });
            });

            await Promise.all(filesPromises);
            await Promise.all(picturesPromises);

            const updatedWidget = updateWidgetLambda(widget, files, pictures);
            const finalWidget = await this.updateWidget(widgetResponseData.id, updatedWidget);

            return finalWidget;
        } catch (error) {
            console.error(error);
            await this.deleteWidget(widgetResponseData.id);
            throw new Error('Failed to create widget with blobs, widget deleted');
        }
    }

    static async updateWidgetWithBlobs(widget, files = null, pictures = null, updateWidgetLambda = this.defaultUpdateWidgetLambda) {
        const widgetId = widget.id;
        
        try {
            const filesLocalIds = Object.keys(files || {});
            const picturesLocalIds = Object.keys(pictures || {});

            const filesPromises = filesLocalIds.map((localId) => {
                return new Promise(async (resolve, reject) => {
                    const file = files[localId];
                    this.createWidgetFile(file, widgetId)
                        .then((fileResponseData) => {
                            files[localId] = fileResponseData;
                            resolve();
                        })
                        .catch((error) => {
                            console.error(error);
                            reject(error);
                        });
                });
            });

            const picturesPromises = picturesLocalIds.map((localId) => {
                return new Promise(async (resolve, reject) => {
                    const picture = pictures[localId];
                    this.createWidgetPicture(picture, widgetId)
                        .then((pictureResponseData) => {
                            pictures[localId] = pictureResponseData;
                            resolve();
                        })
                        .catch((error) => {
                            console.error(error);
                            reject(error);
                        });
                });
            });

            await Promise.all(filesPromises);
            await Promise.all(picturesPromises);

            const updatedWidget = updateWidgetLambda(widget, files, pictures);
            const finalWidget = await this.updateWidget(widgetId, updatedWidget);

            return finalWidget;
        } catch (error) {
            console.error(error);
            throw new Error('Failed to update widget with blobs');
        }
    }
}

// // test createWidgetWithBlobs
// const widget = {
//     portfolio: 2,
//     widget_type: 1,
//     order: 0,
//     data: {
//         title: "About me",
//         text: "We offer a wide selection of new and used vehicles.\n" +
//             "Our Maserati experts welcome you to meet all your expectations. The expertise of our mechanics, MASERATI certified, guarantees you a\n" +
//             "tailor-made service: mechanical workshop, bodywork and accessories.\n" +
//             "We remain at your disposal for further information.\n" +
//             "We look forward to welcoming you soon.",
//     },
// };

// const base64File = "data:text/txt;base64,SGVsbG8gV29ybGQ=";
// const base64Picture = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=";

// const files = {
//     1: base64File,
// };
// const pictures = {
//     1: base64Picture,
// };

// const finalWidget = await WidgetService.createWidgetWithBlobs(widget, files, pictures, (widget, files, pictures) => {
//     widget.data.title = widget.data.title + " : " + files[1].id + " - " + pictures[1].id;
//     return widget;
// });

// console.log(finalWidget);

export default WidgetService;
