import React from 'react';

function SvgFilterUserSearch({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4"} `} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00423 8.00212C9.84518 8.00212 11.3376 6.50973 11.3376 4.66878C11.3376 2.82783 9.84518 1.33545 8.00423 1.33545C6.16328 1.33545 4.6709 2.82783 4.6709 4.66878C4.6709 6.50973 6.16328 8.00212 8.00423 8.00212Z"
                stroke={!!color ? color : "#D1D5DB"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.27295 14.6667C2.27295 12.0867 4.83963 10 7.99963 10" stroke={!!color ? color : "#D1D5DB"} strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M12.1333 14.2667C13.3115 14.2667 14.2667 13.3115 14.2667 12.1333C14.2667 10.9551 13.3115 10 12.1333 10C10.9551 10 10 10.9551 10 12.1333C10 13.3115 10.9551 14.2667 12.1333 14.2667Z"
                stroke={!!color ? color : "#D1D5DB"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.6667 14.6667L14 14" stroke={!!color ? color : "#D1D5DB"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgFilterUserSearch;