import React from 'react';

function SvgSidebarProfylead({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.44196 5.02943C6.79062 5.02943 7.88392 3.93613 7.88392 2.58747C7.88392 1.23881 6.79062 0.145508 5.44196 0.145508C4.0933 0.145508 3 1.23881 3 2.58747C3 3.93613 4.0933 5.02943 5.44196 5.02943Z"
                fill={!!color ? color : "#4B5563"}/>
            <path
                d="M11.2935 5.02943C12.6422 5.02943 13.7355 3.93613 13.7355 2.58747C13.7355 1.23881 12.6422 0.145508 11.2935 0.145508C9.94486 0.145508 8.85156 1.23881 8.85156 2.58747C8.85156 3.93613 9.94486 5.02943 11.2935 5.02943Z"
                fill={!!color ? color : "#4B5563"}/>
            <path
                d="M5.44196 10.587C6.79062 10.587 7.88392 9.49374 7.88392 8.14509C7.88392 6.79643 6.79062 5.70312 5.44196 5.70312C4.0933 5.70312 3 6.79643 3 8.14509C3 9.49374 4.0933 10.587 5.44196 10.587Z"
                fill={!!color ? color : "#4B5563"}/>
            <path
                d="M11.2935 10.587C12.6422 10.587 13.7355 9.49374 13.7355 8.14509C13.7355 6.79643 12.6422 5.70312 11.2935 5.70312C9.94486 5.70312 8.85156 6.79643 8.85156 8.14509C8.85156 9.49374 9.94486 10.587 11.2935 10.587Z"
                fill={!!color ? color : "#4B5563"}/>
            <path
                d="M5.44196 16.1456C6.79062 16.1456 7.88392 15.0523 7.88392 13.7037C7.88392 12.355 6.79062 11.2617 5.44196 11.2617C4.0933 11.2617 3 12.355 3 13.7037C3 15.0523 4.0933 16.1456 5.44196 16.1456Z"
                fill={!!color ? color : "#4B5563"}/>
        </svg>
    );
}

export default SvgSidebarProfylead;