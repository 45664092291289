import { subMonths } from "date-fns";
import { useState } from 'react';
import { Icons } from "../../../assets/Svgs";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import PopupFilterByView from "../../../components/Popups/TemplateFilter/PopupFilterByView";
import PopupSelectorCalendar from "../../../components/Selectors/PopupSelectorCalendar";
import SelectorDate from "../../../components/Selectors/SelectorDate";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import { StatisticsService } from "../../../services/Statistics/StatisticsService";
import { ContactsDownloadedChartCard } from "./components/ContactsDownloadedChartCard";
import { LeadsGeneratedChartCard } from "./components/LeadsGeneratedChartCard";
import { ProfileViewsChartCard } from "./components/ProfileViewsChartCard";
import { TopPerfomersCard } from "./components/TopPerfomersCard";

export const Statistics = () => {
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();

    const [ selectedView, setSelectedView ] = useState("team");
    const [ selectedRange, setSelectedRange ] = useState({
        from: subMonths(new Date(), 1),
        to: new Date(),
    });
    
    return (
        <div className="flex flex-col h-screen">
            <header className=" z-10">
                <div className="p-7 flex justify-between">
                    <div className="text-[1.5rem] leading-6 font-medium">{_("Statistics")}</div>
                    <div className="flex items-center gap-7 text-sm">
                        <SelectorDate isFlexible={true} onClick={() => 
                            addPopup(<PopupSelectorCalendar initialRange={selectedRange} onConfirm={setSelectedRange} />)
                        } />
                        <ButtonBase
                            onClick={() => { addPopup(<PopupFilterByView selectedView={selectedView} onSelectedViewChange={setSelectedView} />) }}
                            className="bg-black text-white py-3.5 px-6 rounded-full"
                            iconColor="white"
                            icon={<Icons.SvgStatisticFilterOutline color="white" className="h-3" />}
                        >
                            {_("Filter")}
                        </ButtonBase>
                    </div>
                </div>
            </header>

            <div className="flex flex-wrap gap-7 h-auto p-7">
                <div className="flex gap-7 w-full">
                    <ProfileViewsChartCard
                        // className = {"flex-1"}
                        startDate = {selectedRange.from}
                        endDate = {selectedRange.to}
                    />
                    <ContactsDownloadedChartCard
                        // className = {"flex-1"}
                        startDate = {selectedRange.from}
                        endDate = {selectedRange.to}
                    />
                    <LeadsGeneratedChartCard
                        // className = {"flex-1"}
                        startDate = {selectedRange.from}
                        endDate = {selectedRange.to}
                    />
                </div>
                <div className="flex gap-7 w-full">
                    {/* <ViewsOverTime
                        row
                        datas={generalPerform[0].viewsOverTime}
                        value={generalPerform[0].value}
                        increase={generalPerform[0].increase}
                        title={_("Profile Views Over Time")}
                        displayViewSelector={true}
                    /> */}
                    <div className="flex-1"></div>
                    <TopPerfomersCard
                        className = {"flex-1"}
                        startDate = {selectedRange.from}
                        endDate = {selectedRange.to}
                    />
                </div>
                {/* <StatisTopWebsite datas={generalPerform[0].websites.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} />
                <StatisTopPictures datas={generalPerform[0].pictures.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5)} />
                <StatisTopMedias datas={generalPerform[0].medias.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} />
                <StatisTopFiles datas={generalPerform[0].downloads.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} />
                <StatisTopVideos datas={generalPerform[0].videos.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5)} />
                <StatisTopContacts datas={generalPerform[0].contacts.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} /> */}
            </div>
        </div>
    );
}

export default Statistics;