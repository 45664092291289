import React from 'react';
import SelectorIntergrationConnect from "./SelectorIntergrationConnect";
import {Icons} from "../../../assets/Svgs";
import {useTranslation} from "../../../services/i18n/i18nService";

function LayoutIntergration() {
    const { t: _ } = useTranslation();

    const icons = [
        {
            type: "Salesforce",
            icon: <Icons.SvgSettingsSalesForce className="h-[3.125rem]"/>
        },
        {
            type: "Outlook",
            icon: <Icons.SvgSettingsOutlook className="h-[3.125rem]"/>
        },
        {
            type: "Hubspot",
            icon: <Icons.SvgSettingsHubspot className="h-[3.125rem]"/>
        },
        {
            type: "Zoho",
            icon: <Icons.SvgSettingsZoho className="h-[3.125rem]"/>
        },
        {
            type: "Dynamics",
            icon: <Icons.SvgSettingsDynamics className="h-[3.125rem]"/>
        }
    ]

    return (
        <div className="xs:w-full xs:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-7">
            {
                !!icons ?
                    icons.map((item, index) => (
                        <SelectorIntergrationConnect
                            key={index}
                            displayButton={true}
                            // isSelected={ selectedView === "templates" }
                            // onClick={() => setSelectedView("templates")}
                            icon={item.icon}
                            viewName={_(item.type)}
                        />
                    ))
                    : null
            }
        </div>
    );
}

export default LayoutIntergration;