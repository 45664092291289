import { Outlet, useLocation } from "react-router-dom";
import { getFullPath, SUBDOMAIN, SUBDOMAINS } from '../../router/Router';
import Sidebar from "./Sidebar";

const Layout = () => {
    const { pathname } = useLocation();

    const displaySidebar = !([
        getFullPath(["ROOT", "LOGIN"]),
        getFullPath(["ROOT", "RESET_PASSWORD"]),
    ].includes(pathname));

    return (
        <div className="bg-neutral-100 h-screen w-screen overflow-hidden flex flex-row">
            {
                SUBDOMAIN.path === SUBDOMAINS.APP.path
                    ? null
                    : (displaySidebar ? <Sidebar /> : null)
            }

            <div className="flex flex-col flex-1 overflow-hidden">
                <Outlet />
            </div>
        </div>
    )
}

export default Layout;