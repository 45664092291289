import React, {useRef} from 'react';
import ButtonBase from "../Buttons/ButtonBase";
import {useTranslation} from "../../services/i18n/i18nService";
import {Icons} from "../../assets/Svgs";

function InputUploadDocument(
    {
        isAdmin,
        values,
        setValues,
        buttonInnerText,
        buttonStyleClass,
        isMobileAdd,
        isMobileEdit,
    }) {

    const { t: _ } = useTranslation();
    const hiddenFileInput = useRef(null);

    const onChangeFile = () => {
        hiddenFileInput.current.click();
    }

    const handleSelectFile = (event) => {
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            setValues({ ...values, ["title"]: event.target.value, ["link"]: fileReader.result, ["imgName"]: file.name });
        });
        fileReader.readAsDataURL(file);
    }

    return (
        <div className="flex flex-col gap-7 overflow-y-scroll">
            <label className="flex flex-col">
                <input
                    type="file"
                    className="hidden"
                    ref={ hiddenFileInput }
                    onChange={ handleSelectFile }
                    accept="image/*, application/*"
                />

                {
                    !!isAdmin && (
                        <div className="flex flex-col gap-7">
                            <div className={`flex flex-col align-center gap-1.5`}>
                                <p className="text-[0.688rem] font-medium text-[#9CA3AF]" >
                                    { values["imgName"] }
                                </p>
                                <ButtonBase
                                    onClick={ onChangeFile }
                                    className={`${!!buttonStyleClass ? buttonStyleClass : ""}`}
                                >
                                    {_(!!buttonInnerText ? buttonInnerText : "")}
                                </ButtonBase>
                            </div>
                        </div>
                    )
                }

                {
                    (!!isMobileEdit || !!isMobileAdd) && (!!values["link"]) && (!isAdmin)
                        ? <div className="flex flex-col gap-4">
                            <div onClick={ onChangeFile } className="flex flex-col bg-[#D6D6FF] rounded-lg p-2.5 items-center justify-center gap-2.5">
                                <Icons.SvgProfileEditDoc className="h-8" color="#0400BD" />

                                <div className="text-[0.625rem] font-medium leading-7 text-[#0400BD] truncate xs:w-full w-80">
                                    { values["link"] }
                                </div>
                            </div>

                            <div className="w-full flex flex-col justify-center align-center gap-7">
                                <ButtonBase onClick={ onChangeFile } className={`w-full rounded-lg py-2.5 px-[1.125rem] bg-[#0400BD] text-white`}>
                                    {_(!!buttonInnerText ? buttonInnerText : "")}
                                </ButtonBase>
                            </div>
                        </div>
                        : (!isAdmin)
                            ? <div onClick={ onChangeFile } className="flex flex-col bg-[#0400BD] rounded-lg p-2.5 items-center justify-center gap-2.5">
                                <Icons.SvgProfileEditDoc className="h-8" />
                                <div className="text-[1rem] font-medium leading-7 text-white">
                                    {_(!!buttonInnerText ? buttonInnerText : "")}
                                </div>
                            </div>
                            : null
                }
            </label>
        </div>
    );
}

export default InputUploadDocument;