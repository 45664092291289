import React from 'react';

function SvgProfileAddSection({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.27 11.25H16.23C14.22 11.25 13.25 10.27 13.25 8.27V4.23C13.25 2.22 14.23 1.25 16.23 1.25H20.27C22.28 1.25 23.25 2.23 23.25 4.23V8.27C23.25 10.27 22.27 11.25 20.27 11.25ZM16.23 2.75C15.05 2.75 14.75 3.05 14.75 4.23V8.27C14.75 9.45 15.05 9.75 16.23 9.75H20.27C21.45 9.75 21.75 9.45 21.75 8.27V4.23C21.75 3.05 21.45 2.75 20.27 2.75H16.23Z" fill={!!color ? color : "white"}/>
            <path d="M8.77 11.25H4.73C2.72 11.25 1.75 10.36 1.75 8.52V3.98C1.75 2.14 2.73 1.25 4.73 1.25H8.77C10.78 1.25 11.75 2.14 11.75 3.98V8.51C11.75 10.36 10.77 11.25 8.77 11.25ZM4.73 2.75C3.39 2.75 3.25 3.13 3.25 3.98V8.51C3.25 9.37 3.39 9.74 4.73 9.74H8.77C10.11 9.74 10.25 9.36 10.25 8.51V3.98C10.25 3.12 10.11 2.75 8.77 2.75H4.73Z" fill={!!color ? color : "white"}/>
            <path d="M8.77 22.75H4.73C2.72 22.75 1.75 21.77 1.75 19.77V15.73C1.75 13.72 2.73 12.75 4.73 12.75H8.77C10.78 12.75 11.75 13.73 11.75 15.73V19.77C11.75 21.77 10.77 22.75 8.77 22.75ZM4.73 14.25C3.55 14.25 3.25 14.55 3.25 15.73V19.77C3.25 20.95 3.55 21.25 4.73 21.25H8.77C9.95 21.25 10.25 20.95 10.25 19.77V15.73C10.25 14.55 9.95 14.25 8.77 14.25H4.73Z" fill={!!color ? color : "white"}/>
            <path d="M21 18.25H15C14.59 18.25 14.25 17.91 14.25 17.5C14.25 17.09 14.59 16.75 15 16.75H21C21.41 16.75 21.75 17.09 21.75 17.5C21.75 17.91 21.41 18.25 21 18.25Z" fill={!!color ? color : "white"}/>
            <path d="M18 21.25C17.59 21.25 17.25 20.91 17.25 20.5V14.5C17.25 14.09 17.59 13.75 18 13.75C18.41 13.75 18.75 14.09 18.75 14.5V20.5C18.75 20.91 18.41 21.25 18 21.25Z" fill={!!color ? color : "white"}/>
        </svg>
    );
}

export default SvgProfileAddSection;