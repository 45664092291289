import React, { useImperativeHandle, useState } from 'react';
import { useParams } from "react-router-dom";
import { WIDGET_FIELD_TYPES } from '../../../constants/Widgets';
import { useTranslation } from "../../../services/i18n/i18nService";
import LeadsService from '../../../services/Leads/LeadsService';
import { usePopup } from '../../../services/PopupService';
import ButtonBase from "../../Buttons/ButtonBase";
import SelectField from '../../Inputs/SelectField';
import TextField from '../../Inputs/TextField';
import PopupBase from '../../Popups/PopupBase';

const WidgetFormView = React.forwardRef(({
    widget,
    pageUri,
}, ref) => {
    const { t: _ } = useTranslation();
    const { addPopup, removePopup } = usePopup();
    
    useImperativeHandle(ref, () => {
        return {
            handleOpen,
            handleClose,
        }
    });

    const handleSubmitForm = pageUri
        ? (answers) => {
                LeadsService.createLeadFromForm({
                    page_uri: pageUri,
                    lead_creation_form_widget: widget.id,
                    lead_data: answers,
                })
                    .then(lead => {
                        console.log(lead);
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        : undefined;

    const handleOpen = () => {
        addPopup(<WidgetFormPopup widget={widget} onSubmitForm={handleSubmitForm} />);
    }

    const handleClose = () => {
        removePopup();
    }

    return (
        <ButtonBase onClick={handleOpen} className="text-[1rem] leading-6 font-medium bg-black text-white py-3 px-4 rounded-full">
            <div className="truncate w-64 xs:w-full">{widget.data.ctaLabel}</div>
        </ButtonBase>
    );
});

const WidgetFormPopup = ({
    widget,
    onSubmitForm,
}) => {
    /* Hooks */
    const { t: _ } = useTranslation();

    /* States */
    const [answers, setAnswers] = useState([]);

    /* Handlers */
    const handleAnswerChange = (key, newAnswer) => {
        console.log(key, newAnswer);
        setAnswers(prevAnswers => {
            let updatedAnswers = [...prevAnswers];
            const answerIndex = updatedAnswers.findIndex(a => a.key === key);
            if (answerIndex === -1) {
                updatedAnswers.push(newAnswer);
            } else {
                updatedAnswers[answerIndex] = newAnswer;
            }
            return updatedAnswers;
        });
    }

    /* Renderers */
    const renderFormField = ({field, answer, setAnswer, ...rest}) => {
        const isMandatoryField = field.required == null ? true : !!field.required;

        const defaultFormFieldRenderer = () => {
        }

        const formFieldRenderers = {
            [WIDGET_FIELD_TYPES.PHONE]: () => {
                return <TextField
                    type="text"
                    name={field.key}
                    label={field.details?.label || ""}
                    placeholder={field.details?.label || ""}
                    value={answer?.value || ""}
                    onChange={(e) => {
                        setAnswer({
                            key: field.key,
                            value: e.target.value,
                        });
                    }}
                    required={isMandatoryField}
                    {...rest}
                />
            },
            [WIDGET_FIELD_TYPES.TEXT]: () => {
                return <TextField
                    type="text"
                    name={field.key}
                    label={field.details?.label || ""}
                    placeholder={field.details?.label || ""}
                    value={answer?.value || ""}
                    onChange={(e) => {
                        setAnswer({
                            key: field.key,
                            value: e.target.value,
                        });
                    }}
                    required={isMandatoryField}
                    {...rest}
                />
            },
            [WIDGET_FIELD_TYPES.MAIL]: () => {
                return <TextField
                    type="text"
                    pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                    errorMsg={_("Please enter a valid email address")}
                    name={field.key}
                    label={field.details?.label || ""}
                    placeholder={field.details?.label || ""}
                    value={answer?.value || ""}
                    onChange={(e) => {
                        setAnswer({
                            key: field.key,
                            value: e.target.value,
                        });
                    }}
                    required={isMandatoryField}
                    {...rest}
                />
            },
            [WIDGET_FIELD_TYPES.SELECT]: () => {
                if (!answer && isMandatoryField && field.details?.options?.length > 0) {
                    setAnswer({
                        key: field.key,
                        value: field.details.options.sort((a, b) => a.order - b.order)[0].label,
                    });
                }
                const orderedOptions = [
                    ...(!isMandatoryField ? [{ value: "", content: "-" }] : []),
                    ...(field.details?.options?.sort((a, b) => a.order - b.order).map(option => ({
                        value: option.value,
                        content: option.label,
                    })) || [])
                ]
                return <SelectField
                    label={field.details?.label || ""}
                    options={orderedOptions}
                    onChange={(e) => {
                        setAnswer({
                            key: field.key,
                            value: orderedOptions.find(o => o.value == e.target.value)?.content ?? "",
                        });
                    }}
                    required={isMandatoryField}
                    {...rest}
                />
            },
        }

        return formFieldRenderers[field.type]
            ? formFieldRenderers[field.type]()
            : defaultFormFieldRenderer();
    }

    return (
        <PopupBase
            title={widget.data.title}
            footerButtons={
                [
                    {
                        label: _("Send"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-fit text-sm rounded-full cursor-pointer px-5 py-2",
                        onClick: () => {
                            onSubmitForm(answers);
                        }
                    }
                ].filter(_ => widget.id != null && !!onSubmitForm)
            }
        >
            <div className="flex flex-col gap-3.5">
                {
                    widget.data.form.sort((a, b) => a.order - b.order).map((field, _index) => {
                        const answer = answers.find(answer => answer.key === field.key);
                        return renderFormField({
                            field,
                            answer,
                            setAnswer: (newAnswer) => {
                                handleAnswerChange(field.key, newAnswer);
                            },
                            key: field.key
                        })
                    })
                }
            </div>
        </PopupBase>
    );
}

export default WidgetFormView;