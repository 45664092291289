import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import { Icons } from "../../../assets/Svgs";
import { useTranslation } from "../../../services/i18n/i18nService";
import CustomersService from "../../../services/Customer/CustomersService";
import {format} from "date-fns";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import Table from "../../../components/Layouts/Table";
import ButtonViewDetails from "../../../components/Buttons/ButtonViewDetails";
import UserService from '../../../services/User/UserService';

function CustomerAccounts() {
    const { t: _ } = useTranslation();
    const [ listAccount, setListAccount ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const { custom } = useParams();

    useEffect(() => {
        UserService.getUsers()
            .then(accounts => {
                setListAccount(accounts.filter(account => account.membership && account.membership.company.toString() === custom.toString()));
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [custom]);

    return (
        <div className="flex flex-col h-screen p-7 gap-7">
            <header className=" flex justify-between items-center">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={ getFullPath(["ROOT", "CUSTOMERS"]) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Customers")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    <Link to={ getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER"]).replace(":custom", custom) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("View")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("Accounts")}

                </div>

                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER"]).replace(":custom", custom) } className="flex justify-center gap-[0.438rem] text-sm">
                        <ButtonBack isFlexible={false}/>
                    </Link>

                    <Link to={
                        getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER", "CREATE_ACCOUNT"])
                            .replace(":custom", custom)
                    } className="flex justify-center">
                        <ButtonBase className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={ <Icons.SvgUserAddOutline className="h-3" /> } >
                            {_("Create a new Account")}
                        </ButtonBase>
                    </Link>
                </div>
            </header>

            <div className="flex-1 overflow-auto">
                {
                    <Table
                        displayHeaders={true}
                        columns={[
                            {
                                label: _("Account Name"),
                                sortable: false,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Role"),
                                sortable: false,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Created Date"),
                                sortable: false,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("View"),
                                sortable: false,
                            },
                        ]}
                        rows={ listAccount.map((account) =>
                            ({
                                data: [
                                    {
                                        value: <div className="grid gap-[0.4rem]">
                                            <p className="text-[1rem] leading-4 font-semibold">{ account.first_name } { account.last_name }</p>
                                        </div>,
                                    },
                                    {
                                        value: <div className="flex items-center justify-center gap-[1.5rem]">
                                            <p className="text-[0.813rem] leading-3 font-medium">{ account.membership.job_title }</p>
                                        </div>,
                                    },
                                    {
                                        value: <div className="flex flex-col items-center justify-center gap-[0.438rem] text-[#4B5563]">
                                            <p>
                                                { format(new Date(account.date_joined), 'dd/MM/yyyy') }
                                            </p>
                                        </div>,
                                    },
                                    {
                                        value: <Link to={
                                            getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER", "VIEW_ACCOUNT"]).replace(":custom", custom).replace(":acc", account.id) }
                                                        className="flex justify-center gap-[0.438rem]">
                                            <ButtonViewDetails />
                                        </Link>,
                                    },
                                ]
                            })
                        )}
                    />
                }

            </div>
        </div>
    );
}

export default CustomerAccounts;