import React from 'react';
import {WIDGET_TYPES} from "../../../constants/Widgets";
import PopupSocialMedia from "./PopupSocialMedia";
import PopupPicture from "./PopupPicture";
import PopupDocument from "./PopupDocument";
import PopupWebsite from "./PopupWebsite";
import PopupCta from "./PopupCta";

const defaultOnSave = () => {
    // Call the API to save the widget
}

const defaultOnDelete = () => {
    // Call the API to delete the widget
}

const popupsMap = {
    [WIDGET_TYPES.SOCIAL_MEDIA]: PopupSocialMedia,
    [WIDGET_TYPES.PICTURE]: PopupPicture,
    [WIDGET_TYPES.DOCUMENT]: PopupDocument,
    [WIDGET_TYPES.WEBSITE]: PopupWebsite,
    [WIDGET_TYPES.CONTACT]: PopupCta,
    [WIDGET_TYPES.CTA]: PopupCta,
}

function PopupEdit(
    {
        widget,
        widgetType,
        mode,
        itemSelected,
        list,
        setList,
    }) {

    return (
        <>
            {React.createElement(popupsMap[!!widget ? widget.type : widgetType], {
                mode,
                itemSelected,
                list,
                setList,
            })}
        </>
    );
}

export default PopupEdit;