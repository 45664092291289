import { CONTRACTS_DATAS, FILES_CONTRACTS_DATAS } from "../../constants/DatasConstants";

let contracts = null;
let listFiles = null;

export const getContracts = () => {
    return new Promise((resolve, reject) => {
        if (contracts === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            //data template
            contracts = CONTRACTS_DATAS;
            resolve(contracts);
        } else {
            resolve(contracts);
        }
    });
}

export const getContractById = (id) => {
    return new Promise((resolve, reject) => {
        if ((contracts === null)) {
            getContracts()
                .then(resContracts => {
                    const contract = resContracts.find((item) => item.id.toString() === id.toString());
                    if(contract){
                        resolve(contract)
                    }else{
                        reject(new Error("Contract not found"));
                    }
                })
                .catch(err => reject(err))
        } else {
            const contract = contracts.find((item) => item.id.toString() === id.toString());
            if(contract){
                resolve(contract)
            }else{
                reject(new Error("Contract not found"));
            }
        }
    });
}

export const getFiles = () => {
    return new Promise((resolve, reject) => {
        if (listFiles === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            //data template
            listFiles = FILES_CONTRACTS_DATAS
            resolve(listFiles)
        } else {
            resolve(listFiles);
        }
    });
}

export const getFileById = (idListFiles) => {
    return new Promise((resolve, reject) => {
        if ((listFiles === null)) {
            getFiles()
                .then(resListFiles => {
                    const files = resListFiles.find((item) => item.id.toString() === idListFiles.toString());
                    if(files){
                        resolve(files)
                    }else{
                        reject(new Error("ContractFiles not found"));
                    }
                })
                .catch(err => reject(err))
        } else {
            const files = listFiles.find((item) => item.id.toString() === idListFiles.toString());
            if(files){
                resolve(files)
            }else{
                reject(new Error("ContractFiles not found"));
            }
        }
    });
}

export default {
    getContracts,
    getContractById,
    getFiles,
    getFileById,
};