import React from 'react';

function SvgProfileModelContactLocation({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-5" } `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.9999 11.8084C8.2249 11.8084 6.7749 10.3667 6.7749 8.58337C6.7749 6.80003 8.2249 5.3667 9.9999 5.3667C11.7749 5.3667 13.2249 6.80837 13.2249 8.5917C13.2249 10.375 11.7749 11.8084 9.9999 11.8084ZM9.9999 6.6167C8.91657 6.6167 8.0249 7.50003 8.0249 8.5917C8.0249 9.68337 8.90824 10.5667 9.9999 10.5667C11.0916 10.5667 11.9749 9.68337 11.9749 8.5917C11.9749 7.50003 11.0832 6.6167 9.9999 6.6167Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M9.9999 18.9667C8.76657 18.9667 7.5249 18.5001 6.55824 17.5751C4.0999 15.2084 1.38324 11.4334 2.40824 6.94175C3.33324 2.86675 6.89157 1.04175 9.9999 1.04175C9.9999 1.04175 9.9999 1.04175 10.0082 1.04175C13.1166 1.04175 16.6749 2.86675 17.5999 6.95008C18.6166 11.4417 15.8999 15.2084 13.4416 17.5751C12.4749 18.5001 11.2332 18.9667 9.9999 18.9667ZM9.9999 2.29175C7.5749 2.29175 4.45824 3.58341 3.63324 7.21675C2.73324 11.1417 5.1999 14.5251 7.43324 16.6667C8.8749 18.0584 11.1332 18.0584 12.5749 16.6667C14.7999 14.5251 17.2666 11.1417 16.3832 7.21675C15.5499 3.58341 12.4249 2.29175 9.9999 2.29175Z" fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgProfileModelContactLocation;