import React, {useEffect} from 'react';
import {Icons} from "../../../assets/Svgs";

function PortfolioMoveUpDown({values, setValues, item, index, handleEdit}) {

    const getMinOrderReorganize = () => {
        let orderMin = Number.MAX_VALUE;

        if(!!values){
            values.map((data) => {
                if(data.order < orderMin){
                    orderMin = data.order;
                }
            })
        }
        return orderMin
    }

    const getMaxOrderReorganize = () => {
        let orderMax = -1;

        if(!!values){
            values.map((data) => {
                if(data.order > orderMax){
                    orderMax = data.order;
                }
            })
        }
        return orderMax
    }

    const moveUpReorganize = (index, mediaCurrent) => {
        if(index !== 0) {
            const prev = values[index - 1]
            mediaCurrent.order = mediaCurrent.order + prev.order
            prev.order = mediaCurrent.order - prev.order
            mediaCurrent.order = mediaCurrent.order - prev.order
            values = values.sort((a, b) => (a.order > b.order) ? 1 : -1)
            setValues([...values]);
        }
    }

    const moveDownReorganize = (index, mediaCurrent) => {
        let indexMax = values.length - 1
        if(index !== indexMax) {
            const next = values[index + 1]
            mediaCurrent.order = mediaCurrent.order + next.order
            next.order = mediaCurrent.order - next.order
            mediaCurrent.order = mediaCurrent.order - next.order
            values = values.sort((a, b) => (a.order > b.order) ? 1 : -1)
            setValues([...values]);
        }
    }

    return (
        <div className="flex gap-3 items-center">
            <div onClick={() => moveUpReorganize(index, item)}>
                <Icons.SvgProfileEditArrowSquareUp color={`${ item.order === getMinOrderReorganize() ? "#EEEFF2" : "#0400BD"}`} />
            </div>

            <div onClick={() => moveDownReorganize(index, item)}>
                <Icons.SvgProfileEditArrowSquareDown color={`${ item.order === getMaxOrderReorganize() ? "#EEEFF2" : "#0400BD"}`} />
            </div>

            {
                !!handleEdit
                    ? <div onClick={ handleEdit } >
                        <Icons.SvgProfileEditArrowSquare />
                    </div>
                    : null
            }
        </div>
    );
}

export default PortfolioMoveUpDown;