import React from 'react';
import { Icons } from "../../../../assets/Svgs";
import { Link } from "react-router-dom";
import { getFullPath } from "../../../../router/Router";
import { useTranslation } from "../../../../services/i18n/i18nService";
import Table from "../../../../components/Layouts/Table";

function StatisTopPictures({ datas }) {
    const { t: _ } = useTranslation();

    return (
        <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm w-full">
            <header className="flex justify-between items-center">
                <div className="text-sm flex gap-1.5 items-center">
                    <p>{_("Top 5 Viewed Pictures")}</p>
                    <Icons.SvgStatisticEyeFill />
                </div>

                <Link to={getFullPath(["ROOT", "STATISTICS", "PICTURES"])} className="flex justify-center gap-2">
                    <div className="text-sm flex gap-1 items-center hover:underline hover:select-all">
                        {_("View")}
                        <Icons.SvgStatisticCirclePlusFill color="#292D32" />
                    </div>
                </Link>
            </header>

            <div className="w-full">
                <Table
                    displayHeaders={false}
                    bgColorClass="bg-white"
                    columns={[{}, {}, {}]}
                    rows={datas.map((perform, index) => ({
                        data: [
                            {
                                value: <div className="flex justify-center items-center">{index + 1}</div>,
                            },
                            {
                                value: (
                                    <div className="flex gap-2">
                                        <img src={perform.src} className="rounded-sm h-6 w-6" alt="picture" />
                                        <div>{perform.picture}</div>
                                    </div>
                                ),
                            },
                            {
                                value: <div className="flex justify-center items-center">{perform.views}</div>,
                            },
                        ],
                    }))}
                />
            </div>
        </div>
    );
}

export default StatisTopPictures;