import React from 'react';
import {Icons} from "../../../../assets/Svgs";
import PopupMobile from "../../../../components/Popups/PopupMobile";
import {useTranslation} from "../../../../services/i18n/i18nService";
import {usePopup} from "../../../../services/PopupService";

function PopupStickerDelete({tags, setTags, tagSelected}) {
    const { t: _ } = useTranslation();
    const { clearAllPopups } = usePopup();

    return (
        <PopupMobile
            gapClass="gap-0"
            hasBtnClose={true}
            icon={<Icons.SvgProfilePopupDelete />}
            title={_("Are you sure that you want to delete this sticker?")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "border border-[#D1D5DB]",
                        onClick: () => {
                            clearAllPopups()
                        }
                    },
                    {
                        label: _("Delete"),
                        background: "bg-[#EC0000]",
                        color: "text-white",
                        onClick: () => {
                            let tagsUpdated = tags.filter(tag => tag.id !== tagSelected.id)
                            setTags(tagsUpdated)
                            clearAllPopups()
                        }
                    }
                ]
            }>
        </PopupMobile>
    );
}

export default PopupStickerDelete;