import React from 'react';

function SvgStatisticFileDownload(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M11.957 5.94352H10.2712C8.8887 5.94352 7.76287 4.81768 7.76287 3.43518V1.74935C7.76287 1.42852 7.50037 1.16602 7.17953 1.16602H4.7062C2.90953 1.16602 1.45703 2.33268 1.45703 4.41518V9.58352C1.45703 11.666 2.90953 12.8327 4.7062 12.8327H9.2912C11.0879 12.8327 12.5404 11.666 12.5404 9.58352V6.52685C12.5404 6.20602 12.2779 5.94352 11.957 5.94352Z" fill={props.color || "#292D32"}/>
        <path d="M9.21583 1.28945C8.97667 1.05028 8.5625 1.21361 8.5625 1.54611V3.58195C8.5625 4.43361 9.28583 5.13945 10.1667 5.13945C10.7208 5.14528 11.4908 5.14528 12.15 5.14528C12.4825 5.14528 12.6575 4.75445 12.4242 4.52111C11.5842 3.67528 10.0792 2.15278 9.21583 1.28945Z" fill={props.color || "#292D32"}/>
        <path d="M7.16448 8.58667C6.99531 8.4175 6.71531 8.4175 6.54615 8.58667L6.12615 9.00667V6.5625C6.12615 6.32333 5.92781 6.125 5.68865 6.125C5.44948 6.125 5.25115 6.32333 5.25115 6.5625V9.00667L4.83115 8.58667C4.66198 8.4175 4.38198 8.4175 4.21281 8.58667C4.04365 8.75583 4.04365 9.03583 4.21281 9.205L5.37948 10.3717C5.38531 10.3775 5.39115 10.3775 5.39115 10.3833C5.42615 10.4183 5.47281 10.4475 5.51948 10.4708C5.57781 10.4883 5.63031 10.5 5.68865 10.5C5.74698 10.5 5.79948 10.4883 5.85198 10.465C5.90448 10.4417 5.95115 10.4125 5.99781 10.3717L7.16448 9.205C7.33365 9.03583 7.33365 8.75583 7.16448 8.58667Z" fill={props.color || "#292D32"}/>
    </svg>
}

export default SvgStatisticFileDownload;