import React from 'react';

function SvgTemplateFormSex(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M45.149 38.5866L34.0949 23.8574H17.7324C14.9324 23.8574 13.5324 27.2408 15.5157 29.2241L30.624 44.3324C33.0449 46.7533 36.9824 46.7533 39.4032 44.3324L45.149 38.5866Z" fill="#373742"/>
            <path d="M52.2646 23.8574H34.0938L45.1479 38.5866L54.5104 29.2241C56.4646 27.2408 55.0646 23.8574 52.2646 23.8574Z" fill="#373742"/>
        </svg>
    );
}

export default SvgTemplateFormSex;