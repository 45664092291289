import React from 'react';

function SvgProfileModelContactMsg({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-5" } `} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.8436 19.9027C10.2686 19.9027 9.72692 19.611 9.34359 19.1027L8.09359 17.436C8.06859 17.4027 7.96859 17.361 7.92692 17.3527H7.51025C4.03525 17.3527 1.88525 16.411 1.88525 11.7277V7.56104C1.88525 3.8777 3.82692 1.93604 7.51025 1.93604H14.1769C17.8603 1.93604 19.8019 3.8777 19.8019 7.56104V11.7277C19.8019 15.411 17.8603 17.3527 14.1769 17.3527H13.7603C13.6936 17.3527 13.6353 17.386 13.5936 17.436L12.3436 19.1027C11.9603 19.611 11.4186 19.9027 10.8436 19.9027ZM7.51025 3.18604C4.52692 3.18604 3.13525 4.5777 3.13525 7.56104V11.7277C3.13525 15.4944 4.42692 16.1027 7.51025 16.1027H7.92692C8.35192 16.1027 8.83525 16.3444 9.09359 16.686L10.3436 18.3527C10.6353 18.736 11.0519 18.736 11.3436 18.3527L12.5936 16.686C12.8686 16.3194 13.3019 16.1027 13.7603 16.1027H14.1769C17.1603 16.1027 18.5519 14.711 18.5519 11.7277V7.56104C18.5519 4.5777 17.1603 3.18604 14.1769 3.18604H7.51025Z"
                fill={!!color ? color : "#9CA3AF"}/>
            <path
                d="M10.8436 10.8942C10.3769 10.8942 10.0103 10.5192 10.0103 10.0609C10.0103 9.60254 10.3853 9.22754 10.8436 9.22754C11.3019 9.22754 11.6769 9.60254 11.6769 10.0609C11.6769 10.5192 11.3103 10.8942 10.8436 10.8942Z"
                fill={!!color ? color : "#9CA3AF"}/>
            <path
                d="M14.1768 10.8942C13.7102 10.8942 13.3435 10.5192 13.3435 10.0609C13.3435 9.60254 13.7185 9.22754 14.1768 9.22754C14.6352 9.22754 15.0102 9.60254 15.0102 10.0609C15.0102 10.5192 14.6435 10.8942 14.1768 10.8942Z"
                fill={!!color ? color : "#9CA3AF"}/>
            <path
                d="M7.51009 10.8942C7.04342 10.8942 6.67676 10.5192 6.67676 10.0609C6.67676 9.60254 7.05176 9.22754 7.51009 9.22754C7.96842 9.22754 8.34342 9.60254 8.34342 10.0609C8.34342 10.5192 7.97676 10.8942 7.51009 10.8942Z"
                fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgProfileModelContactMsg;