import React from 'react';
import { Icons } from "../../assets/Svgs";
import ButtonBase from "./ButtonBase";
import {useTranslation} from "../../services/i18n/i18nService";

function ButtonViewDetails() {
    const { t: _ } = useTranslation();

    return (
        <ButtonBase className="text-sm leading-3 md:leading-4 bg-[#D1D5DB] text-[#4B5563] hover:bg-[#9CA3AF] hover:text-[#000000] px-2 py-1 gap-[0.438rem] shadow-sm flex justify-center items-center rounded-full cursor-pointer">
            <Icons.SvgSearchView className="h-3 md:h-4" color="#4B5563"/>
            {_("View")}
        </ButtonBase>
    );
}

export default ButtonViewDetails;