import React from 'react';
import { usePopup } from '../../services/PopupService';

function PopupLayout({className, title, children, footerButtons, rows}) {
    const { removePopup, queue } = usePopup();

    return (
        <>
            {
                queue.length > 0 && 
                <div
                    className={`fixed inset-0 bg-black bg-opacity-50 z-20 ${className || ''}`}
                >
                    {queue.map(({ component: Component, params }, idx) => {
                        const isActive = idx === queue.length - 1;

                        return (
                            <div
                                key={idx}
                                className={`flex w-full h-full items-center justify-center ${isActive ? 'block' : 'hidden'}`}
                                onClick={(e) => {
                                    if (params.closeOnDarkAreaClick && e.target === e.currentTarget) {
                                        removePopup();
                                    }
                                }}
                            >
                                {Component}
                            </div>
                        );
                    })}
                </div>
            }
        </>
    );
}

export default PopupLayout;