import React from 'react';
import { Icons } from "../../assets/Svgs";

function TagTypeContact({contact}) {
    switch (contact) {
        case 'Calling':
            return (
                <div className="flex items-center gap-[1.5rem]">
                    <Icons.SvgContactCallFill />
                    <span className="truncate">{ contact }</span>
                </div>
            )
        case 'Address':
            return (
                <div className="flex items-center gap-[1.5rem]">
                    <Icons.SvgContactLocationFill />
                    <span className="truncate">{ contact }</span>
                </div>
            )
        case 'Mailing':
            return (
                <div className="flex items-center gap-[1.5rem]">
                    <Icons.SvgContactSmsFill />
                    <span className="truncate">{ contact }</span>
                </div>
            )
        case 'Messaging':
            return (
                <div className="flex items-center gap-[1.5rem]">
                    <Icons.SvgContactMessageFill />
                    <span className="truncate">{ contact }</span>
                </div>
            )
        case 'Website':
            return (
                <div className="flex items-center gap-[1.5rem]">
                    <Icons.SvgContactGlobalFill />
                    <span className="truncate">{ contact }</span>
                </div>
            )
        default:
            return (
                <span className="capitalize py-1 px-2 rounded-[0.625rem] text-xs text-gray-600 bg-gray-100">
					{ contact }
				</span>
            )
    }
}

export default TagTypeContact;