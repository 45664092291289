import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import { Icons } from "../../../assets/Svgs";
import { useTranslation } from "../../../services/i18n/i18nService";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import InputField from "../../../components/Layouts/InputField";
import InputTextarea from "../../../components/Layouts/InputTextarea";
import userService from '../../../services/User/UserService';

function AccountCreate() {
    const { t: _ } = useTranslation();
    const { custom } = useParams();
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        note: '',
    });

    const cols = [
        {
            inputs: [
                {
                    id: 1,
                    name: "firstName",
                    type: "text",
                    placeholder: "",
                    label: "First Name",
                    isTextarea: false,
                },
                {
                    id: 3,
                    name: "email",
                    type: "text",
                    placeholder: "",
                    label: "Business Mail",
                    isTextarea: false,
                },
                {
                    id: 5,
                    name: "role",
                    type: "text",
                    placeholder: "",
                    label: "Role",
                    isTextarea: false,
                },
            ]
        },
        {
            inputs: [
                {
                    id: 2,
                    name: "lastName",
                    type: "text",
                    placeholder: "",
                    label: "Last Name",
                    isTextarea: false,
                },
                {
                    id: 7,
                    name: "note",
                    type: "text",
                    placeholder: "",
                    label: "Note",
                    isTextarea: true,
                }
            ]
        }
    ]

    const formSubmit = (e) => {
        e.preventDefault();
        const payload = {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            role: values.role,
            note: values.note,
            info: [],
        }

        userService.createUserInCompany(payload, custom)
        .then(() => {
            console.log("User created successfully");
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <div className="flex flex-col h-screen p-7 gap-7">
            <header className=" flex justify-between items-center">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={getFullPath(["ROOT", "CUSTOMERS"])} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Customers")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    <Link to={getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER"]).replace(":custom", custom)} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("View")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    <Link to={getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER"]).replace(":custom", custom)} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Accounts")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("Create")}

                </div>

                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER"]).replace(":custom", custom)} className="flex text-sm justify-center">
                        <ButtonBack isFlexible={false} />
                    </Link>

                    <ButtonBase className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={<Icons.SvgUserAddOutline className="h-3" />} 
                        onClick={formSubmit}
                    >
                        {_("Create this Account")}
                    </ButtonBase>
                </div>
            </header>

            <div className="flex-1 overflow-auto">
                <div className="p-7 md:w-full bg-white grid md:grid-cols-2 rounded-[1.25rem]">
                    {
                        cols.map((col, index) => (
                            <div className="flex flex-col gap-7" key={index}>
                                {
                                    col.inputs.map((input) => (
                                        input.isTextarea
                                            ? <InputTextarea
                                                gapClass="gap-3.5"
                                                rows={3}
                                                name="note"
                                                key={input.id}
                                                widthInputClass="w-4/6"
                                                value={values["note"]}
                                                onChange={onChange}
                                                label="Note"
                                                required={false}
                                            />
                                            : <InputField
                                                widthInputClass="w-4/6"
                                                key={input.id}
                                                {...input}
                                                value={values[input.name]}
                                                onChange={onChange}
                                            />
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default AccountCreate;