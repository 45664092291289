import React from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {usePopup} from "../../../services/PopupService";
import PopupBase from "../PopupBase";

function PopupConfirmDeleteAdminAccess() {
    const { t: _ } = useTranslation();
    const { clearAllPopups } = usePopup();

    return (
        <PopupBase
            title={_("The Admin acces has been removed.")}
            footerButtons={
                [
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: clearAllPopups,
                    },
                ]
            }>
        </PopupBase>
    );
}

export default PopupConfirmDeleteAdminAccess;