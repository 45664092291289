import React, {useEffect} from 'react';
import { Link } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import TagTemplate from "../../../components/Tags/TagTemplate";
import InputCheckbox from "../../../components/Layouts/InputCheckbox";
import ButtonViewDetails from "../../../components/Buttons/ButtonViewDetails";
import {useTranslation} from "../../../services/i18n/i18nService";

function TeamCard({listChecked, setListChecked, isSelectAll, team, setIsSelectAll}) {

    const handleClick = (e) => {
        const { id, checked } = e.target;
        setListChecked([...listChecked, parseInt(id)]);
        if (!checked) {
            setListChecked(listChecked.filter(item => item !== e.target.id));
        }
        //remove when checkbox already selected
        if(listChecked.includes( parseInt(id) )){
            setListChecked(listChecked.filter(item => item !== parseInt(id)));
        }
    }

    const TagAdminAccess = (isAccess) => {
        const { t: _ } = useTranslation();
        return isAccess && (
            <span className="capitalize border border-orange-gradient py-1 px-2 rounded-[0.625rem] text-sm text-black">
            {_("ADMIN")}
        </span>
        )
    }

    useEffect(() => {
        if(!!setIsSelectAll){
            setIsSelectAll(isSelectAll);
            if (isSelectAll === true) {
                listChecked.push(parseInt(team.id));
                setListChecked([...listChecked]);
            } else {
                setListChecked([]);
            }
        }
    }, [isSelectAll]);

    return (
        <div key={team.id} className="bg-white w-full rounded-[1.25rem] p-5 md:p-6 lg:p-7 flex flex-col justify-center items-center shadow-sm gap-2 md:gap-3 lg:gap-4">
            <div className="flex justify-between w-full overflow-hidden">
                <InputCheckbox
                    id={team.id}
                    handleClick={handleClick}
                    isChecked={listChecked.includes(parseInt(team.id))}
                />
                <Link to={getFullPath(["ROOT", "TEAM", "VIEW_TEAM_MEMBER"]).replace(":id", team.id)} className="flex justify-center gap-[0.438rem]">
                    <ButtonViewDetails />
                </Link>
            </div>

            <img className="w-20 h-20 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full" src={team.avatar} alt="preview-image" />

            <div className="text-base md:text-lg lg:text-xl font-semibold text-center truncate xs:w-full ls:w-80 py-1">
                {team.first_name} {team.last_name}
            </div>

            <p className="block font-sans text-sm font-light leading-relaxed text-inherit antialiased text-center">
                {team.position}
            </p>

            <div className="flex flex-wrap justify-center pt-0 gap-2 md:gap-4">
                {team.isAdmin && <span>{TagAdminAccess(team.isAdmin)}</span>}
                <span>{TagTemplate(team.template)}</span>
            </div>
        </div>
    );
}

export default TeamCard;