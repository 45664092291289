import { TOP_COMPLETE_VIDEOS_DATAS } from "../../constants/DatasConstants";

let topCompleteVideos = null;

export const getTopVideos = () => {
    return new Promise((resolve, reject) => {
        if (topCompleteVideos === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            //data template
            topCompleteVideos = TOP_COMPLETE_VIDEOS_DATAS;
            resolve(topCompleteVideos);
        } else {
            resolve(topCompleteVideos);
        }
    });
}

export default {
    getTopVideos,
}