import React from 'react';

function SvgProfileEditPersoInfosCompany({className, color}) {
    return (
        <svg className={`${!!className ? className : "" } absolute top-1/2 left-3 -translate-y-1/2 h-4 `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8334 18.9582H4.16675C2.15008 18.9582 1.04175 17.8498 1.04175 15.8332V9.1665C1.04175 7.14984 2.15008 6.0415 4.16675 6.0415H8.33341C8.67508 6.0415 8.95841 6.32484 8.95841 6.6665V15.8332C8.95841 17.1498 9.51675 17.7082 10.8334 17.7082C11.1751 17.7082 11.4584 17.9915 11.4584 18.3332C11.4584 18.6748 11.1751 18.9582 10.8334 18.9582ZM4.16675 7.2915C2.85008 7.2915 2.29175 7.84984 2.29175 9.1665V15.8332C2.29175 17.1498 2.85008 17.7082 4.16675 17.7082H8.16674C7.86674 17.2165 7.70841 16.5915 7.70841 15.8332V7.2915H4.16675Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M8.33342 7.29183H4.16675C3.82508 7.29183 3.54175 7.0085 3.54175 6.66683V5.00016C3.54175 3.7335 4.56675 2.7085 5.83341 2.7085H8.42507C8.61674 2.7085 8.80009 2.80014 8.91676 2.95014C9.03343 3.10848 9.07507 3.3085 9.02507 3.49183C8.97507 3.67516 8.95842 3.8835 8.95842 4.16683V6.66683C8.95842 7.0085 8.67508 7.29183 8.33342 7.29183ZM4.79175 6.04183H7.70842V4.16683C7.70842 4.09183 7.70842 4.02516 7.70842 3.9585H5.83341C5.25841 3.9585 4.79175 4.42516 4.79175 5.00016V6.04183Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M11.6667 11.4582C11.3251 11.4582 11.0417 11.1748 11.0417 10.8332V6.6665C11.0417 6.32484 11.3251 6.0415 11.6667 6.0415C12.0084 6.0415 12.2917 6.32484 12.2917 6.6665V10.8332C12.2917 11.1748 12.0084 11.4582 11.6667 11.4582Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M15 11.4582C14.6583 11.4582 14.375 11.1748 14.375 10.8332V6.6665C14.375 6.32484 14.6583 6.0415 15 6.0415C15.3417 6.0415 15.625 6.32484 15.625 6.6665V10.8332C15.625 11.1748 15.3417 11.4582 15 11.4582Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M15.0001 18.9582H11.6667C11.3251 18.9582 11.0417 18.6748 11.0417 18.3332V14.9998C11.0417 14.1998 11.7001 13.5415 12.5001 13.5415H14.1667C14.9667 13.5415 15.6251 14.1998 15.6251 14.9998V18.3332C15.6251 18.6748 15.3417 18.9582 15.0001 18.9582ZM12.2917 17.7082H14.3751V14.9998C14.3751 14.8832 14.2834 14.7915 14.1667 14.7915H12.5001C12.3834 14.7915 12.2917 14.8832 12.2917 14.9998V17.7082Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M5 14.7918C4.65833 14.7918 4.375 14.5085 4.375 14.1668V10.8335C4.375 10.4918 4.65833 10.2085 5 10.2085C5.34167 10.2085 5.625 10.4918 5.625 10.8335V14.1668C5.625 14.5085 5.34167 14.7918 5 14.7918Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M15.8333 18.9582H10.8333C8.81659 18.9582 7.70825 17.8498 7.70825 15.8332V4.1665C7.70825 2.14984 8.81659 1.0415 10.8333 1.0415H15.8333C17.8499 1.0415 18.9583 2.14984 18.9583 4.1665V15.8332C18.9583 17.8498 17.8499 18.9582 15.8333 18.9582ZM10.8333 2.2915C9.51659 2.2915 8.95825 2.84984 8.95825 4.1665V15.8332C8.95825 17.1498 9.51659 17.7082 10.8333 17.7082H15.8333C17.1499 17.7082 17.7083 17.1498 17.7083 15.8332V4.1665C17.7083 2.84984 17.1499 2.2915 15.8333 2.2915H10.8333Z" fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgProfileEditPersoInfosCompany;