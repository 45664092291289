import React, { useState } from 'react';
import { useTranslation } from "../../services/i18n/i18nService";
import { Icons } from "../../assets/Svgs";

function InputField({ label, labelNote, labelColorClass, errorMsg, onChange, widthInputClass, backgroundClass, hasUrl, canCopy, ...inputProps }) {
    const [ valid, setValid ] = useState(false);
    const [ errorMsgDisplayed, setErrorMsgDisplayed ] = useState(false);
    const { t: _ } = useTranslation();

    const handleBlur = (e) => {
        setErrorMsgDisplayed(true);
    }

    const handleChange = (e) => {
        //console.log(e.target.validity.valid);
        // console.log(e.target.value);
        setValid(e.target.validity.valid);
        onChange(e);
    }

    return (
        <label className={`flex flex-col gap-3.5 ${inputProps.className || ""}`}>
            <div className="flex">
                <span className={`
                ${!!inputProps.required ? 'after:content-[\'*\'] after:ml-0 after:text-red-500' : ''} 
                ${!!canCopy ? 'gap-[0.438rem]' : ''} 
                ${!!labelColorClass ? labelColorClass : "text-[#4B5563]"}
                text-sm font-medium flex`}
                >
                    { label }
                    { !!canCopy ? <Icons.SvgTemplateSimCardOutLine className="h-3" color="#000000" /> : null }
                </span>
                <p className="text-[0.625rem] ">{_(!!labelNote ? "("+labelNote+")" : "")}</p>
            </div>

            {
                !!hasUrl
                    ? <p className="text-sm text-[#9CA3AF]">app.profylead.com/profil/</p>
                    : null
            }
            <input
                className={`${ !!widthInputClass ? widthInputClass : null } ${ !!backgroundClass ? backgroundClass : null } ${ inputProps.disabled ? 'opacity-50' : '' }
            peer h-10 text-sm px-5 py-3.5 bg-[#F6F7F8] rounded-[0.625rem] text-gray-900 focus:outline-none focus:borer-rose-600`}
                type={ inputProps.type }
                name={ inputProps.name }
                placeholder={ inputProps.placeholder }
                disabled={ inputProps.disabled }
                pattern={ inputProps.pattern }
                required={ inputProps.required }
                value={ inputProps.value }
                onChange={ handleChange }
                onBlur={ handleBlur }
            />
            {
                errorMsg
                    ? <p className={`text-sm p-0.5 text-red-600 ${(errorMsgDisplayed && !valid) ? '' : 'hidden'}`}>
                        { _(errorMsg) }
                    </p>
                    : null
            }
        </label>
    );
}

export default InputField;