import React from 'react';
import ChartArea from "../../../components/Layouts/AreaChart";

function DateSelector({selectedDate, setSelectedDate, statistics}) {
    return (
        <div className="flex flex-col gap-7">
            <div className="flex gap-7">
                <div
                    onClick={() => setSelectedDate("1D")}
                    className={`${selectedDate === "1D" ? "bg-[#0400BD] text-white" : "bg-white text-[#9CA3AF]"} flex w-1/5 justify-center font-normal text-[1.25rem] leading-5 rounded-3xl py-1.5 px-2.5`}
                >1D</div>
                <div
                    onClick={() => setSelectedDate("1W")}
                    className={`${selectedDate === "1W" ? "bg-[#0400BD] text-white" : "bg-white text-[#9CA3AF]"} flex w-1/5 justify-center font-normal text-[1.25rem] leading-5 rounded-3xl py-1.5 px-2.5`}
                >1W</div>
                <div
                    onClick={() => setSelectedDate("1M")}
                    className={`${selectedDate === "1M" ? "bg-[#0400BD] text-white" : "bg-white text-[#9CA3AF]"} flex w-1/5 justify-center font-normal text-[1.25rem] leading-5 rounded-3xl py-1.5 px-2.5`}
                >1M</div>
                <div
                    onClick={() => setSelectedDate("1Y")}
                    className={`${selectedDate === "1Y" ? "bg-[#0400BD] text-white" : "bg-white text-[#9CA3AF]"} flex w-1/5 justify-center font-normal text-[1.25rem] leading-5 rounded-3xl py-1.5 px-2.5`}
                >1Y</div>
                <div
                    onClick={() => setSelectedDate("All")}
                    className={`${selectedDate === "All" ? "bg-[#0400BD] text-white" : "bg-white text-[#9CA3AF]"} flex w-1/5 justify-center font-normal text-[1.25rem] leading-5 rounded-3xl py-1.5 px-2.5`}
                >All</div>
            </div>

            <div className="h-[14rem]">
                <ChartArea
                    data={ statistics.chart }
                    startColor="#0400BD"
                    stopColor="#EEEEFE" />
            </div>
        </div>
    );
}

export default DateSelector;