import React from 'react';

function SvgProfileEditHeaderSave({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99992 15.1668C4.04659 15.1668 0.833252 11.9535 0.833252 8.00016C0.833252 4.04683 4.04659 0.833496 7.99992 0.833496C11.9533 0.833496 15.1666 4.04683 15.1666 8.00016C15.1666 11.9535 11.9533 15.1668 7.99992 15.1668ZM7.99992 1.8335C4.59992 1.8335 1.83325 4.60016 1.83325 8.00016C1.83325 11.4002 4.59992 14.1668 7.99992 14.1668C11.3999 14.1668 14.1666 11.4002 14.1666 8.00016C14.1666 4.60016 11.3999 1.8335 7.99992 1.8335Z" fill={!!color ? color : "white"}/>
            <path d="M7.05321 10.3867C6.91988 10.3867 6.79321 10.3334 6.69988 10.2401L4.81321 8.3534C4.61988 8.16007 4.61988 7.84007 4.81321 7.64673C5.00655 7.4534 5.32655 7.4534 5.51988 7.64673L7.05321 9.18007L10.4799 5.7534C10.6732 5.56007 10.9932 5.56007 11.1865 5.7534C11.3799 5.94673 11.3799 6.26673 11.1865 6.46006L7.40655 10.2401C7.31321 10.3334 7.18655 10.3867 7.05321 10.3867Z" fill={!!color ? color : "white"}/>
        </svg>
    );
}

export default SvgProfileEditHeaderSave;