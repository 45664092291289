import React from 'react';
import { Icons } from "../../../../assets/Svgs";
import ChartArea from "../../../../components/Layouts/AreaChart";

function StatisGrid({grids, datas}) {

    return (
        <div className="flex flex-col md:flex-row gap-7 w-full">
            {
                grids && grids.map((grid, index) => (
                    <div key={index} className={`md:mt-0 w-full md:w-1/3 bg-white rounded-[0.625rem] flex flex-col shadow-sm overflow-auto`}>
                        <div className="bg-white rounded-[0.625rem] p-7 flex flex-1 shadow-sm items-center">
                            <div className="flex-col w-3/6">
                                <span className="text-sm">{grid.title}</span>
                                <div className="flex gap-3 items-end">
                                    <strong className="text-lg font-medium">${grid.value}</strong>
                                    {
                                        grid.increase > 0 ? (
                                        <span className="text-sm text-green-500 flex items-end">
                                            <Icons.SvgStatisticCaretUpFill color="#25D366" />{grid.increase}%
                                        </span>
                                    ) : (
                                        <span className="text-sm text-red-500 flex items-end">
                                            <Icons.SvgStatisticCaretDownFill color="#FF0000" />{Math.abs(grid.increase)}%
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="w-3/6 h-[2.6rem]">
                                <ChartArea
                                    id={index}
                                    data={datas}
                                    startColor={index === 2 ? "#FFD99F" : index === 1 ? "#9FFFA9" : "#B09FFF"}
                                    stopColor={index === 2 ? "#FFAE35" : index === 1 ? "#8AF679" : "#8D79F6"}
                                />
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default StatisGrid;