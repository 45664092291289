import React from 'react';

function SvgTemplatesExportGradient(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99219 1.5C3.23219 1.5 0.992188 3.74 0.992188 6.5C0.992188 9.26 3.23219 11.5 5.99219 11.5C8.75219 11.5 10.9922 9.26 10.9922 6.5" stroke="url(#paint0_linear_5245_2765)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.49219 6.00039L10.5922 1.90039" stroke="url(#paint1_linear_5245_2765)" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.9931 3.915V1.5H8.57812" stroke="url(#paint2_linear_5245_2765)" strokeLinecap="round" strokeLinejoin="round"/>
            <defs>
                <linearGradient id="paint0_linear_5245_2765" x1="2.31759" y1="0.704929" x2="13.3053" y2="4.1645" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF9100"/>
                    <stop offset="1" stopColor="#FFC67B"/>
                </linearGradient>
                <linearGradient id="paint1_linear_5245_2765" x1="7.0356" y1="1.57441" x2="11.5405" y2="2.99284" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF9100"/>
                    <stop offset="1" stopColor="#FFC67B"/>
                </linearGradient>
                <linearGradient id="paint2_linear_5245_2765" x1="8.89821" y1="1.30799" x2="11.5517" y2="2.14348" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF9100"/>
                    <stop offset="1" stopColor="#FFC67B"/>
                </linearGradient>
            </defs>
        </svg>

    );
}

export default SvgTemplatesExportGradient;