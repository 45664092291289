import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import TemplateSelectorSocialMedia from "../../pages/Admin/Templates/TemplateSelectorSocialMedia";
import PopupSelectorSocialMediaType from "../../pages/Admin/Templates/Popups/PopupSelectorSocialMediaType";
import InputGroup from "../Layouts/InputGroup";
import {Icons} from "../../assets/Svgs";
import {usePopup} from "../../services/PopupService";
import InputField from "../Layouts/InputField";
import TemplateSectionDraggable from "../../pages/Admin/Templates/TemplateSectionDraggable";
import { MEDIAS, SOCIAL_MEDIA_TYPES } from "../../constants/SocialMediasTypes";

function WidgetSocialMedia(
    {
        setMedia,
        typeSelect,
        setTypeSelect,
        socialMediaValues,
        setSocialMediaValues,
        onChange,
    }) {
    const { idContent } = useParams();
    const [ toggleNote, setToggleNote ] = useState(false);
    const { addPopup } = usePopup();

    const SwitchFieldsInputSocialMedias = (socialMediaValues, onChange) => {
        switch (typeSelect) {
            case SOCIAL_MEDIA_TYPES.LINKEDIN:
                return <InputField
                    name="link"
                    widthInputClass="md:w-4/6 sm:w-full"
                    labelNote="please note that the LinkedIn link will be replaced if filled in Team Profiles with the personal LinkedIn profile"
                    value={socialMediaValues["link"]}
                    label="Social Media Link"
                    required={true}
                    onChange={ onChange }
                />
            case SOCIAL_MEDIA_TYPES.INSTAGRAM:
                return <InputField
                    name="link"
                    widthInputClass="md:w-4/6 sm:w-full"
                    value={socialMediaValues["link"]}
                    label="Social Media Link"
                    required={true}
                    onChange={ onChange }
                />
            case SOCIAL_MEDIA_TYPES.FACEBOOK:
                return <InputField
                    name="link"
                    widthInputClass="md:w-4/6 sm:w-full"
                    value={socialMediaValues["link"]}
                    label="Social Media Link"
                    required={true}
                    onChange={ onChange }
                />
            case SOCIAL_MEDIA_TYPES.YOUTUBE:
                return <InputField
                    name="link"
                    widthInputClass="md:w-4/6 sm:w-full"
                    value={socialMediaValues["link"]}
                    label="Social Media Link"
                    required={true}
                    onChange={ onChange }
                />
            case SOCIAL_MEDIA_TYPES.WHATSAPP:
                return <div className="w-full gap-7 grid">
                    <InputField
                        name="tel"
                        widthInputClass="md:w-4/6 sm:w-full"
                        labelNote="please note that the phone number will be replaced if filled in Team Profiles with the personal phone number"
                        value={socialMediaValues["tel"]}
                        label="Phone Number"
                        required={true}
                        onChange={ onChange }
                    />

                    <TemplateSectionDraggable
                        canRedirToContent={false}
                        displayButtonEdit={false}
                        displayIconListDraggable={false}
                        title="Do you want to send a personal note ?"
                        isDisabled={false}
                        setToggle={setToggleNote}
                    />

                    <InputField
                        name="note"
                        widthInputClass="md:w-4/6 sm:w-full"
                        value={socialMediaValues["note"]}
                        label="WhatsApp Note"
                        required={true}
                        onChange={ onChange }
                    />
                </div>
            case SOCIAL_MEDIA_TYPES.TWITTER:
                return <InputField
                    name="link"
                    widthInputClass="md:w-4/6 sm:w-full"
                    value={socialMediaValues["link"]}
                    label="Social Media Link"
                    required={true}
                    onChange={ onChange }
                />
            case SOCIAL_MEDIA_TYPES.TIKTOK:
                return <InputField
                    name="link"
                    widthInputClass="md:w-4/6 sm:w-full"
                    value={socialMediaValues["link"]}
                    label="Social Media Link"
                    required={true}
                    onChange={ onChange }
                />
            default:
                return null
        }
    }

    useEffect(() => {
        // Add new SocialMedia Widget in Admin
        if(window.location.href.includes("content")){
            setSocialMediaValues(
                {
                    ...socialMediaValues,
                    ["type"]: typeSelect,
                    ["name"]: "",
                    ["tel"]: "",
                    ["note"]: "",
                    ["link"]: "",
                }
            )
        }
        // Edit SocialMedia in Mobile
        if (idContent == null) {
            if (setSocialMediaValues) {
                setSocialMediaValues({
                    ...socialMediaValues,
                    ["type"]: typeSelect,
                    ["name"]: socialMediaValues ? socialMediaValues.name : "",
                    ["tel"]: socialMediaValues ? socialMediaValues.tel : "",
                    ["note"]: socialMediaValues ? socialMediaValues.note : "",
                    ["link"]: socialMediaValues ? socialMediaValues.link : "",
                });
            }
        }
    }, []);

    return (
        window.location.href.includes("content")
            ? <form className="grid w-full h-full gap-7" onSubmit={ (e) => { e.preventDefault() } }>
                <div className="w-full">
                    <TemplateSelectorSocialMedia
                        label="Social Media"
                        selectedType={!!typeSelect ? typeSelect : ""}
                        openPopupSelectorMedia={
                            () => addPopup(<PopupSelectorSocialMediaType selectedType={typeSelect} setSelectedType={setTypeSelect} />)
                        }
                    />
                </div>
                { SwitchFieldsInputSocialMedias(socialMediaValues, onChange) }
            </form>

            : <div className="flex flex-col gap-4 w-full">
                <div className="flex gap-1.5 overflow-x-scroll">
                    {
                        MEDIAS.map((media, index) => (
                            <div key={index} onClick={() => { setTypeSelect(media.type); setSocialMediaValues({ ...socialMediaValues, ["type"]: media.type }); }}
                                 className={` ${!!typeSelect && (typeSelect === media.type) ? "bg-[#D6D6FF] border border-[#0400BD]" : "" } flex justify-center items-center p-3 rounded-lg shadow-lg m-px`}
                            >
                                { !!setMedia ? setMedia(media.type) : null }
                            </div>
                        ))
                    }
                </div>

                <InputGroup
                    fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                    widthInputClass="w-full"
                    name="link"
                    placeholder="Link"
                    value={ !!socialMediaValues ? socialMediaValues["link"] : "" }
                    onChange={ onChange }
                    icon={ <Icons.SvgProfilePopupMediaLink className="absolute top-1/2 left-3 -translate-y-1/2 h-5" /> }
                />
            </div>
    );
}

export default WidgetSocialMedia;