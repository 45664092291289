import React from 'react';

function SvgLeadsImportOutline({className, color}) {
    return <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9984 1L6.89844 5.1" stroke={color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.5 3.08594V5.50094H8.915" stroke={color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11H7.5C10 11 11 10 11 7.5V6.5" stroke={color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export default SvgLeadsImportOutline;