import React from 'react';

function SvgLoginEditPicture({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.00008 7.16667C4.98675 7.16667 4.16675 6.34667 4.16675 5.33333C4.16675 4.32 4.98675 3.5 6.00008 3.5C7.01341 3.5 7.83341 4.32 7.83341 5.33333C7.83341 6.34667 7.01341 7.16667 6.00008 7.16667ZM6.00008 4.5C5.54008 4.5 5.16675 4.87333 5.16675 5.33333C5.16675 5.79333 5.54008 6.16667 6.00008 6.16667C6.46008 6.16667 6.83341 5.79333 6.83341 5.33333C6.83341 4.87333 6.46008 4.5 6.00008 4.5Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M9.99992 15.1668H5.99992C2.37992 15.1668 0.833252 13.6202 0.833252 10.0002V6.00016C0.833252 2.38016 2.37992 0.833496 5.99992 0.833496H8.66658C8.93992 0.833496 9.16658 1.06016 9.16658 1.3335C9.16658 1.60683 8.93992 1.8335 8.66658 1.8335H5.99992C2.92658 1.8335 1.83325 2.92683 1.83325 6.00016V10.0002C1.83325 13.0735 2.92658 14.1668 5.99992 14.1668H9.99992C13.0733 14.1668 14.1666 13.0735 14.1666 10.0002V6.66683C14.1666 6.3935 14.3933 6.16683 14.6666 6.16683C14.9399 6.16683 15.1666 6.3935 15.1666 6.66683V10.0002C15.1666 13.6202 13.6199 15.1668 9.99992 15.1668Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M10.4466 6.50028C10.1866 6.50028 9.94659 6.40695 9.77326 6.22695C9.56659 6.02028 9.46659 5.71362 9.51326 5.40028L9.64659 4.47362C9.67992 4.24028 9.81992 3.95362 9.99326 3.78695L12.4133 1.36695C13.3799 0.400285 14.2199 0.953618 14.6333 1.36695C15.0266 1.76028 15.2066 2.17362 15.1666 2.60028C15.1333 2.94028 14.9599 3.26695 14.6333 3.58695L12.2133 6.00695C12.0466 6.17362 11.7599 6.31362 11.5266 6.35362L10.5999 6.48695C10.5466 6.50028 10.4933 6.50028 10.4466 6.50028ZM13.1133 2.08028L10.6933 4.50028C10.6733 4.52028 10.6399 4.59362 10.6333 4.62695L10.5066 5.49362L11.3799 5.37362C11.4066 5.36695 11.4799 5.33362 11.5066 5.30695L13.9266 2.88695C14.0733 2.74028 14.1599 2.60695 14.1666 2.50695C14.1799 2.36695 14.0399 2.19362 13.9266 2.08028C13.5799 1.73362 13.4199 1.77362 13.1133 2.08028Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M13.9333 4.08635C13.8866 4.08635 13.8399 4.07968 13.7999 4.06635C12.9066 3.81301 12.1933 3.09968 11.9399 2.20635C11.8666 1.93968 12.0199 1.66635 12.2866 1.59301C12.5533 1.51968 12.8266 1.67301 12.8999 1.93968C13.0599 2.49968 13.5066 2.95301 14.0733 3.11301C14.3399 3.18635 14.4933 3.46635 14.4199 3.72635C14.3466 3.93968 14.1466 4.08635 13.9333 4.08635Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M1.78003 13.133C1.62003 13.133 1.46003 13.053 1.3667 12.913C1.21337 12.6864 1.27337 12.373 1.50003 12.2197L4.7867 10.013C5.5067 9.53305 6.50003 9.58638 7.15337 10.1397L7.37337 10.333C7.7067 10.6197 8.27337 10.6197 8.60003 10.333L11.3734 7.95305C12.08 7.34638 13.1934 7.34638 13.9067 7.95305L14.9934 8.88638C15.2 9.06638 15.2267 9.37971 15.0467 9.59305C14.8667 9.79971 14.5534 9.82638 14.34 9.64638L13.2534 8.71305C12.92 8.42638 12.3534 8.42638 12.0267 8.71305L9.25337 11.093C8.5467 11.6997 7.43337 11.6997 6.72003 11.093L6.50003 10.8997C6.19337 10.6397 5.6867 10.613 5.3467 10.8464L2.0667 13.053C1.97337 13.1064 1.87337 13.133 1.78003 13.133Z"
                fill={!!color ? color : "white"}/>
        </svg>
    );
}

export default SvgLoginEditPicture;