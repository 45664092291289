import React from 'react';
import PopupBase from "../PopupBase";
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";

function PopupHaveBeenSaved({onHandleRedirectToPrev}) {
    const { clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            rows
            title={_("The modifications have been saved.")}
            footerButtons={
                [
                    {
                        label: _("Confirm and Go Back to the Template View"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[44rem]",
                        onClick: !!onHandleRedirectToPrev ? () => {onHandleRedirectToPrev(); clearAllPopups();} : clearAllPopups,
                    },
                ]
            }>
        </PopupBase>
    );
}

export default PopupHaveBeenSaved;