import React from 'react';

function SvgProfilePopupExchangeContact({className}) {
    return (
        <svg className={`${!!className ? className : "h-14" } `} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" strokeWidth="8"/>
            <path d="M29 18L38 27L29 35.5V30C22 30 19 37.5 19 37.5C19 29 21.5 23.5 29 23.5V18Z" stroke="#0400BD"
                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgProfilePopupExchangeContact;