import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {getFullPath} from "../../../router/Router";
import {Icons} from "../../../assets/Svgs";
import ContractsService from "../../../services/Contract/ContractsService";
import {useTranslation} from "../../../services/i18n/i18nService";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import InputSearch from "../../../components/Layouts/InputSearch";
import Table from "../../../components/Layouts/Table";
import ButtonBase from "../../../components/Buttons/ButtonBase";

function ContractFiles() {
    const { t: _ } = useTranslation();
    const navigate = useNavigate();
    const { id, idfiles } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ files, setFiles ] = useState([]);
    const [ value, setValue ] = useState("");
    const [ connectionsOriginal, setConnectionsOriginal ] = useState([]);

    const onSearch = (listToSearch, keyword) => {
        if (!keyword) return listToSearch;
        const lowerCaseKeyword = keyword.toLowerCase();
        return listToSearch.filter(item => {
            return item.file_name.toLowerCase().includes(lowerCaseKeyword) ||
                   item.type.toLowerCase().includes(lowerCaseKeyword);
        });
    }    

    useEffect(() => {
        setLoading(true);
        ContractsService.getFileById(idfiles)
            .then(resFiles => {
                setFiles(resFiles);
                setConnectionsOriginal(resFiles.files);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                navigate('../', { replace: true });
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div className="p-7 flex flex-col gap-7">
            <header className=" flex justify-between items-center border-gray-200 flex-1 min-h-0 overflow-auto">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={ getFullPath(["ROOT", "CONTRACTS"]) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Contracts")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    <Link to={ getFullPath(["ROOT", "CONTRACTS", "VIEW_CONTRACT"]).replace(":id", id) } className="flex justify-center gap-[0.438rem]">
                        <p className="hover:underline hover:select-all">{_("View")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("Files")}
                </div>

                <div className="flex items-center gap-7 w-5/12">
                    <Link to={ getFullPath(["ROOT", "CONTRACTS", "VIEW_CONTRACT"]).replace(":id", id) } className="flex text-sm justify-center gap-[0.438rem]">
                        <ButtonBack isFlexible={false}/>
                    </Link>

                    <InputSearch
                        onSearch={onSearch}
                        value={value}
                        setValue={setValue}
                        setList={setFiles}
                        listToSearchOriginal={connectionsOriginal}
                        icon={<Icons.SvgFilterSearchOutline color="#D1D5DB" className="absolute top-1/2 left-3 -translate-y-1/2 h-3"/>}
                        isFlexible={false}
                        placeholder={"Search contract reference or company"}
                    />
                </div>
            </header>

            <div>
                <div className="flex-1 min-h-0 overflow-auto">
                    {
                        files.files
                            ? <Table
                                displayHeaders={true}
                                columns={[
                                    {
                                        label: _("File"),
                                        sortable: false,
                                    },
                                    {
                                        label: _("Type"),
                                        sortable: false,
                                    },
                                    {
                                        label: _("Delete"),
                                        sortable: false,
                                    },
                                    {
                                        label: _("Download"),
                                        sortable: false,
                                    },
                                ]}
                                rows={files.files.map((file) =>
                                    ({
                                        data: [
                                            {
                                                value: <div className="flex items-center justify-center">
                                                    { file.file_name }
                                                </div>,
                                            },
                                            {
                                                value: <div className="flex items-center justify-center">
                                                    { file.type }
                                                </div>,
                                            },
                                            {
                                                value: <div className="flex justify-center gap-[0.438rem]">
                                                    <ButtonBase className="bg-[#FF0000] text-sm text-[#FFFFFF] gap-[0.438rem] shadow-sm flex justify-center py-1.5 px-3 items-center rounded-full cursor-pointer rounded-full">
                                                        <Icons.SvgTrashOutline className="h-3" iconColor="#FFFFFF" color="#FFFFFF" />
                                                        {_("Delete")}
                                                    </ButtonBase>
                                                </div>,
                                            },
                                            {
                                                value: <div className="flex justify-center gap-[0.438rem]">
                                                    <ButtonBase className="bg-[#D1D5DB] text-sm color-[#4B5563] gap-[0.438rem] shadow-sm flex justify-center py-1.5 px-3 items-center rounded-full cursor-pointer rounded-full">
                                                        <Icons.SvgArrangeAscOutline className="h-3" iconColor="#FFFFFF" color="#4B5563" />
                                                        {_("Download")}
                                                    </ButtonBase>
                                                </div>,
                                            },
                                        ]
                                    })
                                )}
                            />
                            : null
                    }
                </div>

                <table className="bg-transparent flex w-full">
                    <tbody className="bg-transparent flex w-full">
                        <tr className="bg-transparent flex w-full">
                            <td className="border border-[#9CA3AF] flex w-full items-center justify-center py-[1.25rem] px-[0.625rem] rounded-[1.25rem] text-[0.813rem] leading-[0.813rem] font-medium">
                                {_("Glisser ou Rechercher un document .pdf")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ContractFiles;