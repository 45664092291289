import React from 'react';

function SvgLoginArrowCircleRight({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99935 18.9583C5.05768 18.9583 1.04102 14.9416 1.04102 9.99996C1.04102 5.05829 5.05768 1.04163 9.99935 1.04163C14.941 1.04163 18.9577 5.05829 18.9577 9.99996C18.9577 14.9416 14.941 18.9583 9.99935 18.9583ZM9.99935 2.29163C5.74935 2.29163 2.29102 5.74996 2.29102 9.99996C2.29102 14.25 5.74935 17.7083 9.99935 17.7083C14.2493 17.7083 17.7077 14.25 17.7077 9.99996C17.7077 5.74996 14.2493 2.29163 9.99935 2.29163Z" fill={!!color ? color : "white"}/>
            <path d="M8.94909 13.5667C8.79075 13.5667 8.63242 13.5083 8.50742 13.3833C8.26576 13.1417 8.26576 12.7417 8.50742 12.5L11.0074 10L8.50742 7.50001C8.26576 7.25834 8.26576 6.85834 8.50742 6.61667C8.74909 6.37501 9.14909 6.37501 9.39076 6.61667L12.3324 9.55834C12.5741 9.80001 12.5741 10.2 12.3324 10.4417L9.39076 13.3833C9.26576 13.5083 9.10742 13.5667 8.94909 13.5667Z" fill={!!color ? color : "white"}/>
        </svg>

    );
}

export default SvgLoginArrowCircleRight;