import APIService from "../APIService";
import CustomersService from "../Customer/CustomersService";

class UserService {
    
    static users = null;
    static currentUserId = null;

    static invalidateCache = () => {
        this.users = null;
        this.currentUserId = null;
    }

    // Fetch current user with caching logic
    static async fetchCurrentUser() {
        if (!this.currentUserId) {
            const response = await APIService.apiCall("users/me/", "GET");
            if (!response.ok) throw new Error('Failed to fetch current user');
            const data = await response.json();
            this.currentUserId = data.id;
        }
    }

    // Get the current user
    static async getCurrentUser() {
        await this.fetchUsers(); // Ensure users are loaded
        await this.fetchCurrentUser(); // Ensure current user is loaded
        return this.users.find(user => user.id === this.currentUserId);
    }

    // Fetch users with caching logic
    static async fetchUsers() {
        if (!this.users) {
            const response = await APIService.apiCall("users/", "GET");
            if (!response.ok) throw new Error('Failed to fetch users');
            const data = await response.json();
            this.users = data; // Cache data
        }
    }

    // Get all users
    static async getUsers() {
        await this.fetchUsers(); // Ensure users are loaded
        return this.users;
    }

    // Get a single user by ID
    static async getUserById(id) {
        await this.fetchUsers(); // Ensure users are loaded
        const user = this.users.find(u => u.id.toString() === id.toString());
        if (!user) throw new Error('User not found');
        return user;
    }

    // Create a new user and invalidate cache
    static async createUser(user) {
        const response = await APIService.apiCall("users/", "POST", user, true, {});
        const data = await response.json();
        if (response.status !== 201) throw new Error('Failed to create user');
        this.invalidateCache(); // Invalidate cache
        return data;
    }

    // Add a new user to the company of the current user
    static async createUserInCurrentCompany(user) {
        console.log("Add new user to company");
        console.log(user);
        const response = await APIService.apiCall("users/add-new-user-to-company/", "POST", user)
        if (!response.ok) throw new Error('Failed to add new user to company');
        const data = await response.json();
        this.invalidateCache(); // Invalidate cache
        return data;
    }

    // Create a new user and add it in a company (customer)
    static async createUserInCompany(user, companyId) {
        const newUser = await this.createUser(user);

        const membershipPayload = {
            member: parseInt(newUser.id),
            company: parseInt(companyId),
            job_title: user.role,
            is_manager: false,
        };
        try {
            await CustomersService.addUserToCustomer(membershipPayload);
        } catch (error) {
            console.error(error);
            await this.deleteUser(newUser.id);
            throw new Error('Failed to add user to company, user deleted');
        }
    }

    // Update a user and invalidate cache
    static async updateUser(user) {
        const userId = user.id;
        const response = await APIService.apiCall(`users/${userId}/`, "PUT", user, true, {});
        const data = await response.json();
        if (response.status !== 200) throw new Error('Failed to update user');
        this.invalidateCache(); // Invalidate cache
        return data;
    }
    
    static async updateUser(id, user) {
        const response = await APIService.apiCall(`users/${id}/`, "PATCH", user, true, {});
        const data = await response.json();
        if (response.status !== 200) throw new Error('Failed to update user');
        this.invalidateCache(); // Invalidate cache
        return data;
    }

    // Delete a user and invalidate cache
    static async deleteUser(id) {
        const response = await APIService.apiCall(`users/${id}/`, "DELETE");
        if (response.status !== 204) throw new Error('Failed to delete user');
        this.invalidateCache(); // Invalidate cache
    }

    // Request a password reset
    static async requestPasswordReset({ email }) {
        const payload = { email };
        const response = await APIService.apiCall(`users/request-password-reset/`, "POST", payload, true, {}, null);
        if (response.status !== 204) throw new Error('Failed to request password reset');
        this.invalidateCache(); // Invalidate cache
    }

    // Reset a user password
    static async resetPassword({ email, token, newPassword }) {
        const payload = { email, token, new_password: newPassword };
        const response = await APIService.apiCall(`users/reset-password/`, "POST", payload, true, {}, null);
        if (response.status !== 204) throw new Error('Failed to reset password');
        this.invalidateCache(); // Invalidate cache
    }

    /* Membership */
    static async updateMembership(id, membership) {
        const response = await APIService.apiCall(`structures/company-memberships/${id}/`, "PATCH", membership);
        const data = await response.json();
        if (response.status !== 200) throw new Error('Failed to update membership');
        this.invalidateCache(); // Invalidate cache
        return data;
    }
}

export default UserService;
