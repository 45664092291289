import React, {useEffect} from 'react';
import InputGroup from "../../Layouts/InputGroup";
import InputTel from "../../Layouts/InputTel";
import {useTranslation} from "../../../services/i18n/i18nService";
import {Icons} from "../../../assets/Svgs";

function WidgetPortfolioEdit(
    {
        onSave,
        onDelete,
        profile,
        setProfile
    }) {
    const { t: _ } = useTranslation();

    const inputs = [
        {
            id: 1,
            name: "full_name",
            type: "text",
            icon: <Icons.SvgProfileEditPersoInfosName className="absolute top-1/2 left-4 -translate-y-1/2 h-5" />,
            isSelectable: false,
        },
        {
            id: 2,
            name: "position",
            type: "text",
            icon: <Icons.SvgProfileEditPersoInfosPosition className="absolute top-1/2 left-4 -translate-y-1/2 h-5"/>,
            isSelectable: false,
        },
        {
            id: 3,
            name: "company",
            type: "text",
            icon: <Icons.SvgProfileEditPersoInfosCompany className="absolute top-1/2 left-4 -translate-y-1/2 h-5"/>,
            isSelectable: false,
        },
        {
            id: 4,
            name: "email",
            type: "text",
            icon: <Icons.SvgProfileEditPersoInfosMail className="absolute top-1/2 left-4 -translate-y-1/2 h-5"/>,
            isSelectable: false,
        },
        {
            id: 5,
            name: "tel",
            type: "text",
            icon: null,
            isSelectable: true,
        },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newProfile = { ...profile };
        newProfile.currentWidget.data[name] = value;
        setProfile(newProfile);
    };

    return (
        <div className="grid gap-4">
            <div className="text-[#111827] font-medium text-[1.25rem] leading-7 w-4/6">
                { _("Modify your personal informations") }
            </div>

            <div className="grid gap-4">
                {
                    inputs.map((input) =>
                        input.isSelectable ? (
                            <InputTel
                                fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                                key={input.id}
                                {...input}
                                value={profile.currentWidget.data[input.name]}
                                setValues={setProfile}
                                onChange={handleChange}
                                telLocaleCurrent={profile.country}
                            />
                        ) : (
                            <InputGroup
                                fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                                widthInputClass="w-full"
                                key={input.id}
                                {...input}
                                value={profile.currentWidget.data[input.name]}
                                onChange={handleChange}
                                icon={input.icon}
                            />
                        )
                    )
                }
            </div>
        </div>
    );
}

export default WidgetPortfolioEdit;