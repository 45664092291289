import { TOP_COMPLETE_CONTACTS_DATAS } from "../../constants/DatasConstants";

let topCompleteContacts = null;

export const getTopContacts = () => {
    return new Promise((resolve, reject) => {
        if (topCompleteContacts === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            //data template
            topCompleteContacts = TOP_COMPLETE_CONTACTS_DATAS;
            resolve(topCompleteContacts);
        } else {
            resolve(topCompleteContacts);
        }
    });
}

export default {
    getTopContacts,
};