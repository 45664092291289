import React from 'react';

function SvgTemplateSectionAppLink(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.1018 19.16C19.6918 18.26 19.9118 17.8 20.3618 16.79C17.0418 15.53 16.5118 10.8 19.7918 8.99C18.7918 7.73 17.3818 7 16.0518 7C15.0918 7 14.4318 7.25 13.8418 7.48C13.3418 7.67 12.8918 7.84 12.3318 7.84C11.7318 7.84 11.2018 7.65 10.6418 7.45C10.0418 7.23 9.40178 7 8.60178 7C7.11178 7 5.52179 7.91 4.51179 9.47C3.09179 11.67 3.34178 15.79 5.63178 19.31C6.45178 20.57 7.55179 21.98 8.98179 22C9.58179 22.01 9.97179 21.83 10.4018 21.64C10.8918 21.42 11.4218 21.18 12.3518 21.18C13.2818 21.17 13.8018 21.42 14.2918 21.64C14.7118 21.83 15.0918 22.01 15.6818 22C17.1218 21.98 18.2818 20.42 19.1018 19.16Z" fill={props.color || "#292D32"}/>
            <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M15.8404 2C16.0004 3.1 15.5504 4.19 14.9604 4.95C14.3304 5.77 13.2304 6.41 12.1704 6.37C11.9804 5.31 12.4704 4.22 13.0704 3.49C13.7404 2.69 14.8704 2.07 15.8404 2Z" fill={props.color || "#292D32"}/>
        </svg>
    );
}

export default SvgTemplateSectionAppLink;