import React from 'react';
import {getFullPath} from "../../../router/Router";
import {useTranslation} from "../../../services/i18n/i18nService";
import {useNavigate, useParams} from "react-router";
import {Icons} from "../../../assets/Svgs";
import ButtonMobile from "../../../components/Buttons/ButtonMobile";

function PortfolioAddSection() {
    const { t: _ } = useTranslation();
    const { link } = useParams();
    const navigate = useNavigate();

    const sections = [
        {
            id: 1,
            title: "Text zone",
            desc: "Create a text zone with unlimited caracters. Talk about yourself, your business, or simply tell us who are you",
            component: "TEXT",
            icon: <Icons.SvgProfileEditText className="h-8" color="white"/>,
            onClick: () => navigate(getFullPath(["ROOT", "PROFILE", "EDIT_WIDGET"]).replace(":link", link).replace(":widgetType", "TEXT").replace(":widgetId", null)),
        },
        {
            id: 2,
            title: "Social Media",
            desc: "Add multiple social media links to share your private of professional life with your connections",
            component: "SOCIAL_MEDIA",
            icon: <Icons.SvgProfileReorganizeMedia className="h-8" color="white"/>,
            onClick: () => navigate(getFullPath(["ROOT", "PROFILE", "EDIT_WIDGET"]).replace(":link", link).replace(":widgetType", "SOCIAL_MEDIA").replace(":widgetId", null)),
        },
        {
            id: 3,
            title: "Pictures",
            desc: "Share unlimited pictures of your product or services that your company offers, and link them directly to a custom link",
            component: "PICTURE",
            icon: <Icons.SvgProfileReorganizePicture className="h-8" color="white"/>,
            onClick: () => navigate(getFullPath(["ROOT", "PROFILE", "EDIT_WIDGET"]).replace(":link", link).replace(":widgetType", "PICTURE").replace(":widgetId", null)),
        },
        {
            id: 4,
            title: "Videos",
            desc: "Post one embeded YouTube video with your connections to share with them a unique experience of discovering your company",
            component: "VIDEO",
            icon: <Icons.SvgProfileReorganizeVideo className="h-8" color="white"/>,
            onClick: () => navigate(getFullPath(["ROOT", "PROFILE", "EDIT_WIDGET"]).replace(":link", link).replace(":widgetType", "VIDEO").replace(":widgetId", null)),
        },
        {
            id: 5,
            title: "Documents",
            desc: "Share any documents, such as PDF, pictures, videos, and much more through a direct link with your connections",
            component: "DOCUMENT",
            icon: <Icons.SvgProfileEditDoc className="h-8" color="white"/>,
            onClick: () => navigate(getFullPath(["ROOT", "PROFILE", "EDIT_WIDGET"]).replace(":link", link).replace(":widgetType", "DOCUMENT").replace(":widgetId", null)),
        },
        {
            id: 6,
            title: "Website links",
            desc: "A landing page, a contact page form, or even a shop page, share as much information as you want through your website link",
            component: "WEBSITE",
            icon: <Icons.SvgProfileModelContactWeb className="h-8" color="white"/>,
            onClick: () => navigate(getFullPath(["ROOT", "PROFILE", "EDIT_WIDGET"]).replace(":link", link).replace(":widgetType", "WEBSITE").replace(":widgetId", null)),
        },
        {
            id: 7,
            title: "Contact links",
            desc: "Create some easy quick access contact links so  your connections can call you, send you a message or a mail within seconds",
            component: "CONTACT",
            icon: <Icons.SvgProfileModelContactTel className="h-8" color="white"/>,
            onClick: () => navigate(getFullPath(["ROOT", "PROFILE", "EDIT_WIDGET"]).replace(":link", link).replace(":widgetType", "CONTACT").replace(":widgetId", null)),
        },
        {
            id: 8,
            title: "Click-To-Action",
            desc: "Want to increase your conversion rate ? Simply add a CTA to a calendar, a form or your shop to offer your connections a direct action to do",
            component: "CTA",
            icon: <Icons.SvgProfileReorganizeCta className="h-8" color="white"/>,
            onClick: () => navigate(getFullPath(["ROOT", "PROFILE", "EDIT_WIDGET"]).replace(":link", link).replace(":widgetType", "CTA").replace(":widgetId", null)),
        }
    ]

    return (
        <div className="m-4 grid gap-10">
            <div className="flex justify-between">
                <ButtonMobile
                    onClick={() => {
                        navigate(getFullPath(["ROOT", "PROFILE"]));
                    }}
                    className="px-2.5 py-1.5 bg-white border border-[#0400BD]"
                    textClass="text-[#0400BD] font-normal text-[1rem] leading-5"
                    label="Cancel"
                    icon={<Icons.SvgProfileEditHeaderCancel />}
                />
                <ButtonMobile
                    onClick={() => {
                        navigate(getFullPath(["ROOT", "PROFILE"]));
                    }}
                    className="px-2.5 py-1.5"
                    textClass="text-white font-normal text-[1rem] leading-5"
                    label="Save"
                    icon={<Icons.SvgProfileEditHeaderSave />}
                />
            </div>

            <div className="flex flex-col gap-8">
                <div className="grid gap-4">
                    <div className="text-[#111827] font-medium text-[1.25rem] leading-7 w-4/6">
                        { _("Select the section that you want to add") }
                    </div>

                    {
                        !!sections
                            ? sections.map((section, index) => (
                                <div onClick={section.onClick} key={index} className="flex gap-4 py-2 px-4 bg-blue-gradient rounded-lg justify-between items-center">
                                    <div>{ section.icon }</div>
                                    <div className="flex flex-col gap-2">
                                        <div className="font-normal leading-6 text-[1rem] text-white">{ section.title }</div>
                                        <div className="font-normal leading-5 text-[0.75rem] text-white">{ section.desc }</div>
                                    </div>
                                </div>
                            ))
                            : null
                    }
                </div>
            </div>
        </div>
    );
}

export default PortfolioAddSection;