import React from 'react';

function SvgLoginArrowCircleLeft({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00065 15.1666C4.04732 15.1666 0.833984 11.9533 0.833984 7.99992C0.833984 4.04659 4.04732 0.833252 8.00065 0.833252C11.954 0.833252 15.1673 4.04659 15.1673 7.99992C15.1673 11.9533 11.954 15.1666 8.00065 15.1666ZM8.00065 1.83325C4.60065 1.83325 1.83398 4.59992 1.83398 7.99992C1.83398 11.3999 4.60065 14.1666 8.00065 14.1666C11.4007 14.1666 14.1673 11.3999 14.1673 7.99992C14.1673 4.59992 11.4007 1.83325 8.00065 1.83325Z" fill={!!color ? color : "#0400BD"}/>
            <path d="M8.83995 10.8534C8.71328 10.8534 8.58661 10.8068 8.48661 10.7068L6.13328 8.35344C5.93995 8.1601 5.93995 7.8401 6.13328 7.64677L8.48661 5.29344C8.67995 5.1001 8.99995 5.1001 9.19328 5.29344C9.38661 5.48677 9.38661 5.80677 9.19328 6.0001L7.19328 8.0001L9.19328 10.0001C9.38661 10.1934 9.38661 10.5134 9.19328 10.7068C9.09995 10.8068 8.97328 10.8534 8.83995 10.8534Z" fill={!!color ? color : "#0400BD"}/>
        </svg>
    );
}

export default SvgLoginArrowCircleLeft;