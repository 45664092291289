import React from 'react';
import { Card } from './Card';
import { MetricAreaChart } from './MetricAreaChart';
import { MetricValueVariation } from './MetricValueVariation';
import { cn } from '../../utils';

export const MetricChartCard = ({ className, title, chartData, colors }) => {
    return (
        <Card className={cn("w-full min-h-28", className)}>
            <div className="flex gap-3.5 w-full">
                <div className="flex flex-col items-center justify-end">
                    <div className="text-sm">{title}</div>
                    <MetricValueVariation
                        value={
                            chartData?.slice(-1)?.[0]?.value ?? 0
                        }
                        previousValue={
                            chartData?.[0]?.value ?? 0
                        }
                    />
                </div>

                <MetricAreaChart
                    data = {chartData}
                    colors = {colors}
                />
            </div>
        </Card>
    );
}
