import React, {useEffect, useState} from 'react';
import PortfolioFooter from "../Layouts/PortfolioFooter";
import {useTranslation} from "../../../services/i18n/i18nService";
import StatisticsMobileService from "../../../services/StatisticsMobile/StatisticsMobileService";
import DateSelector from "./DateSelector";

function StatisticsMobile() {
    const { t: _ } = useTranslation();
    const [ selectedDate, setSelectedDate ] = useState("1D");
    const [ statistics, setStatistics ] = useState({});
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        setLoading(true);
        StatisticsMobileService.getStatistics()
            .then(res => {
                setStatistics(res);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div>
            <div className="m-4 grid gap-10 mb-24">
                <div className="flex flex-col gap-20">
                    <div className="flex flex-col gap-7">
                        <div className="flex flex-col gap-4">
                            <div className="grid gap-7">
                                <div className="text-[#111827] font-medium text-[1.25rem] leading-7">
                                    { _("Your Statistics") }
                                </div>

                                <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} statistics={statistics} />

                                {
                                    !!statistics.datas
                                        ? statistics.datas.map((statistic, index) => (
                                            <div key={index} className="flex flex-col gap-2">
                                                <div className="leading-6 text-[1rem] font-medium">{_(statistic.title)}</div>
                                                <div className="flex gap-4 w-full">
                                                    {
                                                        statistic.datas.map((data, index) => (
                                                            <div key={index} className="w-2/6 border border-[#F4F3FF] flex flex-col gap-2 px-4 py-2 items-center rounded-lg">
                                                                <div className="text-[1rem] font-normal leading-6">{_(data.name)}</div>
                                                                <div className="text-[1.5rem] font-semibold leading-9 text-[#0400BD]">{ data.nb }</div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PortfolioFooter routerSelected="statistics" />

        </div>
    );
}

export default StatisticsMobile;