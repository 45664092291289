import React from 'react';

function SvgFooterSetting({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.25 28.4375C23.7375 28.4375 23.3125 28.0125 23.3125 27.5V13.75C23.3125 13.2375 23.7375 12.8125 24.25 12.8125C24.7625 12.8125 25.1875 13.2375 25.1875 13.75V27.5C25.1875 28.0125 24.7625 28.4375 24.25 28.4375Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M24.25 9.6875C23.7375 9.6875 23.3125 9.2625 23.3125 8.75V2.5C23.3125 1.9875 23.7375 1.5625 24.25 1.5625C24.7625 1.5625 25.1875 1.9875 25.1875 2.5V8.75C25.1875 9.2625 24.7625 9.6875 24.25 9.6875Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M15.5 28.4375C14.9875 28.4375 14.5625 28.0125 14.5625 27.5V21.25C14.5625 20.7375 14.9875 20.3125 15.5 20.3125C16.0125 20.3125 16.4375 20.7375 16.4375 21.25V27.5C16.4375 28.0125 16.0125 28.4375 15.5 28.4375Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M15.5 17.1875C14.9875 17.1875 14.5625 16.7625 14.5625 16.25V2.5C14.5625 1.9875 14.9875 1.5625 15.5 1.5625C16.0125 1.5625 16.4375 1.9875 16.4375 2.5V16.25C16.4375 16.7625 16.0125 17.1875 15.5 17.1875Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M6.75 28.4375C6.2375 28.4375 5.8125 28.0125 5.8125 27.5V13.75C5.8125 13.2375 6.2375 12.8125 6.75 12.8125C7.2625 12.8125 7.6875 13.2375 7.6875 13.75V27.5C7.6875 28.0125 7.2625 28.4375 6.75 28.4375Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M6.75 9.6875C6.2375 9.6875 5.8125 9.2625 5.8125 8.75V2.5C5.8125 1.9875 6.2375 1.5625 6.75 1.5625C7.2625 1.5625 7.6875 1.9875 7.6875 2.5V8.75C7.6875 9.2625 7.2625 9.6875 6.75 9.6875Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M9.25 14.6875H4.25C3.7375 14.6875 3.3125 14.2625 3.3125 13.75C3.3125 13.2375 3.7375 12.8125 4.25 12.8125H9.25C9.7625 12.8125 10.1875 13.2375 10.1875 13.75C10.1875 14.2625 9.7625 14.6875 9.25 14.6875Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M26.75 14.6875H21.75C21.2375 14.6875 20.8125 14.2625 20.8125 13.75C20.8125 13.2375 21.2375 12.8125 21.75 12.8125H26.75C27.2625 12.8125 27.6875 13.2375 27.6875 13.75C27.6875 14.2625 27.2625 14.6875 26.75 14.6875Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M18 17.1875H13C12.4875 17.1875 12.0625 16.7625 12.0625 16.25C12.0625 15.7375 12.4875 15.3125 13 15.3125H18C18.5125 15.3125 18.9375 15.7375 18.9375 16.25C18.9375 16.7625 18.5125 17.1875 18 17.1875Z" fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgFooterSetting;