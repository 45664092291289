import React from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";
import Table from "../../Layouts/Table";

function PopupToReassignConfirm({member}) {
    const { removePopup, clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={
                _("Are you sure you want to reassign this lead to :")
            }
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[14.375rem]",
                        onClick: clearAllPopups,
                    },
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[14.375rem]",
                        onClick: removePopup,
                    },
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[14.375rem]",
                        onClick: clearAllPopups,
                    },
                ]
            }>
            <div className="w-full flex flex-col justify-center align-center" >
                <div className="grid w-full items-center">
                    <div className="grid gap-7 px-48">
                        <Table
                            roundedClass="rounded-[0.625rem]"
                            tdheightClass="h-14"
                            tdwidthClass="w-96"
                            columns={[
                                {
                                    label: _(""),
                                    sortable: false,
                                },
                            ]}
                            rows={
                                [member].map((template, index) =>
                                    ({
                                        id: index,
                                        data: [
                                            {
                                                value: <div className="flex gap-[1.5rem]">
                                                    <img src={ member.avatar } className="rounded-full h-8 w-8"  alt="avatar" />
                                                    <span className="flex items-center">{ member.connected }</span>
                                                </div>,
                                            },
                                        ]
                                    })
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </PopupBase>
    );
}

export default PopupToReassignConfirm;