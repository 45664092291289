import React from 'react';
import PopupPicturesPreview from "../../../pages/Mobile/Popups/PopupPicturesPreview";
import { usePopup } from "../../../services/PopupService";
import WidgetSection from "../../Layouts/WidgetSection";
import { Icons } from '../../../assets/Svgs';

function WidgetWebsiteView(
    {
        widget,
        displayEditAction,
        onEdit,
    }) {
    /* Hooks */
    const { addPopup } = usePopup();

    /* Utils */
    const getWebsiteIconImg = (website) => {
        return website.file_content ? website.file_content : widgetPictures?.find(p => p.id === parseInt(website.icon))?.picture;
    }

    const widgetPictures = widget.pictures;
    const widgetData = widget.data;
    const widgetTitle = widgetData.title;
    const websites = (widgetData.websites?.sort((a, b) => a.order - b.order) || []).map(website => (
        { ...website, iconImg: getWebsiteIconImg(website) }
    ));
    
    return (
        <WidgetSection title={widgetTitle} displayEditAction={displayEditAction} onEdit={onEdit} >
        {websites.map((website, index) => (
            <div key={index} className="flex px-2.5 justify-between items-center align-center cursor-pointer"
                    onClick={() => {window.open(website.link, "_blank")}}
            >
                <div className="flex gap-2 overflow-hidden">
                    <div className="flex h-8 w-8 justify-center items-center">
                        {!!website.iconImg
                            ? <img src={ website.iconImg } alt="website logo"
                                className="object-cover rounded"
                            />
                            : <Icons.SvgTemplateSectionWebsiteLinks />
                        }
                    </div>

                    <div className="flex flex-col items-center justify-center overflow-hidden">
                        <div className="text-[0.875rem] leading-4 font-normal text-[#111827] truncate w-full">{website.label}</div>
                        <div className="text-[0.625rem] leading-3 font-normal text-[#6B7280] truncate w-full">{website.link}</div>
                    </div>
                </div>
                <div className="flex p-2.5">
                    <Icons.SvgProfileWebExport />
                </div>
            </div>
        ))}
        </WidgetSection>
    );
}

export default WidgetWebsiteView;