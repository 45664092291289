import React from 'react';

function SvgTemplatesExport(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.48437 1.5C3.72437 1.5 1.48438 3.74 1.48438 6.5C1.48438 9.26 3.72437 11.5 6.48437 11.5C9.24437 11.5 11.4844 9.26 11.4844 6.5" stroke={props.color || "#9CA3AF"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.98438 6.00039L11.0844 1.90039" stroke={props.color || "#9CA3AF"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.4853 3.915V1.5H9.07031" stroke={props.color || "#9CA3AF"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgTemplatesExport;