import React from 'react';

function SvgContactMailFill(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4974 37.9199C9.4474 37.9199 2.91406 44.4241 2.91406 52.5033C2.91406 60.5824 9.4474 67.0866 17.4974 67.0866C25.5474 67.0866 32.0807 60.5533 32.0807 52.5033C32.0807 44.4533 25.5766 37.9199 17.4974 37.9199ZM11.5474 46.5533H17.4974C18.6057 46.5533 19.4807 47.4574 19.4807 48.5366C19.4807 49.6158 18.5766 50.5199 17.4974 50.5199H11.5474C10.439 50.5199 9.56406 49.6158 9.56406 48.5366C9.56406 47.4574 10.439 46.5533 11.5474 46.5533ZM23.4474 58.4532H11.5183C10.4099 58.4532 9.53486 57.5491 9.53486 56.4699C9.53486 55.3908 10.4391 54.4866 11.5183 54.4866H23.4474C24.5558 54.4866 25.4307 55.3908 25.4307 56.4699C25.4307 57.5491 24.5558 58.4532 23.4474 58.4532Z" fill="#373742"/>
            <path opacity="0.4" d="M49.5859 8.75195H20.4193C11.6693 8.75195 5.83594 13.127 5.83594 23.3353V32.4936C5.83594 34.6228 8.02348 35.9644 9.97764 35.1186C13.186 33.7186 16.8317 33.1936 20.6526 33.8061C28.2942 35.0603 34.5359 41.0978 36.0818 48.6811C36.5193 50.8978 36.5776 53.0561 36.2859 55.1269C36.0526 56.8478 37.4526 58.3644 39.1734 58.3644H49.5859C58.3359 58.3644 64.1693 53.9894 64.1693 43.7811V23.3644C64.1693 13.1269 58.3359 8.75195 49.5859 8.75195Z" fill="#373742"/>
            <path d="M34.9975 34.6228C32.5475 34.6228 30.0683 33.8645 28.1725 32.3187L19.0433 25.027C18.11 24.2687 17.935 22.8979 18.6933 21.9645C19.4517 21.0312 20.8225 20.8562 21.7558 21.6145L30.885 28.9062C33.1016 30.6854 36.8641 30.6854 39.0808 28.9062L48.21 21.6145C49.1433 20.8562 50.5433 21.002 51.2724 21.9645C52.0308 22.8979 51.885 24.2979 50.9225 25.027L41.7933 32.3187C39.9266 33.8645 37.4475 34.6228 34.9975 34.6228Z" fill="#373742"/>
        </svg>
    );
}

export default SvgContactMailFill;