import React from 'react';

function SvgTemplateSectionWebsiteLinks(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M55.6258 41.5327C54.7799 42.3785 53.4383 42.3785 52.6216 41.5327C51.7758 40.6869 51.7758 39.3452 52.6216 38.5285C58.4549 32.6952 58.4549 23.216 52.6216 17.4119C46.7883 11.6077 37.3091 11.5785 31.5049 17.4119C25.7008 23.2452 25.6716 32.7244 31.5049 38.5285C32.3508 39.3744 32.3508 40.716 31.5049 41.5327C30.6591 42.3785 29.3174 42.3785 28.5008 41.5327C21.0049 34.0369 21.0049 21.8452 28.5008 14.3785C35.9966 6.91185 48.1883 6.88269 55.6549 14.3785C63.1216 21.8744 63.1216 34.0369 55.6258 41.5327Z" fill="#373742"/>
            <path opacity="0.4" d="M55.6198 14.3777C63.1156 21.8736 63.1156 34.0361 55.6198 41.5319C53.0531 44.0986 49.9031 45.7902 46.6073 46.6069C48.1531 40.3069 46.4615 33.3944 41.5323 28.4652C36.6031 23.5361 29.6906 21.8444 23.3906 23.3902C24.2073 20.0944 25.8698 16.9444 28.4656 14.3777C35.9615 6.8819 48.124 6.8819 55.6198 14.3777Z" fill="#373742"/>
            <path d="M14.3755 28.4684C15.2214 27.6225 16.563 27.6225 17.3797 28.4684C18.2255 29.3142 18.2255 30.6559 17.3797 31.4725C11.5464 37.3059 11.5464 46.785 17.3797 52.5892C23.213 58.3934 32.6922 58.4225 38.4964 52.5892C44.3005 46.7559 44.3297 37.2767 38.4964 31.4725C37.6505 30.6267 37.6505 29.285 38.4964 28.4684C39.3422 27.6225 40.6839 27.6225 41.5005 28.4684C48.9964 35.9642 48.9964 48.1559 41.5005 55.6225C34.0047 63.0892 21.813 63.1184 14.3464 55.6225C6.87969 48.1267 6.87969 35.9642 14.3755 28.4684Z" fill="#373742"/>
            <path opacity="0.5" d="M41.5378 28.4665C46.4669 33.3956 48.1586 40.3081 46.6128 46.6081C45.7961 49.904 44.1044 53.054 41.5378 55.6206C34.0419 63.1165 21.8794 63.1165 14.3836 55.6206C6.88776 48.1248 6.88776 35.9623 14.3836 28.4665C16.9503 25.8706 20.1003 24.2081 23.3961 23.3915C29.6961 21.8456 36.6086 23.5373 41.5378 28.4665Z" fill="#373742"/>
        </svg>
    );
}

export default SvgTemplateSectionWebsiteLinks;