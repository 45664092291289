import React from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";
import {Icons} from "../../../assets/Svgs";
import SquareView from "../../Layouts/SelectorSquareView";
import LeadsService from "../../../services/Leads/LeadsService";

function PopupConfirmExport({selectedWayExport, listChecked}) {
    const { clearAllPopups, removePopup } = usePopup();
    const { t: _ } = useTranslation();

    const exportToCSV = (data) => {
        const csvContent = "data:text/csv;charset=utf-8,"
            + data.map(row => row.join(",")).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "leads.csv");
        document.body.appendChild(link);
        link.click();
    }

    return (
        <PopupBase
            title={
                !!listChecked && listChecked.length > 1
                    ? _("Are you sure you want to export the selected leads (" + listChecked.length + " selected) to :")
                    : _("Are you sure you want to export this lead to :")
            }
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[15.375rem]",
                        onClick: clearAllPopups,
                    },
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[15.375rem]",
                        onClick: removePopup,
                    },
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[15.375rem]",
                        onClick: () => {
                            if(selectedWayExport === "csv"){
                                LeadsService.getLeads()
                                    .then(leads => {
                                        const filteredLeads = leads.filter(lead => listChecked.includes(lead.id));
                                        exportToCSV(filteredLeads.map(lead => [
                                            lead.id,
                                            lead.first_name,
                                            lead.last_name,
                                            lead.full_name,
                                            lead.email,
                                            lead.tel,
                                            lead.avatar,
                                            lead.update_at,
                                            lead.position,
                                            lead.company,
                                            lead.note,
                                            lead.template,
                                            lead.connected,
                                        ]));
                                        clearAllPopups();
                                    })
                                    .catch(err => {
                                        console.error(err);
                                    })
                            }
                        },
                    },
                ]
            }>
            {
                selectedWayExport === "csv"
                    ? <SquareView
                        className="gap-7"
                        isSelected={ selectedWayExport === "csv" }
                        icon={<Icons.SvgSelectorCSV className="h-[3.125rem]"/>}
                        viewName={_(".CSV file")}
                    />
                    : selectedWayExport === "vcf"
                        ? <SquareView
                            className="gap-7"
                            isSelected={ selectedWayExport === "vcf" }
                            icon={<Icons.SvgSelectorCSV className="h-[3.125rem]"/>}
                            viewName={_("Export to VCF")}
                        />
                        : selectedWayExport === "salesforce"
                            ? <SquareView
                                className="gap-7"
                                isSelected={ selectedWayExport === "salesforce" }
                                icon={<Icons.SvgSelectorSalesforce className="h-[3.125rem]"/>}
                                viewName={_("Salesforce")}
                            />
                            : null
            }
        </PopupBase>
    );
}

export default PopupConfirmExport;