import React from 'react';

function SvgMediaX(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="4" fill="black"/>
            <g clipPath="url(#clip0_4772_35012)">
                <path d="M12.6333 5.79297H14.039L10.9681 9.30276L14.5807 14.0788H11.7521L9.53658 11.1822L7.00154 14.0788H5.59508L8.87966 10.3247L5.41406 5.79297H8.31452L10.3171 8.44061L12.6333 5.79297ZM12.14 13.2375H12.9189L7.89131 6.59011H7.0555L12.14 13.2375Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_4772_35012">
                    <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgMediaX;