import {Icons} from "../assets/Svgs";
import React from "react";

export const CONTACT_TYPES = {
    TEL: 'tel',
    MSG: 'msg',
    MAIL: 'mail',
    WEB: 'web',
    LOCATION: 'location',
};

export const contactIconOf = ({ type, props }) => {
    const iconComponents = {
        [CONTACT_TYPES.TEL]: Icons.SvgContactCallFill,
        [CONTACT_TYPES.MSG]: Icons.SvgTemplateCTAMsg,
        [CONTACT_TYPES.MAIL]: Icons.SvgContactMailFill,
        [CONTACT_TYPES.WEB]: Icons.SvgTemplateSectionWebsiteLinks,
        [CONTACT_TYPES.LOCATION]: Icons.SvgTemplateSectionLocation,
    }
    const IconComponent = iconComponents[type];
    if (!IconComponent) return null;
    return <IconComponent {...props} />;
}