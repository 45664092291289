import React, { useEffect } from 'react';
import { Icons } from "../../../assets/Svgs";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import { useTranslation } from "../../../services/i18n/i18nService";
import { Link, useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import PopupItemDelete from "../../Mobile/Popups/PopupItemDelete";
import { usePopup } from "../../../services/PopupService";

function TemplateSectionDraggable(
    {
        widgetId,
        idContent,
        widget_type,
        icon,
        canRedirToContent,
        img,
        sizeImg,
        title,
        displayIconListDraggable,
        displayButtonDelete,
        displayButtonEdit,
        isDisabled,
        setToggle,
        list,
        setList,
        currentItem,
        handleDelete
    }) {

    const { t: _ } = useTranslation();
    const { id, idType } = useParams();
    const { addPopup } = usePopup();

    return (
        <div className="bg-[#F6F7F8] rounded-[0.625rem] flex justify-between px-5 py-2.5">
            <div className="flex justify-center items-center align-center gap-2.5">
                { displayIconListDraggable ? <Icons.SvgTemplateSectionListDraggable /> : null }
                { icon ? icon : null }
                { img ? <img src={img} alt="logo web" className={sizeImg || "h-4 w-4"} /> : null }
                { title ? <p className="text-sm">{ _(title) }</p> : null }
            </div>

            <div className="flex justify-center items-center align-center gap-5">
                {displayButtonDelete && (
                    <ButtonBase
                        onClick={() => addPopup(<PopupItemDelete handleDelete={handleDelete} />)}
                        className="bg-white text-[#FF0000] gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-2 rounded-full"
                    >
                        <Icons.SvgTrashOutline className="h-2.5" color="#FF0000" />
                        {_("Delete")}
                    </ButtonBase>
                )}

                {displayButtonEdit && (
                    <Link to={getFullPath(canRedirToContent ? ["ROOT", "TEMPLATES", "EDIT_TEMPLATE", "EDIT_WIDGET", "EDIT_CONTENT"] : ["ROOT", "TEMPLATES", "EDIT_TEMPLATE", "EDIT_WIDGET"])
                        .replace(":id", id)
                        .replace(":idType", widget_type)
                        .replace(":widgetId", widgetId)
                        .replace(":idContent", idContent)}
                          className="flex justify-center gap-[0.438rem]"
                    >
                        <ButtonBase className="bg-black text-white gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-1.5 rounded-full">
                            {_("Edit")}
                        </ButtonBase>
                    </Link>
                )}

                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" checked={isDisabled} onChange={!!setToggle ? setToggle : undefined} className="sr-only peer" readOnly />
                    <div
                        className={`
                        ${ !isDisabled
                            ? "bg-[#EEEFF2] peer-checked:after:-translate-x-5 peer-checked:bg-[#EEEFF2]"
                            : "bg-black peer-checked:after:translate-x-5 peer-checked:bg-black"
                        }
                        group peer ring-0 rounded-full outline-none duration-300 after:duration-300 w-11 h-6 peer-focus:outline-none after:rounded-full after:absolute after:bg-gray-50 after:outline-none after:h-6 after:w-6 after:flex after:justify-center after:items-center peer-hover:after:scale-90`
                        }
                    />
                </label>
            </div>
        </div>
    );
}

export default TemplateSectionDraggable;