import React from 'react';

function SvgTeamPasswordCheckOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.51 9.75H3.75C3.44 9.75 3.165 9.74 2.92 9.705C1.605 9.56 1.25 8.94 1.25 7.25V4.75C1.25 3.06 1.605 2.44 2.92 2.295C3.165 2.26 3.44 2.25 3.75 2.25H5.48" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.51172 2.25H8.25172C8.56172 2.25 8.83672 2.26 9.08172 2.295C10.3967 2.44 10.7517 3.06 10.7517 4.75V7.25C10.7517 8.94 10.3967 9.56 9.08172 9.705C8.83672 9.74 8.56172 9.75 8.25172 9.75H7.51172" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.5 1V11" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.54803 6H5.55253" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.54803 6H3.55253" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgTeamPasswordCheckOutline;