import React from 'react';

function SvgProfileEditPersoInfosMail({className, color}) {
    return (
        <svg className={`${!!className ? className : "" } absolute top-1/2 left-3 -translate-y-1/2 h-4 `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8333 4.1665H4.16667C3.24619 4.1665 2.5 4.9127 2.5 5.83317V14.1665C2.5 15.087 3.24619 15.8332 4.16667 15.8332H15.8333C16.7538 15.8332 17.5 15.087 17.5 14.1665V5.83317C17.5 4.9127 16.7538 4.1665 15.8333 4.1665Z" stroke={!!color ? color : "#9CA3AF"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.5 5.83301L10 10.833L17.5 5.83301" stroke={!!color ? color : "#9CA3AF"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgProfileEditPersoInfosMail;