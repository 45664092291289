import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Icons } from "../../../assets/Svgs";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import ButtonFlexible from "../../../components/Buttons/ButtonFlexible";
import InputSearch from "../../../components/Layouts/InputSearch";
import { MetricAreaChart } from '../../../components/Layouts/MetricAreaChart';
import { MetricValueVariation } from '../../../components/Layouts/MetricValueVariation';
import PopupBase from "../../../components/Popups/PopupBase";
import { getFullPath } from "../../../router/Router";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import TemplateService from "../../../services/Template/TemplateService";

const onSearch = (listToSearch, keyword) => {
    const lowerKeyword = keyword.toLowerCase();
    return listToSearch.filter(item => {
        const titleMatch = item.title.toLowerCase().includes(lowerKeyword);
        const descriptionMatch = item.description.toLowerCase().includes(lowerKeyword);
        return titleMatch || descriptionMatch;
    });
};

function TemplateCharts() {
    /* Hooks */
    const { t: _ } = useTranslation();
    const { addPopup, removePopup, clearAllPopups } = usePopup();
    const navigate = useNavigate();

    /* States */
    const [generalTemplates, setGeneralTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [toggle, setToggle] = useState(false);
    const [value, setValue] = useState("");
    const [listOriginal, setListOriginal] = useState([]);

    /* Handlers */
    const handleDeleteTemplate = (id) => {
        TemplateService.deleteTemplate(id)
            .then(() => {
                TemplateService.getTemplates()
                    .then(templates => {
                        setGeneralTemplates(templates);
                        setListOriginal(templates);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            })
            .catch(err => {
                console.error(err);
            });
    }

    /* Effects */
    useEffect(() => {
        setLoading(true);
        TemplateService.getTemplates()
            .then(data => {
                setGeneralTemplates(data);
                setListOriginal(data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }, []);

    return (
        <div className="flex flex-col h-screen">
            <header className=" z-10">
                <div className="p-7 flex justify-between">
                    <div className="text-[1.5rem] flex items-center gap-3 font-medium">
                        {_("Template")}
                        {!loading && <span className="text-[#4B5563] text-[1.25rem] leading-5 font-medium">({generalTemplates.length})</span>}
                    </div>

                    <div className="flex items-center gap-7">
                        {!toggle && (
                            <ButtonFlexible onClick={() => { setToggle((prev) => !prev) }} icon={<Icons.SvgSearchView />}>
                                {_("Search")}
                            </ButtonFlexible>
                        )}

                        <InputSearch
                            onSearch={onSearch}
                            value={value}
                            setValue={setValue}
                            toggle={toggle}
                            setToggle={setToggle}
                            placeholder={_("Search a template name")}
                            setList={setGeneralTemplates}
                            listToSearchOriginal={listOriginal}
                            icon={<Icons.SvgSearchView className="absolute top-1/2 left-3 -translate-y-1/2 h-3" />}
                        />

                        <ButtonBase
                            className="bg-black text-white text-sm py-3.5 px-6 rounded-full"
                            iconColor="white"
                            icon={<Icons.SvgAssignOutline color="white" className="h-3" />}
                            onClick={() => { navigate(getFullPath(["ROOT", "TEMPLATES", "CREATE_TEMPLATE"])); }}
                        >
                            {_("New template")}
                        </ButtonBase>
                    </div>
                </div>
            </header>

            <div className="flex-1 overflow-auto gap-7 px-7">
                {generalTemplates && (
                    <div className="flex flex-col flex-1 gap-7">
                        {
                            generalTemplates.map((template, index) => (
                                <div key={index} className="md:flex bg-white rounded-[0.625rem] justify-between items-center p-7 gap-7 md:h-fit">
                                    <div className="h-fit w-full">
                                        <div className="flex items-center justify-between pb-7">
                                            <div className="text-xl font-semibold">{_(template.title)}</div>
                                            <div className="text-sm">{template.company} {_("assigned members")}</div>
                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7">
                                            {[
                                                {
                                                    title: _("Profile Views"),
                                                    chartData: template.metrics.views,
                                                    colors: ["#B09FFF", "#8D79F6"],
                                                },
                                                {
                                                    title: _("Contacts Downloaded"),
                                                    chartData: template.metrics.contacts_downloaded,
                                                    colors: ["#9FFFA9", "#8AF679"],
                                                },
                                                {
                                                    title: _("Leads Generated"),
                                                    chartData: template.metrics.views,
                                                    colors: ["#FFD99F", "#FFAE35"],
                                                }
                                            ].map(({ title, chartData, colors }, index) =>
                                                <div key={index} className="flex flex-col gap-3.5 h-full">
                                                    <header className="flex justify-between items-center w-full">
                                                        <div className="text-sm">{title}</div>
                                                    </header>

                                                    <div className={`flex-col gap-3.5 flex h-full`}>
                                                        <MetricValueVariation 
                                                            value={
                                                                chartData?.slice(-1)?.[0]?.value ?? 0
                                                            }
                                                            previousValue={
                                                                chartData?.[0]?.value ?? 0
                                                            }
                                                        />

                                                        <div className="h-[4rem]">
                                                            <MetricAreaChart
                                                                data = {chartData}
                                                                colors = {colors}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="md:w-2/6 xs:w-full grid gap-3.5">
                                        <Link to={getFullPath(["ROOT", "TEMPLATES", "EDIT_TEMPLATE"]).replace(":id", template.id)}>
                                            <div className="w-full flex flex-col justify-center align-center gap-7">
                                                <ButtonBase className="bg-white text-black shadow-sm w-full text-sm font-medium py-3.5 px-6 rounded-full">
                                                    <Icons.SvgBrushUnderline color="black" className="h-3" />
                                                    {_("Edit Template")}
                                                </ButtonBase>
                                            </div>
                                        </Link>

                                        <div className="w-full flex flex-col justify-center align-center gap-7">
                                            <ButtonBase
                                                onClick={() => {
                                                    addPopup(
                                                        <PopupBase
                                                            title={_("Are you sure you want to duplicate this template ?")}
                                                            footerButtons={[
                                                                {
                                                                    label: _("Back"),
                                                                    background: "bg-white",
                                                                    color: "text-black",
                                                                    onClick: removePopup,
                                                                },
                                                                {
                                                                    label: _("Confirm"),
                                                                    background: "bg-black",
                                                                    color: "text-white",
                                                                    onClick: removePopup,
                                                                }
                                                            ]}
                                                        />
                                                    )
                                                }}
                                                className="bg-white text-black shadow-sm w-full text-sm font-medium py-3.5 px-6 rounded-full"
                                            >
                                                <Icons.SvgTemplateSimCardOutLine className="h-3" />
                                                {_("Duplicate Template")}
                                            </ButtonBase>
                                        </div>

                                        <div className="w-full flex flex-col justify-center align-center gap-7">
                                            <ButtonBase
                                                onClick={() => {
                                                    addPopup(
                                                        <PopupBase
                                                            title={_("Are you sure you want to delete this template?")}
                                                            footerButtons={[
                                                                {
                                                                    label: _("Back"),
                                                                    background: "bg-white",
                                                                    color: "text-black",
                                                                    onClick: removePopup,
                                                                },
                                                                {
                                                                    label: _("Yes, delete this template"),
                                                                    background: "bg-[#FF0000]",
                                                                    color: "text-white",
                                                                    onClick: () => {
                                                                        handleDeleteTemplate(template.id);
                                                                        removePopup();
                                                                    },
                                                                }
                                                            ]}
                                                        >
                                                            <div className="flex text-[1.25rem] justify-center items-center font-medium">
                                                                {_("Note: All the profiles linked to this template will be unlinked, and all the statistics deleted.")}
                                                            </div>
                                                        </PopupBase>
                                                    )
                                                }}
                                                className="bg-white text-[#FF0000] shadow-sm w-full text-sm font-medium py-3.5 px-6 rounded-full"
                                            >
                                                <Icons.SvgTrashOutline color="#FF0000" className="h-3" />
                                                {_("Delete Template")}
                                            </ButtonBase>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )}
            </div>
        </div>
    );
}

export default TemplateCharts;