import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Icons } from "../../../assets/Svgs";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import ButtonDropDown from "../../../components/Buttons/ButtonDropDown";
import ButtonFlexible from "../../../components/Buttons/ButtonFlexible";
import ButtonViewDetails from "../../../components/Buttons/ButtonViewDetails";
import InputSearch from "../../../components/Layouts/InputSearch";
import Table, { SelectionType } from "../../../components/Layouts/Table";
import PopupDeletePersonSelected from "../../../components/Popups/DeletePerson/PopupDeletePersonSelected";
import PopupFilterViewByDivision from "../../../components/Popups/FilterView/PopupFilterViewByDivision";
import PopupResetPasswdSelectedTeam from "../../../components/Popups/Password/PopupResetPasswdSelectedTeam";
import PopupAssignPersonSelected from "../../../components/Popups/TemplateFilter/PopupAssignPersonSelected";
import { getFullPath } from '../../../router/Router';
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import TeamsService from "../../../services/Team/TeamService";
import UserService from '../../../services/User/UserService';
import PopupTemplateFilterPersonView from "../Templates/Popups/PopupTemplateFilterPersonView";
import SwitchTeamView from "./SwitchTeamView";
import TeamCard from "./TeamCard";

const templateStyles = {
    'Direction': 'capitalize py-1 px-2 rounded-[0.625rem] text-sm text-white bg-[#33D933]',
    'Vente': 'capitalize py-1 px-2 rounded-[0.625rem] text-sm text-white bg-[#FF0000]',
    'default': 'capitalize py-1 px-2 rounded-[0.625rem] text-sm text-gray-600 bg-gray-100'
};

const TagTemplate = (template) => {
    const className = templateStyles[template] || templateStyles['default'];
    return (
        <span className={className}>
            {template}
        </span>
    );
};

const TeamTable = ({ listTeams, isSelectAll, setIsSelectAll, listChecked, setListChecked, value, _ }) => (
    <Table
        selectionType={SelectionType.MULTIPLE}
        displayHeaders={false}
        columns={[
            {
                label: _("Team member"),
                sortable: true,
                sortOn: value => value.props.sort_on,
            },
            {
                label: _("Job position"),
                sortable: true,
                sortOn: value => value.props.sort_on,
            },
            {
                label: _("Template"),
                sortable: true,
                sortOn: value => value.props.sort_on,
            },
            {
                label: _("Access"),
                sortable: true,
                sortOn: value => value.props.sort_on,
            },
            {
                label: _("View"),
                sortable: false,
            },
        ]}
        rows={listTeams.map((lead) => ({
            id: lead.id,
            data: [
                {
                    value: <div className="flex items-center gap-[1.5rem]">
                        <img src={lead.avatar} className="rounded-full h-8 w-8" alt="avatar" />
                        <span>{lead.first_name} {lead.last_name}</span>
                    </div>,
                },
                {
                    value: <div className="flex items-center justify-center gap-[1.5rem]">
                        <span>{lead.position}</span>
                    </div>,
                },
                {
                    value: <div className="flex items-center justify-center gap-[1.5rem]">
                        <span>{TagTemplate(lead.template)}</span>
                    </div>,
                },
                {
                    value: <div className="flex items-center justify-center gap-[1.5rem]">
                        <span>
                            {
                                lead.isAdmin && (
                                    <span className="capitalize border border-orange-gradient py-1 px-2 rounded-[0.625rem] text-sm text-black">
                                        {_("ADMIN")}
                                    </span>)
                            }
                        </span>
                    </div>,
                },
                {
                    value: <Link to={getFullPath(["ROOT", "TEAM", "VIEW_TEAM_MEMBER"]).replace(":id", lead.id)} className="flex justify-center gap-[0.438rem]">
                        <ButtonViewDetails />
                    </Link>,
                },
            ]
        }))}
    />
);

const TeamGrid = ({ listTeams, isSelectAll, setIsSelectAll, listChecked, setListChecked }) => (
    <div className="w-full gap-7 justify-center align-center grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 h-auto">
        {listTeams.map((team) => (
            <TeamCard
                isSelectAll={isSelectAll}
                setIsSelectAll={setIsSelectAll}
                listChecked={listChecked}
                setListChecked={setListChecked}
                key={team.id}
                team={team}
            />
        ))}
    </div>
);

const onSearch = (listToSearch, keyword) => {
    if (!keyword) return listToSearch;
    const lowerCaseKeyword = keyword.toLowerCase();
    return listToSearch.filter(item => {
        return item.first_name.toLowerCase().includes(lowerCaseKeyword) ||
               item.email.toLowerCase().includes(lowerCaseKeyword);
    });
}

function Team() {
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const [listTeams, setListTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [listChecked, setListChecked] = useState([]);
    const [selectedView, setSelectedView] = useState("templates");
    const [toggle, setToggle] = useState(false);
    const [toggleSearch, setToggleSearch] = useState(false);
    const [value, setValue] = useState("");
    const [listOriginal, setListOriginal] = useState([]);

    useEffect(() => {
        setLoading(true);
        TeamsService.getTeamMembers()
            .then(async teams => {
                const currentUser = await UserService.getCurrentUser();
                const userCompanyTeam = teams.filter(team => 
                    team.membership?.company === currentUser.membership?.company
                );
                setListTeams(userCompanyTeam);
                setListOriginal(userCompanyTeam);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    const links = [
        {
            id: 0,
            icon: <Icons.SvgAssignOutline className="h-3" />,
            iconColor: "#000000",
            label: "Assign a template",
            onClick: () => {
                addPopup(<PopupAssignPersonSelected listChecked={listChecked} />)
            },
        },
        {
            id: 1,
            icon: <Icons.SvgLeadsReassign className="h-3" />,
            iconColor: "#000000",
            label: "Reset password",
            onClick: () => {
                addPopup(<PopupResetPasswdSelectedTeam listChecked={listChecked} />)
            },
        },
        {
            id: 2,
            icon: <Icons.SvgTeamThreePersonOutline className="h-3" />,
            iconColor: "#000000",
            label: "Assign to a Division",
            onClick: () => {
                addPopup(<PopupFilterViewByDivision isTeamAssign={true} listChecked={listChecked} />)
            },
        },
        {
            id: 3,
            icon: <Icons.SvgTrashOutline className="h-3" />,
            iconColor: "#FF0000",
            label: "Delete",
            onClick: () => {
                addPopup(<PopupDeletePersonSelected objectToDelete="teams" listChecked={listChecked} />)
            },
        }
    ]

    return (
        <div className="flex flex-col h-screen">
            <header className=" z-10">
                <div className="p-7 flex justify-between">
                    <div className="text-[1.5rem] gap-3 flex items-center font-medium">
                        {_("Team")}
                        {!loading && <span className="text-[#4B5563] text-[1.25rem] font-medium leading-5">({listTeams.length})</span>}
                    </div>

                    <div className="flex gap-7">
                        <SwitchTeamView setToggle={setToggle} toggle={toggle} />

                        <ButtonFlexible
                            icon={<Icons.SvgStatisticFilterOutline />}
                            onClick={() => addPopup(
                                <PopupTemplateFilterPersonView title="Filter the team member view by :" selectedView={selectedView} onSelectedViewChange={setSelectedView} />
                            )}
                        >
                            {_("Filter")}
                        </ButtonFlexible>

                        {
                            !toggleSearch && (
                                <ButtonFlexible onClick={() => { setToggleSearch((prev) => !prev) }} icon={<Icons.SvgSearchView />}>
                                    {_("Search")}
                                </ButtonFlexible>
                            )
                        }

                        <InputSearch
                            onSearch={onSearch}
                            value={value}
                            setValue={setValue}
                            toggle={toggleSearch}
                            setToggle={setToggleSearch}
                            placeholder={_("Search a name, template, mail or job position")}
                            setList={setListTeams}
                            listToSearchOriginal={listOriginal}
                            icon={<Icons.SvgSearchView className="absolute top-1/2 left-3 -translate-y-1/2 h-3" />}
                        />

                        <ButtonFlexible onClick={() => setIsSelectAll(!isSelectAll)} icon={<Icons.SvgCheckboxFill />}>
                            {_("Select all")}
                        </ButtonFlexible>

                        <ButtonDropDown links={links} />

                        <Link to={getFullPath(["ROOT", "TEAM", "ADD_TEAM_MEMBER"])} className="flex justify-center">
                            <ButtonBase className="bg-black text-white text-sm h-10 py-3.5 px-6 rounded-full" iconColor="#ffffff" icon={<Icons.SvgUserAddOutline />}>{_("New profile")}</ButtonBase>
                        </Link>
                    </div>
                </div>
            </header>

            <div className="flex-1 overflow-auto gap-7 px-7">
                {
                    toggle ? (
                        <TeamTable
                            listTeams={listTeams}
                            isSelectAll={isSelectAll}
                            setIsSelectAll={setIsSelectAll}
                            listChecked={listChecked}
                            setListChecked={setListChecked}
                            value={value}
                            _={_}
                        />
                    ) : (
                        <TeamGrid
                            listTeams={listTeams}
                            isSelectAll={isSelectAll}
                            setIsSelectAll={setIsSelectAll}
                            listChecked={listChecked}
                            setListChecked={setListChecked}
                        />
                    )
                }
            </div>
        </div>
    );
}

export default Team;