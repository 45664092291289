import React from 'react';
import {Icons} from "../../assets/Svgs";

function FieldCopy({label, canCopy, children}) {
    return (
        <div className="flex flex-col items-start gap-3.5">
            <p className="flex text-sm text-[#4B5563] gap-[0.438rem]">
                { label }
                { canCopy ? <Icons.SvgTemplateSimCardOutLine className="h-3" /> : null }
            </p>
            <span className="text-[0.875rem] font-medium leading-[0.875rem]">
                {
                    !!children ? children : "-"
                }
            </span>
        </div>
    );
}

export default FieldCopy;