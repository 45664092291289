import React from 'react';

function SvgProfileWebExport({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1491_2684)">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.2704 0.321289L15.6795 3.73036L12.2703 7.1383L11.377 6.2446L13.2612 4.36106H9.35636C9.00027 4.36106 8.65877 4.50251 8.40697 4.7543C8.15518 5.0061 8.01372 5.3476 8.01372 5.70369V8.17203H6.75007V5.70369C6.75007 5.01246 7.02466 4.34954 7.51343 3.86076C8.00221 3.37199 8.66513 3.0974 9.35636 3.0974H13.2594L11.3769 1.21483L12.2704 0.321289ZM2.94014 6.33763C2.84581 6.33763 2.75533 6.37507 2.6886 6.44174C2.62192 6.50835 2.58438 6.59868 2.58421 6.69291V14.5899C2.58438 14.6841 2.62187 14.7744 2.68846 14.841C2.75499 14.9075 2.84515 14.945 2.93922 14.9452C2.93903 14.9452 2.9394 14.9452 2.93922 14.9452L11.824 14.9452C11.8238 14.9452 11.8242 14.9452 11.824 14.9452C11.9182 14.945 12.0089 14.9075 12.0755 14.8408C12.1421 14.7741 12.1796 14.6836 12.1796 14.5893V9.65262H13.4432V14.5893C13.4432 15.0185 13.2728 15.4302 12.9695 15.7339C12.6661 16.0376 12.2546 16.2084 11.8254 16.2089L2.93909 16.2089C2.51015 16.2084 2.09823 16.0378 1.79492 15.7345C1.49161 15.4312 1.32101 15.02 1.32056 14.591L1.32056 6.6925C1.32101 6.26326 1.49185 5.85108 1.79553 5.54772C2.09922 5.24437 2.5109 5.07397 2.94014 5.07397H4.41967V6.33763H2.94014Z" fill={!!color ? color : "#9CA3AF"}/>
            </g>
            <defs>
                <clipPath id="clip0_1491_2684">
                    <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgProfileWebExport;