import { useTranslation } from "../../services/i18n/i18nService";

const ButtonToggle = ({ onChange, isToggled, className }) => {
    const { t: _ } = useTranslation();

    return (
        <label className={`relative inline-flex items-center cursor-pointer ${className || ""}`}>
            <input type="checkbox" checked={isToggled || false} onChange={onChange} className="sr-only peer" readOnly />
            <div
                className={`
                ${ !isToggled
                    ? "bg-[#EEEFF2] peer-checked:after:-translate-x-5 peer-checked:bg-[#EEEFF2]"
                    : "bg-black peer-checked:after:translate-x-5 peer-checked:bg-black"
                }
                group peer ring-0 rounded-full outline-none duration-300 after:duration-300 w-11 h-6 peer-focus:outline-none after:rounded-full after:absolute after:bg-gray-50 after:outline-none after:h-6 after:w-6 after:flex after:justify-center after:items-center peer-hover:after:scale-90`
                }
            />
        </label>
    );
}

export default ButtonToggle;
