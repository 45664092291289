import React from 'react';

function SvgTrashOutline(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 2.99023C9.335 2.82523 7.66 2.74023 5.99 2.74023C5 2.74023 4.01 2.79023 3.02 2.89023L2 2.99023" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.75 2.485L4.86 1.83C4.94 1.355 5 1 5.845 1H7.155C8 1 8.065 1.375 8.14 1.835L8.25 2.485" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.92422 4.57031L9.59922 9.60531C9.54422 10.3903 9.49922 11.0003 8.10422 11.0003H4.89422C3.49922 11.0003 3.45422 10.3903 3.39922 9.60531L3.07422 4.57031" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.66406 8.25H7.32906" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.25 6.25H7.75" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
}

export default SvgTrashOutline;