import React, {useState} from 'react';
import {useTranslation} from "../../../../services/i18n/i18nService";
import {usePopup} from "../../../../services/PopupService";
import PopupBase from "../../../../components/Popups/PopupBase";
import Table, {SelectionType} from "../../../../components/Layouts/Table";
import TagTemplate from "../../../../components/Tags/TagTemplate";
import InputSearch from "../../../../components/Layouts/InputSearch";
import {Icons} from "../../../../assets/Svgs";

function PopupDivisionAssignNewMember({listTeams}) {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();
    const [ isSelectAll, setIsSelectAll ] = useState(false);
    const [ listChecked, setListChecked ] = useState([]);
    const [ value, setValue ] = useState("");
    const [ connections, setConnections ] = useState(listTeams ?? []);
    const [ connectionsOriginal, setConnectionsOriginal ] = useState(listTeams ?? []);

    const onSearch = (listToSearch, keyword) => {
        if (!keyword) return listToSearch;
        const lowerCaseKeyword = keyword.toLowerCase();
        return listToSearch.filter(item => {
            return item.position.toLowerCase().includes(lowerCaseKeyword) ||
                   item.template.toLowerCase().includes(lowerCaseKeyword) ||
                   item.full_name.toLowerCase().includes(lowerCaseKeyword);
        });
    }    

    return (
        <PopupBase
            title={ _("Please select the team member you want to add to this division") }
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[30rem]",
                        onClick: removePopup,
                    },
                    {
                        label: _("Add this selection (" + listChecked.length + " selected)"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[30rem]",
                        onClick: removePopup,
                    },
                ]
            }>
            <div className="flex-1 h-80 overflow-scroll">

                <InputSearch
                    onSearch={onSearch}
                    value={value}
                    setValue={setValue}
                    setList={setConnections}
                    listToSearchOriginal={connectionsOriginal}
                    icon={<Icons.SvgTeamUserSearch color="#D1D5DB" className="absolute top-1/2 left-3 -translate-y-1/2 h-4" />}
                    placeholder={"Search name, email or template "}
                    isFlexible={false}
                />

                <div className="flex">
                    {
                        listTeams
                            ?  <Table
                                selectionType={SelectionType.MULTIPLE}
                                listChecked={ listChecked }
                                setListChecked={ setListChecked }
                                columns={[
                                    {
                                        label: _("Team Member"),
                                        sortable: true,
                                        sortOn: value => value.props.sort_on,
                                    },
                                    {
                                        label: _("Role"),
                                        sortable: true,
                                        sortOn: value => value.props.sort_on,
                                    },
                                    {
                                        label: _("Template"),
                                        sortable: true,
                                        sortOn: value => value.props.sort_on,
                                    },
                                ]}
                                rows={connections.map( (lead) =>
                                    ({
                                        id: lead.id,
                                        data: [
                                            {
                                                value: <div sort_on={lead.last_name} className="flex items-center justify-center gap-[1.5rem]">
                                                    <img src={lead.avatar} className="rounded-full h-8 w-8"  alt="avatar" />
                                                    <span>{ lead.first_name } { lead.last_name }</span>
                                                </div>,
                                            },
                                            {
                                                value: <div sort_on={lead.last_name} className="flex items-center justify-center gap-[1.5rem]">
                                                    <span>{ lead.position }</span>
                                                </div>,
                                            },
                                            {
                                                value: <div sort_on={lead.last_name} className="flex items-center justify-center gap-[1.5rem]">
                                                    <span>{ TagTemplate(lead.template) }</span>
                                                </div>,
                                            },
                                        ]
                                    })
                                )}
                            />
                            : null
                    }

                </div>
            </div>
        </PopupBase>
    );
}

export default PopupDivisionAssignNewMember;