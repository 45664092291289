import React from 'react';
import PopupBase from "../PopupBase";
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import {useNavigate} from "react-router-dom";

function PopupSaveModification({onHandleRedirectToPrev}) { // TODO: rename onHandleRedirectToPrev to onConfirm

    const { clearAllPopups, addPopup } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={_("Are you sure you want to save your modifications ?")}
            footerButtons={
                [
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[23.375rem]",
                        onClick: clearAllPopups
                    },
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[23.375rem]",
                        onClick: () => {
                            onHandleRedirectToPrev();
                            clearAllPopups();
                        }
                    },
                ]
            }>
        </PopupBase>
    );
}

export default PopupSaveModification;