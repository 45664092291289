import LogoProfylead from "../Imgs/LogoProfylead.svg";
import LoginBg from "../Imgs/LoginBg.svg";
import ProfileModelWebLogo from "../Imgs/ProfileModelWebLogo.svg";
import SvgSidebarLeads from "./Svg.SidebarLeads";
import SvgSidebarStatistics from "./Svg.SidebarStatistics";
import SvgSidebarTeam from "./Svg.SidebarTeam";
import SvgSidebarTemplates from "./Svg.SidebarTemplates";
import SvgSidebarSettings from "./Svg.SidebarSettings";
import SvgFilterSearchOutline from "./Svg.FilterSearchOutline";
import SvgArrowReturnOutline from "./Svg.ArrowReturnOutline";
import SvgStatisticFilterOutline from "./Svg.StatisticFilterOutline";
import SvgSelectorCalendarOutline from "./Svg.SelectorCalendarOutline";
import SvgSearchView from "./Svg.SearchView";
import SvgArrangeDownUpFill from "./Svg.ArrangeDownUpFill";
import SvgArrangeAscFill from "./Svg.ArrangeAscFill";
import SvgUserAddOutline from "./Svg.UserAddOutline";
import SvgLeadsReassign from "./Svg.LeadsReassign";
import SvgAssignOutline from "./Svg.AssignOutline";
import SvgTrashOutline from "./Svg.TrashOutline";
import SvgStatisticCaretUpFill from "./Svg.StatisticCaretUpFill";
import SvgStatisticCaretDownFill from "./Svg.StatisticCaretDownFill";
import SvgMediaTwitterFill from "./Svg.MediaTwitterFill";
import SvgMediaWhatsappFill from "./Svg.MediaWhatsappFill";
import SvgMediaLinkedinFill from "./Svg.MediaLinkedinFill";
import SvgMediaFacebookFill from "./Svg.MediaFacebookFill";
import SvgMediaYoutubeFill from "./Svg.MediaYoutubeFill";
import SvgStatisticSearchStatus from "./Svg.StatisticSearchStatus";
import SvgStatisticClickFill from "./Svg.StatisticClickFill";
import SvgStatisticEyeFill from "./Svg.StatisticEyeFill";
import SvgStatisticCirclePlusFill from "./Svg.StatisticCirclePlusFill";
import SvgStatisticChevronCompactDownOutline from "./Svg.StatisticChevronCompactDownOutline";
import SvgArrowRightOutline from "./Svg.ArrowRightOutline";
import SvgProfileTwoUser from "./Svg.ProfileTwoUser";
import SvgStatisticUserCircleAdd from "./Svg.StatisticUserCircleAdd";
import SvgStatisticFileDownload from "./Svg.StatisticFileDownload";
import SvgContactCallFill from "./Svg.ContactCallFill";
import SvgContactLocationFill from "./Svg.ContactLocationFill";
import SvgContactSmsFill from "./Svg.ContactSmsFill";
import SvgContactMessageFill from "./Svg.ContactMessageFill";
import SvgContactGlobalFill from "./Svg.ContactGlobalFill";
import SvgTemplateTwoPersonFill from "./Svg.TemplateTwoPersonFill";
import SvgArrowLeftRightOutline from "./Svg.ArrowLeftRightOutline";
import SvgTemplateSimCardOutLine from "./Svg.TemplateSimCardOutLine";
import SvgCheckboxFill from "./Svg.CheckboxFill";
import SvgTeamPreviewCard from "./Svg.TeamPreviewCard";
import SvgTeamPreviewTable from "./Svg.TeamPreviewTable";
import SvgTeamPasswordCheckOutline from "./Svg.TeamPasswordCheckOutline";
import SvgTeamEyeOutline from "./Svg.TeamEyeOutline";
import SvgTeamSaveUserOutline from "./Svg.TeamSaveUserOutline";
import SvgTeamUserOctagon from "./Svg.TeamUserOctagon";
import SvgTeamThreePersonOutline from "./Svg.TeamThreePersonOutline";
import SvgTeamWarningFilled from "./Svg.TeamWarningFilled";
import SvgSidebarCustomer from "./Svg.SidebarCustomer";
import SvgSidebarCardOutline from "./Svg.SidebarCardOutline";
import SvgSidebarContracts from "./Svg.SidebarContracts";
import SvgBrushUnderline from "./Svg.BrushUnderline";
import SvgLeadsTagOutline from "./Svg.LeadsTagOutline";
import SvgLeadsMailOutline from "./Svg.LeadsMailOutline";
import SvgSidebarDivision from "./Svg.SidebarDivision";
import SvgAccountKeyOutline from "./Svg.AccountKeyOutline";
import SvgStatisticGlassOutline from "./Svg.StatisticGlassOutline";
import SvgCardSave from "./Svg.CardSave";
import SvgCardAssignOutline from "./Svg.CardAssignOutline";
import SvgBrushOutline from "./Svg.BrushOutline";
import SvgCardUnAssignOutline from "./Svg.CardUnAssignOutline";
import SvgArrangeAscOutline from "./Svg.ArrangeAscOutline";
import SvgTemplatePersonAdd from "./Svg.TemplatePersonAdd";
import SvgLeadsExportOutline from "./Svg.LeadsExportOutline";
import SvgSelectorGeneral from "./Svg.SelectorGeneral";
import SvgSelectorTemplate from "./Svg.SelectorTemplate";
import SvgSelectorSalesforce from "./Svg.SelectorSalesforce";
import SvgTemplatesExport from "./Svg.TemplatesExport";
import SvgTemplatesExportGradient from "./Svg.TemplatesExportGradient";
import SvgTemplateAddNewSection from "./Svg.TemplateAddNewSection";
import SvgTemplateSectionListDraggable from "./Svg.TemplateSectionListDraggable";
import SvgTemplateSectionText from "./Svg.TemplateSectionText";
import SvgTemplateSectionCalendly from "./Svg.TemplateSectionCalendly";
import SvgTemplateSectionCTA from "./Svg.TemplateSectionCTA";
import SvgTemplateSectionGoogleReview from "./Svg.TemplateSectionGoogleReview";
import SvgTemplateSectionAppLink from "./Svg.TemplateSectionAppLink";
import SvgTemplateSectionVideo from "./Svg.TemplateSectionVideo";
import SvgTemplateSectionPicture from "./Svg.TemplateSectionPicture";
import SvgTemplateSectionInstagramFeed from "./Svg.TemplateSectionInstagramFeed";
import SvgTemplateSectionLocation from "./Svg.TemplateSectionLocation";
import SvgMediaInstagram from "./Svg.MediaInstagram";
import SvgMediaX from "./Svg.MediaX";
import SvgMediaTikTok from "./Svg.MediaTikTok";
import SvgContactMailFill from "./Svg.ContactMailFill";
import SvgTemplateSectionWebsiteLinks from "./Svg.TemplateSectionWebsiteLinks";
import SvgTemplateCTAMsg from "./Svg.TemplateCTAMsg";
import SvgTemplateInstagramWhite from "./Svg.TemplateInstagramWhite";
import SvgTemplateSectionSocialMedia from "./Svg.TemplateSectionSocialMedia";
import SvgTemplateSectionForm from "./Svg.TemplateSectionForm";
import SvgTemplateSectionFile from "./Svg.TemplateSectionFile";
import SvgTemplateSectionPaypalCTA from "./Svg.TemplateSectionPaypalCTA";
import SvgTemplateSectionWhatsAppCTA from "./Svg.TemplateSectionWhatsAppCTA";
import SvgTemplateSectionQuickContact from "./Svg.TemplateSectionQuickContact";
import SvgTemplateFormConfirm from "./Svg.TemplateFormConfirm";
import SvgTemplateFormSex from "./Svg.TemplateFormSex";
import SvgSettingsSalesForce from "./Svg.SettingsSalesForce";
import SvgSettingsOutlook from "./Svg.SettingsOutlook";
import SvgSettingsHubspot from "./Svg.SettingsHubspot";
import SvgSettingsZoho from "./Svg.SettingsZoho";
import SvgSettingsDynamics from "./Svg.SettingsDynamics";
import SvgStatisticTeamMemberThree from "./Svg.StatisticTeamMemberThree";
import SvgTranslateEnglish from "./Svg.TranslateEnglish";
import SvgTranslateFrench from "./Svg.TranslateFrench";
import SvgTranslateDeustch from "./Svg.TranslateDeustch";
import SvgTranslateItalian from "./Svg.TranslateItalian";
import SvgTeamUserSearch from "./Svg.TeamUserSearch";
import SvgProfileEditPersoInfosName from "./Svg.ProfileEditPersoInfosName";
import SvgLoginPasswd from "./Svg.LoginPasswd";
import SvgLoginArrowCircleLeft from "./Svg.LoginArrowCircleLeft";
import SvgLoginArrowCircleRight from "./Svg.LoginArrowCircleRight";
import SvgLoginProfyleadLogo from "./Svg.LoginProfyleadLogo";
import SvgLoginEditPicture from "./Svg.LoginEditPicture";
import SvgProfileSectionEdit from "./Svg.ProfileSectionEdit";
import SvgProfileWebExport from "./Svg.ProfileWebExport";
import SvgProfileDocDownload from "./Svg.ProfileDocDownload";
import SvgProfileModelContactTel from "./Svg.ProfileModelContactTel";
import SvgProfileModelContactMail from "./Svg.ProfileModelContactMail";
import SvgProfileModelContactWeb from "./Svg.ProfileModelContactWeb";
import SvgProfileModelContactLocation from "./Svg.ProfileModelContactLocation";
import SvgProfileModelContactMsg from "./Svg.ProfileModelContactMsg";
import SvgProfileAddSection from "./Svg.ProfileAddSection";
import SvgProfileReorganizeSection from "./Svg.ProfileReorganizeSection";
import SvgFooterConnection from "./Svg.FooterConnection";
import SvgFooterProfile from "./Svg.FooterProfile";
import SvgFooterStatistic from "./Svg.FooterStatistic";
import SvgFooterSetting from "./Svg.FooterSetting";
import SvgProfileEditPersoInfosMail from "./Svg.ProfileEditPersoInfosMail";
import SvgProfileEditPersoInfosCompany from "./Svg.ProfileEditPersoInfosCompany";
import SvgProfileEditPersoInfosPosition from "./Svg.ProfileEditPersoInfosPosition";
import SvgProfileEditHeaderSave from "./Svg.ProfileEditHeaderSave";
import SvgProfileEditHeaderCancel from "./Svg.ProfileEditHeaderCancel";
import SvgProfileEditText from "./Svg.ProfileEditText";
import SvgProfileEditArrowSquareDown from "./Svg.ProfileEditArrowSquareDown";
import SvgProfileEditArrowSquareUp from "./Svg.ProfileEditArrowSquareUp";
import SvgProfileEditArrowSquare from "./Svg.ProfileEditArrowSquare";
import SvgProfilePopupClose from "./Svg.ProfilePopupClose";
import SvgProfilePopupMedia from "./Svg.ProfilePopupMedia";
import SvgProfilePopupMediaLink from "./Svg.ProfilePopupMediaLink";
import SvgProfilePopupDelete from "./Svg.ProfilePopupDelete";
import SvgProfilePopupPicture from "./Svg.ProfilePopupPicture";
import SvgProfileAddPicture from "./Svg.ProfileAddPicture";
import SvgProfileEditDoc from "./Svg.ProfileEditDoc";
import SvgProfilePopupWeb from "./Svg.ProfilePopupWeb";
import SvgProfilePopupCta from "./Svg.ProfilePopupCta";
import SvgProfileReorganizeMedia from "./Svg.ProfileReorganizeMedia";
import SvgProfileReorganizeVideo from "./Svg.ProfileReorganizeVideo";
import SvgProfileReorganizePicture from "./Svg.ProfileReorganizePicture";
import SvgProfileReorganizeCta from "./Svg.ProfileReorganizeCta";
import SvgConnectionFlag from "./Svg.ConnectionFlag";
import SvgConnectionPopupContactAdd from "./Svg.ConnectionPopupContactAdd";
import SvgConnectionFilterSticker from "./Svg.ConnectionFilterSticker";
import SvgProfileClientLogo from "./Svg.ProfileClientLogo";
import SvgProfileClientLogin from "./Svg.ProfileClientLogin";
import SvgSidebarProfylead from "./Svg.SidebarProfylead";
import SvgMediaTiktokFill from "./Svg.MediaTiktokFill";
import SvgProfileSaveContact from "./Svg.ProfileSaveContact";
import SvgProfilePopupExchangeContact from "./Svg.ProfilePopupExchangeContact";
import SvgFilterUserSearch from "./Svg.FilterUserSearch";
import SvgArrangeDescFill from "./Svg.ArrangeDescFill";
import SvgLeadsImportOutline from "./Svg.LeadsImportOutline";
import SvgSelectorCSV from "./Svg.SelectorCSV";
import SvgLeadsTagClose from "./Svg.LeadsTagClose";
import SvgPortfolioUserEdit from "./Svg.PortfolioUserEdit";
import SvgSidebarStatisticsAdmin from "./Svg.SidebarStatisticsAdmin";

export const Icons = {
    LogoProfylead,
    LoginBg,
    ProfileModelWebLogo,

    SvgSidebarLeads,
    SvgSidebarStatistics,
    SvgSidebarTeam,
    SvgSidebarStatisticsAdmin,
    SvgSidebarTemplates,
    SvgSidebarSettings,
    SvgSidebarProfylead,
    SvgSidebarContracts,
    SvgSidebarCustomer,
    SvgSidebarCardOutline,
    SvgSidebarDivision,

    SvgFilterSearchOutline,
    SvgFilterUserSearch,

    SvgArrowReturnOutline,
    SvgArrowLeftRightOutline,
    SvgArrangeDownUpFill,
    SvgArrangeAscFill,
    SvgArrangeDescFill,
    SvgArrangeAscOutline,
    SvgArrowRightOutline,

    SvgMediaTwitterFill,
    SvgMediaWhatsappFill,
    SvgMediaLinkedinFill,
    SvgMediaFacebookFill,
    SvgMediaTiktokFill,
    SvgMediaYoutubeFill,
    SvgMediaInstagram,
    SvgMediaX,
    SvgMediaTikTok,

    SvgStatisticCaretUpFill,
    SvgStatisticCaretDownFill,
    SvgStatisticGlassOutline,
    SvgStatisticFilterOutline,
    SvgStatisticSearchStatus,
    SvgStatisticClickFill,
    SvgStatisticEyeFill,
    SvgStatisticCirclePlusFill,
    SvgStatisticChevronCompactDownOutline,
    SvgStatisticTeamMemberThree,
    SvgStatisticFileDownload,
    SvgStatisticUserCircleAdd,

    SvgContactMailFill,
    SvgContactCallFill,
    SvgContactLocationFill,
    SvgContactSmsFill,
    SvgContactMessageFill,
    SvgContactGlobalFill,

    SvgAccountKeyOutline,

    SvgCardSave,
    SvgCardUnAssignOutline,
    SvgCardAssignOutline,

    SvgTeamThreePersonOutline,
    SvgTeamPreviewCard,
    SvgTeamPreviewTable,
    SvgTeamUserSearch,
    SvgTeamWarningFilled,
    SvgTeamEyeOutline,
    SvgTeamSaveUserOutline,
    SvgTeamPasswordCheckOutline,
    SvgTeamUserOctagon,

    SvgLeadsReassign,
    SvgLeadsImportOutline,
    SvgLeadsTagOutline,
    SvgLeadsMailOutline,
    SvgLeadsExportOutline,
    SvgLeadsTagClose,

    SvgSelectorCalendarOutline,
    SvgSelectorGeneral,
    SvgSelectorTemplate,
    SvgSelectorSalesforce,
    SvgSelectorCSV,

    SvgTemplatePersonAdd,
    SvgTemplateSimCardOutLine,
    SvgTemplateTwoPersonFill,
    SvgTemplatesExport,
    SvgTemplatesExportGradient,
    SvgTemplateAddNewSection,
    SvgTemplateSectionListDraggable,
    SvgTemplateSectionText,
    SvgTemplateSectionCalendly,
    SvgTemplateSectionCTA,
    SvgTemplateSectionGoogleReview,
    SvgTemplateSectionAppLink,
    SvgTemplateSectionVideo,
    SvgTemplateSectionPicture,
    SvgTemplateSectionInstagramFeed,
    SvgTemplateSectionLocation,

    SvgTemplateSectionWebsiteLinks,
    SvgTemplateCTAMsg,
    SvgTemplateInstagramWhite,
    SvgTemplateSectionSocialMedia,
    SvgTemplateSectionForm,
    SvgTemplateSectionFile,
    SvgTemplateSectionPaypalCTA,
    SvgTemplateSectionWhatsAppCTA,
    SvgTemplateSectionQuickContact,
    SvgTemplateFormConfirm,
    SvgTemplateFormSex,

    SvgSettingsSalesForce,
    SvgSettingsOutlook,
    SvgSettingsHubspot,
    SvgSettingsZoho,
    SvgSettingsDynamics,

    SvgTranslateEnglish,
    SvgTranslateFrench,
    SvgTranslateDeustch,
    SvgTranslateItalian,

    SvgProfileEditPersoInfosName,
    SvgLoginPasswd,
    SvgLoginArrowCircleLeft,
    SvgLoginArrowCircleRight,
    SvgLoginProfyleadLogo,
    SvgLoginEditPicture,

    SvgPortfolioUserEdit,
    SvgProfileTwoUser,
    SvgProfileSectionEdit,
    SvgProfileDocDownload,
    SvgProfileModelContactTel,
    SvgProfileModelContactMail,
    SvgProfileModelContactWeb,
    SvgProfileModelContactLocation,
    SvgProfileModelContactMsg,
    SvgProfileAddSection,
    SvgProfileReorganizeSection,
    SvgProfileWebExport,
    SvgProfileEditPersoInfosMail,
    SvgProfileEditPersoInfosCompany,
    SvgProfileEditPersoInfosPosition,
    SvgProfileEditHeaderSave,
    SvgProfileEditHeaderCancel,
    SvgProfileEditText,
    SvgProfileEditArrowSquareDown,
    SvgProfileEditArrowSquareUp,
    SvgProfileEditArrowSquare,
    SvgProfilePopupClose,
    SvgProfilePopupMedia,
    SvgProfilePopupMediaLink,
    SvgProfilePopupDelete,
    SvgProfilePopupPicture,
    SvgProfileAddPicture,
    SvgProfileEditDoc,
    SvgProfilePopupWeb,
    SvgProfilePopupCta,
    SvgProfileReorganizeMedia,
    SvgProfileReorganizeVideo,
    SvgProfileReorganizePicture,
    SvgProfileReorganizeCta,
    SvgProfileClientLogo,
    SvgProfileClientLogin,
    SvgProfileSaveContact,
    SvgProfilePopupExchangeContact,
    SvgConnectionFlag,
    SvgConnectionPopupContactAdd,
    SvgConnectionFilterSticker,

    SvgFooterConnection,
    SvgFooterProfile,
    SvgFooterStatistic,
    SvgFooterSetting,

    SvgSearchView,
    SvgUserAddOutline,
    SvgAssignOutline,
    SvgTrashOutline,
    SvgBrushUnderline,
    SvgBrushOutline,
    SvgCheckboxFill,
};
