import React from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {useLocation, useNavigate} from "react-router-dom";
import ButtonLoginPrevious from "../../../components/Buttons/ButtonLoginPrevious";
import {getFullPath} from "../../../router/Router";
import InputField from "../../../components/Layouts/InputField";
import {Icons} from "../../../assets/Svgs";
import StorageService from "../../../services/StorageService";
import Transition from "../../../transition";
import AuthService from '../../../services/Auth/AuthService';

function Password() {
    const { t: _ } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const transitionType = searchParams.get('transition');

    const inputs = [
        {
            id: 1,
            name: "passwd",
            type: "password",
            placeholder: "Enter your password",
            label: "Password",
            errorMsg: "",
            pattern: "",
            required: false,
        },
        {
            id: 2,
            name: "passwdConfirm",
            type: "password",
            placeholder: "Confirm your password",
            label: "Confirm your password",
            errorMsg: "",
            pattern: "",
            required: false,
        },
    ]

    const [values, setValues] = StorageService.useLocalStorage("passwd", {
        passwd: "",
        passwdConfirm: "",
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const [personalInfoValues] = StorageService.useLocalStorage("personalInfo", {
        first_name: "",
        last_name: "",
    });

    const [positionValues] = StorageService.useLocalStorage("position", {
        position: "",
        company: "",
    });

    const [profilePictureValues] = StorageService.useLocalStorage("profilePicture", {
        avatar: "",
    });

    const [bgPictureValues] = StorageService.useLocalStorage("bgPicture", {
        background: "",
    });

    return (
        <Transition transitionType={transitionType}>
            <div className="flex w-full h-full bg-white">
                <ButtonLoginPrevious onClick={() => {
                    navigate(getFullPath(["ROOT", "BG_PICTURE"]) + "?transition=turn-right");
                }} />

                <div className="absolute top-32 w-full">
                    <div className="ss:p-20 w-full px-6 gap-4 ss:border-0 bg-white xxs:rounded-[0.914rem] flex flex-col align-center items-center justify-center">

                        <div className="flex flex-col w-full gap-8">
                            <div className="flex flex-col gap-4">
                                <div className="w-full gap-6">
                                    <div className="text-[#111827] font-bold ss:text-[2.5rem] xxs:text-[1.25rem] leading-7 font-medium">
                                        { _("Password") }
                                    </div>
                                </div>

                                <div className="flex w-full flex-col gap-2.5">
                                    <div className="flex flex-col gap-4">
                                        {
                                            inputs.map((input, index) => (
                                                <InputField
                                                    widthInputClass="w-full"
                                                    backgroundClass="bg-white border border-[#EEEFF2] border-[0.1rem]"
                                                    key={ index}
                                                    { ...input }
                                                    value={ values[input.name] }
                                                    onChange={ onChange }
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col w-full gap-3">
                                <div className={
                                    `flex flex-row items-center hover:no-underline gap-2 px-4 py-3 hover:bg-blue-gradient rounded-full text-base font-semibold
                                    text-white bg-[#0400BD] justify-center items-center`}
                                     onClick={() => {
                                        AuthService.signUp({
                                            first_name: "",
                                            last_name: "",
                                            full_name: "",
                                            email: "",
                                            tel: "",
                                            country: "",
                                            position: "",
                                            company: "",
                                            avatar: "",
                                            background: "",
                                        })
                                             .then(res => {
                                                 console.log(res)
                                                 // navigate(getFullPath(["ROOT", "PROFILE"]).replace(":link", "null") );
                                             })
                                             .catch(err => { console.log(err) })
                                         navigate(getFullPath(["ROOT", "PROFILE"]).replace(":link", "null") );
                                     }}
                                >
                                    { _("Start to use Profylead") }
                                    <Icons.SvgLoginArrowCircleRight/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
}

export default Password;