import React from 'react';
import PopupMobile from "../../../components/Popups/PopupMobile";
import { Icons } from "../../../assets/Svgs";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";

function PopupItemDelete({ handleDelete }) {
    const { t: _ } = useTranslation();
    const { clearAllPopups } = usePopup();

    return (
        <PopupMobile
            icon={<Icons.SvgProfilePopupDelete />}
            title={_("Are you sure you want to delete this item?")}
            footerButtons={[
                {
                    label: _("Cancel"),
                    background: "bg-white",
                    color: "text-[#111827]",
                    className: "border border-[#D1D5DB]",
                    onClick: () => {
                        clearAllPopups();
                    },
                },
                {
                    label: _("Delete"),
                    background: "bg-[#EC0000]",
                    color: "text-white",
                    onClick: () => {
                        handleDelete();
                        clearAllPopups();
                    },
                },
            ]}
        >
        </PopupMobile>
    );
}

export default PopupItemDelete;