import React, { useEffect, useState } from 'react';
import { useTranslation } from "../../services/i18n/i18nService";

const InputGroup = (
    {
        label,
        labelNote,
        icon,
        errorMsg,
        onChange,
        classFontLabel = "text-sm font-medium flex",
        classGapLabelToField = "",
        fontClass = "text-sm text-gray-900",
        widthInputClass = "",
        backgroundClass = "",
        ...inputProps
    }) => {
    const [valid, setValid] = useState(false);
    const [errorMsgDisplayed, setErrorMsgDisplayed] = useState(false);
    const { t: _ } = useTranslation();

    const handleBlur = () => {
        setErrorMsgDisplayed(true);
    };

    const handleChange = (e) => {
        setValid(e.target.validity.valid);
        onChange(e);
    };

    const inputClassNames = `
        ${widthInputClass} 
        ${backgroundClass} 
        ${inputProps.disabled ? 'opacity-50' : ''} 
        ${fontClass} 
        peer ${icon ? 'pl-12' : 'px-5'} py-3 border border-[#EEEFF2] rounded-lg focus:outline-none
    `;

    return (
        <label className={`flex flex-col w-full ${classGapLabelToField}`}>
            <div className="flex">
                <span className={`${inputProps.required ? 'after:content-["*"] after:ml-0 after:text-red-500' : ''} ${classFontLabel}`}>
                    {label}
                </span>
                {
                    labelNote && <span className="ml-2 text-xs text-gray-500">{labelNote}</span>
                }
            </div>
            <div className="relative w-full">
                {
                    icon && <div className="absolute top-1/2 transform -translate-y-1/2">{icon}</div>
                }
                <input
                    {...inputProps}
                    className={inputClassNames}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {
                    errorMsg && (
                        <p className={`text-sm p-0.5 text-red-600 ${errorMsgDisplayed && !valid ? '' : 'hidden'}`}>
                            {_(errorMsg)}
                        </p>
                    )
                }
            </div>
        </label>
    );
}

export default InputGroup;