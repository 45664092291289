import React from 'react';

function SvgCardAssignOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4.25H6.75" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 8.25H4" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.25 8.25H7.25" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 6.015V8.055C11 9.81 10.555 10.25 8.78 10.25H3.22C1.445 10.25 1 9.81 1 8.055V3.945C1 2.19 1.445 1.75 3.22 1.75H6.75" stroke="black" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.25 3.125H11" stroke="#292D32" strokeWidth="0.75" strokeLinecap="round"/>
            <path d="M9.625 4.5V1.75" stroke="#292D32" strokeWidth="0.75" strokeLinecap="round"/>
        </svg>
    );
}

export default SvgCardAssignOutline;