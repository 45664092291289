import React from 'react';

function SvgTemplateCTAMsg(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M5.82812 37.8279V20.3862C5.82812 12.3362 12.3615 5.83203 20.4115 5.83203H49.5781C57.6281 5.83203 64.1615 12.3362 64.1615 20.3862V40.7445C64.1615 48.7654 57.6281 55.2695 49.5781 55.2695H45.2031C44.299 55.2695 43.424 55.707 42.8698 56.4362L38.4948 62.2404C36.5698 64.807 33.4198 64.807 31.4948 62.2404L27.1198 56.4362C26.6531 55.7945 25.6031 55.2695 24.7865 55.2695H20.4115C12.3615 55.2695 5.82812 48.7654 5.82812 40.7445V37.8279Z" fill="#373742"/>
            <path d="M35.0104 35.0013C33.3771 35.0013 32.0938 33.6888 32.0938 32.0846C32.0938 30.4805 33.4062 29.168 35.0104 29.168C36.6146 29.168 37.9271 30.4805 37.9271 32.0846C37.9271 33.6888 36.6437 35.0013 35.0104 35.0013Z" fill="#373742"/>
            <path d="M46.6667 35.0013C45.0333 35.0013 43.75 33.6888 43.75 32.0846C43.75 30.4805 45.0625 29.168 46.6667 29.168C48.2708 29.168 49.5833 30.4805 49.5833 32.0846C49.5833 33.6888 48.3 35.0013 46.6667 35.0013Z" fill="#373742"/>
            <path d="M23.3307 35.0013C21.6974 35.0013 20.4141 33.6888 20.4141 32.0846C20.4141 30.4805 21.7266 29.168 23.3307 29.168C24.9349 29.168 26.2474 30.4805 26.2474 32.0846C26.2474 33.6888 24.9641 35.0013 23.3307 35.0013Z" fill="#373742"/>
        </svg>
    );
}

export default SvgTemplateCTAMsg;