import { CONNECTION } from "../../constants/DatasConstants";
import APIService from "../APIService";

let Connections = null;

export const getConnections = () => {
    return new Promise((resolve, reject) => {
        if (Connections === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            Connections = CONNECTION
            resolve(Connections)
        } else {
            resolve(Connections)
        }
    });
}

export default {
    getConnections,
};