import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import InputGroup from "../../Layouts/InputGroup";
import WidgetService from "../../../services/Widget/WidgetService";
import {useParams} from "react-router-dom";

const WidgetPaypalEdit = React.forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
) => {
    useImperativeHandle(ref, () => ({
        handleSave,
        handleCreate,
    }));

    const { idType } = useParams();

    const [data, setData] = useState({
        title: widget?.data?.title || "",
        link: widget?.data?.link || "",
    });

    const handleSave = () => {
        const updatedWidget = { ...widget, data };

        WidgetService.updateWidget(widget.id, widget)
            .then(() => {
                onSave(updatedWidget);
            })
            .catch((error) => {
                console.log('Failed to save widget');
                throw error;
            });
    }

    const handleCreate = () => {
        const updatedWidget = {
            portfolio: 2, // fix
            section: 1, // fix
            widget_type: idType,
            order: 27, // fix
            data
        };

        WidgetService.createWidget(updatedWidget)
            .then((newWidget) => {
                onSave(newWidget); // Pass the new widget to the onSave callback
            })
            .catch((error) => {
                console.log('Failed to create widget');
                throw error;
            });
    }

    const handleChanges = (data) => {
        setData(data);
        const updatedWidget = { ...widget, data };
        onChange(updatedWidget);
    }

    useEffect(() => {
        if (widget?.data) {
            setData({
                title: widget?.data?.title || "",
                link: widget?.data?.link || "",
            });
        }
    }, [widget]);

    return (
        <div className="grid w-full gap-7">
            <div className="grid grid-col gap-7">
                <InputGroup
                    name="title"
                    widthInputClass="w-3/6"
                    classGapLabelToField="gap-3.5"
                    label="Paypal Button Title"
                    value={ data.title }
                    onChange={(e) => handleChanges({ ...data, title: e.target.value })}
                    backgroundClass="bg-[#F6F7F8]"
                    required={true}
                />

                <InputGroup
                    name="link"
                    widthInputClass="w-3/6"
                    classGapLabelToField="gap-3.5"
                    label="Paypal Link"
                    value={ data.link }
                    onChange={(e) => handleChanges({ ...data, link: e.target.value })}
                    backgroundClass="bg-[#F6F7F8]"
                    required={true}
                />
            </div>
        </div>
    );
})

export default WidgetPaypalEdit;