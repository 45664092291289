import React from 'react';

function SvgSidebarDivision(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.08125 5.435C5.03125 5.43 4.97125 5.43 4.91625 5.435C3.72625 5.395 2.78125 4.42 2.78125 3.22C2.78125 1.995 3.77125 1 5.00125 1C6.22625 1 7.22125 1.995 7.22125 3.22C7.21625 4.42 6.27125 5.395 5.08125 5.435Z" stroke={props.color || 'black'} strokeWidth="0.8125" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.70562 2C9.67562 2 10.4556 2.785 10.4556 3.75C10.4556 4.695 9.70563 5.465 8.77063 5.5C8.73063 5.495 8.68563 5.495 8.64062 5.5" stroke={props.color || 'black'} strokeWidth="0.8125" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.57937 7.28C1.36938 8.09 1.36938 9.41 2.57937 10.215C3.95437 11.135 6.20937 11.135 7.58437 10.215C8.79437 9.405 8.79437 8.085 7.58437 7.28C6.21437 6.365 3.95937 6.365 2.57937 7.28Z" stroke={props.color || 'black'} strokeWidth="0.8125" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.67188 10C10.0319 9.925 10.3719 9.78 10.6519 9.565C11.4319 8.98 11.4319 8.015 10.6519 7.43C10.3769 7.22 10.0419 7.08 9.68688 7" stroke={props.color || 'black'} strokeWidth="0.8125" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgSidebarDivision;