import React, {useEffect, useState} from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";
import InputSearch from "../../Layouts/InputSearch";
import {Icons} from "../../../assets/Svgs";
import Table from "../../Layouts/Table";
import PopupFilterViewByMembersConfirm from "./PopupFilterViewByMembersConfirm";

function PopupFilterViewByMembers({members, selectedMember: selectedTemplateParent, onSelectedMember}) {
    const { removePopup, addPopup } = usePopup();
    const { t: _ } = useTranslation();
    const [ selectedMember, setSelectedMember ] = useState(selectedTemplateParent ?? "");
    const [ value, setValue ] = useState("");
    const [ list, setList ] = useState(members ?? []);
    const [ connectionsOriginal, setConnectionsOriginal ] = useState(members ?? []);

    useEffect(() => {
        onSelectedMember && onSelectedMember(selectedMember);
    }, [selectedMember, onSelectedMember]);

    const onSearch = (listToSearch, keyword) => {
        if (!keyword) return listToSearch;
        return listToSearch.filter(item => {
            return item.connected.toLowerCase().includes(keyword.toLowerCase());
        });
    }    

    return (
        <PopupBase
            title={_("Filter your view by team members :")}
            footerButtons={
                [
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                ]
            }>
            <div className="w-full flex flex-col justify-center align-center" >
                <div className="grid w-full items-center">
                    <div className="grid gap-7 px-48">
                        <InputSearch
                            onSearch={onSearch}
                            value={value}
                            setValue={setValue}
                            setList={setList}
                            listToSearchOriginal={connectionsOriginal}
                            icon={<Icons.SvgTeamUserSearch color="#D1D5DB" className="absolute top-1/2 left-3 -translate-y-1/2 h-4" />}
                            placeholder={"Search name, email or template "}
                            isFlexible={false}
                        />

                        <div className="h-40 w-96 overflow-auto">
                            <Table
                                roundedClass="rounded-[0.44rem]"
                                columns={[
                                    {
                                        label: _(""),
                                        sortable: false,
                                    },
                                ]}
                                rows={list.map((member, index) =>
                                    ({
                                        id: index,
                                        data: [
                                            {
                                                value: <div
                                                    className="flex gap-[1.5rem]"
                                                    onClick={() => addPopup(<PopupFilterViewByMembersConfirm member={member} onConfirmMember={setSelectedMember} />) }
                                                >
                                                    <img src={ member.avatar } className="rounded-full h-7 w-7"  alt="avatar" />
                                                    <span className="flex items-center">{ member.connected }</span>
                                                </div>,
                                            },
                                        ]
                                    })
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PopupBase>
    );
}

export default PopupFilterViewByMembers;