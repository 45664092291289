import { useRef, useState } from 'react';
import { Icons } from "../../assets/Svgs";

const TextField = ({
    label,
    labelNote,
    labelClassName,
    errorMsg,
    onChange,
    inputClassName,
    containerClassName,
    showCopyButton,
    ...inputProps
}) => {
    const [valid, setValid] = useState(false);
    const [errorMsgDisplayed, setErrorMsgDisplayed] = useState(false);
    const inputRef = useRef(null);

    const handleBlur = () => {
        setErrorMsgDisplayed(true);
    };

    const handleChange = (e) => {
        setValid(e.target.validity.valid);
        onChange && onChange(e);
    };

    const handleCopyClick = () => {
        const textToCopy = inputRef.current?.value;
        if (textToCopy) {
            navigator.clipboard.writeText(textToCopy);
        }
    };

    return (
        <label className={`flex flex-col ${containerClassName || ""} ${inputProps.className || ""}`}>
            <div className="flex items-center">
                <span className={`${labelClassName || "text-gray-900"} text-sm font-medium flex`}>
                    {label}
                </span>
                {inputProps.required && <span className="ml-0 text-red-500">*</span>}
                {showCopyButton && (
                    <div onClick={handleCopyClick} className="cursor-pointer">
                        <Icons.SvgTemplateSimCardOutLine
                            className="h-3 ms-1"
                            color="#000000"
                        />
                    </div>
                )}
                {labelNote && <p className="text-[0.625rem] ms-1">({labelNote})</p>}
            </div>

            <input
                ref={inputRef}
                className={`${inputClassName || ""} ${inputProps.disabled ? 'opacity-50 cursor-not-allowed' : ''} peer h-10 text-sm px-5 py-3.5 bg-[#F6F7F8] rounded-[0.625rem] text-gray-900 focus:outline-none focus:border-rose-600`}
                {...inputProps}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {errorMsg && (
                <p className={`text-sm p-0.5 text-red-600 ${errorMsgDisplayed && !valid ? '' : 'hidden'}`}>
                    {errorMsg}
                </p>
            )}
        </label>
    );
}

export default TextField;