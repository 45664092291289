import React from 'react';

function SvgProfilePopupMedia({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-12" } `} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" strokeWidth="8"/>
            <path
                d="M35.77 27.25H31.73C29.72 27.25 28.75 26.27 28.75 24.27V20.23C28.75 18.22 29.73 17.25 31.73 17.25H35.77C37.78 17.25 38.75 18.23 38.75 20.23V24.27C38.75 26.27 37.77 27.25 35.77 27.25ZM31.73 18.75C30.55 18.75 30.25 19.05 30.25 20.23V24.27C30.25 25.45 30.55 25.75 31.73 25.75H35.77C36.95 25.75 37.25 25.45 37.25 24.27V20.23C37.25 19.05 36.95 18.75 35.77 18.75H31.73Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M24.27 27.25H20.23C18.22 27.25 17.25 26.36 17.25 24.52V19.98C17.25 18.14 18.23 17.25 20.23 17.25H24.27C26.28 17.25 27.25 18.14 27.25 19.98V24.51C27.25 26.36 26.27 27.25 24.27 27.25ZM20.23 18.75C18.89 18.75 18.75 19.13 18.75 19.98V24.51C18.75 25.37 18.89 25.74 20.23 25.74H24.27C25.61 25.74 25.75 25.36 25.75 24.51V19.98C25.75 19.12 25.61 18.75 24.27 18.75H20.23Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M24.27 38.75H20.23C18.22 38.75 17.25 37.77 17.25 35.77V31.73C17.25 29.72 18.23 28.75 20.23 28.75H24.27C26.28 28.75 27.25 29.73 27.25 31.73V35.77C27.25 37.77 26.27 38.75 24.27 38.75ZM20.23 30.25C19.05 30.25 18.75 30.55 18.75 31.73V35.77C18.75 36.95 19.05 37.25 20.23 37.25H24.27C25.45 37.25 25.75 36.95 25.75 35.77V31.73C25.75 30.55 25.45 30.25 24.27 30.25H20.23Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M36.5 34.25H30.5C30.09 34.25 29.75 33.91 29.75 33.5C29.75 33.09 30.09 32.75 30.5 32.75H36.5C36.91 32.75 37.25 33.09 37.25 33.5C37.25 33.91 36.91 34.25 36.5 34.25Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M33.5 37.25C33.09 37.25 32.75 36.91 32.75 36.5V30.5C32.75 30.09 33.09 29.75 33.5 29.75C33.91 29.75 34.25 30.09 34.25 30.5V36.5C34.25 36.91 33.91 37.25 33.5 37.25Z"
                fill={!!color ? color : "#0400BD"}/>
        </svg>
    );
}

export default SvgProfilePopupMedia;