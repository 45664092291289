import React from 'react';
import { CHARTS_DIVISIONS } from "../../constants/DatasConstants";

let listCharts = null;

export const getListCharts = () => {
    return new Promise((resolve, reject) => {
        if (listCharts === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            //data template
            listCharts = CHARTS_DIVISIONS;
            resolve(listCharts);
        } else {
            resolve(listCharts);
        }
    });
}

export const getDivisionChartById = (id) => {
    return new Promise((resolve, reject) => {
        if ((listCharts === null)) {
            getListCharts()
                .then(resDiv => {
                    const division = resDiv.find((item) => item.id.toString() === id.toString());
                    if(division){
                        resolve(division);
                    }else{
                        reject(new Error("Chart not found"));
                    }

                })
                .catch(err => reject(err))
        } else {
            const division = listCharts.find((item) => item.id.toString() === id.toString());
            if(division){
                resolve(division);
            }else{
                reject(new Error("Chart not found"));
            }
        }
    });
}

export default { getListCharts, getDivisionChartById };