import React from 'react';

function SvgTemplateSectionCTA(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M11.9688 22C17.4916 22 21.9688 17.5228 21.9688 12C21.9688 6.47715 17.4916 2 11.9688 2C6.4459 2 1.96875 6.47715 1.96875 12C1.96875 17.5228 6.4459 22 11.9688 22Z" fill={props.color || "#292D32"}/>
            <path d="M15.2524 11.2992H13.5524V7.3392C13.5524 6.4192 13.0524 6.2292 12.4424 6.9192L12.0024 7.4192L8.28241 11.6492C7.77241 12.2292 7.98241 12.6992 8.75241 12.6992H10.4524V16.6592C10.4524 17.5792 10.9524 17.7692 11.5624 17.0792L12.0024 16.5792L15.7224 12.3492C16.2324 11.7692 16.0224 11.2992 15.2524 11.2992Z" fill={props.color || "#292D32"}/>
        </svg>
    );
}

export default SvgTemplateSectionCTA;