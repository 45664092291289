import React from 'react';

function SvgMediaTiktokFill({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-6" } `} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.981445 4C0.981445 1.92893 2.66038 0.25 4.73145 0.25H21.2314C23.3025 0.25 24.9814 1.92893 24.9814 4V20.5C24.9814 22.5711 23.3025 24.25 21.2314 24.25H4.73145C2.66038 24.25 0.981445 22.5711 0.981445 20.5V4Z"
                fill={!!color ? color : "black"}/>
            <path
                d="M11.8552 10.9752V10.5081C11.6952 10.4867 11.5325 10.4733 11.3644 10.4733C9.36122 10.4707 7.73145 12.1018 7.73145 14.1094C7.73145 15.3401 8.34494 16.4293 9.28386 17.0887C8.67837 16.44 8.3076 15.567 8.3076 14.6113C8.3076 12.6331 9.89203 11.0206 11.8552 10.9752Z"
                fill={!!color ? color : "#25F4EE"}/>
            <path
                d="M11.9432 16.2718C12.8368 16.2718 13.5677 15.559 13.5997 14.6727L13.6024 6.75189H15.0481C15.0187 6.58904 15.0027 6.42086 15.0027 6.25H13.0289L13.0262 14.1708C12.9942 15.0571 12.2633 15.7699 11.3698 15.7699C11.0923 15.7699 10.8309 15.7005 10.5989 15.5777C10.8976 15.9968 11.3884 16.2718 11.9432 16.2718Z"
                fill={!!color ? color : "#25F4EE"}/>
            <path
                d="M17.7504 9.44021V8.99975C17.1983 8.99975 16.6861 8.83689 16.254 8.55389C16.6381 8.9944 17.1583 9.31477 17.7504 9.44021Z"
                fill={!!color ? color : "#25F4EE"}/>
            <path
                d="M16.254 8.55388C15.8325 8.07069 15.5791 7.44066 15.5791 6.75189H15.051C15.187 7.50473 15.6352 8.15079 16.254 8.55388Z"
                fill={!!color ? color : "#FE2C55"}/>
            <path
                d="M11.3671 12.4462C10.4522 12.4462 9.70801 13.191 9.70801 14.1067C9.70801 14.7448 10.0708 15.3 10.5989 15.5777C10.4015 15.3054 10.2842 14.9717 10.2842 14.6086C10.2842 13.6929 11.0284 12.9481 11.9433 12.9481C12.114 12.9481 12.2794 12.9774 12.4341 13.0255V11.0073C12.274 10.9859 12.1113 10.9725 11.9433 10.9725C11.9139 10.9725 11.8873 10.9752 11.8579 10.9752V12.5236C11.7005 12.4755 11.5378 12.4462 11.3671 12.4462Z"
                fill={!!color ? color : "#FE2C55"}/>
            <path
                d="M17.7501 9.44019V10.9752C16.7258 10.9752 15.7762 10.6469 15.0027 10.0916V14.1094C15.0027 16.1143 13.3729 17.7481 11.367 17.7481C10.5935 17.7481 9.87331 17.5025 9.28381 17.0887C9.94799 17.8015 10.8949 18.25 11.9432 18.25C13.9464 18.25 15.5788 16.6188 15.5788 14.6113V10.5935C16.3524 11.1488 17.302 11.4771 18.3262 11.4771V9.50161C18.1262 9.50161 17.9341 9.48023 17.7501 9.44019Z"
                fill={!!color ? color : "#FE2C55"}/>
            <path
                d="M15.0027 14.1094V10.0916C15.7762 10.6469 16.7258 10.9752 17.7501 10.9752V9.4402C17.1579 9.31476 16.6378 8.99439 16.2537 8.55388C15.6348 8.15079 15.1894 7.50473 15.048 6.75189H13.6023L13.5996 14.6727C13.5677 15.559 12.8368 16.2718 11.9432 16.2718C11.3884 16.2718 10.9003 15.9968 10.5962 15.5804C10.068 15.3 9.70527 14.7475 9.70527 14.1094C9.70527 13.1937 10.4495 12.4489 11.3644 12.4489C11.5351 12.4489 11.7005 12.4782 11.8552 12.5263V10.9779C9.89198 11.0206 8.30756 12.6331 8.30756 14.6113C8.30756 15.567 8.67832 16.4373 9.28382 17.0887C9.87331 17.5025 10.5935 17.7481 11.367 17.7481C13.3703 17.7481 15.0027 16.1143 15.0027 14.1094Z"
                fill="white"/>
        </svg>
    );
}

export default SvgMediaTiktokFill;