import React, { useState, useEffect, useContext } from "react";
import { LocalStorageUpdateContext } from "../contexts/LocalStorageUpdateContext";

export const useLocalStorage = (key, defaultValue = null) => {
    /*
     * usage: 'key' parameter should not change
    */
    const { updateStorage, storageUpdates } = useContext(LocalStorageUpdateContext);
    const [lastUpdate, setLastUpdate] = useState(Date.now())
    const [value, setValue] = useState(() => {
        let currentValue;

        try {
            const item = localStorage.getItem(key)
            if (item) {
                currentValue = JSON.parse(item);
            }
            else {
                currentValue = defaultValue;
            }
        } catch (error) {
            console.error(error);
            currentValue = defaultValue;
        }

        return currentValue;
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));

        // Broadcast changes
        if (updateStorage) {
            updateStorage(key);
        }
    }, [value]);

    // local storage updates from other components
    useEffect(() => {
        const now = Date.now();
        if ((storageUpdates[key] || 0) > lastUpdate) {
            const storedValue = localStorage.getItem(key);
            let parsedValue;
            try {
                parsedValue = JSON.parse(storedValue);
                if (!(parsedValue===value)) {
                    setValue(parsedValue);
                }
            } catch (error) {
                console.error(error);
            }
            setLastUpdate(now);
        }
    }, [storageUpdates]);

    return [value, setValue];
};

export default { useLocalStorage };
