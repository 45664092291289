import React, { useState } from 'react';
import ButtonBase from "./ButtonBase";
import { Icons } from "../../assets/Svgs";
import { useTranslation } from "../../services/i18n/i18nService";
import MenuDropdown from "../Layouts/MenuDropdown";

function ButtonDropDown({links}) {
    const { t: _ } = useTranslation();
    const [toggle, setToggle] = useState(false);

    return (
        <div className="relative inline-block">
            <ButtonBase onClick={() => { setToggle((prev) => !prev)} } className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="#ffffff" icon={ <Icons.SvgArrangeAscOutline className="h-3" color="white" /> } >
                {_("Action")}
            </ButtonBase>
            <MenuDropdown links={ links } toggle={ toggle } />
        </div>
    );
}

export default ButtonDropDown;