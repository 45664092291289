import React, {useEffect, useState} from 'react';
import {Icons} from "../../../assets/Svgs";
import {useTranslation} from "../../../services/i18n/i18nService";
import customersService from "../../../services/Customer/CustomersService";
import {format} from "date-fns";
import {Link} from "react-router-dom";
import {getFullPath} from "../../../router/Router";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import Table from "../../../components/Layouts/Table";
import ButtonViewDetails from "../../../components/Buttons/ButtonViewDetails";
import InputSearch from "../../../components/Layouts/InputSearch";

const onSearch = (listToSearch, keyword) => {
    if (!keyword) return listToSearch;
    const lowerCaseKeyword = keyword.toLowerCase();
    return listToSearch.filter(item => {
        return item.name.toLowerCase().includes(lowerCaseKeyword);
    });
}

function Customers() {
    const { t: _ } = useTranslation();
    const [ listCustomers, setListCustomers ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ value, setValue ] = useState("");
    const [ connectionsOriginal, setConnectionsOriginal ] = useState([]);

    useEffect(() => {
        customersService.getCustomers()
            .then(customers => {
                setListCustomers(customers);
                setConnectionsOriginal(customers);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div className="flex flex-col h-screen p-7 gap-7">
            <header className=" z-10">
                <div className="flex justify-between">
                    <div className="text-[1.5rem] gap-3 flex items-center font-medium">
                        {_("Customers")}
                        {!loading && <span className="text-[#4B5563] text-[1.25rem] font-medium leading-5">({listCustomers.length})</span>}
                    </div>

                    <div className="flex items-center gap-7">
                        <InputSearch
                            onSearch={onSearch}
                            value={value}
                            setValue={setValue}
                            setList={setListCustomers}
                            listToSearchOriginal={connectionsOriginal}
                            icon={<Icons.SvgFilterSearchOutline color="#D1D5DB" className="absolute top-1/2 left-3 -translate-y-1/2 h-3" />}
                            isFlexible={false}
                            placeholder={"Search name, email or company"}
                        />

                        <Link to={getFullPath(["ROOT", "CUSTOMERS", "ADD_CUSTOMER"])} className="flex justify-center gap-[0.438rem]">
                            <ButtonBase className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={<Icons.SvgUserAddOutline className="h-3" />}>
                                {_("Create a new Customer")}
                            </ButtonBase>
                        </Link>
                    </div>
                </div>
            </header>

            <div className="flex-1 overflow-auto">
                <Table
                    tdpaddingClass="py-3.5 px-8"
                    displayHeaders={true}
                    columns={[
                        {
                            label: _("Business Name"),
                            sortable: false,
                        },
                        {
                            label: _("Assigned to"),
                            sortable: false,
                        },
                        {
                            label: _("Created Date"),
                            sortable: false,
                        },
                        {
                            label: _("View"),
                            sortable: false,
                        },
                    ]}
                    rows={listCustomers.map(customer => ({
                        data: [
                            {
                                value: <div className="flex items-center justify-center">
                                    <div className="font-semibold text-[1rem] leading-4 text-black">{customer.name}</div>
                                </div>
                            },
                            {
                                value: customer.assigned_to
                                    ? <div className="flex items-center justify-center gap-[1.5rem]">
                                        <img src={customer.assigned_to[0].avatar} className="rounded-full h-8 w-8" alt="avatar" />
                                        <span>{customer.assigned_to[0].full_name}</span>
                                    </div>
                                    : <div className="flex items-center justify-center gap-[1.5rem]">-</div>,
                            },
                            {
                                value: <div className="flex flex-col items-center justify-center gap-[0.438rem]">
                                    <p>
                                        {format(new Date(customer.created_at), 'dd/MM/yyyy')}
                                    </p>
                                </div>,
                            },
                            {
                                value: <Link to={getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER"]).replace(":custom", customer.id)} className="flex justify-center gap-[0.438rem]">
                                    <ButtonViewDetails />
                                </Link>,
                            },
                        ]
                    }))}
                />
            </div>
        </div>
    );
}

export default Customers;