import React, {useState} from 'react';
import {usePopup} from "../../../../services/PopupService";
import {useTranslation} from "../../../../services/i18n/i18nService";
import PopupMobile from "../../../../components/Popups/PopupMobile";
import {Icons} from "../../../../assets/Svgs";
import InputGroup from "../../../../components/Layouts/InputGroup";
import InputTel from "../../../../components/Layouts/InputTel";

function PopupContactAdd({connections, setConnections}) {
    const { clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();

    const [values, setValues] = useState({
        name: "",
        email: "",
        tel: "",
        country: ""
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <PopupMobile
            cols
            hasBtnClose={true}
            icon={<Icons.SvgConnectionPopupContactAdd />}
            title={_("Add a new contact to your connections list")}
            footerButtons={
                [
                    {
                        label: _("Add contact"),
                        background: "bg-[#0400BD]",
                        color: "text-white",
                        className: "text-[1rem] font-normal leading-6",
                        onClick: () => {
                            let orderMax = -1;
                            if(!!connections){
                                connections.map((data) => {
                                    if(data.order > orderMax){
                                        orderMax = data.order;
                                    }
                                })
                            }

                            connections.push({
                                id: orderMax + 1,
                                name: values["name"],
                                email: values["email"],
                                tel: values["tel"],
                                country: values["country"],
                                tags: [],
                            })
                            clearAllPopups()
                        }
                    }
                ]
            }>

            <div className="flex flex-col gap-4 w-full">
                <InputGroup
                    fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                    widthInputClass="w-full"
                    name="name"
                    placeholder="Full Name"
                    value={ values["name"] }
                    onChange={ onChange }
                    icon={ <Icons.SvgProfileEditPersoInfosName className="absolute top-1/2 left-3 -translate-y-1/2 h-5" /> }
                />

                <InputGroup
                    fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                    widthInputClass="w-full"
                    name="email"
                    placeholder="Email"
                    value={ values["email"] }
                    onChange={ onChange }
                    icon={ <Icons.SvgProfileEditPersoInfosMail className="absolute top-1/2 left-3 -translate-y-1/2 h-5" /> }
                />

                <InputTel
                    fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                    name="tel"
                    className=""
                    placeholder="Phone"
                    value={ values["tel"] }
                    setValues={setValues}
                    onChange={ onChange }
                    telLocaleCurrent={values["country"]}
                />
            </div>
        </PopupMobile>
    );
}

export default PopupContactAdd;