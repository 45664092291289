export class FileService {

    /**
     * Load file content using FileReader.
     * @param {File} file - The file to be read.
     * @returns {Promise<string>} - A promise that resolves to the content of the file.
     */
    static async loadFile(file) {
        try {
            const text = await file.text();
            return text;
        } catch (error) {
            throw new Error('Error reading file: ' + error.message);
        }
    }

    static async loadFileBase64(file) {
        try {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        } catch (error) {
            throw new Error('Error reading file: ' + error.message);
        }
    }

    static downloadFile(url, fileName) {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }
}

export default FileService;