import APIService from "../APIService";
import {PROFILE} from "../../constants/DatasConstants";

let profile = null;
let widget = null;

export const _getProfile = () => {
    return new Promise((resolve, reject) => {
        if (profile === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            profile = PROFILE
            resolve(profile)
        } else {
            resolve(profile)
        }
    });
}

export const _getWidget = (payloadId) => {
    return new Promise((resolve, reject) => {
        if (widget === null) {
            // APIService.apiCall("/card/components/" + payloadId + "/", "GET", )
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })
            widget = PROFILE.find((item) => item.section === parseInt(payloadId))
            resolve(widget)
            widget = null
        } else {
            resolve(widget)
            widget = null
        }
    });
}

class PortfolioService {

    static async getPortfolioPage(uri) {
        const response = await APIService.apiCall("portfolios/page/" + uri + "/", "GET", null, true, {}, null);
        if (!response.ok) throw new Error('Failed to fetch portfolio page');
        const data = await response.json();
        return data;
    }

    static async registerContactDownload(uri) {
        const payload = { uri };
        const response = await APIService.apiCall("portfolios/metrics/contact-downloaded/", "POST", payload, true, {}, null);
        if (!response.ok) throw new Error('Failed to register contact download');
    }

    // the following methods will be removed
    static getProfile = _getProfile;
    static getWidget = _getWidget;
}

export default PortfolioService;
