import React from 'react';
import SquareView from "../../../../components/Layouts/SelectorSquareView";
import { Icons } from "../../../../assets/Svgs";
import PopupBase from "../../../../components/Popups/PopupBase";
import { useTranslation } from "../../../../services/i18n/i18nService";
import { usePopup } from "../../../../services/PopupService";
import { WIDGET_FIELD_TYPES, WIDGET_FIELDS } from '../../../../constants/Widgets';
import { widgetFieldIconOfType } from '../../../../utils/Widgets';

const PopupFieldTypeSelector = ({ selectedType, setSelectedType, title }) => {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    return (
        <PopupBase
            className="max-w-[80%] max-h-[80%] overflow-y-hidden"
            title={title || _("Choose a type:")}
            footerButtons={[
                {
                    label: _("Cancel"),
                    background: "bg-white",
                    color: "text-black",
                    onClick: removePopup,
                },
            ]}
        >
            <div className="flex gap-7 flex-wrap justify-center overflow-y-auto">
                {Object.keys(WIDGET_FIELD_TYPES).map((k) => {
                    const type = WIDGET_FIELD_TYPES[k];
                    return <SquareView
                        key={type}
                        shapCustom="gap-4 px-12 py-4 rounded-[1.25rem]"
                        isSelected={selectedType === type}
                        onClick={() => {
                            setSelectedType(type);
                            removePopup();
                        }}
                        icon={widgetFieldIconOfType({ type, props: { className: "w-16" } })}
                        viewName={_(WIDGET_FIELDS[type].label)}
                    />
                })}
            </div>
        </PopupBase>
    );
}

export default PopupFieldTypeSelector;