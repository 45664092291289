import React from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";
import Table from "../../Layouts/Table";

function PopupFilterViewByMembersConfirm({member: memberParent, onConfirmMember}) {
    const { removePopup, clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={_("Are you sure you want to filter by this team member :")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: clearAllPopups,
                    },
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: () => { onConfirmMember(memberParent); clearAllPopups(); },
                    },
                ]
            }>
            <div className="w-full flex flex-col justify-center align-center" >
                <div className="grid w-full items-center">
                    <div className="grid gap-7 px-48">
                        {
                            memberParent
                                ? <Table
                                    tdwidthClass="w-96"
                                    roundedClass="rounded-[0.44rem]"
                                    columns={[
                                        {
                                            label: _(""),
                                            sortable: false,
                                        },
                                    ]}
                                    rows={[memberParent].map((member, index) =>
                                        ({
                                            id: index,
                                            data: [
                                                {
                                                    value: <div className="flex gap-[1.5rem]">
                                                        <img src={ member.avatar } className="rounded-full h-10 w-10"  alt="avatar" />
                                                        <span className="flex items-center">{ member.connected }</span>
                                                    </div>,
                                                },
                                            ]
                                        })
                                    )}
                                />
                                : null
                        }
                    </div>
                </div>
            </div>
        </PopupBase>
    );
}

export default PopupFilterViewByMembersConfirm;