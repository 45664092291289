import React, { useEffect } from 'react';
import { useTranslation } from "../../services/i18n/i18nService";

function InputSearch(
    {
        value,
        setValue,
        toggle,
        setToggle,
        isFlexible,
        placeholder,
        className,
        listToSearchOriginal,
        setList,
        onSearch,
        icon,
    }) {
    const { t: _ } = useTranslation();

    const handleSearch = (e) => {
        setValue(e.target.value);
    };

    useEffect(() => {
        const listSearched = onSearch(listToSearchOriginal, value);
        setList(listSearched);
    }, [value, listToSearchOriginal]);

    const renderInput = () => (
        <div className="relative w-full">
            <div onClick={() => toggle && setToggle((prev) => !prev)}>{icon}</div>
            <input
                value={value}
                onChange={handleSearch}
                type="text"
                className={`${className ? className : "w-full text-sm focus:outline-none active:outline-none h-10 pl-9 pr-4 rounded-full shadow-sm"}`}
                placeholder={_(placeholder || '')}
            />
        </div>
    );

    return (
        <>
            {
                toggle && (
                    <div className="flex w-full justify-center">
                        {renderInput()}
                    </div>
                )
            }
            {
                !toggle && isFlexible === false && (
                    <div className="flex w-full justify-center">
                        {renderInput()}
                    </div>
                )
            }
        </>
    );
}

export default InputSearch;