import React from 'react';

function SvgTeamEyeOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.79094 6.00094C7.79094 6.99094 6.99094 7.79094 6.00094 7.79094C5.01094 7.79094 4.21094 6.99094 4.21094 6.00094C4.21094 5.01094 5.01094 4.21094 6.00094 4.21094C6.99094 4.21094 7.79094 5.01094 7.79094 6.00094Z" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.00187 10.1344C7.76687 10.1344 9.41187 9.09437 10.5569 7.29437C11.0069 6.58937 11.0069 5.40437 10.5569 4.69937C9.41187 2.89937 7.76687 1.85938 6.00187 1.85938C4.23687 1.85938 2.59187 2.89937 1.44687 4.69937C0.996875 5.40437 0.996875 6.58937 1.44687 7.29437C2.59187 9.09437 4.23687 10.1344 6.00187 10.1344Z" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgTeamEyeOutline;