import React from 'react';

function SvgProfileEditArrowSquareUp({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-8" } `} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0001 30.3332H12.0001C4.76008 30.3332 1.66675 27.2398 1.66675 19.9998V11.9998C1.66675 4.75984 4.76008 1.6665 12.0001 1.6665H20.0001C27.2401 1.6665 30.3334 4.75984 30.3334 11.9998V19.9998C30.3334 27.2398 27.2401 30.3332 20.0001 30.3332ZM12.0001 3.6665C5.85341 3.6665 3.66675 5.85317 3.66675 11.9998V19.9998C3.66675 26.1465 5.85341 28.3332 12.0001 28.3332H20.0001C26.1467 28.3332 28.3334 26.1465 28.3334 19.9998V11.9998C28.3334 5.85317 26.1467 3.6665 20.0001 3.6665H12.0001Z" fill={!!color ? color : "#EEEFF2"}/>
            <path d="M20.7066 18.9465C20.4533 18.9465 20.2 18.8532 20 18.6532L16 14.6532L12 18.6532C11.6133 19.0398 10.9733 19.0398 10.5866 18.6532C10.2 18.2665 10.2 17.6265 10.5866 17.2398L15.2933 12.5332C15.68 12.1465 16.32 12.1465 16.7066 12.5332L21.4133 17.2398C21.8 17.6265 21.8 18.2665 21.4133 18.6532C21.2133 18.8532 20.96 18.9465 20.7066 18.9465Z" fill={!!color ? color : "#EEEFF2"}/>
        </svg>
    );
}

export default SvgProfileEditArrowSquareUp;