import React from 'react';

function SvgTeamPreviewTable(props) {
    return (
        <svg className={`${!! props.className ? props.className : "h-3 duration-300 absolute top-3.5 right-[1.125rem] w-3 z-50" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.95 6.75H2.05C1.3 6.75 1 7.07 1 7.865V9.885C1 10.68 1.3 11 2.05 11H9.95C10.7 11 11 10.68 11 9.885V7.865C11 7.07 10.7 6.75 9.95 6.75Z" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.95 1H2.05C1.3 1 1 1.32 1 2.115V4.135C1 4.93 1.3 5.25 2.05 5.25H9.95C10.7 5.25 11 4.93 11 4.135V2.115C11 1.32 10.7 1 9.95 1Z" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgTeamPreviewTable;