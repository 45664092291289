import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { useId } from 'react';

export const MetricAreaChart = ({ data, colors }) => {
    const chartId = useId();
    const [primaryColor, secondaryColor = primaryColor] = colors;

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                data={data}
            >
                <defs>
                    <linearGradient id={`colorUv${chartId}`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor={primaryColor} stopOpacity={0.33} />
                        <stop offset="100%" stopColor={secondaryColor} stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis
                    dataKey={({ timestamp }) => new Date(timestamp).getTime()}
                    hide={true}
                    scale='time'
                    type='number'
                    domain={['dataMin', 'dataMax']}
                />
                <Tooltip
                    content={({ payload, label }) => {
                        if (payload && payload.length) {
                            const { value } = payload[0].payload;
                            const date = new Date(label).toLocaleDateString();
                            return (
                                <div className="bg-white shadow-lg rounded p-4 border border-gray-200">
                                    <p className="text-gray-700 font-medium">{`${date}: ${Math.round(value)}`}</p>
                                </div>
                            );
                        }
                        return null;
                    }}
                />
                <Area
                    type="monotone"
                    dataKey={({ value }) => parseFloat(value)}
                    stroke={primaryColor}
                    strokeWidth={2}
                    fillOpacity={1}
                    fill={`url(#colorUv${chartId})`}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
}