import { Reorder } from "framer-motion";
import React, { useImperativeHandle, useRef, useState } from 'react';
import { Icons } from "../../../assets/Svgs";
import PopupItemDelete from "../../../pages/Mobile/Popups/PopupItemDelete";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import WidgetService from "../../../services/Widget/WidgetService";
import ButtonBase from "../../Buttons/ButtonBase";
import InputField from "../../Layouts/InputField";
import InputGroup from "../../Layouts/InputGroup";
import FileService from "../../../services/FileService";

const WidgetFileEditSteps = {
    FILE_LIST: "FILE_LIST",
    FILE_DETAIL: "FILE_DETAIL",
}

const WidgetDocumentEdit = React.forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
) => {
    useImperativeHandle(ref, () => ({
        handleSave,
        handleCreate
    }));

    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();

    const [data, setData] = useState({
        title: widget.data?.title || "",
        documents: (widget.data?.documents || []).map((item, index) => ({ ...item, id: index })),
    });
    const [step, setStep] = useState(WidgetFileEditSteps.FILE_LIST);
    const [editedDocument, setEditedDocument] = useState(null);

    const buildPayload = (
        widgetData,
    ) => {
        return {
            widget: {
                ...widget,
                data: {
                    title: widgetData.title,
                    documents: widgetData.documents.map((item) => ({ 
                        label: item.label,
                        order: item.order,
                        file_url: item.file_url,
                        widget_file_id: item.file_content ? ("l_" + item.id) : item.widget_file_id,
                        file_name: item.file_name,
                    })),
                }
            },
            filesToAdd: widgetData.documents.reduce((acc, item) => {
                if (!item.file_content) return acc;
                return { ...acc, ["l_" + item.id]: item.file_content };
            }, {}),
        }
    }

    const deleteUnusedFiles = (widget, files) => {
        files.forEach(file => {
            if (!widget.data.documents.find(item => item.widget_file_id === file.id.toString())) {
                WidgetService.deleteWidgetFile(file.id);
            }
        });
    }

    const handleSave = () => {
        const updatedWidget = buildPayload(data);
        deleteUnusedFiles(updatedWidget.widget, updatedWidget.widget.files ?? []);
        WidgetService.updateWidgetWithBlobs(updatedWidget.widget, updatedWidget.filesToAdd, null, (savedWidget, files, _pictures) => {
            savedWidget.data.documents = savedWidget.data.documents.map((item) => ({ 
                ...item,
                widget_file_id: item.widget_file_id?.startsWith("l_")
                    ? files[item.widget_file_id].id.toString()
                    : item.widget_file_id,
            }));
            return savedWidget;
        })
            .then((savedWidget) => onSave(savedWidget))
            .catch((error) => {
                console.log('Failed to save widget', error);
                throw error;
            });
    };

    const handleCreate = () => {
        const updatedWidget = buildPayload(data);
        deleteUnusedFiles(updatedWidget.widget, updatedWidget.widget.files ?? []);
        WidgetService.createWidgetWithBlobs(updatedWidget.widget, updatedWidget.filesToAdd, null, (savedWidget, files, _pictures) => {
            savedWidget.data.documents = savedWidget.data.documents.map((item) => ({ 
                ...item,
                widget_file_id: item.widget_file_id?.startsWith("l_")
                    ? files[item.widget_file_id].id.toString()
                    : item.widget_file_id,
            }));
            return savedWidget;
        })
            .then((savedWidget) => onSave(savedWidget))
            .catch((error) => {
                console.log('Failed to save widget', error);
                throw error;
            });
    }

    const handleChange = (updatedData) => {
        setData(updatedData);
        onChange({ ...widget, data: updatedData });
    };

    const handleReorder = (orderedItems) => {
        handleChange({
            ...data,
            documents: orderedItems.map((item, index) => ({ ...item, order: index })),
        });
    };

    const handleDocumentChange = (updatedDocument) => {
        updatedDocument.id = editedDocument == null
            ? data.documents.reduce((maxId, item) => Math.max(maxId, item.id), 0) + 1
            : editedDocument.id;
        if (editedDocument === null) {
            handleReorder([
                ...data.documents,
                updatedDocument
            ]);
        } else {
            handleReorder(
                data.documents.map((item) => (item.id === updatedDocument.id ? updatedDocument : item)),
            );
        }
        setEditedDocument(updatedDocument);
    };

    const handleFileDelete = (id) => {
        handleReorder(data.documents.filter(item => item.id !== id));
    }

    const renderAddFileButton = () => {
        return <ButtonBase
            className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full w-40"
            iconColor="white"
            icon={<Icons.SvgTemplateAddNewSection color="white" className="w-3" />}
            onClick={() => { setStep(WidgetFileEditSteps.FILE_DETAIL); }}
        >
            {_("Add a new file")}
        </ButtonBase>
    }

    const renderPreviousButton = () => {
        return <ButtonBase
            className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full w-40 self-end"
            iconColor="white"
            icon={<Icons.SvgArrowReturnOutline color="white" className="w-3" />}
            onClick={() => { 
                setStep(WidgetFileEditSteps.FILE_LIST);
                setEditedDocument(null);
            }}
        >
            {_("Previous")}
        </ButtonBase>
    }

    return (
        <div className="flex flex-col w-full h-full gap-7">
            {
                {
                    [WidgetFileEditSteps.FILE_LIST]: renderAddFileButton,
                    [WidgetFileEditSteps.FILE_DETAIL]: renderPreviousButton,
                }[step]()
            }
            
            {
                {
                    [WidgetFileEditSteps.FILE_LIST]: <div className="flex flex-col h-full gap-7 overflow-hidden">
                        <div className="w-3/6">
                            <InputField
                                name="title"
                                widthInputClass="w-4/6"
                                label="Section Title"
                                value={data.title}
                                onChange={(e) => handleChange({ ...data, title: e.target.value })}
                                required={true}
                            />
                        </div>

                        <Reorder.Group 
                            className="flex flex-col gap-4"
                            axis="y"
                            onReorder={handleReorder}
                            values={data.documents}
                        >
                            {
                                data.documents
                                .map((item) => {
                                    return <Reorder.Item key={item.id} value={item}>
                                        <div className="bg-[#F6F7F8] rounded-[0.625rem] flex justify-between px-5 py-2.5">
                                            <div className="flex justify-center items-center align-center gap-2.5">
                                                <Icons.SvgTemplateSectionListDraggable />
                                                <p className="text-sm">{ _(item.label) }</p>
                                            </div>

                                            <div className="flex justify-center items-center align-center gap-5">
                                                <ButtonBase
                                                    onClick={() => addPopup(<PopupItemDelete handleDelete={() => handleFileDelete(item.id)} />)}
                                                    className="bg-white text-[#FF0000] gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-2"
                                                >
                                                    <Icons.SvgTrashOutline className="h-2.5" color="#FF0000" />
                                                    {_("Delete")}
                                                </ButtonBase>

                                                <ButtonBase className="bg-black text-white gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-1.5"
                                                    onClick={() => {
                                                        setEditedDocument(item);
                                                        setStep(WidgetFileEditSteps.FILE_DETAIL); 
                                                    }}
                                                >
                                                    {_("Edit")}
                                                </ButtonBase>

                                                {/* <label className="relative inline-flex items-center cursor-pointer">
                                                    <input type="checkbox" className="sr-only peer" readOnly />
                                                    <div
                                                        className={`
                                                        ${!false
                                                            ? "bg-[#EEEFF2] peer-checked:after:-translate-x-5 peer-checked:bg-[#EEEFF2]"
                                                            : "bg-black peer-checked:after:translate-x-5 peer-checked:bg-black"
                                                        }
                                                            group peer ring-0 rounded-full outline-none duration-300 after:duration-300 w-11 h-6 peer-focus:outline-none after:rounded-full after:absolute after:bg-gray-50 after:outline-none after:h-6 after:w-6 after:flex after:justify-center after:items-center peer-hover:after:scale-90`
                                                        }
                                                    />
                                                </label> */}
                                            </div>
                                        </div>
                                    </Reorder.Item>
                                })
                            }
                        </Reorder.Group>
                    </div>,
                    [WidgetFileEditSteps.FILE_DETAIL]: <DocumentDetailEdit file={editedDocument} onChange={handleDocumentChange} />,
                }[step]
            }
        </div>
    );
})

const DocumentDetailEdit = ({ file, onChange }) => {
    const { t: _ } = useTranslation();
    const documentSelectRef = useRef(null);
    const [ data, setData ] = useState({
        label: file?.label || "",
        file_content: file?.file_content || null,
        file_name: file?.file_name || null,
        file_url: file?.file_url || "",
    });

    const handleDocumentChange = (updatedDocument) => {
        setData(updatedDocument);
        onChange(updatedDocument);
    }

    const handleSelectDocument = (e) => {
        const file = e.target.files[0];
        FileService.loadFileBase64(file)
            .then(fileContent => {
                const label = data.label;
                handleDocumentChange({
                    ...data,
                    file_content: fileContent,
                    file_name: file.name,
                    file_url: "",
                    label: label === "" ? file.name.substring(0, file.name.lastIndexOf(".")) : label,
                });
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleDataChange = (key, value) => {
        handleDocumentChange({
            ...data,
            [key]: value,
        });
    }

    const handleRemoveDocument = () => {
        const { file_content, file_name, ...restData } = data;
        handleDocumentChange(
            {
                ...restData,
                file_content: null,
                file_name: null,
            }
        );
    }

    return (
        <div className="w-full h-full gap-7 flex flex-col">
            <InputGroup
                fontClass="text-sm font-medium flex"
                classGapLabelToField="gap-3.5"
                widthInputClass="w-full"
                name="label"
                placeholder="Document Label"
                value={ data.label }
                label={ _("Document Label") }
                required={true}
                onChange={ (e) => handleDataChange("label", e.target.value) }
            />

            <label className="flex flex-col">
                <input
                    ref={ documentSelectRef }
                    type="file"
                    className="hidden"
                    onChange={ handleSelectDocument }
                    accept="application/pdf"
                />

                <div className="flex flex-col gap-7">
                    <div className={`flex flex-col align-center gap-1.5`}>
                        <p className="text-[0.688rem] font-medium text-[#9CA3AF]" >
                            { "Some document" }
                        </p>
                        <ButtonBase
                            onClick={ () => { documentSelectRef.current?.click() } }
                            className={`bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full`}
                        >
                            {_("Select a document")}
                        </ButtonBase>
                    </div>
                </div>

            </label>
            {
                !!data.file_content && (
                    <div className="flex gap-7 justify-center items-center">
                        <p className="text-sm">{ _(data.file_name) }</p>
                        <ButtonBase
                            onClick={ handleRemoveDocument }
                            className={`bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full`}
                        >
                            {_("Remove this document")}
                        </ButtonBase>
                    </div>
                )
            }
            <p className="text-center"> or </p>

            <InputGroup
                fontClass="text-sm font-medium flex"
                classGapLabelToField="gap-3.5"
                widthInputClass="w-full"
                name="file_url"
                placeholder="Document URL"
                value={ data.file_url }
                label={ _("Document URL") }
                required={ false }
                onChange={ (e) => handleDataChange("file_url", e.target.value) }
            />
        </div>
    );
}

export default WidgetDocumentEdit;