import React from 'react';

function SvgTemplateAddNewSection(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6H8" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 8V4" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.5 11H7.5C10 11 11 10 11 7.5V4.5C11 2 10 1 7.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11Z"
                  stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgTemplateAddNewSection;