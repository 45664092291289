import React from 'react';
import { Icons } from "../../../../assets/Svgs";
import { Link } from "react-router-dom";
import { getFullPath } from "../../../../router/Router";
import {useTranslation} from "../../../../services/i18n/i18nService";
import Table, {SelectionType} from "../../../../components/Layouts/Table";
import { StatisticsService } from "../../../../services/Statistics/StatisticsService";
import { Card } from '../../../../components/Layouts/Card';
import { cn } from '../../../../utils';
import TeamService from '../../../../services/Team/TeamService';

export const TopPerfomersCard = ({
    className,
    startDate,
    endDate,
}) => {
    const { t: _ } = useTranslation();

    const { data, isLoading, error } = StatisticsService.useTeamPerformance({
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
    });

    if (isLoading) return <>...</>;
    if (error) return <>error</>;

    return (
        <Card className={cn("w-full min-h-28", className)}>
            <div className="flex flex-col w-full">
                <header className="flex w-full justify-between items-center">
                    <div className="text-sm flex gap-1.5 items-center">
                        <p>{_("Top 5 Performers")}</p>
                        <Icons.SvgStatisticEyeFill />
                    </div>
                    <Link to={getFullPath(["ROOT", "STATISTICS", "PERFORMERS"])} className="flex justify-center gap-[0.438rem]">
                        <div className="text-sm flex gap-[0.188rem] items-center hover:underline hover:select-all">
                            {_("View")}
                            <Icons.SvgStatisticCirclePlusFill color="#292D32" />
                        </div>
                    </Link>
                </header>

                <Table
                    displayHeaders={false}
                    bgColorClass="bg-white"
                    columns={[{}, {}, {}]}
                    rows={data
                        // first 5 sorted by views
                        .sort((a, b) => b.views - a.views)
                        .slice(0, 5)
                        .map((performance, index) => ({
                            data: [
                                { value: <div>{index + 1}</div> },
                                {
                                    value: <PerformerCell memberId={performance.user_id} />,
                                },
                                { value: <div className="flex justify-center items-center">{performance.views}</div> },
                            ],
                    }))}
                />
            </div>
        </Card>
    );
}

const PerformerCell = ({ memberId }) => {
    const { data: member, isLoading, error } = TeamService.useTeamMemberQuery(memberId);

    if (isLoading) return <>...</>;

    return (
        <div className="flex items-center gap-[1.5rem]">
            <img src={member.avatar} className="rounded-full h-7 w-7" alt="avatar" />
            <span>{member.first_name} {member.last_name}</span>
        </div>
    )
}