import React, {useState} from 'react';
import {useTranslation} from "../../../../services/i18n/i18nService";
import {usePopup} from "../../../../services/PopupService";
import PopupBase from "../../../../components/Popups/PopupBase";
import InputField from "../../../../components/Layouts/InputField";

function PopupDivisionAdd() {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    const [values, setValues] = useState({
        title: "",
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <PopupBase
            title={ _("Please choose a title for your new Division") }
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[23.375rem]",
                        onClick: removePopup,
                    },
                    {
                        label: _("Create your new Division"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[23.375rem]",
                        onClick: removePopup,
                    },
                ]
            }>
            <div className="w-7/12">
                <InputField
                    backgroundClass="bg-white"
                    widthInputClass="w-full"
                    name="title"
                    label="Title"
                    value={ values["title"] }
                    onChange={ onChange }
                />
            </div>
        </PopupBase>
    );
}

export default PopupDivisionAdd;