import React from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../../../components/Popups/PopupBase";


function PopupShowGenStatistics() {
    const { removePopup, clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={_("Are you sure you want to show general statistics ?")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[15.375rem]",
                        onClick: clearAllPopups,
                    },
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[15.375rem]",
                        onClick: removePopup,
                    },
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[15.375rem]",
                        onClick: clearAllPopups,
                    },
                ]
            }>
        </PopupBase>
    );
}

export default PopupShowGenStatistics;