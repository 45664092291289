import React, {useEffect, useState} from 'react';
import {Icons} from "../../assets/Svgs";
import {useTranslation} from "../../services/i18n/i18nService";
import ButtonMobile from "../../components/Buttons/ButtonMobile";
import ButtonBase from "../../components/Buttons/ButtonBase";
import PortfolioFooter from "./Layouts/PortfolioFooter";
import {useNavigate} from "react-router-dom";
import {getFullPath} from "../../router/Router";
import {useParams} from "react-router";
import PopupBackgroundEdit from "./Popups/PopupBackgroundEdit";
import {usePopup} from "../../services/PopupService";
import PortfolioAvatar from "./Layouts/PortfolioAvatar";
import Widget from "../../components/Widgets/Widget";
import TemplateService from "../../services/Template/TemplateService";
import PortfolioService from "../../services/Portfolio/PortfolioService";
import WidgetService from "../../services/Widget/WidgetService";

function PortfolioEdit() {
    const { t: _ } = useTranslation();

    const { link, widgetId } = useParams();
    const navigate = useNavigate();

    const { addPopup } = usePopup();
    const [ pageData, setPageData ] = useState({});

    const loading = Object.keys(pageData).length === 0;

    useEffect(() => {
        PortfolioService.getPortfolioPage("abcd123") // fix error
            .then(data => {
                console.log(data);
                setPageData(data);
            })
            .catch(err => {
                console.error(err);
            });
    }, [link]);

    return (
        <div>
            {
                !loading
                    ?
                    <div className="flex flex-col bg-white">
                        <div className={`h-[22rem] border-b-0 w-full relative bg-white flex flex-col items-center justify-center text-center overflow-auto`}>
                            {
                                !!pageData.background
                                    ? <div className="w-full h-[11rem] absolute top-0" style={{ backgroundImage: `url(${pageData.background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} />
                                    : <div className="w-full h-[11rem] bg-blue-gradient absolute top-0" />
                            }

                            <div className="absolute top-0 w-full">
                                <div className="flex justify-between p-4 items-center">
                                    <Icons.SvgProfileClientLogo />
                                    <ButtonMobile textClass="font-normal text-[1rem] leading-6 text-white" className="px-2.5 py-1 bg-black " label="Login" icon={<Icons.SvgProfileClientLogin />} />
                                </div>
                            </div>

                            {
                                !!pageData.user_data?.avatar
                                    ? <div className="w-36 h-36 rounded-full z-5 border-4 border-white" style={{ backgroundImage: `url(${pageData.user_data.avatar})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} />
                                    : <div className="w-36 h-36 rounded-full z-5 bg-blue-gradient border-4 border-white" />
                            }

                            <div className="absolute bottom-[0.75rem]">
                                <div className="grid gap-2">
                                    <div className="text-[1.625rem] leading-8 font-semibold">
                                        { pageData.user_data?.first_name } { pageData.user_data?.last_name }
                                    </div>
                                    <span className="text-[0.875rem] text-[#6B7280] leading-5 font-medium">
                                                {/* { profile[0].data.position }
                                                {_(" at ")}
                                                { profile[0].data.company } */}
                                    </span>
                                    <ButtonMobile
                                        // onClick={() => {
                                        //     navigate(getFullPath(["ROOT", "PROFILE", "EDIT_WIDGET"]).replace(":link", link).replace(":widgetType", pageData.user_data?.type).replace(":widgetId", pageData.user_data?.id));
                                        // }}
                                        className="px-3 py-2"
                                        label="Edit your main information"
                                        icon={<Icons.SvgPortfolioUserEdit />}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-4 grid gap-3">
                            <div className="mx-4">
                                {
                                    pageData.widgets?.map((widget, index) => (
                                        <Widget
                                            key={index}
                                            widget={widget}
                                            displayEditAction={true}
                                            onEdit={
                                                () => navigate(getFullPath(["ROOT", "PROFILE", "SECTION"]).replace(":widgetType", widget.widget_type).replace(":widgetId", widget.id))
                                            }
                                        />
                                    ))
                                }
                            </div>

                            <div className="grid gap-3 mb-20 mx-4">
                                <ButtonBase
                                    onClick={() => {
                                        navigate(getFullPath(["ROOT", "PROFILE", "SECTION_ADD"]).replace(":link", link));
                                    }} className="text-[1rem] leading-6 font-medium py-3 px-4 bg-[#0400BD] text-white rounded-full"
                                >
                                    <Icons.SvgProfileAddSection className="h-6" />
                                    { _("Add a new section") }
                                </ButtonBase>

                                <ButtonBase
                                    onClick={() => {
                                        navigate(getFullPath(["ROOT", "PROFILE", "SECTION_REORGANIZE"]).replace(":link", link));
                                    }}
                                    className="text-[1rem] leading-6 font-medium py-3 px-4 bg-white border border-[#0400BD] text-[#0400BD] rounded-full"
                                >
                                    <Icons.SvgProfileReorganizeSection className="h-6" />
                                    { _("Reorganize the sections") }
                                </ButtonBase>
                            </div>

                            <PortfolioFooter routerSelected="profile" />
                        </div>
                    </div>
                    : null
            }
        </div>
    );
}

export default PortfolioEdit;