import React, { useEffect, useState } from 'react';
import ButtonMobile from "../../../components/Buttons/ButtonMobile";
import { Icons } from "../../../assets/Svgs";
import { useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import WidgetEdit from "../../../components/Widgets/WidgetEdit";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import PopupSectionDelete from "../Popups/PopupSectionDelete";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import { useNavigate } from "react-router";
import PopupEdit from "../Popups/PopupEdit";
import WidgetService from "../../../services/Widget/WidgetService";

function PortfolioSection() {
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const navigate = useNavigate();
    const [widget, setWidget] = useState({});
    const { widgetId, section } = useParams();
    const [list, setList] = useState({ title: "", datas: [] });

    const isEditableSection = ["SOCIAL_MEDIA", "PICTURE", "DOCUMENT", "WEBSITE", "CONTACT", "CTA"].includes(section);
    const isDeletableSection = ["TEXT", "SOCIAL_MEDIA", "VIDEO", "PICTURE", "DOCUMENT", "WEBSITE", "CONTACT", "CTA"].includes(section);

    const labels = {
        SOCIAL_MEDIA: "Add a social media link",
        PICTURE: "Add a new picture",
        DOCUMENT: "Add a new document",
        WEBSITE: "Add a new website",
        CONTACT: "Add a new button",
        CTA: "Add a new CTA",
    };

    const icons = {
        SOCIAL_MEDIA: <Icons.SvgProfileAddSection className="h-6" />,
        PICTURE: <Icons.SvgProfileAddPicture className="h-6" />,
        DOCUMENT: <Icons.SvgProfileEditDoc className="h-6" />,
        WEBSITE: <Icons.SvgProfilePopupMediaLink className="h-6" color="white" />,
        CONTACT: <Icons.SvgProfileAddSection className="h-6" color="white" />,
        CTA:  <Icons.SvgProfilePopupMediaLink className="h-6" color="white" />,
    };

    const handleAddSection = () => {
        const newPopup = widgetId !== "null"
            ? <PopupEdit
                widget={widget}
                list={list}
                setList={setList}
            />
            : (
                <PopupEdit
                    widgetType={section}
                    list={list}
                    setList={setList}
                />
            );
        addPopup(newPopup);
    };

    useEffect(() => {
        WidgetService.getWidgetById(parseInt(widgetId))
            .then(data => {
                console.log(data);
                setWidget(data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [widgetId]);

    const handleWidgetChange = (updatedWidget) => {
        setWidget(updatedWidget);
        // Optionally update other states if the updatedWidget affects them
    };

    const handleWidgetSave = async (widget) => {
        try {
            const updatedWidget = await WidgetService.updateWidget(widget.id, widget);
            setWidget(updatedWidget);
            console.log('Widget saved successfully', updatedWidget);
            navigate(getFullPath(["ROOT", "PROFILE"])); // Redirect after successful save
        } catch (error) {
            console.error('Failed to save widget', error);
            // Handle error (e.g., show error message to the user)
        }
    };

    return (
        <div className="m-4 grid gap-10">
            <div className="flex justify-between">
                <ButtonMobile
                    onClick={() => navigate(getFullPath(["ROOT", "PROFILE"]))}
                    className="px-2.5 py-1.5 bg-white border border-[#0400BD]"
                    textClass="text-[#0400BD] font-normal text-[1rem] leading-5"
                    label="Cancel"
                    icon={<Icons.SvgProfileEditHeaderCancel />}
                />
                <ButtonMobile
                    onClick={() => navigate(getFullPath(["ROOT", "PROFILE"]))}
                    className="px-2.5 py-1.5"
                    textClass="text-white font-normal text-[1rem] leading-5"
                    label="Save"
                    icon={<Icons.SvgProfileEditHeaderSave />}
                />
            </div>

            <WidgetEdit widget={widget} onChange={handleWidgetChange} onSave={handleWidgetSave} />

            <div className="flex flex-col gap-4">
                {
                    isEditableSection && (
                        <ButtonBase
                            onClick={handleAddSection}
                            className="bg-[#0400BD] text-white font-normal py-3 px-4 rounded-full"
                        >
                            { icons[section] }
                            { _(labels[section]) }
                        </ButtonBase>
                    )
                }
                {
                    isDeletableSection && (
                        <ButtonBase
                            onClick={() => addPopup(<PopupSectionDelete />)}
                            className="bg-[#EC0000] text-white font-normal py-3 px-4 rounded-full"
                        >
                            { _("Delete this section") }
                        </ButtonBase>
                    )
                }
            </div>
        </div>
    );
}

export default PortfolioSection;