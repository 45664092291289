import React from 'react';
import { motion } from 'framer-motion';

const Transition = ({ transitionType, children }) => {
    let animationProps = {};

    switch (transitionType) {
        case "turn-left":
            animationProps = {
                initial: { opacity: 0, x: window.innerWidth },
                animate: { opacity: 1, x: 0 },
                exit: { x: -window.innerWidth, transition: { duration: 1 }, opacity: 0 }
            };
            break;
        case "turn-right":
            animationProps = {
                initial: { opacity: 0, x: -window.innerWidth },
                animate: { opacity: 1, x: 0 },
                exit: { x: 0, transition: { duration: 1 }, opacity: 0 }
            };
            break;
        default: // fade
            animationProps = {
                initial: { opacity: 0 },
                animate: { opacity: 1 },
                exit: { opacity: 0 }
            };
            break;
    }

    return (
        <motion.div {...animationProps} className="h-full">
            {children}
        </motion.div>
    );
};

export default Transition;