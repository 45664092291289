import React from 'react';

function SvgTeamUserSearch(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.0013 8.00065C9.84225 8.00065 11.3346 6.50827 11.3346 4.66732C11.3346 2.82637 9.84225 1.33398 8.0013 1.33398C6.16035 1.33398 4.66797 2.82637 4.66797 4.66732C4.66797 6.50827 6.16035 8.00065 8.0013 8.00065Z" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.27344 14.6667C2.27344 12.0867 4.84012 10 8.00012 10" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.1333 14.2667C13.3115 14.2667 14.2667 13.3115 14.2667 12.1333C14.2667 10.9551 13.3115 10 12.1333 10C10.9551 10 10 10.9551 10 12.1333C10 13.3115 10.9551 14.2667 12.1333 14.2667Z" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.6667 14.6667L14 14" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgTeamUserSearch;