import React from 'react';
import { SETTINGS } from "../../constants/DatasConstants";

let listSettings = null;

export const getListSettings = () => {
    return new Promise((resolve, reject) => {
        if (listSettings === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            //data template
            listSettings = SETTINGS;
            resolve(listSettings);
        } else {
            resolve(listSettings);
        }
    });
}

export const getOneListSettingById = (id) => {
    return new Promise((resolve, reject) => {
        if ((listSettings === null)) {
            getListSettings()
                .then(lists => {
                    const setting = lists.find((item) => item.id.toString() === id.toString());
                    if(setting){
                        resolve(setting);
                    }else{
                        reject(new Error("Setting not found"));
                    }
                })
                .catch(err => reject(err))
        } else {
            const setting = listSettings.find((item) => item.id.toString() === id.toString());
            if(setting){
                resolve(setting);
            }else{
                reject(new Error("Setting not found"));
            }
        }
    });
}

export default { getListSettings, getOneListSettingById };