import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const IPhoneWrapper = ({ className, children }) => {
    const wrapperRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            console.log("changed");
            for (let entry of entries) {
                const { offsetWidth, offsetHeight } = entry.target;
                const height = offsetWidth * 2.08333; // aspect ratio 0.48:1
                const width = offsetHeight / 2.08333;
                if (height <= offsetHeight) {
                    setDimensions({ width: offsetWidth, height });
                } else {
                    setDimensions({ width, height: offsetHeight });
                }
            }
        });

        const element = wrapperRef.current;
        console.log(element);
        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, []);

    return (
        <div ref={wrapperRef} className={twMerge("flex w-full h-full justify-center items-center overflow-hidden", className)}>
            <div className="flex flex-col h-full rounded-[3.5rem] bg-[#4B5563] p-2 overflow-hidden"
                style={{ width: dimensions.width, height: dimensions.height }}>
                <div className="flex flex-col w-full h-full bg-white rounded-[3rem] overflow-hidden">
                    <div className="top-0 m-2 self-center aspect-[4] w-[30%] h-auto bg-[#4B5563] rounded-[0.913rem] z-10" />
                    <div className="flex flex-col flex-1 overflow-auto">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IPhoneWrapper;
