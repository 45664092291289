import React, {useState} from 'react';
import PopupMobile from "../../../../components/Popups/PopupMobile";
import {Icons} from "../../../../assets/Svgs";
import InputGroup from "../../../../components/Layouts/InputGroup";
import {useTranslation} from "../../../../services/i18n/i18nService";
import {usePopup} from "../../../../services/PopupService";
import PopupStickerDelete from "./PopupStickerDelete";

function PopupStickerEdit({tags, setTags, tagSelected}) {
    const { t: _ } = useTranslation();
    const { clearAllPopups, addPopup } = usePopup();
    const [values, setValues] = useState({
        name: tagSelected.name,
        bgClass: tagSelected.bgClass,
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <PopupMobile
            cols
            hasBtnClose={true}
            icon={<Icons.SvgProfilePopupDelete />}
            title={_("Do you want to edit this sticker ?")}
            footerButtons={
                [
                    {
                        label: _("Save the modifications"),
                        background: "bg-[#0400BD]",
                        color: "text-white",
                        onClick: () => {
                            let tagToUpdate = tags.find((data) => data.id === tagSelected.id)
                            tagToUpdate.name = values["name"]
                            tagToUpdate.bgClass = values["bgClass"]
                            clearAllPopups()
                        }
                    },
                    {
                        label: _("Delete this sticker"),
                        background: "bg-[#EC0000]",
                        color: "text-white",
                        onClick: () => {
                            addPopup(<PopupStickerDelete tags={tags} setTags={setTags} tagSelected={tagSelected} />)
                        }
                    }
                ]
            }>

            <div className="flex flex-col w-full">
                <div className="flex gap-4 justify-center px-4 pb-4">
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#FFC266]" }) } className={`${values["bgClass"] === "bg-[#FFC266]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#FFC266]`}/>
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#FF3F61]" }) } className={`${values["bgClass"] === "bg-[#FF3F61]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#FF3F61]`}/>
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#B63FFF]" }) } className={`${values["bgClass"] === "bg-[#B63FFF]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#B63FFF]`}/>
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#00CBBF]" }) } className={`${values["bgClass"] === "bg-[#00CBBF]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#00CBBF]`}/>
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#FF6B00]" }) } className={`${values["bgClass"] === "bg-[#FF6B00]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#FF6B00]`}/>
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#FF3FB2]" }) } className={`${values["bgClass"] === "bg-[#FF3FB2]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#FF3FB2]`}/>
                </div>
                <InputGroup
                    fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                    widthInputClass="w-full"
                    name="name"
                    placeholder="Name"
                    value={ values["name"] }
                    onChange={ onChange }
                    icon={ <Icons.SvgProfileEditText className="absolute top-1/2 left-3 -translate-y-1/2 h-5" /> }
                />
            </div>
        </PopupMobile>
    );
}

export default PopupStickerEdit;