import React from 'react';

function SvgTemplateFormConfirm(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M34.9948 64.1693C51.1031 64.1693 64.1615 51.1109 64.1615 35.0026C64.1615 18.8943 51.1031 5.83594 34.9948 5.83594C18.8865 5.83594 5.82812 18.8943 5.82812 35.0026C5.82812 51.1109 18.8865 64.1693 34.9948 64.1693Z" fill="#373742"/>
            <path d="M30.8484 45.4423C30.2651 45.4423 29.7109 45.209 29.3026 44.8006L21.0484 36.5465C20.2026 35.7007 20.2026 34.3007 21.0484 33.4548C21.8943 32.609 23.2943 32.609 24.1401 33.4548L30.8484 40.1632L45.8401 25.1715C46.6859 24.3257 48.0859 24.3257 48.9318 25.1715C49.7776 26.0173 49.7776 27.4173 48.9318 28.2631L32.3943 44.8006C31.9859 45.209 31.4318 45.4423 30.8484 45.4423Z" fill="#373742"/>
        </svg>
    );
}

export default SvgTemplateFormConfirm;