import React from 'react';

function SvgTemplateSectionPicture(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M22 7.81V13.9L20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L2.67 18.95L2.56 19.03C2.19 18.23 2 17.28 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z" fill={props.color || "#292D32"}/>
            <path d="M8.99719 10.3791C10.3116 10.3791 11.3772 9.31358 11.3772 7.99914C11.3772 6.6847 10.3116 5.61914 8.99719 5.61914C7.68275 5.61914 6.61719 6.6847 6.61719 7.99914C6.61719 9.31358 7.68275 10.3791 8.99719 10.3791Z" fill={props.color || "#292D32"}/>
            <path d="M21.9986 13.9005V16.1905C21.9986 19.8305 19.8286 22.0005 16.1886 22.0005H7.80859C5.25859 22.0005 3.41859 20.9305 2.55859 19.0305L2.66859 18.9505L7.58859 15.6505C8.38859 15.1105 9.51859 15.1705 10.2286 15.7905L10.5686 16.0705C11.3486 16.7405 12.6086 16.7405 13.3886 16.0705L17.5486 12.5005C18.3286 11.8305 19.5886 11.8305 20.3686 12.5005L21.9986 13.9005Z" fill={props.color || "#292D32"}/>
        </svg>
    );
}

export default SvgTemplateSectionPicture;