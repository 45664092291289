import React from 'react';

function SelectorTutorialVideo({className, children}) {
    return (
        <div className={`${!!className ? className: ""} h-[16rem] bg-gray-100 gap-4 text-[0.813rem] font-medium rounded-[0.438rem] flex flex-col justify-center items-center shadow-sm`}>
            {children}
        </div>
    );
}

export default SelectorTutorialVideo;