import WidgetSection from "../../Layouts/WidgetSection";
import RuleVertical from "../../Layouts/RuleVertical";
import {CONTACT_TYPES, contactIconOf} from "../../../constants/ContactsTypes";

export const injectUserData = (data, userData) => {
    if (!userData) {
        return data;
    }

    const injectUserDataOf = (contact) => {
        if (!!contact.details.commonValue) return contact;

        const defaultInjectUserData = () => {
            return contact;
        }

        const injectPhoneNumber = () => {
            const userPhoneNumber = userData.info?.find(i => i.key === 'phone_number')?.value;
            if (!userPhoneNumber) return null;
            return {
                ...contact,
                details: {
                    ...contact.details || {},
                    value: userPhoneNumber,
                }
            }
        }

        const injectUserDataOf = {
            [CONTACT_TYPES.TEL]: () => {
                return injectPhoneNumber();
            },
            [CONTACT_TYPES.MSG]: () => {
                return injectPhoneNumber();
            },
            [CONTACT_TYPES.MAIL]: () => {
                const userEmail = userData.email;
                if (!userEmail) return null;
                return {
                    ...contact,
                    details: {
                        ...contact.details || {},
                        value: userEmail,
                    }
                }
            },
        };

        return injectUserDataOf[contact.type]
               ? injectUserDataOf[contact.type]()
               : defaultInjectUserData();
    }

    return data.map(d => injectUserDataOf(d)).filter(d => !!d);
}

export const labelPreviewOf = (contact) => {
    if (!contact.type) return null;

    const defaultContactDetails = () => (
        contact.details.value
        ? contact.details.value
        : "Member data..."
    );

    const contactDetails = {
        [CONTACT_TYPES.LOCATION]: () => (
            (contact.details.address && contact.details.cityAndZip)
                ? contact.details.address + " " + contact.details.cityAndZip
                : "Address and Zip Code..."
        ),
        [CONTACT_TYPES.WEB]: () => contact.details.label
    };

    return (contactDetails[contact.type] && contactDetails[contact.type]())
            || defaultContactDetails();
}

export const actionOf = (contact) => {
    const defaultAction = () => {}

    const actions = {
        [CONTACT_TYPES.TEL]: () => contact.details.value && window.open('tel:' + contact.details.value),
        [CONTACT_TYPES.MSG]: () => contact.details.value && window.open('sms:' + contact.details.value),
        [CONTACT_TYPES.MAIL]: () => contact.details.value && window.open('mailto:' + contact.details.value),
        [CONTACT_TYPES.WEB]: () => contact.details.link && window.open(contact.details.link),
        [CONTACT_TYPES.LOCATION]: () => (
            (contact.details.address && contact.details.cityAndZip)
            && window.open('https://www.google.com/maps/search/?api=1&query=' + contact.details.address + ' ' + contact.details.cityAndZip)
        ),
    };

    return actions[contact.type]
           || defaultAction;
}

function WidgetContactView(
    {
        widget,
        displayEditAction,
        onEdit,
        userData = null,
}) {

    /* Utils */
    const widgetData = widget.data;
    const widgetTitle = widgetData?.title;

    /* Handlers */
    const handleClick = (contact) => {
        actionOf(contact)();
    }

    /* Renders */
    const renderContactIcon = (type) => {
        return contactIconOf({type});
    }

    const renderContactDetails = (contact) => {
        return labelPreviewOf(contact);
    }

    if (!widgetData.contacts) return null;

    return (
        <WidgetSection title={widgetTitle} displayEditAction={displayEditAction} onEdit={onEdit} >
            <div className="flex flex-col gap-3">
                {
                    injectUserData(widgetData.contacts, userData).map((contact, index) => (
                        <div key={index} className="shadow-lg h-14 w-full rounded-lg cursor-pointer">
                            <div className="flex py-4 px-3.5 w-full h-full items-center align-center">
                                <div onClick={() => handleClick(contact)} className="flex w-full gap-3 h-full items-center">
                                    { renderContactIcon(contact.type) }

                                    <RuleVertical bgClass="bg-[#9CA3AF]"/>

                                    <div className="flex-1 text-[0.875rem] leading-5 font-normal text-[#6B7280] truncate md:w-44">
                                        {
                                            renderContactDetails(contact)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </WidgetSection>
    );
}

export default WidgetContactView;