import React from 'react';

function SvgBrushUnderline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.13163 1.79962L3.02663 6.14462C2.87163 6.30962 2.72163 6.63462 2.69163 6.85962L2.50663 8.47962C2.44163 9.06462 2.86163 9.46462 3.44163 9.36462L5.05163 9.08962C5.27663 9.04962 5.59163 8.88462 5.74663 8.71462L9.85163 4.36962C10.5616 3.61962 10.8816 2.76462 9.77663 1.71962C8.67663 0.68462 7.84163 1.04962 7.13163 1.79962Z" stroke={props.color || 'black'} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.44531 2.52539C6.66031 3.90539 7.78031 4.96039 9.17031 5.10039" stroke={props.color || 'black'} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 11H11" stroke={props.color || 'black'} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgBrushUnderline;