import React from 'react';

function SvgCardSave(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.08203 5.91699H11.9154" stroke={props.color || "white"} strokeWidth="0.8125" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.25646 11.6043H3.48855C1.56563 11.6043 1.07812 11.1276 1.07812 9.22637V4.77387C1.07812 3.05137 1.47897 2.49887 2.99022 2.4122C3.14189 2.40679 3.3098 2.40137 3.48855 2.40137H9.50646C11.4294 2.40137 11.9169 2.87803 11.9169 4.77928V7.16804" stroke={props.color || "white"} strokeWidth="0.8125" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.25 9.16699H5.41667" stroke={props.color || "white"} strokeWidth="0.8125" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.9154 10.2497C11.9154 10.6559 11.8016 11.0405 11.6012 11.3655C11.2274 11.9938 10.5395 12.4163 9.7487 12.4163C8.95786 12.4163 8.26996 11.9938 7.89621 11.3655C7.69579 11.0405 7.58203 10.6559 7.58203 10.2497C7.58203 9.05259 8.55161 8.08301 9.7487 8.08301C10.9458 8.08301 11.9154 9.05259 11.9154 10.2497Z" stroke={props.color || "white"} strokeWidth="0.8125" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.90625 10.2496L9.44249 10.7858L10.5962 9.71875" stroke={props.color || "white"} strokeWidth="0.8125" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgCardSave;