function ButtonSwitchMobile({setToggle, toggle}) {
    return (
        <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer"/>
            <div
                onChange={() => setToggle(prev => !prev)}
                className={`${toggle === false
                    ? "bg-[#EEEFF2] peer-checked:after:translate-x-5 peer-checked:bg-[#0400BD]"
                    : "bg-[#0400BD] peer-checked:after:-translate-x-5 after:right-0 peer-checked:bg-[#EEEFF2]"}
                    group peer ring-0 rounded-full outline-none duration-300 after:duration-300 w-11 h-6
                    peer-focus:outline-none after:rounded-full after:absolute after:bg-gray-50 after:outline-none 
                    after:h-6 after:w-6 after:flex after:justify-center after:items-center peer-hover:after:scale-90`}
            />
        </label>
    );
}

export default ButtonSwitchMobile;