import React from 'react';

function SvgAccountKeyOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.89629 7.46613C8.86629 8.49113 7.39129 8.80613 6.09629 8.40113L3.74129 10.7511C3.57129 10.9261 3.23629 11.0311 2.99629 10.9961L1.90629 10.8461C1.54629 10.7961 1.21129 10.4561 1.15629 10.0961L1.00629 9.00613C0.971294 8.76613 1.08629 8.43113 1.25129 8.26113L3.60129 5.91113C3.20129 4.61113 3.51129 3.13613 4.54129 2.11113C6.01629 0.636133 8.41129 0.636133 9.89129 2.11113C11.3713 3.58613 11.3713 5.99113 9.89629 7.46613Z" stroke={props.color || "black"} strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.44922 8.74512L4.59922 9.89512" stroke={props.color || "black"} strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.25 5.5C7.66421 5.5 8 5.16421 8 4.75C8 4.33579 7.66421 4 7.25 4C6.83579 4 6.5 4.33579 6.5 4.75C6.5 5.16421 6.83579 5.5 7.25 5.5Z" stroke={props.color || "black"} strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgAccountKeyOutline;