import React from 'react';
import {useTranslation} from "../../services/i18n/i18nService";
import {Icons} from "../../assets/Svgs";

function InputTextarea({ label, rows, cols, labelNote, classFontLabel, labelColorClass, errorMsg, onChange, widthInputClass, paddingClass, backgroundClass, fontClass, gapClass, canCopy, ...inputProps }) {
    const { t: _ } = useTranslation();

    const handleChange = (e) => {
        //console.log(e.target.validity.valid);
        onChange(e);
    }

    return (
        <label className={`flex flex-col ${!!gapClass ? gapClass : ""}`}>
            <div className="flex ">
                <span className={`
                    ${!!inputProps.required ? 'after:content-[\'*\'] after:ml-0 after:text-red-500' : ''} 
                    ${!!canCopy ? 'gap-[0.438rem]' : ''}
                    ${!!classFontLabel ? classFontLabel : "text-sm font-medium flex"}
                    ${!!labelColorClass ? labelColorClass : ""} 
                `}>
                    { label }
                    { !!canCopy ? <Icons.SvgTemplateSimCardOutLine color="#D1D5DB" /> : null }
                </span>
                <p className="text-[0.625rem] ">{_(!!labelNote ? "("+labelNote+")" : "")}</p>
            </div>

            <textarea
                rows={!!rows ? rows : 3} cols={!!cols ? cols : 40}
                className={`
                    ${!!paddingClass ? paddingClass : "px-5 py-3.5"} 
                    ${ !!widthInputClass ? widthInputClass : "" } 
                    ${ !!backgroundClass ? backgroundClass : '' } 
                    ${ !!inputProps.disabled ? 'opacity-50' : '' }
                    ${ !!fontClass ? fontClass : "text-sm text-gray-900" }
                    peer rounded-[0.625rem] focus:outline-none focus:borer-rose-600`
                }
                placeholder={ inputProps.placeholder }
                disabled={ inputProps.disabled }
                name={ inputProps.name }
                required={ inputProps.required }
                value={ inputProps.value }
                onChange={ handleChange }
            />
        </label>
    );
}

export default InputTextarea;