import React from 'react';

function SvgTemplateSectionWhatsAppCTA(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M6.94006 20.63C8.43006 21.5 10.1601 22 12.0001 22C17.6301 22 22.31 17.03 21.98 11.41C21.64 5.60997 16.37 1.13996 10.3 2.13996C6.12004 2.82996 2.77005 6.21996 2.12005 10.4C1.74005 12.82 2.24007 15.11 3.33007 17L2.44006 20.31C2.24006 21.06 2.93004 21.74 3.67004 21.53L6.94006 20.63Z" fill="#292D32"/>
            <path d="M17 15.17C17 15.35 16.96 15.54 16.87 15.72C16.78 15.9 16.67 16.07 16.53 16.23C16.28 16.5 16.01 16.7 15.71 16.82C15.41 16.95 15.08 17.01 14.73 17.01C14.22 17.01 13.67 16.89 13.1 16.64C12.52 16.39 11.95 16.06 11.38 15.65C10.8 15.23 10.26 14.76 9.73999 14.25C9.21999 13.73 8.76003 13.18 8.34003 12.61C7.93003 12.04 7.59999 11.47 7.35999 10.9C7.11999 10.33 7 9.77998 7 9.25998C7 8.91998 7.05999 8.58998 7.17999 8.28998C7.29999 7.97998 7.49001 7.69997 7.76001 7.44997C8.08001 7.12997 8.42999 6.97998 8.79999 6.97998C8.93999 6.97998 9.08002 7.00998 9.21002 7.06998C9.34002 7.12998 9.45999 7.21998 9.54999 7.34998L10.7 8.99998C10.79 9.12998 10.86 9.23998 10.9 9.34998C10.95 9.45998 10.97 9.55998 10.97 9.65998C10.97 9.77998 10.93 9.89997 10.86 10.02C10.79 10.14 10.7 10.26 10.58 10.38L10.2 10.78C10.14 10.84 10.12 10.9 10.12 10.98C10.12 11.02 10.13 11.06 10.14 11.1C10.16 11.14 10.17 11.17 10.18 11.2C10.27 11.37 10.43 11.58 10.65 11.84C10.88 12.1 11.12 12.37 11.38 12.63C11.65 12.9 11.91 13.14 12.18 13.37C12.44 13.59 12.66 13.74 12.83 13.83C12.86 13.84 12.89 13.86 12.92 13.87C12.96 13.89 13 13.89 13.05 13.89C13.14 13.89 13.2 13.86 13.26 13.8L13.64 13.42C13.77 13.29 13.89 13.2 14 13.14C14.12 13.07 14.23 13.03 14.36 13.03C14.46 13.03 14.56 13.05 14.67 13.1C14.78 13.15 14.9 13.21 15.02 13.3L16.68 14.48C16.81 14.57 16.9 14.68 16.96 14.8C16.97 14.91 17 15.03 17 15.17Z" fill="#292D32"/>
        </svg>
    );
}

export default SvgTemplateSectionWhatsAppCTA;