import { useState } from "react";
import { useTranslation } from "../../services/i18n/i18nService";
import { usePopup } from "../../services/PopupService";
import { Calendar } from "../Inputs/Calendar";
import PopupBase from "../Popups/PopupBase";

const PopupSelectorCalendar = ({
    initialRange,
    onConfirm,
}) => {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    const [selectedRange, setSelectedRange] = useState({
        from: initialRange?.from ?? undefined,
        to: initialRange?.to ?? undefined,
    });

    const handleDatePickerChange = (date) => {
        if (!date) return;
        setSelectedRange(date);
    }

    return (
        <PopupBase
            title=""
            footerButtons={
                [
                    {
                        label: _("Save and Apply"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-12",
                        onClick: () => {
                            onConfirm && onConfirm(selectedRange);
                            removePopup();
                        },
                    },
                ]
            }>
            <div className="flex flex-col">
                <h2 className="text-lg font-semibold mb-4 text-center">Select a Date Range</h2>
                <Calendar
                    initialFocus
                    showOutsideDays={false}
                    mode="range"
                    defaultMonth={selectedRange?.from}
                    selected={selectedRange}
                    onSelect={handleDatePickerChange}
                    numberOfMonths={2}
                />
                {selectedRange.from && selectedRange.to && (
                    <p className="mt-4 text-center">
                        {_("From: ") + selectedRange.from.toLocaleDateString() + " " + _("To: ") + selectedRange.to.toLocaleDateString()}
                    </p>
                )}
            </div>
        </PopupBase>
    );
}

export default PopupSelectorCalendar;