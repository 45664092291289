import React from 'react';

function SvgTeamUserOctagon(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5381 4.29002V7.71C10.5381 8.27 10.2381 8.79002 9.75312 9.07502L6.78312 10.79C6.29812 11.07 5.69812 11.07 5.20812 10.79L2.23812 9.07502C1.75312 8.79502 1.45312 8.275 1.45312 7.71V4.29002C1.45312 3.73002 1.75312 3.21 2.23812 2.925L5.20812 1.21C5.69312 0.93 6.29312 0.93 6.78312 1.21L9.75312 2.925C10.2381 3.21 10.5381 3.72502 10.5381 4.29002Z" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.00094 5.49991C6.64435 5.49991 7.16594 4.97831 7.16594 4.3349C7.16594 3.69149 6.64435 3.16992 6.00094 3.16992C5.35753 3.16992 4.83594 3.69149 4.83594 4.3349C4.83594 4.97831 5.35753 5.49991 6.00094 5.49991Z" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 8.32919C8 7.42919 7.105 6.69922 6 6.69922C4.895 6.69922 4 7.42919 4 8.32919" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgTeamUserOctagon;