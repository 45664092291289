import React from 'react';

function SvgBrushOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4028 1.96994C10.6328 3.88994 8.70281 6.49994 7.08781 7.79494L6.10281 8.58494C5.97781 8.67494 5.85281 8.75494 5.71281 8.80994C5.71281 8.71994 5.70781 8.61994 5.69281 8.52494C5.63781 8.10494 5.44781 7.71494 5.11281 7.37994C4.77281 7.03994 4.35781 6.83994 3.93281 6.78494C3.83281 6.77994 3.73281 6.76994 3.63281 6.77994C3.68781 6.62494 3.77281 6.47994 3.87781 6.35994L4.65781 5.37494C5.94781 3.75994 8.56781 1.81994 10.4828 1.05494C10.7778 0.944939 11.0628 1.02494 11.2428 1.20994C11.4328 1.39494 11.5228 1.67994 11.4028 1.96994Z" stroke={props.color || "black"} strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.71465 8.81039C5.71465 9.36039 5.50465 9.88539 5.10965 10.2854C4.80465 10.5904 4.38965 10.8004 3.89465 10.8654L2.66465 11.0004C1.99465 11.0754 1.41965 10.5054 1.49965 9.82539L1.63465 8.59539C1.75465 7.50039 2.66965 6.80039 3.63965 6.78039C3.73965 6.77539 3.84465 6.78039 3.93965 6.78539C4.36465 6.84039 4.77965 7.03539 5.11965 7.38039C5.45465 7.71539 5.64465 8.10539 5.69965 8.52539C5.70465 8.62039 5.71465 8.71539 5.71465 8.81039Z" stroke={props.color || "black"} strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.62281 7.23512C7.62281 5.93012 6.56281 4.87012 5.25781 4.87012" stroke={props.color || "black"} strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgBrushOutline;