import React from 'react';

function SvgSidebarTeam(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9987 4.91852C11.9587 4.91185 11.9121 4.91185 11.8721 4.91852C10.9521 4.88518 10.2188 4.13185 10.2188 3.19852C10.2188 2.24518 10.9854 1.47852 11.9387 1.47852C12.8921 1.47852 13.6587 2.25185 13.6587 3.19852C13.6521 4.13185 12.9187 4.88518 11.9987 4.91852Z" stroke={props.color || "black"} />
            <path d="M11.313 9.77196C12.2263 9.92529 13.233 9.76529 13.9396 9.29196C14.8796 8.66529 14.8796 7.63862 13.9396 7.01196C13.2263 6.53862 12.2063 6.37862 11.293 6.53862" stroke={props.color || "black"} />
            <path d="M3.98031 4.91852C4.02031 4.91185 4.06698 4.91185 4.10698 4.91852C5.02698 4.88518 5.76031 4.13185 5.76031 3.19852C5.76031 2.24518 4.99365 1.47852 4.04031 1.47852C3.08698 1.47852 2.32031 2.25185 2.32031 3.19852C2.32698 4.13185 3.06031 4.88518 3.98031 4.91852Z" stroke={props.color || "black"} />
            <path d="M4.6676 9.77196C3.75427 9.92529 2.7476 9.76529 2.04094 9.29196C1.10094 8.66529 1.10094 7.63862 2.04094 7.01196C2.75427 6.53862 3.77427 6.37862 4.6876 6.53862" stroke={props.color || "black"} />
            <path d="M7.99484 9.89899C7.95484 9.89232 7.90818 9.89232 7.86818 9.89899C6.94818 9.86565 6.21484 9.11232 6.21484 8.17899C6.21484 7.22565 6.98151 6.45898 7.93484 6.45898C8.88818 6.45898 9.65484 7.23232 9.65484 8.17899C9.64818 9.11232 8.91484 9.87232 7.99484 9.89899Z" stroke={props.color || "black"} />
            <path d="M6.05656 11.9987C5.11656 12.6254 5.11656 13.6521 6.05656 14.2787C7.12323 14.9921 8.8699 14.9921 9.93656 14.2787C10.8766 13.6521 10.8766 12.6254 9.93656 11.9987C8.87656 11.2921 7.12323 11.2921 6.05656 11.9987Z" stroke={props.color || "black"} />
        </svg>
}

export default SvgSidebarTeam;