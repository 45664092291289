import React from 'react';

function SvgPortfolioUserEdit({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00033 8.50004C5.88699 8.50004 4.16699 6.78004 4.16699 4.66671C4.16699 2.55337 5.88699 0.833374 8.00033 0.833374C10.1137 0.833374 11.8337 2.55337 11.8337 4.66671C11.8337 6.78004 10.1137 8.50004 8.00033 8.50004ZM8.00033 1.83337C6.44033 1.83337 5.16699 3.10671 5.16699 4.66671C5.16699 6.22671 6.44033 7.50004 8.00033 7.50004C9.56033 7.50004 10.8337 6.22671 10.8337 4.66671C10.8337 3.10671 9.56033 1.83337 8.00033 1.83337Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M10.547 15.1667C10.2937 15.1667 10.0537 15.0734 9.88036 14.9001C9.6737 14.6934 9.58036 14.3934 9.62703 14.0801L9.7537 13.1801C9.78703 12.9468 9.92704 12.6734 10.0937 12.5001L12.4537 10.1401C13.4004 9.19343 14.2337 9.73343 14.6404 10.1401C14.987 10.4868 15.167 10.8601 15.167 11.2334C15.167 11.6134 14.9937 11.9667 14.6404 12.3201L12.2803 14.6801C12.1137 14.8468 11.8337 14.9868 11.6004 15.0201L10.7004 15.1467C10.647 15.1601 10.6004 15.1667 10.547 15.1667ZM13.5404 10.6134C13.4204 10.6134 13.3137 10.6934 13.1604 10.8468L10.8004 13.2067C10.7804 13.2267 10.747 13.2934 10.747 13.3201L10.627 14.1534L11.4604 14.0334C11.487 14.0267 11.5537 13.9934 11.5737 13.9734L13.9337 11.6134C14.0404 11.5067 14.167 11.3534 14.167 11.2334C14.167 11.1334 14.087 10.9934 13.9337 10.8468C13.7737 10.6868 13.6537 10.6134 13.5404 10.6134Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M13.9471 12.8134C13.9004 12.8134 13.8537 12.8067 13.8137 12.7934C12.9337 12.5467 12.2337 11.8467 11.9871 10.9667C11.9137 10.7 12.0671 10.4267 12.3337 10.3534C12.6004 10.2801 12.8737 10.4334 12.9471 10.7C13.1004 11.2467 13.5337 11.68 14.0804 11.8334C14.3471 11.9067 14.5004 12.1867 14.4271 12.4467C14.3671 12.6667 14.1671 12.8134 13.9471 12.8134Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M2.27344 15.1667C2.0001 15.1667 1.77344 14.94 1.77344 14.6667C1.77344 11.82 4.56679 9.5 8.00012 9.5C8.72679 9.5 9.44678 9.60668 10.1201 9.80668C10.3868 9.88668 10.5335 10.1667 10.4535 10.4267C10.3735 10.6933 10.0935 10.84 9.83346 10.76C9.24679 10.5867 8.63346 10.4933 8.00012 10.4933C5.12012 10.4933 2.77344 12.36 2.77344 14.66C2.77344 14.94 2.54677 15.1667 2.27344 15.1667Z"
                fill={!!color ? color : "white"}/>
        </svg>

    );
}

export default SvgPortfolioUserEdit;