import React from 'react';

function SvgProfileClientLogin({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00008 8.49967C5.88675 8.49967 4.16675 6.77967 4.16675 4.66634C4.16675 2.55301 5.88675 0.833008 8.00008 0.833008C10.1134 0.833008 11.8334 2.55301 11.8334 4.66634C11.8334 6.77967 10.1134 8.49967 8.00008 8.49967ZM8.00008 1.83301C6.44008 1.83301 5.16675 3.10634 5.16675 4.66634C5.16675 6.22634 6.44008 7.49967 8.00008 7.49967C9.56008 7.49967 10.8334 6.22634 10.8334 4.66634C10.8334 3.10634 9.56008 1.83301 8.00008 1.83301Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M10.5465 15.1663C10.2932 15.1663 10.0532 15.0729 9.87988 14.8996C9.67321 14.6929 9.57987 14.3929 9.62654 14.0796L9.75321 13.1796C9.78654 12.9463 9.92655 12.6729 10.0932 12.4996L12.4532 10.1396C13.3999 9.19294 14.2332 9.73294 14.6399 10.1396C14.9866 10.4863 15.1665 10.8596 15.1665 11.2329C15.1665 11.6129 14.9932 11.9662 14.6399 12.3196L12.2799 14.6796C12.1132 14.8463 11.8332 14.9863 11.5999 15.0196L10.6999 15.1462C10.6465 15.1596 10.5999 15.1663 10.5465 15.1663ZM13.5399 10.6129C13.4199 10.6129 13.3132 10.6929 13.1599 10.8463L10.7999 13.2063C10.7799 13.2263 10.7465 13.2929 10.7465 13.3196L10.6265 14.1529L11.4599 14.0329C11.4865 14.0263 11.5532 13.9929 11.5732 13.9729L13.9332 11.6129C14.0399 11.5062 14.1665 11.3529 14.1665 11.2329C14.1665 11.1329 14.0866 10.9929 13.9332 10.8463C13.7732 10.6863 13.6532 10.6129 13.5399 10.6129Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M13.9468 12.8132C13.9001 12.8132 13.8535 12.8066 13.8135 12.7933C12.9335 12.5466 12.2335 11.8466 11.9868 10.9666C11.9135 10.6999 12.0668 10.4266 12.3335 10.3533C12.6002 10.2799 12.8735 10.4332 12.9468 10.6999C13.1001 11.2466 13.5335 11.6799 14.0802 11.8332C14.3468 11.9066 14.5002 12.1866 14.4268 12.4466C14.3668 12.6666 14.1668 12.8132 13.9468 12.8132Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M2.27344 15.1667C2.0001 15.1667 1.77344 14.94 1.77344 14.6667C1.77344 11.82 4.56679 9.5 8.00012 9.5C8.72679 9.5 9.44678 9.60668 10.1201 9.80668C10.3868 9.88668 10.5335 10.1667 10.4535 10.4267C10.3735 10.6933 10.0935 10.84 9.83346 10.76C9.24679 10.5867 8.63346 10.4933 8.00012 10.4933C5.12012 10.4933 2.77344 12.36 2.77344 14.66C2.77344 14.94 2.54677 15.1667 2.27344 15.1667Z"
                fill={!!color ? color : "white"}/>
        </svg>
    );
}

export default SvgProfileClientLogin;