import React from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import SelectorTutorialVideo from "../../../pages/Admin/Settings/SelectorTutorialVideo";
import WidgetSection from "../../Layouts/WidgetSection";

function WidgetVideoView(
    {
        widget,
        displayEditAction,
        onEdit,
    }) {

    const widgetData = widget.data;
    const widgetTitle = widgetData?.title;
    const widgetLink = widgetData?.link;
    const widgetDescription = widgetData?.description;
    const { t: _ } = useTranslation();

    return (
        <WidgetSection title={widgetTitle} displayEditAction={displayEditAction} onEdit={onEdit} >
            <div className="w-full grid gap-2.5">
                <div className="w-full rounded-lg h-64 overflow-hidden">
                    <SelectorTutorialVideo>
                        <iframe
                            className="w-full h-full"
                            src={widgetLink}
                        />
                    </SelectorTutorialVideo>
                </div>
                <p className="text-[#6B7280] leading-4 font-normal text-[0.688rem]">{ _(widgetDescription) }</p>
            </div>
        </WidgetSection>
    );
}

export default WidgetVideoView;