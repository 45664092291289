import React from 'react';

function SvgLoginPasswd({className, color}) {
    return (
        <svg className={`${!!className ? className : ""} absolute top-1/2 left-3 -translate-y-1/2 h-5`} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.76247 24.0189C6.65702 24.0189 6.54103 24.0084 6.44613 23.9978L4.15793 23.6815C3.06128 23.5338 2.07008 22.5532 1.90136 21.4354L1.58502 19.1262C1.47958 18.388 1.79592 17.4285 2.32315 16.8907L6.95227 12.2616C6.2036 9.26687 7.06826 6.10346 9.27211 3.92071C12.6886 0.514776 18.2456 0.504232 21.6727 3.92071C23.3282 5.57623 24.235 7.78007 24.235 10.121C24.235 12.4619 23.3282 14.6658 21.6727 16.3213C19.4583 18.5146 16.3054 19.3792 13.3318 18.62L8.69215 23.2491C8.24927 23.7131 7.45842 24.0189 6.76247 24.0189ZM15.4618 2.94006C13.6165 2.94006 11.7817 3.63601 10.3793 5.03845C8.48125 6.92595 7.79585 9.68866 8.5867 12.2616C8.67106 12.5463 8.59724 12.8415 8.38635 13.0524L3.43034 18.0084C3.25108 18.1877 3.10346 18.6516 3.13509 18.8942L3.45143 21.2035C3.5147 21.6042 3.94703 22.0576 4.34773 22.1103L6.64648 22.4266C6.89955 22.4688 7.36352 22.3212 7.54278 22.1419L12.5199 17.1754C12.7308 16.9645 13.0366 16.9012 13.3107 16.9856C15.852 17.787 18.6252 17.1016 20.5233 15.2035C21.873 13.8538 22.6217 12.0401 22.6217 10.121C22.6217 8.19131 21.873 6.38817 20.5233 5.03845C19.1525 3.64655 17.3072 2.94006 15.4618 2.94006Z" fill={color || "#9CA3AF"}/>
            <path d="M9.93652 21.6879C9.73617 21.6879 9.53582 21.6141 9.37765 21.456L6.95237 19.0307C6.64657 18.7249 6.64657 18.2187 6.95237 17.9129C7.25817 17.6071 7.76431 17.6071 8.07011 17.9129L10.4954 20.3382C10.8012 20.644 10.8012 21.1502 10.4954 21.456C10.3372 21.6141 10.1369 21.6879 9.93652 21.6879Z" fill={color || "#9CA3AF"}/>
            <path d="M15.5356 12.4199C14.2281 12.4199 13.1631 11.3549 13.1631 10.0474C13.1631 8.73982 14.2281 7.6748 15.5356 7.6748C16.8432 7.6748 17.9082 8.73982 17.9082 10.0474C17.9082 11.3549 16.8432 12.4199 15.5356 12.4199ZM15.5356 9.25651C15.1033 9.25651 14.7448 9.61503 14.7448 10.0474C14.7448 10.4797 15.1033 10.8382 15.5356 10.8382C15.968 10.8382 16.3265 10.4797 16.3265 10.0474C16.3265 9.61503 15.968 9.25651 15.5356 9.25651Z" fill={color || "#9CA3AF"}/>
        </svg>
    );
}

export default SvgLoginPasswd;