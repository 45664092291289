import React from 'react';

function SvgTemplateSectionFile(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z" fill="#292D32"/>
            <path d="M12.1999 17.66C11.4999 17.66 10.7899 17.39 10.2599 16.86C9.18995 15.79 9.18995 14.04 10.2599 12.97L11.6699 11.56C11.9599 11.27 12.4399 11.27 12.7299 11.56C13.0199 11.85 13.0199 12.33 12.7299 12.62L11.3199 14.03C10.8299 14.52 10.8299 15.31 11.3199 15.8C11.8099 16.29 12.5999 16.29 13.0899 15.8L15.3099 13.58C15.9199 12.97 16.2599 12.15 16.2599 11.28C16.2599 10.41 15.9199 9.60003 15.3099 8.98003C14.0799 7.75003 11.9399 7.75003 10.7099 8.98003L8.28995 11.4C7.24995 12.44 7.24995 14.14 8.28995 15.19C8.57995 15.48 8.57995 15.96 8.28995 16.25C7.99995 16.54 7.51995 16.54 7.22995 16.25C5.59995 14.62 5.59995 11.97 7.22995 10.34L9.64995 7.92003C10.5499 7.02003 11.7399 6.53003 13.0099 6.53003C14.2799 6.53003 15.4699 7.02003 16.3699 7.92003C17.2699 8.82003 17.7599 10.01 17.7599 11.28C17.7599 12.55 17.2699 13.74 16.3699 14.64L14.1499 16.86C13.6099 17.39 12.9099 17.66 12.1999 17.66Z" fill="#292D32"/>
        </svg>
    );
}

export default SvgTemplateSectionFile;