import React from 'react';

function SvgProfileSaveContact({className}) {
    return (
        <svg className={`${!!className ? className : "h-12" } `} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" strokeWidth="8"/>
            <path
                d="M35 37H21C20.4696 37 19.9609 36.7893 19.5858 36.4142C19.2107 36.0391 19 35.5304 19 35V21C19 20.4696 19.2107 19.9609 19.5858 19.5858C19.9609 19.2107 20.4696 19 21 19H32L37 24V35C37 35.5304 36.7893 36.0391 36.4142 36.4142C36.0391 36.7893 35.5304 37 35 37Z"
                stroke="#0400BD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M33 37V29H23V37" stroke="#0400BD" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M23 19V24H31" stroke="#0400BD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}

export default SvgProfileSaveContact;