import React, { useState } from 'react';
import { Icons } from "../../../../assets/Svgs";
import ChartArea from "../../../../components/Layouts/AreaChart";
import { usePopup } from "../../../../services/PopupService";
import { useTranslation } from "../../../../services/i18n/i18nService";
import PopupTemplateFilterProfileViewOverTime from "../../Templates/Popups/PopupTemplateFilterProfileViewOverTime";

export const ViewsOverTime = ({ id, title, displayViewSelector, value, increase, datas, col, row, heightChart }) => {
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const [selectedView, setSelectedView] = useState("profileViews");

    return (
        <div className="flex flex-col gap-3.5 h-full">
            <header className="flex justify-between items-center w-full">
                <div className="text-sm">{title}</div>
                {
                    displayViewSelector && (
                        <span
                            onClick={() => addPopup(<PopupTemplateFilterProfileViewOverTime selectedView={selectedView} onSelectedViewChange={setSelectedView} />)}
                            className="inline-flex items-center min-w-fit rounded-l-md text-sm text-black gap-[0.438rem]"
                        >
                            <Icons.SvgStatisticSearchStatus color="black" />
                            <p className="cursor-default">{_("Choose the view")}</p>
                            <Icons.SvgStatisticChevronCompactDownOutline color="black" />
                        </span>
                    )
                }
            </header>

            <div className={`${!!col ? "flex-col gap-3.5" : ""} ${!!row ? "flex-row" : ""} flex h-full`}>
                <div className="w-1/3 flex gap-3 items-end">
                    {
                        typeof value !== "number" ? (
                            <p className="text-sm">{value}</p>
                        ) : (
                            <strong className="text-lg font-medium">{value}</strong>
                        )
                    }
                    {
                        increase > 0 ? (
                            <span className="text-sm text-green-500 flex items-end">
                                <Icons.SvgStatisticCaretUpFill color="#25D366" />
                                {increase}%
                            </span>
                        ) : (
                            <span className="text-sm text-red-500 flex items-end">
                                <Icons.SvgStatisticCaretDownFill color="#FF0000" />
                                {Math.abs(increase)}%
                            </span>
                        )
                    }
                </div>

                <div className={`${!!heightChart ? heightChart : "h-full"}  ${!!row ? "w-2/3" : ""}`}>
                    <ChartArea
                        id={id}
                        data={datas}
                        startColor={id === 2 ? "#FFD99F" : id === 1 ? "#9FFFA9" : "#B09FFF"}
                        stopColor={id === 2 ? "#FFAE35" : id === 1 ? "#8AF679" : "#8D79F6"}
                    />
                </div>
            </div>

        </div>
    );
};

export default ViewsOverTime;