import React from 'react';
import PopupPicturesPreview from "../../../pages/Mobile/Popups/PopupPicturesPreview";
import { usePopup } from "../../../services/PopupService";
import WidgetSection from "../../Layouts/WidgetSection";

function WidgetPictureView(
    {
        widget,
        displayEditAction,
        onEdit,
    }) {
    /* Hooks */
    const { addPopup } = usePopup();

    /* Utils */
    const getPictureImg = (picture) => {
        return picture.file_content ? picture.file_content : widgetPictures?.find(p => p.id === parseInt(picture.widget_picture_id))?.picture;
    }

    const widgetPictures = widget.pictures;
    const widgetData = widget.data;
    const widgetTitle = widgetData.title;
    const pictures = (widgetData.pictures?.sort((a, b) => a.order - b.order) || []).map(picture => (
        { ...picture, img: getPictureImg(picture) }
    ));
    const pictureCount = pictures.length;

    return (
        <WidgetSection title={widgetTitle} displayEditAction={displayEditAction} onEdit={onEdit} >
            <div className={`w-full h-56 flex flex-wrap rounded-lg overflow-hidden`}>
                {pictures.slice(0, 4).map((picture, index) => (
                    <div
                        key={index}
                        className={`relative ${pictureCount === 1 ? 'w-full h-full' : pictureCount === 2 ? 'w-1/2 h-full' : pictureCount === 3 && index === 2 ? 'w-full h-1/2' : 'w-1/2 h-1/2'}`}
                        style={{
                            backgroundImage: `url(${picture.img})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                        onClick={() => addPopup(<PopupPicturesPreview pictures={pictures} startPictureIndex={index} />)}
                    >
                        {index === 3 && pictureCount > 4 && (
                            <div className="absolute inset-0 flex h-full text-white justify-center items-center bg-black bg-opacity-60">
                                <p className="leading-6 text-[1rem] font-normal">+{pictureCount - 4}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </WidgetSection>
    );
}

export default WidgetPictureView;