import React, { useState } from 'react';
import PortfolioFooter from "../Layouts/PortfolioFooter";
import { useTranslation } from "../../../services/i18n/i18nService";
import { Icons } from "../../../assets/Svgs";
import InputGroup from "../../../components/Layouts/InputGroup";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import ButtonSwitchMobile from "../../../components/Buttons/ButtonSwitchMobile";

function SettingsMobile() {
    const { t: _ } = useTranslation();
    const [toggleMail, setToggleMail] = useState(true);
    const [toggleLogo, setToggleLogo] = useState(false);
    const [values, setValues] = useState({
        link: "profylead.com/profile/davordjuric",
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <div>
            <div className="m-4 sm:m-6 md:m-8 lg:m-10 xl:m-12 flex flex-col gap-10">
                <div className="flex flex-col gap-4">
                    <InputGroup
                        label="Manage the settings of your profile"
                        classGapLabelToField="gap-3.5"
                        classFontLabel="text-[#111827] font-medium text-[1.25rem] leading-7 w-4/6"
                        fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                        widthInputClass="w-full"
                        name="link"
                        placeholder="Link"
                        value={ values["link"] }
                        onChange={ onChange }
                        icon={ <Icons.SvgProfilePopupMediaLink className="absolute top-1/2 left-4 -translate-y-1/2 h-5" /> }
                    />

                    <div className="flex flex-col gap-4">
                        <div className="text-[#111827] font-medium text-[1.25rem] leading-7 w-4/6">
                            { _("Manage your preferences") }
                        </div>

                        <div className="flex justify-between items-center">
                            <div className="text-[1rem] font-normal leading-7">{_("Send a mail to your news leads")}</div>
                            <ButtonSwitchMobile toggle={toggleMail} setToggle={setToggleMail} />
                        </div>

                        <div className="flex justify-between items-center">
                            <div className="text-[1rem] font-normal leading-7 w-4/6">{_("Remove the “Profylead” logo from your profile page")}</div>
                            <ButtonSwitchMobile toggle={toggleLogo} setToggle={setToggleLogo} />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <ButtonBase className="bg-[#0400BD] text-white text-[1rem] leading-6 font-medium py-3 px-4 rounded-full">
                        { _("How to ?") }
                    </ButtonBase>

                    <ButtonBase className="bg-[#EC0000] text-white text-[1rem] leading-6 font-medium py-3 px-4 rounded-full">
                        { _("Sign out") }
                    </ButtonBase>

                    <ButtonBase className="bg-[#D6D6FF] text-[#111827] text-[1rem] leading-6 font-medium py-3 px-4 rounded-full">
                        { _("Share feedback") }
                    </ButtonBase>
                </div>

                <div className="flex flex-col gap-4">
                    <ButtonBase className="bg-white shadow-lg text-[#111827] text-[1rem] leading-6 font-medium py-3 px-4 rounded-full">
                        { _("Help and Support") }
                    </ButtonBase>

                    <div className="flex gap-1 justify-center">
                        <span className="text-black font-normal leading-4 text-[0.75rem]">{_("Terms")}</span>
                        <span className="text-[#6B7280] font-normal leading-4 text-[0.75rem]">{_("and")}</span>
                        <span className="text-black font-normal leading-4 text-[0.75rem]">{_("Privacy")}</span>
                    </div>
                </div>
            </div>
            <PortfolioFooter routerSelected="settings" />
        </div>
    );
}

export default SettingsMobile;