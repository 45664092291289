import React from 'react';
import { Icons } from "../../assets/Svgs";
import { useTranslation } from "../../services/i18n/i18nService";
import ButtonFlexible from "../Buttons/ButtonFlexible";

function SelectorDate({isFlexible, onClick}) {
    const { t: _ } = useTranslation();

    return <>
        {
            !isFlexible
                ? <div className="w-fit">
                    <div className={`px-4 whitespace-nowrap bg-white flex gap-4 justify-between items-center h-10 rounded-full cursor-pointer`}>
                        <div className={`flex w-11 h-7 justify-self-start items-center flex-col bg-black rounded-full`}>
                            <p className="font-poppins font-medium text-[1.125rem] h-10 rounded-[1.25rem] flex items-center justify-center">
                                <span className="text-base">
                                    <Icons.SvgSelectorCalendarOutline color="white"/>
                                </span>
                            </p>
                        </div>

                        <p className="text-[#9CA3AF] flex items-center text-sm focus:outline-none active:outline-none h-10 rounded-full shadow-sm">
                            {_(`{dd} {mm} {yyyy}`, {
                                dd: _("1"),
                                mm: _("Jan"),
                                yyyy: _("2019"),
                            })}
                            <Icons.SvgArrowRightOutline color="#9CA3AF" />
                            {_(`{dd} {mm} {yyyy}`, {
                                dd: _("14"),
                                mm: _("Feb"),
                                yyyy: _("2019"),
                            })}
                        </p>
                    </div>
                </div>
                : <ButtonFlexible onClick={onClick} icon={<Icons.SvgSelectorCalendarOutline />}>
                    {_("Calendar")}
                </ButtonFlexible>
        }
        </>
}

export default SelectorDate;