// PopupSocialMedia.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from "../../../services/i18n/i18nService";
import WidgetSocialMedia from "../../../components/Widgets/WidgetSocialMedia";
import PopupItemDelete from "./PopupItemDelete";
import PopupMobile from "../../../components/Popups/PopupMobile";
import { Icons } from "../../../assets/Svgs";
import { usePopup } from "../../../services/PopupService";
import { SOCIAL_MEDIA_TYPES, MEDIAS } from "../../../constants/SocialMediasTypes";

const PopupSocialMedia = ({ mode, itemSelected, list, setList }) => {
    const { clearAllPopups, addPopup } = usePopup();
    const { t: _ } = useTranslation();
    const initialType = mode === 'edit' ? itemSelected.type : "wa";
    const initialValues = mode === 'edit'
        ? { ...itemSelected }
        : { id: 0, type: initialType, name: "", tel: "", note: "", link: "", isDisabled: false, isInactive: false, order: 0 };

    const [typeSelect, setTypeSelect] = useState(initialType);
    const [socialMediaValues, setSocialMediaValues] = useState(initialValues);

    const onChange = (e) => {
        setSocialMediaValues({ ...socialMediaValues, [e.target.name]: e.target.value });
    };

    const setMedia = (type) => {
        switch (type) {
            case SOCIAL_MEDIA_TYPES.WHATSAPP:
                return <Icons.SvgMediaWhatsappFill className="h-8" color={`${typeSelect === type ? "" : "#9CA3AF"}`} />;
            case SOCIAL_MEDIA_TYPES.LINKEDIN:
                return <Icons.SvgMediaLinkedinFill className="h-8" color={`${typeSelect === type ? "" : "#9CA3AF"}`} />;
            case SOCIAL_MEDIA_TYPES.YOUTUBE:
                return <Icons.SvgMediaYoutubeFill className="h-8" color={`${typeSelect === type ? "" : "#9CA3AF"}`} />;
            case SOCIAL_MEDIA_TYPES.FACEBOOK:
                return <Icons.SvgMediaFacebookFill className="h-8" color={`${typeSelect === type ? "" : "#9CA3AF"}`} />;
            case SOCIAL_MEDIA_TYPES.TWITTER:
                return <Icons.SvgMediaTwitterFill className="h-8" color={`${typeSelect === type ? "" : "#9CA3AF"}`} />;
            case SOCIAL_MEDIA_TYPES.TIKTOK:
                return <Icons.SvgMediaTiktokFill className="h-8" color={`${typeSelect === type ? "" : "#9CA3AF"}`} />;
            case SOCIAL_MEDIA_TYPES.INSTAGRAM:
                return <Icons.SvgMediaInstagram className="h-8" color={`${typeSelect === type ? "" : "#9CA3AF"}`} />;
            default:
                return null;
        }
    };

    const handleSave = () => {
        if (mode === 'edit') {
            let mediaToUpdate = list.datas.find((data) => data.id === itemSelected.id);
            mediaToUpdate.type = typeSelect;
            mediaToUpdate.name = MEDIAS.find((media) => media.type == typeSelect).name;
            mediaToUpdate.tel = socialMediaValues.tel;
            mediaToUpdate.note = socialMediaValues.note;
            mediaToUpdate.link = socialMediaValues.link;
            setList({ ...list, datas: list.datas });
        } else {
            let orderMax = Math.max(...list.datas.map(data => data.order), -1);
            list.datas.push({
                id: orderMax + 1,
                name: MEDIAS.find((media) => media.type === typeSelect).name,
                type: socialMediaValues.type,
                link: socialMediaValues.link,
                tel: socialMediaValues.tel,
                note: socialMediaValues.note,
                isDisabled: socialMediaValues.isDisabled,
                isInactive: socialMediaValues.isInactive,
                order: orderMax + 1,
            });
            setList({ ...list, datas: list.datas });
        }
        clearAllPopups();
    }

    return (
        <PopupMobile
            cols
            hasBtnClose={true}
            icon={<Icons.SvgProfilePopupMedia />}
            title={mode === 'edit' ? _("Do you want to modify or delete this link ?") : _("Which social media link do you want to add ?")}
            footerButtons={[
                ...(mode === 'edit' ? [
                    {
                        label: _("Delete this link"),
                        background: "bg-[#EC0000]",
                        color: "text-white",
                        onClick: () => { addPopup(<PopupItemDelete />); }
                    }
                ] : []),
                {
                    label: mode === 'edit' ? _("Save the modifications") : _("Add this social media link"),
                    background: "bg-[#0400BD]",
                    color: "text-white",
                    onClick: handleSave,
                }
            ]}
        >
            <WidgetSocialMedia
                typeSelect={typeSelect}
                setTypeSelect={setTypeSelect}
                setMedia={setMedia}
                socialMediaValues={socialMediaValues}
                setSocialMediaValues={setSocialMediaValues}
                onChange={onChange}
            />
        </PopupMobile>
    );
}

export default PopupSocialMedia;