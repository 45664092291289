import React from 'react';

function SvgStatisticUserCircleAdd(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9586 16.8336V17.0753C12.7253 17.8669 11.2503 18.3336 9.66693 18.3336C8.05859 18.3336 6.55859 17.8503 5.30859 17.0253V16.8336C5.30859 14.892 7.25026 13.3086 9.63359 13.3086C12.0253 13.3086 13.9586 14.892 13.9586 16.8336Z" fill="#292D32"/>
        <path opacity="0.4" d="M17.5833 10.4167C17.5833 13.2083 16.1417 15.6583 13.9583 17.075V16.8333C13.9583 14.8917 12.025 13.3083 9.63333 13.3083C7.25 13.3083 5.30833 14.8917 5.30833 16.8333V17.025C3.16667 15.6083 1.75 13.1833 1.75 10.4167C1.75 6.04167 5.29167 2.5 9.66667 2.5C10.7583 2.5 11.8 2.71666 12.75 3.11666C12.6417 3.44999 12.5833 3.8 12.5833 4.16667C12.5833 4.79167 12.7583 5.38333 13.0667 5.88333C13.2333 6.16666 13.45 6.42498 13.7 6.64164C14.2833 7.17498 15.0583 7.5 15.9167 7.5C16.2833 7.5 16.6333 7.44165 16.9583 7.32498C17.3583 8.27498 17.5833 9.325 17.5833 10.4167Z" fill="#292D32"/>
        <path d="M18.3904 1.94233C17.7904 1.259 16.8987 0.833984 15.9154 0.833984C14.982 0.833984 14.132 1.21734 13.5237 1.84234C13.1737 2.20067 12.907 2.63398 12.7487 3.11731C12.6404 3.45064 12.582 3.80065 12.582 4.16732C12.582 4.79232 12.757 5.38398 13.0654 5.88398C13.232 6.16732 13.4487 6.42563 13.6987 6.64229C14.282 7.17563 15.057 7.50065 15.9154 7.50065C16.282 7.50065 16.632 7.4423 16.957 7.32563C17.7237 7.08397 18.3654 6.55898 18.7654 5.88398C18.9404 5.60065 19.0737 5.27564 19.1487 4.94231C19.2154 4.69231 19.2487 4.43398 19.2487 4.16732C19.2487 3.31732 18.9237 2.534 18.3904 1.94233ZM17.157 4.77563H16.5404V5.42566C16.5404 5.76733 16.257 6.05066 15.9154 6.05066C15.5737 6.05066 15.2904 5.76733 15.2904 5.42566V4.77563H14.6737C14.332 4.77563 14.0487 4.4923 14.0487 4.15063C14.0487 3.80897 14.332 3.52563 14.6737 3.52563H15.2904V2.934C15.2904 2.59233 15.5737 2.309 15.9154 2.309C16.257 2.309 16.5404 2.59233 16.5404 2.934V3.52563H17.157C17.4987 3.52563 17.782 3.80897 17.782 4.15063C17.782 4.4923 17.507 4.77563 17.157 4.77563Z" fill="#292D32"/>
        <path d="M9.63073 12.2751C10.924 12.2751 11.9724 11.2267 11.9724 9.93346C11.9724 8.64019 10.924 7.5918 9.63073 7.5918C8.33746 7.5918 7.28906 8.64019 7.28906 9.93346C7.28906 11.2267 8.33746 12.2751 9.63073 12.2751Z" fill="#292D32"/>
    </svg>
}

export default SvgStatisticUserCircleAdd;