import React from 'react';

function SvgTeamThreePersonOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.49906 3.58C9.46906 3.575 9.43406 3.575 9.40406 3.58C8.71406 3.555 8.16406 2.99 8.16406 2.29C8.16406 1.575 8.73906 1 9.45406 1C10.1691 1 10.7441 1.58 10.7441 2.29C10.7391 2.99 10.1891 3.555 9.49906 3.58Z" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.98375 7.22008C9.66875 7.33508 10.4238 7.21508 10.9538 6.86008C11.6588 6.39008 11.6588 5.62008 10.9538 5.15008C10.4188 4.79508 9.65375 4.67508 8.96875 4.79508" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.48328 3.58C3.51328 3.575 3.54828 3.575 3.57828 3.58C4.26828 3.555 4.81828 2.99 4.81828 2.29C4.81828 1.575 4.24328 1 3.52828 1C2.81328 1 2.23828 1.58 2.23828 2.29C2.24328 2.99 2.79328 3.555 3.48328 3.58Z" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.99875 7.22008C3.31375 7.33508 2.55875 7.21508 2.02875 6.86008C1.32375 6.39008 1.32375 5.62008 2.02875 5.15008C2.56375 4.79508 3.32875 4.67508 4.01375 4.79508" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.49906 7.31535C6.46906 7.31035 6.43406 7.31035 6.40406 7.31535C5.71406 7.29035 5.16406 6.72535 5.16406 6.02535C5.16406 5.31035 5.73906 4.73535 6.45406 4.73535C7.16906 4.73535 7.74406 5.31535 7.74406 6.02535C7.73906 6.72535 7.18906 7.29535 6.49906 7.31535Z" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.04438 8.89066C4.33938 9.36066 4.33938 10.1307 5.04438 10.6007C5.84438 11.1357 7.15437 11.1357 7.95437 10.6007C8.65937 10.1307 8.65937 9.36066 7.95437 8.89066C7.15937 8.36066 5.84438 8.36066 5.04438 8.89066Z" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgTeamThreePersonOutline;