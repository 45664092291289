import React, {useEffect, useState} from 'react';
import {Icons} from "../../../../assets/Svgs";
import PopupMobile from "../../../../components/Popups/PopupMobile";
import {useTranslation} from "../../../../services/i18n/i18nService";
import {usePopup} from "../../../../services/PopupService";
import ButtonMobile from "../../../../components/Buttons/ButtonMobile";

function PopupStickerFilter({stickerSelected: selectedStickerParent, onSelectedStickerChange, setConnections, connectionsOriginal}) {
    const { t: _ } = useTranslation();
    const { clearAllPopups } = usePopup();
    const [ stickerSelected, setStickerSelected ] = useState(selectedStickerParent ?? {
        Business : true,
        ToContact : true,
        Private : true,
        Invite : true,
        Cocktail : true,
    });

    let connections = connectionsOriginal;

    useEffect(() => {
        onSelectedStickerChange && onSelectedStickerChange(stickerSelected);
    }, [stickerSelected, onSelectedStickerChange]);

    return (
        <PopupMobile
            cols
            hasBtnClose={true}
            icon={<Icons.SvgConnectionFilterSticker />}
            title={_("Select the different stickers to filter")}
            footerButtons={
                [
                    {
                        label: _("Filter"),
                        background: "bg-[#0400BD]",
                        color: "text-white",
                        className: "text-[1rem] font-normal leading-6",
                        onClick: () => {
                            const idToFilter = [];
                            connections.map((connection) => {
                                connection.tags.map((tag) => {
                                    if(stickerSelected.Business === true){
                                        if(tag.includes("Business")){
                                            idToFilter.push(connection.id)
                                        }
                                    }

                                    if(stickerSelected.ToContact === true){
                                        if(tag.includes("To contact")){
                                            idToFilter.push(connection.id)
                                        }
                                    }

                                    if(stickerSelected.Private === true){
                                        if(tag.includes("Private")){
                                            idToFilter.push(connection.id)
                                        }
                                    }

                                    if(stickerSelected.Invite === true){
                                        if(tag.includes("Invite for the next event")){
                                            idToFilter.push(connection.id)
                                        }
                                    }

                                    if(stickerSelected.Cocktail === true){
                                        if(tag.includes("Cocktail Evening")){
                                            idToFilter.push(connection.id)
                                        }
                                    }
                                })
                            })

                            const idToFilterSet = new Set(idToFilter);
                            const uniqueIdToFilter = Array.from(idToFilterSet);

                            const connectionUpdated = [];
                            uniqueIdToFilter.map((idFilter) => {
                                const connectionToUpdate = connections.find(connection => connection.id === idFilter)
                                connectionUpdated.push(connectionToUpdate)
                            })
                            setConnections(connectionUpdated)
                            clearAllPopups()
                        }
                    }
                ]
            }>

            <div className="flex flex-col gap-1.5 w-full">
                <div className="flex gap-1">
                    <ButtonMobile
                        onClick={
                            () => {
                                stickerSelected.Business = !stickerSelected.Business
                                setStickerSelected({...stickerSelected, ["Business"]: stickerSelected.Business})
                            }
                        }
                        isFlexReverse={true}
                        className={`px-2 py-0.5 border border-[#FF3F61] ${stickerSelected.Business === true ? "bg-[#FF3F61]" : "bg-white"}`}
                        textClass={`${stickerSelected.Business === true ? "text-white" : "text-[#9CA3AF]"} font-normal text-[0.75rem] leading-5`}
                        label="Business"
                    />

                    <ButtonMobile
                        onClick={
                            () => {
                                stickerSelected.ToContact = !stickerSelected.ToContact
                                setStickerSelected({...stickerSelected, ["ToContact"]: stickerSelected.ToContact})
                            }
                        }
                        isFlexReverse={true}
                        className={`px-2 py-0.5 border border-[#B63FFF] ${!!stickerSelected.ToContact ? "bg-[#B63FFF]" : "bg-white"}`}
                        textClass={`${!!stickerSelected.ToContact ? "text-white" : "text-[#9CA3AF]"} font-normal text-[0.75rem] leading-5`}
                        label="To contact"
                    />

                    <ButtonMobile
                        onClick={
                            () => {
                                stickerSelected.Private = !stickerSelected.Private
                                setStickerSelected({...stickerSelected, ["Private"]: stickerSelected.Private})
                            }
                        }
                        isFlexReverse={true}
                        className={`px-2 py-0.5 border border-[#FF3FB2] ${!!stickerSelected.Private ? "bg-[#FF3FB2]" : "bg-white"}`}
                        textClass={`${!!stickerSelected.Private ? "text-white" : "text-[#9CA3AF]"} font-normal text-[0.75rem] leading-5`}
                        label="Private"
                    />

                </div>
                <div className="flex gap-1">
                    <ButtonMobile
                        onClick={
                            () => {
                                stickerSelected.Invite = !stickerSelected.Invite
                                setStickerSelected({...stickerSelected, ["Invite"]: stickerSelected.Invite})
                            }
                        }
                        isFlexReverse={true}
                        className={`px-2 py-0.5 border border-[#FFC266] ${!!stickerSelected.Invite ? "bg-[#FFC266]" : "bg-white"}`}
                        textClass={`${!!stickerSelected.Invite ? "text-white" : "text-[#9CA3AF]"} font-normal text-[0.75rem] leading-5`}
                        label="Invite for the next event"
                    />

                    <ButtonMobile
                        onClick={
                            () => {
                                stickerSelected.Cocktail = !stickerSelected.Cocktail
                                setStickerSelected({...stickerSelected, ["Cocktail"]: stickerSelected.Cocktail})
                            }
                        }
                        isFlexReverse={true}
                        className={`px-2 py-0.5 border border-[#FFC266] ${!!stickerSelected.Cocktail ? "bg-[#FF6B00]" : "bg-white"}`}
                        textClass={`${!!stickerSelected.Cocktail ? "text-white" : "text-[#9CA3AF]"} font-normal text-[0.75rem] leading-5`}
                        label="Cocktail Evening"
                    />
                </div>
            </div>
        </PopupMobile>
    );
}

export default PopupStickerFilter;