import React from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";
import Table from "../../Layouts/Table";

function PopupFilterViewByDivisionConfirm({isTeamAssign, listChecked, division: divisionParent, onConfirmDivision}) {
    const { removePopup, clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={
                !!isTeamAssign && isTeamAssign === true
                    ? _("Are you sure you want to filter by this division :")
                    : !!listChecked && listChecked.length > 1
                        ? _("Are you sure you want to assign the selected members to:")
                        : _("Are you sure you want to assign this team member to:")
            }
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: clearAllPopups,
                    },
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: () => { onConfirmDivision(divisionParent); clearAllPopups(); },
                    },
                ]
            }>
            <div className="w-full flex flex-col justify-center align-center" >
                <div className="grid w-full items-center">
                    <div className="grid gap-7 px-48">
                        {
                            divisionParent
                                ? <Table
                                    roundedClass="rounded-[0.625rem]"
                                    tdheightClass="h-14"
                                    tdwidthClass="w-96"
                                    columns={[
                                        {
                                            label: _(""),
                                            sortable: false,
                                        },
                                    ]}
                                    rows={
                                        [divisionParent].map( (template, index) =>
                                        ({
                                            id: index,
                                            data: [
                                                {
                                                    value: <div className={`text-[#4B5563] flex items-center justify-center gap-[1.5rem]`}>
                                                        <span>{ _(divisionParent) }</span>
                                                    </div>,
                                                },
                                            ]
                                        })
                                    )
                                    }
                                />
                                : null
                        }
                    </div>
                </div>
            </div>
        </PopupBase>
    );
}

export default PopupFilterViewByDivisionConfirm;