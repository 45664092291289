import React from 'react';

function SvgProfileEditPersoInfosName({className, color}) {
    return (
        <svg className={`${!!className ? className : ""} absolute top-1/2 left-3 -translate-y-1/2 h-5`} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8995 12.26C15.229 12.26 17.1174 10.3716 17.1174 8.0421C17.1174 5.71263 15.229 3.82422 12.8995 3.82422C10.57 3.82422 8.68164 5.71263 8.68164 8.0421C8.68164 10.3716 10.57 12.26 12.8995 12.26Z" stroke={color || "#9CA3AF"} strokeWidth="1.89804" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.57263 22.8044V20.6954C6.57263 19.5768 7.01701 18.5039 7.80802 17.7129C8.59902 16.9219 9.67186 16.4775 10.7905 16.4775H15.0084C16.127 16.4775 17.1999 16.9219 17.9909 17.7129C18.7819 18.5039 19.2263 19.5768 19.2263 20.6954V22.8044" stroke={color || "#9CA3AF"} strokeWidth="1.89804" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgProfileEditPersoInfosName;