import React from 'react';
import {useTranslation} from "../../services/i18n/i18nService";
import ButtonBase from "../Buttons/ButtonBase";
import {Icons} from "../../assets/Svgs";
import {usePopup} from "../../services/PopupService";

function PopupMobile({className, classTitleMargin, title, gapClass, children, footerButtons, cols, icon, hasBtnClose}) {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    return (
        <div className={`${!!className ? className: 'flex flex-col gap-7 p-6 bg-white rounded-lg'} `}>
            <div className="flex flex-col gap-2 items-center">
                <div className="flex justify-between w-full items-center">
                    <div>{!!icon ? icon : null}</div>
                    {
                        !!hasBtnClose
                            ? <div onClick={removePopup}>
                                <Icons.SvgProfilePopupClose />
                            </div>
                            : null
                    }
                </div>

                <div className={`flex flex-col ${!!gapClass ? gapClass : "gap-4"}`}>
                    <div>
                        {
                            !!title
                                ? <div className={`text-[1.25rem] leading font-medium ${!!classTitleMargin ? classTitleMargin : ""}`}>
                                    { title }
                                </div>
                                : null
                        }
                    </div>

                    <div>
                        {
                            !!children
                                ? <div className="flex w-full gap-7 align-center">
                                    { children }
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>


            <div>
                {
                    !!footerButtons
                        ? <div className={`flex ${ !cols ? 'flex-row gap-7 align-center justify-center' : 'flex-col gap-2' }`}>
                            {
                                footerButtons.map((button, index) => (
                                    <div onClick={button.onClick} key={index} className="w-full flex flex-col justify-center align-center gap-7">
                                        <ButtonBase className={`${button.background} ${button.color} ${button.className} w-full rounded-lg py-2.5 px-[1.125rem]`}>
                                            { _(button.label) }
                                        </ButtonBase>
                                    </div>
                                ))
                            }
                        </div>
                        : null
                }
            </div>
        </div>
    );
}

export default PopupMobile;