import React from 'react';
import PopupBase from "../../../../components/Popups/PopupBase";
import {useTranslation} from "../../../../services/i18n/i18nService";
import {usePopup} from "../../../../services/PopupService";
import PopupDeleteConfirm from "./PopupDeleteConfirm";

function PopupDivisionDelete() {
    const { t: _ } = useTranslation();
    const { removePopup, addPopup } = usePopup();

    return (
        <PopupBase
            title={ _("Are you sure you want to delete this division ?") }
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[23.375rem]",
                        onClick: removePopup,
                    },
                    {
                        label: _("Yes, delete this division"),
                        background: "bg-[#FF0000]",
                        color: "text-white",
                        className: "w-[23.375rem]",
                        onClick: () => addPopup(<PopupDeleteConfirm />),
                    },
                ]
            }>
        </PopupBase>
    );
}

export default PopupDivisionDelete;