import React from 'react';

function SvgTemplateTwoPersonFill(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M18.7513 4.66602C13.293 4.66602 8.85547 9.10352 8.85547 14.5618C8.85547 19.916 13.043 24.2493 18.5013 24.4368C18.668 24.416 18.8346 24.416 18.9596 24.4368C19.0013 24.4368 19.0221 24.4368 19.0638 24.4368C19.0846 24.4368 19.0846 24.4368 19.1055 24.4368C24.4388 24.2493 28.6263 19.916 28.6471 14.5618C28.6471 9.10352 24.2096 4.66602 18.7513 4.66602Z" fill="#4B5563"/>
            <path d="M29.332 29.9785C23.5195 26.1035 14.0404 26.1035 8.1862 29.9785C5.54036 31.7493 4.08203 34.1452 4.08203 36.7077C4.08203 39.2702 5.54036 41.6452 8.16536 43.3952C11.082 45.3535 14.9154 46.3327 18.7487 46.3327C22.582 46.3327 26.4154 45.3535 29.332 43.3952C31.957 41.6243 33.4154 39.2494 33.4154 36.666C33.3945 34.1035 31.957 31.7285 29.332 29.9785Z" fill="#4B5563"/>
            <path opacity="0.4" d="M41.6445 15.7917C41.9779 19.8334 39.1029 23.375 35.1237 23.8542C35.1029 23.8542 35.1029 23.8542 35.082 23.8542H35.0195C34.8945 23.8542 34.7695 23.8542 34.6654 23.8959C32.6445 24 30.7904 23.3542 29.3945 22.1667C31.5404 20.25 32.7695 17.375 32.5195 14.25C32.3737 12.5625 31.7904 11.0209 30.9154 9.70838C31.707 9.31255 32.6237 9.06255 33.5612 8.97921C37.6445 8.62505 41.2904 11.6667 41.6445 15.7917Z" fill="#292D32"/>
            <path d="M45.8112 35.0627C45.6445 37.0835 44.3529 38.8335 42.1862 40.021C40.1029 41.1668 37.4779 41.7085 34.8737 41.646C36.3737 40.2918 37.2487 38.6043 37.4154 36.8127C37.6237 34.2293 36.3945 31.7502 33.9362 29.771C32.5404 28.6668 30.9154 27.7918 29.1445 27.146C33.7487 25.8127 39.5404 26.7085 43.1029 29.5835C45.0195 31.1252 45.9987 33.0627 45.8112 35.0627Z" fill="#292D32"/>
        </svg>
}

export default SvgTemplateTwoPersonFill;