import React, {useState} from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import {Icons} from "../../../assets/Svgs";
import PopupMobile from "../../../components/Popups/PopupMobile";
import PopupItemDelete from "./PopupItemDelete";
import InputUploadDocument from "../../../components/Layouts/InputUploadDocument";
import InputGroup from "../../../components/Layouts/InputGroup";

function PopupDocument({mode, itemSelected, list, setList}) {
    const { clearAllPopups, addPopup } = usePopup();
    const { t: _ } = useTranslation();

    const initialValues = mode === 'edit'
        ? { ...itemSelected }
        : { id: -1, title: "", link: "", imgName: "", img: "", displayButtonDelete: false, isDisabled: false, order: 0 };

    const [values, setValues] = useState(initialValues);

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const handleSave = () => {
        if (mode === 'edit') {
            let pictureToUpdate = list.datas.find((data) => data.id === itemSelected.id);
            pictureToUpdate.title = values["title"];
            pictureToUpdate.link = values["link"];
            pictureToUpdate.imgName = values["imgName"];
            setList({...list, ["datas"]: list.datas});
        } else {
            let orderMax = Math.max(...list.datas.map(data => data.order), -1);

            list.datas.push({
                id: orderMax + 1,
                title: values["title"],
                link: values["link"],
                imgName: values["imgName"],
                order: orderMax + 1,
            });
            setList({...list, ["datas"]: list.datas});
        }
        clearAllPopups();
    }

    return (
        <PopupMobile
            cols
            hasBtnClose={true}
            icon={<Icons.SvgProfilePopupPicture />}
            title={mode === 'edit' ? _("Do you want to modify or delete this document?") : _("Do you want to add a new document?")}
            footerButtons={
                [
                    ...(mode === 'edit' ? [
                        {
                            label: _("Delete this document"),
                            background: "bg-[#EC0000]",
                            color: "text-white",
                            onClick: () => {
                                addPopup(<PopupItemDelete />);
                            }
                        }
                    ] : []),
                    {
                        label: mode === 'edit' ? _("Save the modifications") : _("Add this document"),
                        background: "bg-[#0400BD]",
                        color: "text-white",
                        onClick: handleSave,
                    }
                ]
            }>
            <div className="flex flex-col gap-4 w-full">
                <InputUploadDocument
                    values={ !!values ? values : null }
                    setValues={ !!setValues ? setValues : null }
                    isAdmin={ false }
                    isMobileAdd={ !!values["link"] }
                    isMobileEdit={ !!values["link"] }
                    buttonInnerText={ !!values["link"] ? "Change this document" : "Upload a document" }
                    buttonStyleClass="bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full"
                />

                <InputGroup
                    fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                    widthInputClass="w-full"
                    name="link"
                    placeholder="Document Link"
                    value={ values["link"] }
                    onChange={ onChange }
                    icon={ <Icons.SvgProfilePopupMediaLink className="absolute top-1/2 left-3 -translate-y-1/2 h-4" /> }
                />

                <InputGroup
                    fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                    widthInputClass="w-full"
                    name="title"
                    placeholder="Document Title"
                    value={ values["title"] }
                    onChange={ onChange }
                    icon={ <Icons.SvgProfileEditText className="absolute top-1/2 left-3 -translate-y-1/2 h-4" /> }
                />
            </div>

            {/*<WidgetDocument*/}
            {/*    classContainerForm="flex flex-col gap-4 w-full"*/}
            {/*    values={values}*/}
            {/*    setValues={setValues}*/}
            {/*    onChange={onChange}*/}
            {/*    isMobileEdit={!!values["link"]}*/}
            {/*    isMobileAdd={!!values["link"]}*/}
            {/*    buttonInnerText={!!values["link"] ? "Change this document" : "Upload a document"}*/}
            {/*    buttonStyleClass="bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full"*/}
            {/*    iconLink={<Icons.SvgProfilePopupMediaLink className="absolute top-1/2 left-3 -translate-y-1/2 h-4" />}*/}
            {/*    iconTitle={<Icons.SvgProfileEditText className="absolute top-1/2 left-3 -translate-y-1/2 h-4" />}*/}
            {/*    widthInputClass="w-full"*/}
            {/*    fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"*/}
            {/*/>*/}
        </PopupMobile>
    );
}

export default PopupDocument;