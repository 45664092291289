import { APIService } from "../APIService";
import { useQuery } from "@tanstack/react-query";

export class StatisticsService {
    // Private method to build query params string
    static buildQueryParams(params) {
        return new URLSearchParams(params).toString();
    }

    // Private fetch methods using APIService
    static async fetchStatistics(route, { start_date, end_date }) {
        const queryParams = this.buildQueryParams({ start_date, end_date });
        const response = await APIService.apiCall(`${route}?${queryParams}`, 'GET');
        if (!response.ok) throw new Error(`Error fetching ${route}`);
        return await response.json();
    }

    // Function to generate a custom hook for each API route
    static createStatisticsHook(route) {
        return (params) => {
            return useQuery({
                queryKey: [route, params],
                queryFn: () => this.fetchStatistics(route, params),
            });
        };
    }

    // Generate specific hooks for each statistics type by using the createStatisticsHook
    static useContactLinksClicks = this.createStatisticsHook('metrics/statistics/contact-links-clicks');
    static useContactsDownloaded = this.createStatisticsHook('metrics/statistics/contacts-downloaded');
    static useFilesDownloads = this.createStatisticsHook('metrics/statistics/files-downloads');
    static useLeadsGenerated = this.createStatisticsHook('metrics/statistics/leads-generated');
    static usePicturesViews = this.createStatisticsHook('metrics/statistics/pictures-views');
    static useProfileViews = this.createStatisticsHook('metrics/statistics/profile-views');
    static useSocialLinksClicks = this.createStatisticsHook('metrics/statistics/social-links-clicks');
    static useTeamPerformance = this.createStatisticsHook('metrics/statistics/team-performance');
    static useVideosViews = this.createStatisticsHook('metrics/statistics/videos-views');
    static useWebsiteLinksClicks = this.createStatisticsHook('metrics/statistics/website-links-clicks');
}
