import React from 'react';

function SvgTemplateSectionGoogleReview(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2 5.87C12.13 5.87 12.06 5.87001 12 5.88001V2.00999C12.07 1.99999 12.13 2 12.2 2C14.46 2 16.4 2.69 17.99 3.86C18.49 4.22 18.53 4.95001 18.09 5.38001L16.7 6.74001C16.36 7.07001 15.84 7.13 15.45 6.86C14.7 6.34 13.63 5.87 12.2 5.87Z" fill={props.color || "#292D32"}/>
            <path d="M21.8 12.2197C21.8 15.2697 20.71 17.8297 18.82 19.5797C17.17 21.1097 14.9 21.9997 12.2 21.9997C12.13 21.9997 12.07 21.9997 12 21.9897V18.1197C12.07 18.1297 12.13 18.1297 12.2 18.1297C13.69 18.1297 14.82 17.7197 15.67 17.1297C17 16.1997 17.6 14.8097 17.71 13.8897H13.2C12.65 13.8897 12.2 13.4397 12.2 12.8897V11.1797C12.2 10.6297 12.65 10.1797 13.2 10.1797H20.75C21.26 10.1797 21.69 10.5597 21.74 11.0697C21.78 11.4197 21.8 11.7897 21.8 12.2197Z" fill={props.color || "#292D32"}/>
            <path opacity="0.4" d="M6.50313 10.0198C6.30313 10.6398 6.18313 11.3098 6.18313 11.9998C6.18313 12.6898 6.30313 13.3498 6.51313 13.9798C7.29313 16.3298 9.45313 18.0398 12.0031 18.1198V21.9898C8.18313 21.9198 4.89313 19.6998 3.28313 16.4898H3.27312C2.59312 15.1298 2.20312 13.6098 2.20312 11.9998C2.20312 10.3898 2.59312 8.86977 3.27312 7.50977C4.88312 4.29977 8.17313 2.07977 12.0031 2.00977V5.87978C9.64313 5.94978 7.63313 7.40977 6.71313 9.47977C6.63313 9.65977 6.56313 9.83978 6.50313 10.0198Z" fill={props.color || "#292D32"}/>
        </svg>
    );
}

export default SvgTemplateSectionGoogleReview;