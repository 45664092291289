import React from 'react';
import ButtonLoginPrevious from "../../../components/Buttons/ButtonLoginPrevious";
import {getFullPath} from "../../../router/Router";
import InputField from "../../../components/Layouts/InputField";
import {Icons} from "../../../assets/Svgs";
import {useTranslation} from "../../../services/i18n/i18nService";
import {useLocation, useNavigate} from "react-router-dom";
import StorageService from "../../../services/StorageService";
import Transition from "../../../transition";
import InputTel from "../../../components/Layouts/InputTel";

function ContactInfos() {
    const { t: _ } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const transitionType = searchParams.get('transition');

    const inputs = [
        {
            id: 1,
            name: "email",
            type: "text",
            placeholder: "Enter your mail address",
            label: "Email",
            errorMsg: "",
            pattern: "",
            required: false,
            isSelectable: false,
        },
        {
            id: 2,
            name: "tel",
            type: "text",
            placeholder: "Enter your phone number",
            label: "Phone Number",
            errorMsg: "",
            pattern: "",
            required: false,
            isSelectable: true,
        },
    ]

    const [values, setValues] = StorageService.useLocalStorage("contactInfo", {
        email: "",
        tel: "",
        country: "",
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <Transition transitionType={transitionType}>
            <div className="flex w-full h-full bg-white">
                <ButtonLoginPrevious onClick={() => {
                    navigate(getFullPath(["ROOT", "PERSO_INFOS"]) + "?transition=turn-right" );
                }} />

                <div className="absolute top-32 w-full">
                    <div className="ss:p-20 w-full px-6 gap-4 ss:border-0 bg-white xxs:rounded-[0.914rem] flex flex-col align-center items-center justify-center">

                        <div className="flex flex-col w-full gap-8">
                            <div className="flex flex-col gap-4">
                                <div className="w-full gap-6">
                                    <div className="text-[#111827] font-bold ss:text-[2.5rem] xxs:text-[1.25rem] leading-7 font-medium">
                                        { _("Contact information") }
                                    </div>
                                </div>

                                <div className="flex w-full flex-col gap-2.5">
                                    <div className="flex flex-col gap-4">
                                        {
                                            inputs.map((input) => (
                                                !input.isSelectable
                                                    ? <InputField
                                                        widthInputClass="w-full"
                                                        backgroundClass="bg-white border border-[#EEEFF2] border-[0.1rem]"
                                                        key={ input.id }
                                                        { ...input }
                                                        value={ values[input.name] }
                                                        onChange={ onChange }
                                                    />
                                                    : <InputTel
                                                        key={ input.id }
                                                        { ...input }
                                                        gapClass="gap-3.5"
                                                        value={ values[input.name] }
                                                        setValues={setValues}
                                                        onChange={ onChange }
                                                        telLocaleCurrent={values.country}
                                                    />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col w-full gap-3">
                                <div className={
                                    `flex flex-row items-center hover:no-underline gap-2 px-4 py-3 hover:bg-blue-gradient rounded-full text-base font-semibold
                                    text-white bg-[#0400BD] justify-center items-center`}
                                     onClick={() => {
                                         navigate(getFullPath(["ROOT", "POSITION"]) + "?transition=turn-left" );
                                     }}
                                >
                                    { _("Next step") }
                                    <Icons.SvgLoginArrowCircleRight/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
}

export default ContactInfos;