import React from 'react';

function SvgTemplateSectionSocialMedia(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M18 18.8597H17.24C16.44 18.8597 15.68 19.1697 15.12 19.7297L13.41 21.4197C12.63 22.1897 11.36 22.1897 10.58 21.4197L8.87 19.7297C8.31 19.1697 7.54 18.8597 6.75 18.8597H6C4.34 18.8597 3 17.5298 3 15.8898V4.97974C3 3.33974 4.34 2.00977 6 2.00977H18C19.66 2.00977 21 3.33974 21 4.97974V15.8898C21 17.5198 19.66 18.8597 18 18.8597Z" fill="#373742"/>
            <path d="M16.5805 9.56876C16.3905 9.29876 16.0705 9.14878 15.6905 9.14878H13.7405C13.6105 9.14878 13.4905 9.09876 13.4105 8.99876C13.3305 8.89876 13.2905 8.76876 13.3105 8.62876L13.5505 7.06876C13.6505 6.60876 13.3405 6.07875 12.8805 5.92875C12.4505 5.76875 11.9405 5.98873 11.7405 6.28873L9.80048 9.16874V8.80875C9.80048 8.10875 9.50047 7.81876 8.76047 7.81876H8.27048C7.53048 7.81876 7.23047 8.10875 7.23047 8.80875V13.5888C7.23047 14.2888 7.53048 14.5788 8.27048 14.5788H8.76047C9.46047 14.5788 9.76047 14.3087 9.79047 13.6687L11.2605 14.7987C11.4605 14.9987 11.9105 15.1087 12.2305 15.1087H14.0805C14.7205 15.1087 15.3605 14.6287 15.5005 14.0387L16.6705 10.4787C16.8005 10.1587 16.7705 9.82876 16.5805 9.56876Z" fill="#373742"/>
        </svg>
    );
}

export default SvgTemplateSectionSocialMedia;