import React from 'react';
import WidgetSection from "../../Layouts/WidgetSection";
import {useTranslation} from "../../../services/i18n/i18nService";

function WidgetTextView(
    {
        widget,
        displayEditAction,
        onEdit,
    }) {

    const widgetData = widget.data;
    const widgetTitle = widgetData?.title;
    const widgetText = widgetData?.text;
    const { t: _ } = useTranslation();

    return (
        <WidgetSection title={widgetTitle} displayEditAction={displayEditAction} onEdit={onEdit} hasShadow={true}>
            <div className='flex flex-col'>
                <div className="leading-5 text-[0.75rem]">
                    <p className="font-normal text-[#6B7280]"> {!!widgetText ? widgetText : ""} </p>
                    <div className="flex font-medium justify-end hover:underline hover:select-all">{_("Voir plus")}</div>
                </div>
            </div>
        </WidgetSection>
    );
}

export default WidgetTextView;