import React from 'react';
import { Icons } from '../../assets/Svgs';

export const MetricValueVariation = ({ value, previousValue }) => {
    const variation = parseFloat(value) - parseFloat(previousValue);
    const relativeVariation = parseFloat(100 * (variation / parseFloat(previousValue))).toFixed(2);

    return (
        <div className="flex gap-3">
            <strong className="text-lg font-medium">{Math.round(parseFloat(value))}</strong>

            {variation !== 0 && previousValue !== 0
            ? <span className={"text-sm flex items-center" + (variation > 0 ? " text-green-500" : " text-red-500")}>
                {variation > 0 ? <Icons.SvgStatisticCaretUpFill color="#25D366" /> : <Icons.SvgStatisticCaretDownFill color="#FF0000" />}
                {Math.abs(relativeVariation) + "%"}
            </span>
            : null}
        </div>
    );
}