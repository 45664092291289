import React from 'react';

function SvgArrangeDownUpFill(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M10.0013 18.3327C14.6037 18.3327 18.3346 14.6017 18.3346 9.99935C18.3346 5.39698 14.6037 1.66602 10.0013 1.66602C5.39893 1.66602 1.66797 5.39698 1.66797 9.99935C1.66797 14.6017 5.39893 18.3327 10.0013 18.3327Z" fill={props.color || "black"}/>
            <path d="M14.4906 7.71665L11.9573 5.18334C11.899 5.125 11.8323 5.08333 11.7573 5.05C11.6073 4.98333 11.4323 4.98333 11.2823 5.05C11.1323 5.11667 11.0073 5.23334 10.9406 5.39167C10.9073 5.46667 10.8906 5.54998 10.8906 5.63332V14.225C10.8906 14.5667 11.174 14.85 11.5156 14.85C11.8573 14.85 12.1406 14.5667 12.1406 14.225V7.14167L13.6073 8.60832C13.7323 8.73332 13.8906 8.79166 14.0489 8.79166C14.2073 8.79166 14.3656 8.73332 14.4906 8.60832C14.7323 8.34999 14.7323 7.95832 14.4906 7.71665Z" fill={props.color || "black"}/>
            <path d="M9.10547 5.625C9.10547 5.28333 8.82214 5 8.48047 5C8.13881 5 7.85547 5.28333 7.85547 5.625V12.7083L6.38883 11.2417C6.14716 11 5.74714 11 5.50547 11.2417C5.2638 11.4833 5.2638 11.8833 5.50547 12.125L8.03881 14.6583C8.09714 14.7167 8.16383 14.7583 8.23883 14.7917C8.31383 14.825 8.39714 14.8417 8.48047 14.8417C8.56381 14.8417 8.63882 14.825 8.72215 14.7917C8.87215 14.725 8.99715 14.6083 9.06382 14.45C9.09715 14.375 9.11382 14.2917 9.11382 14.2083V5.625H9.10547Z" fill={props.color || "black"}/>
        </svg>
}

export default SvgArrangeDownUpFill;