import React from 'react';

function SvgTemplateSectionText(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M22 7.81V16.19C22 19.83 19.83 22 16.19 22H7.81C7.61 22 7.41 21.99 7.22 21.98C5.99 21.9 4.95 21.55 4.13 20.95C3.71 20.66 3.34 20.29 3.05 19.87C2.36 18.92 2 17.68 2 16.19V7.81C2 4.37 3.94 2.24 7.22 2.03C7.41 2.01 7.61 2 7.81 2H16.19C17.68 2 18.92 2.36 19.87 3.05C20.29 3.34 20.66 3.71 20.95 4.13C21.64 5.08 22 6.32 22 7.81Z" fill="#292D32"/>
            <path d="M16.67 5.64062H7.33C6.18 5.64062 5.25 6.57063 5.25 7.72063V8.90063C5.25 9.31063 5.59 9.65063 6 9.65063C6.41 9.65063 6.75 9.31063 6.75 8.90063V7.72063C6.75 7.40063 7.01 7.14063 7.33 7.14063H11.25V16.8606H9.47C9.06 16.8606 8.72 17.2006 8.72 17.6106C8.72 18.0206 9.06 18.3606 9.47 18.3606H14.54C14.95 18.3606 15.29 18.0206 15.29 17.6106C15.29 17.2006 14.95 16.8606 14.54 16.8606H12.76V7.14063H16.68C17 7.14063 17.26 7.40063 17.26 7.72063V8.90063C17.26 9.31063 17.6 9.65063 18.01 9.65063C18.42 9.65063 18.76 9.31063 18.76 8.90063V7.72063C18.75 6.58063 17.82 5.64062 16.67 5.64062Z" fill="#292D32"/>
        </svg>
    );
}

export default SvgTemplateSectionText;