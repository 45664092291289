import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ButtonBase from '../components/Buttons/ButtonBase';
import InputField from "../components/Layouts/InputField";
import { getFullPath } from "../router/Router";
import { useTranslation } from "../services/i18n/i18nService";
import UserService from '../services/User/UserService';

export const ResetPassword = () => {
    const { t: _ } = useTranslation();
    const navigate = useNavigate();

    const [ searchParams ] = useSearchParams();

    const [values, setValues] = useState({
        passwd: "",
        passwdConfirm: "",
    });
    
    const email = searchParams.get("email");
    const token = searchParams.get("token");

    if (!email || !token) {
        return <div>Invalid link</div>;
    }

    const inputs = [
        {
            id: 1,
            name: "passwd",
            type: "password",
            placeholder: "New password",
            label: "New password",
            errorMsg: "",
            pattern: "",
            required: false,
        },
        {
            id: 2,
            name: "passwdConfirm",
            type: "password",
            placeholder: "New password",
            label: "Confirm your new password",
            errorMsg: "",
            pattern: "",
            required: false,
        },
    ]

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const handleSubmit = () => {
        UserService.resetPassword({ email, token, newPassword: values.passwd })
            .then(() => {
                console.log("Password reset successfully");
                navigate(getFullPath(["ROOT", "LOGIN"]));
            })
            .catch(err => {
                console.error(err);
            });
    }

    return (
        <div className="flex w-full h-full bg-white">
            <div className="ss:p-20 w-full px-6 gap-4 ss:border-0 bg-white xxs:rounded-[0.914rem] flex flex-col align-center items-center justify-center">

                <div className="flex flex-col w-full gap-8">
                    <div className="flex flex-col gap-4">
                        <div className="w-full gap-6">
                            <div className="text-[#111827] text-[2.5rem] font-medium">
                                { _("You are about to reset the password of ") + email }
                            </div>
                        </div>

                        <div className="flex w-full flex-col gap-2.5">
                            <div className="flex flex-col gap-4">
                                {
                                    inputs.map((input, index) => (
                                        <InputField
                                            widthInputClass="w-full"
                                            backgroundClass="bg-white border border-[#EEEFF2] border-[0.1rem]"
                                            key={ index }
                                            { ...input }
                                            value={ values[input.name] }
                                            onChange={ onChange }
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="flex w-full justify-end">
                        <ButtonBase className="bg-black text-white text-sm h-10 py-3.5 px-6 rounded-full"
                            onClick={handleSubmit}
                        >{_("Reset my password")}</ButtonBase>
                    </div>
                </div>
            </div>
        </div>
    );
}
