import React, {useEffect, useState} from 'react';
import InputSearch from "../../Layouts/InputSearch";
import Table from "../../Layouts/Table";
import PopupBase from "../PopupBase";
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupConfirmAssignPersonSelected from "./PopupConfirmAssignPersonSelected";
import {Icons} from "../../../assets/Svgs";

function PopupAssignPersonSelected({title, listChecked, selectedTemplate: selectedTemplateParent, onSelectedTemplate }) {
    const { removePopup, addPopup } = usePopup();
    const { t: _ } = useTranslation();
    const [ selectedTemplate, setSelectedTemplate ] = useState(selectedTemplateParent ?? "Vente");
    const [ value, setValue ] = useState("");
    const [ connections, setConnections ] = useState([
        {
            name: "Vente",
            textColor: "text-[#FF0000]",
        },
        {
            name: "Marketing",
            textColor: "text-[#FF7F00]",
        },
        {
            name: "Après-Vente",
            textColor: "text-[#7F00FF]",
        },
        {
            name: "Direction",
            textColor: "text-[#33D933]",
        }
    ]);

    const [ connectionsOriginal, setConnectionsOriginal ] = useState([
        {
            name: "Vente",
            textColor: "text-[#FF0000]",
        },
        {
            name: "Marketing",
            textColor: "text-[#FF7F00]",
        },
        {
            name: "Après-Vente",
            textColor: "text-[#7F00FF]",
        },
        {
            name: "Direction",
            textColor: "text-[#33D933]",
        }
    ]);

    useEffect(() => {
        onSelectedTemplate && onSelectedTemplate(selectedTemplate);
    }, [selectedTemplate, onSelectedTemplate]);

    const onSearch = (listToSearch, keyword) => {
        if (!keyword) return listToSearch;
        return listToSearch.filter(item => {
            return item.name.toLowerCase().includes(keyword.toLowerCase());
        });
    }    

    return (
        <PopupBase
            title={ !!title ? _(title) : _("Choose a template to assign to the selected members:")}
            footerButtons={
                [
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                ]
            }>
            <div className="w-full flex flex-col justify-center align-center" >
                <div className="grid w-full items-center">
                    <div className="grid gap-7 px-48">

                        <InputSearch
                            onSearch={onSearch}
                            value={value}
                            setValue={setValue}
                            setList={setConnections}
                            listToSearchOriginal={connectionsOriginal}
                            icon={<Icons.SvgTeamUserSearch color="#D1D5DB" className="absolute top-1/2 left-3 -translate-y-1/2 h-3" />}
                            placeholder={"Search template"}
                            isFlexible={false}
                        />

                        <div className="w-96 h-fit overflow-auto">
                            <Table
                                roundedClass="rounded-[0.625rem]"
                                tdheightClass="h-14"
                                trColorClass="bg-white"
                                columns={[
                                    {
                                        label: _(""),
                                        sortable: false,
                                    },
                                ]}
                                rows={connections.map( (template, index) =>
                                    ({
                                        id: index,
                                        data: [
                                            {
                                                value: <div
                                                    onClick={() => addPopup(<PopupConfirmAssignPersonSelected template={template} onConfirmTemplate={setSelectedTemplate} />) }
                                                    className={`text-[#4B5563] flex items-center justify-center gap-[1.5rem]`}
                                                >
                                                    <span>{ _(template.name) }</span>
                                                </div>,
                                            },
                                        ]
                                    })
                                )}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </PopupBase>
    );
}

export default PopupAssignPersonSelected;