import React from 'react';
import { Icons } from "../../../assets/Svgs";
import { useTranslation } from "../../../services/i18n/i18nService";
import Table from "../../../components/Layouts/Table";

function StatisAdminTopViewsCustomers({datas}) {
    const { t: _ } = useTranslation();

    return (
        <>
            <header className="flex justify-between items-center">
                <div className="">
                    <div className="text-sm flex gap-1.5">
                        {_("Top 5 Viewed Customers")}
                        <Icons.SvgStatisticGlassOutline />
                    </div>
                </div>
            </header>

            {
                datas
                    ? <Table
                        bgColorClass="bg-white"
                        columns={[{}, {}, {}]}
                        rows={datas.map( (perform, index) =>
                            ({
                                data: [
                                    {
                                        value: <div>
                                            { index + 1 }
                                        </div>
                                    },
                                    {
                                        value: <div className="flex items-center gap-[1.5rem]">
                                            <span>{ perform.business_name }</span>
                                        </div>,
                                    },
                                    {
                                        value: <div className="flex justify-center items-center gap-1.5">
                                            { perform.views }
                                            <Icons.SvgStatisticGlassOutline />
                                        </div>
                                    },
                                ]
                            })
                        )}
                    />
                    : null
            }
        </>
    );
}

export default StatisAdminTopViewsCustomers;