import React, {useState} from 'react';
import {Icons} from "../../../../assets/Svgs";
import PopupMobile from "../../../../components/Popups/PopupMobile";
import {useTranslation} from "../../../../services/i18n/i18nService";
import {usePopup} from "../../../../services/PopupService";
import InputGroup from "../../../../components/Layouts/InputGroup";

function PopupStickerAdd({tags}) {
    const { t: _ } = useTranslation();
    const { clearAllPopups } = usePopup();
    const [values, setValues] = useState({
        name: "",
        bgClass: "bg-[#FFC266]",
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <PopupMobile
            cols
            hasBtnClose={true}
            icon={<Icons.SvgProfilePopupDelete />}
            title={_("Do you want to create a new sticker ?")}
            footerButtons={
                [
                    {
                        label: _("Create this sticker"),
                        background: "bg-[#0400BD]",
                        color: "text-white",
                        onClick: () => {
                            let orderMax = -1;
                            if(!!tags){
                                tags.map((data) => {
                                    if(data.id > orderMax){
                                        orderMax = data.id;
                                    }
                                })
                            }

                            tags.push({
                                id: orderMax + 1,
                                name: values["name"],
                                bgClass: values["bgClass"],
                            })

                            clearAllPopups()
                        }
                    }
                ]
            }>

            <div className="flex flex-col w-full">
                <div className="flex gap-4 justify-center px-4 pb-4">
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#FFC266]" }) } className={`${values["bgClass"] === "bg-[#FFC266]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#FFC266]`}/>
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#FF3F61]" }) } className={`${values["bgClass"] === "bg-[#FF3F61]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#FF3F61]`}/>
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#B63FFF]" }) } className={`${values["bgClass"] === "bg-[#B63FFF]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#B63FFF]`}/>
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#00CBBF]" }) } className={`${values["bgClass"] === "bg-[#00CBBF]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#00CBBF]`}/>
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#FF6B00]" }) } className={`${values["bgClass"] === "bg-[#FF6B00]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#FF6B00]`}/>
                    <div onClick={ () => setValues({ ...values, ["bgClass"]: "bg-[#FF3FB2]" }) } className={`${values["bgClass"] === "bg-[#FF3FB2]" ? "border border-[#0400BD]" : "" } w-[1.25rem] h-[1.25rem] rounded bg-[#FF3FB2]`}/>
                </div>
                <InputGroup
                    fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                    widthInputClass="w-full"
                    name="name"
                    placeholder="Name"
                    value={ values["name"] }
                    onChange={ onChange }
                    icon={ <Icons.SvgProfileEditText className="absolute top-1/2 left-4 -translate-y-1/2 h-5" /> }
                />
            </div>
        </PopupMobile>
    );
}

export default PopupStickerAdd;