import React, { createContext, useState } from 'react';

const DEFAULT_ADD_POPUP_PARAMS = {
    closeOnDarkAreaClick: true,
}

const PopupContext = createContext();

function PopupProvider({ className, children }) {
  const [queue, setQueue] = useState([]);

  const addPopup = (component, params = {}) => {
    setQueue((prev) => [...prev, { component, params: {...DEFAULT_ADD_POPUP_PARAMS, ...params} }]);
  };

  const removePopup = () => {
    setQueue((prev) => prev.slice(0, -1));
  };

  const clearAllPopups = () => {
    setQueue([]);
  };

  return (
    <PopupContext.Provider value={{ addPopup, removePopup, clearAllPopups, queue }}>
        {children}
    </PopupContext.Provider>
  );
}

export { PopupProvider, PopupContext };