import React from 'react';

function SvgStatisticSearchStatus({className, color}) {
    return <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.58203 2.70898H10.832" stroke={color || "black"} strokeWidth="0.8125" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.58203 4.33398H9.20703" stroke={color || "black"} strokeWidth="0.8125" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.3737 6.22982C11.3737 9.07357 9.07161 11.3757 6.22786 11.3757C3.38411 11.3757 1.08203 9.07357 1.08203 6.22982C1.08203 3.38607 3.38411 1.08398 6.22786 1.08398" stroke={color || "black"} strokeWidth="0.8125" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.9154 11.9173L10.832 10.834" stroke={color || "black"} strokeWidth="0.8125" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export default SvgStatisticSearchStatus;