import React from 'react';

function SvgProfileReorganizeSection({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.35 12.75H16.65C13.99 12.75 12.75 11.51 12.75 8.85V5.15C12.75 2.49 13.99 1.25 16.65 1.25H19.35C22.01 1.25 23.25 2.49 23.25 5.15V8.85C23.25 11.51 22.01 12.75 19.35 12.75ZM16.65 2.75C14.81 2.75 14.25 3.31 14.25 5.15V8.85C14.25 10.69 14.81 11.25 16.65 11.25H19.35C21.19 11.25 21.75 10.69 21.75 8.85V5.15C21.75 3.31 21.19 2.75 19.35 2.75H16.65Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M8.35 22.75H5.65C2.99 22.75 1.75 21.51 1.75 18.85V15.15C1.75 12.49 2.99 11.25 5.65 11.25H8.35C11.01 11.25 12.25 12.49 12.25 15.15V18.85C12.25 21.51 11.01 22.75 8.35 22.75ZM5.65 12.75C3.81 12.75 3.25 13.31 3.25 15.15V18.85C3.25 20.69 3.81 21.25 5.65 21.25H8.35C10.19 21.25 10.75 20.69 10.75 18.85V15.15C10.75 13.31 10.19 12.75 8.35 12.75H5.65Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M15.5002 22.75C15.2302 22.75 14.9802 22.6 14.8502 22.37C14.7202 22.14 14.7202 21.85 14.8602 21.62L15.9102 19.87C16.1202 19.51 16.5802 19.4 16.9402 19.61C17.3002 19.82 17.4102 20.28 17.2002 20.64L16.9302 21.09C19.6902 20.44 21.7602 17.96 21.7602 15C21.7602 14.59 22.1002 14.25 22.5102 14.25C22.9202 14.25 23.2602 14.59 23.2602 15C23.2502 19.27 19.7702 22.75 15.5002 22.75Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M2.5 9.75C2.09 9.75 1.75 9.41 1.75 9C1.75 4.73 5.23 1.25 9.5 1.25C9.77 1.25 10.02 1.4 10.15 1.63C10.28 1.86 10.28 2.15 10.14 2.38L9.09 4.14C8.88 4.49 8.42 4.61 8.06 4.39C7.71 4.18 7.59 3.72 7.81 3.36L8.08 2.91C5.31 3.56 3.25 6.04 3.25 9C3.25 9.41 2.91 9.75 2.5 9.75Z"
                fill={!!color ? color : "#0400BD"}/>
        </svg>
    );
}

export default SvgProfileReorganizeSection;