import React from 'react';

function SvgSidebarCustomer(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0929 14.559C11.5062 14.7323 10.8129 14.8123 9.99958 14.8123H5.99958C5.18625 14.8123 4.49292 14.7323 3.90625 14.559C4.05292 12.8257 5.83292 11.459 7.99958 11.459C10.1662 11.459 11.9462 12.8257 12.0929 14.559Z" stroke={props.color || 'white'} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.9987 1.47852H5.9987C2.66536 1.47852 1.33203 2.81185 1.33203 6.14518V10.1452C1.33203 12.6652 2.09203 14.0452 3.90536 14.5585C4.05203 12.8252 5.83203 11.4585 7.9987 11.4585C10.1654 11.4585 11.9454 12.8252 12.092 14.5585C13.9054 14.0452 14.6654 12.6652 14.6654 10.1452V6.14518C14.6654 2.81185 13.332 1.47852 9.9987 1.47852ZM7.9987 9.59184C6.6787 9.59184 5.61203 8.51853 5.61203 7.19853C5.61203 5.87853 6.6787 4.81185 7.9987 4.81185C9.3187 4.81185 10.3854 5.87853 10.3854 7.19853C10.3854 8.51853 9.3187 9.59184 7.9987 9.59184Z" stroke={props.color || 'white'} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.3866 7.19918C10.3866 8.51918 9.31995 9.59249 7.99995 9.59249C6.67995 9.59249 5.61328 8.51918 5.61328 7.19918C5.61328 5.87918 6.67995 4.8125 7.99995 4.8125C9.31995 4.8125 10.3866 5.87918 10.3866 7.19918Z" stroke={props.color || 'white'} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgSidebarCustomer;