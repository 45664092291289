import React, { useEffect, useState } from 'react';
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import { Icons } from "../../../assets/Svgs";
import ButtonDropDown from "../../../components/Buttons/ButtonDropDown";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import Table, {SelectionType} from "../../../components/Layouts/Table";
import ButtonViewDetails from "../../../components/Buttons/ButtonViewDetails";
import TagTemplate from "../../../components/Tags/TagTemplate";
import PopupAssignPersonSelected from "../../../components/Popups/TemplateFilter/PopupAssignPersonSelected";
import PopupDeletePersonSelected from "../../../components/Popups/DeletePerson/PopupDeletePersonSelected";
import DivisionChartsService from "../../../services/Division/DivisionChartsService";
import InputField from "../../../components/Layouts/InputField";
import InputTextarea from "../../../components/Layouts/InputTextarea";
import PopupTemplateFilterPersonView from "../Templates/Popups/PopupTemplateFilterPersonView";
import ButtonFlexible from "../../../components/Buttons/ButtonFlexible";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonStatistics from "../../../components/Buttons/ButtonStatistics";
import PopupDivisionAssignNewMember from "./Popup/PopupDivisionAssignNewMember";
import InputSearch from "../../../components/Layouts/InputSearch";

const onSearch = (listToSearch, keyword) => {
    if (!keyword) return listToSearch;
    const lowerCaseKeyword = keyword.toLowerCase();
    return listToSearch.filter(item => item.full_name.toLowerCase().includes(lowerCaseKeyword));
};

function DivisionEdit() {
    const { t: _ } = useTranslation();
    const { id } = useParams();
    const { addPopup } = usePopup();
    const navigate = useNavigate();
    const [listTeams, setListTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [listChecked, setListChecked] = useState([]);
    const [selectedView, setSelectedView] = useState("templates");
    const [selectedTemplate, setSelectedTemplate] = useState("Vente");
    const [toggle, setToggle] = useState(false);
    const [value, setValue] = useState("");
    const [listOriginal, setListOriginal] = useState([]);
    const [values, setValues] = useState({ divisionName: "", desc: "" });

    const links = [
        {
            id: 0,
            icon: <Icons.SvgAssignOutline className="h-3" />,
            iconColor: "#000000",
            label: "Assign a template",
            onClick: () => {
                addPopup(<PopupAssignPersonSelected listChecked={listChecked} selectedTemplate={selectedTemplate} onSelectedTemplate={setSelectedTemplate} />);
            },
        },
        {
            id: 1,
            icon: <Icons.SvgTrashOutline className="h-3" />,
            iconColor: "#FF0000",
            label: "Delete from Division",
            onClick: () => {
                addPopup(<PopupDeletePersonSelected objectToDelete="division" listChecked={listChecked} />);
            },
        }
    ];

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setLoading(true);
        DivisionChartsService.getDivisionChartById(id)
            .then(res => {
                setListTeams(res.subteam);
                setListOriginal(res.subteam);
                setValues({ divisionName: res.divisionName, desc: res.desc });
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                navigate('../', { replace: true });
            });
    }, [id, navigate]);

    return (
        <div className="p-7 flex flex-col gap-7">
            <header className=" flex justify-between items-center border-gray-200">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={getFullPath(["ROOT", "DIVISIONS"])} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Division")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("Edit")}
                    {!loading && <span className="text-[#4B5563] text-lg font-medium">({listTeams.length})</span>}
                </div>

                <div className="flex items-center gap-7">
                    <ButtonFlexible icon={<Icons.SvgStatisticFilterOutline />}
                                    onClick={() => addPopup(
                                        <PopupTemplateFilterPersonView title="Filter the team member view by :" selectedView={selectedView} onSelectedViewChange={setSelectedView} />
                                    )}
                    >
                        {_("Filter")}
                    </ButtonFlexible>

                    {!toggle && (
                        <ButtonFlexible onClick={() => setToggle(!toggle)} icon={<Icons.SvgSearchView />}>
                            {_("Search")}
                        </ButtonFlexible>
                    )}

                    <InputSearch
                        onSearch={onSearch}
                        value={value}
                        setValue={setValue}
                        toggle={toggle}
                        setToggle={setToggle}
                        placeholder={_("Search a template name")}
                        setList={setListTeams}
                        listToSearchOriginal={listOriginal}
                        icon={<Icons.SvgSearchView className="absolute top-1/2 left-3 -translate-y-1/2 h-3" />}
                    />

                    <ButtonFlexible onClick={() => setIsSelectAll(!isSelectAll)} icon={<Icons.SvgCheckboxFill />}>
                        {_("Select all")}
                    </ButtonFlexible>

                    <Link to={getFullPath(["ROOT", "DIVISIONS", "EDIT_DIVISION", "STATISTICS_DIVISION"]).replace(":id", id)}>
                        <ButtonStatistics />
                    </Link>

                    <Link to={getFullPath(["ROOT", "DIVISIONS"])} className="flex justify-center">
                        <ButtonBack isFlexible={true} />
                    </Link>

                    <ButtonDropDown links={links} />

                    <ButtonBase onClick={() => addPopup(<PopupDivisionAssignNewMember listTeams={listTeams} />)}
                                className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full"
                                iconColor="white"
                                icon={<Icons.SvgUserAddOutline color="white" className="h-3" />}
                    >
                        {_("Assign new team members")}
                    </ButtonBase>
                </div>
            </header>

            <div className="flex-1 overflow-auto">
                <div className="bg-white p-7 rounded-[1.25rem]">
                    <div className="grid grid-cols-2 gap-7">
                        <InputField
                            name="divisionName"
                            widthInputClass="w-4/6"
                            label="Division Name"
                            value={values.divisionName}
                            onChange={onChange}
                            required={true}
                        />
                        <InputTextarea
                            gapClass="gap-3.5"
                            name="desc"
                            widthInputClass="w-4/6"
                            label="Description"
                            value={values.desc}
                            onChange={onChange}
                            required={false}
                        />
                    </div>
                </div>

                <Table
                    selectionType={SelectionType.MULTIPLE}
                    displayHeaders={true}
                    columns={[
                        {
                            label: _("Team Member"),
                            sortable: true,
                            sortOn: value => value.props.sort_on,
                        },
                        {
                            label: _("Role"),
                            sortable: true,
                            sortOn: value => value.props.sort_on,
                        },
                        {
                            label: _("Template"),
                            sortable: true,
                            sortOn: value => value.props.sort_on,
                        },
                        {
                            label: _("Admin"),
                            sortable: true,
                            sortOn: value => value.props.sort_on,
                        },
                        {
                            label: _("View"),
                            sortable: false,
                        },
                    ]}
                    rows={listTeams.map((lead) => ({
                        id: lead.id,
                        data: [
                            {
                                value: <div sort_on={lead.last_name} className="flex items-center justify-center gap-[1.5rem]">
                                    <img src={lead.avatar} className="rounded-full h-8 w-8" alt="avatar" />
                                    <span>{lead.first_name} {lead.last_name}</span>
                                </div>,
                            },
                            {
                                value: <div sort_on={lead.position} className="flex items-center justify-center gap-[1.5rem]">
                                    <span>{lead.position}</span>
                                </div>,
                            },
                            {
                                value: <div sort_on={lead.template} className="flex items-center justify-center gap-[1.5rem]">
                                    <span>{TagTemplate(lead.template)}</span>
                                </div>,
                            },
                            {
                                value: <div sort_on={lead.isAdmin ? "admin" : ""} className="flex items-center justify-center gap-[1.5rem]">
                                    {lead.isAdmin && (
                                        <span className="capitalize border border-orange-gradient py-1 px-2 rounded-[0.625rem] text-sm text-black">
                                            {_("ADMIN")}
                                        </span>
                                    )}
                                </div>,
                            },
                            {
                                value: <Link to={getFullPath(["ROOT", "TEAM", "VIEW_TEAM_MEMBER"]).replace(":id", lead.id)} className="flex justify-center gap-[0.438rem]">
                                    <ButtonViewDetails />
                                </Link>,
                            },
                        ]
                    }))}
                />
            </div>
        </div>
    );
}

export default DivisionEdit;