import APIService from "../APIService";

class CardsService {
    static cards = null;

    // Fetch cards with caching logic
    static async fetchCards() {
        console.log("fetching cards");
        if (!this.cards) {
            const response = await APIService.apiCall("cards/", "GET");
            if (!response.ok) throw new Error('Failed to fetch cards');
            const data = await response.json();
            this.cards = data; // Cache data
        }
    }

    // Create a new card
    static async createCard(card) {
        const response = await APIService.apiCall("cards/", "POST", card);
        if (!response.ok) throw new Error('Failed to create card');
        this.cards = null; // Invalidate cache
    }

    // Bulk create cards
    static async bulkCreateCards(cards) {
        const response = await APIService.apiCall("cards/bulk/", "POST", cards);
        if (!response.ok) throw new Error('Failed to bulk create cards');
        this.cards = null; // Invalidate cache
    }

    // Get all cards
    static async getCards() {
        await this.fetchCards(); // Ensure cards are loaded
        return this.cards;
    }

    // Get a single card by ID
    static async getCardById(id) {
        await this.fetchCards(); // Ensure cards are loaded
        const card = this.cards.find(c => c.id.toString() === id.toString());
        if (!card) throw new Error('Card not found');
        return card;
    }

    // Delete a card and invalidate cache
    static async deleteCard(id) {
        const response = await APIService.apiCall(`cards/${id}/`, "DELETE");
        if (response.status !== 204) throw new Error('Failed to delete card');
        this.cards = null; // Invalidate cache
    }

    static async assignCard(payload) {
        const response = await APIService.apiCall(`cards/assign/`, "POST", payload);
        if (response.status !== 200) throw new Error('Failed to assign card');
        this.cards = null; // Invalidate cache
        console.log("card assigned and cache invalidated");
    }

    static async unassignCard(teamMemberId) {
        const response = await APIService.apiCall(`cards/unassign/`, "POST", { owner: teamMemberId });
        if (response.status !== 200) throw new Error('Failed to unassign card');
        this.cards = null; // Invalidate cache
    }
}

export default CardsService;