import React from 'react';

function SvgProfileReorganizeCta({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 18.9584C6.20833 18.9584 3.125 15.8751 3.125 12.0834V7.91675C3.125 4.12508 6.20833 1.04175 10 1.04175C13.7917 1.04175 16.875 4.12508 16.875 7.91675V12.0834C16.875 15.8751 13.7917 18.9584 10 18.9584ZM10 2.29175C6.9 2.29175 4.375 4.81675 4.375 7.91675V12.0834C4.375 15.1834 6.9 17.7084 10 17.7084C13.1 17.7084 15.625 15.1834 15.625 12.0834V7.91675C15.625 4.81675 13.1 2.29175 10 2.29175Z"
                fill={!!color ? color : "#9CA3AF"}/>
            <path
                d="M10 9.79167C8.96667 9.79167 8.125 8.95 8.125 7.91667V6.25C8.125 5.21667 8.96667 4.375 10 4.375C11.0333 4.375 11.875 5.21667 11.875 6.25V7.91667C11.875 8.95 11.0333 9.79167 10 9.79167ZM10 5.625C9.65833 5.625 9.375 5.90833 9.375 6.25V7.91667C9.375 8.25833 9.65833 8.54167 10 8.54167C10.3417 8.54167 10.625 8.25833 10.625 7.91667V6.25C10.625 5.90833 10.3417 5.625 10 5.625Z"
                fill={!!color ? color : "#9CA3AF"}/>
            <path
                d="M10 5.62508C9.65833 5.62508 9.375 5.34175 9.375 5.00008V1.66675C9.375 1.32508 9.65833 1.04175 10 1.04175C10.3417 1.04175 10.625 1.32508 10.625 1.66675V5.00008C10.625 5.34175 10.3417 5.62508 10 5.62508Z"
                fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgProfileReorganizeCta;