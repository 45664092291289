import React from 'react';

function SvgProfileDocDownload({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66675 11.6237V12.957C2.66675 13.3107 2.80722 13.6498 3.05727 13.8998C3.30732 14.1499 3.64646 14.2904 4.00008 14.2904H12.0001C12.3537 14.2904 12.6928 14.1499 12.9429 13.8998C13.1929 13.6498 13.3334 13.3107 13.3334 12.957V11.6237M4.66675 7.6237L8.00008 10.957M8.00008 10.957L11.3334 7.6237M8.00008 10.957V2.95703" stroke={!!color ? color : "#9CA3AF"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgProfileDocDownload;