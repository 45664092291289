import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ToastProvider} from "./contexts/ToastContext";

String.prototype.format = function (params) {
    let formatedString = this;
    Object.keys(params).forEach(p => {
        formatedString = formatedString.replace(`{${p}}`, params[p])
    })
    return formatedString;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ToastProvider>
          <App />
      </ToastProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
