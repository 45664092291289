import React, {useEffect, useState} from 'react';
import {Icons} from "../../../assets/Svgs";
import {useTranslation} from "../../../services/i18n/i18nService";
import ContractsService from "../../../services/Contract/ContractsService";
import {Link} from "react-router-dom";
import {getFullPath} from "../../../router/Router";
import InputSearch from "../../../components/Layouts/InputSearch";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import Table from "../../../components/Layouts/Table";
import ButtonViewDetails from "../../../components/Buttons/ButtonViewDetails";

function Contracts() {
    const { t: _ } = useTranslation();
    const [ listContracts, setListContracts ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ value, setValue ] = useState("");
    const [ connectionsOriginal, setConnectionsOriginal ] = useState([]);

    const onSearch = (listToSearch, keyword) => {
        if (!keyword) return listToSearch;
        const lowerCaseKeyword = keyword.toLowerCase();
        return listToSearch.filter(item => {
            return item.contract_ref.toLowerCase().includes(lowerCaseKeyword) ||
                   item.business_name.toLowerCase().includes(lowerCaseKeyword);
        });
    }    

    useEffect(() => {
        setLoading(true);
        ContractsService.getContracts()
            .then(contracts => {
                setListContracts(contracts);
                setConnectionsOriginal(contracts);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div className="flex flex-col h-screen">
            <header className=" flex justify-between items-center border-gray-200 p-7">
                <div className="text-[1.5rem] gap-3 flex font-medium">
                    {_("Contracts")}
                </div>
                <div className="flex items-center gap-7">
                    <InputSearch
                        onSearch={onSearch}
                        value={value}
                        setValue={setValue}
                        setList={setListContracts}
                        listToSearchOriginal={connectionsOriginal}
                        icon={<Icons.SvgFilterSearchOutline color="#D1D5DB" className="absolute top-1/2 left-3 -translate-y-1/2 h-3" />}
                        isFlexible={false}
                        placeholder={"Search contract reference or company"}
                    />
                    <Link to={getFullPath(["ROOT", "CONTRACTS", "CREATE_CONTRACT"])} className="flex justify-center gap-[0.438rem]">
                        <ButtonBase className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={<Icons.SvgUserAddOutline className="h-3" />}>
                            {_("Create a new Contract")}
                        </ButtonBase>
                    </Link>
                </div>
            </header>

            <div className="flex-1 min-h-0 overflow-y-auto p-7">
                <Table
                    displayHeaders={true}
                    columns={[
                        {
                            label: _("Business Name"),
                            sortable: false,
                        },
                        {
                            label: _("Assigned to"),
                            sortable: false,
                        },
                        {
                            label: _("Contract Reference"),
                            sortable: false,
                        },
                        {
                            label: _("View"),
                            sortable: false,
                        },
                    ]}
                    rows={listContracts.map(contract => ({
                        data: [
                            {
                                value: <div className="flex items-center justify-center">
                                    {contract.business_name}
                                </div>,
                            },
                            {
                                value: <div className="flex items-center justify-center gap-[1.5rem]">
                                    <img src={contract.assigned_to[0].avatar} className="rounded-full h-8 w-8" alt="avatar" />
                                    <span>{contract.assigned_to[0].full_name}</span>
                                </div>,
                            },
                            {
                                value: <div className="flex items-center justify-center">
                                    {contract.contract_ref}
                                </div>
                            },
                            {
                                value: <Link to={getFullPath(["ROOT", "CONTRACTS", "VIEW_CONTRACT"]).replace(":id", contract.id)} className="flex justify-center gap-[0.438rem]">
                                    <ButtonViewDetails />
                                </Link>,
                            },
                        ]
                    }))}
                />
            </div>
        </div>
    );
}

export default Contracts;