import React from 'react';
import { StatisticsService } from '../../../../services/Statistics/StatisticsService';
import { MetricChartCard } from '../../../../components/Layouts/MetricChartCard';
import { useTranslation } from '../../../../services/i18n/i18nService';

export const LeadsGeneratedChartCard = ({
    className,
    startDate,
    endDate,
}) => {
    const { t: _ } = useTranslation();

    const { data, isLoading, error } = StatisticsService.useLeadsGenerated({
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
    });

    return (
        <MetricChartCard
            className = {className}
            title={_("Leads Generated")}
            chartData={data}
            colors={["#B09FFF", "#8D79F6"]}
        />
    );
}