import React from 'react';

function SvgTemplateSectionVideo(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M22 7.81V16.19C22 19.83 19.83 22 16.19 22H7.81C4.17 22 2 19.83 2 16.19V7.81C2 7.3 2.04 6.81 2.13 6.36C2.64 3.61 4.67 2.01 7.77 2H16.23C19.33 2.01 21.36 3.61 21.87 6.36C21.96 6.81 22 7.3 22 7.81Z" fill={props.color || "#292D32"}/>
            <path d="M22 7.81V7.86H2V7.81C2 7.3 2.04 6.81 2.13 6.36H7.77V2H9.27V6.36H14.73V2H16.23V6.36H21.87C21.96 6.81 22 7.3 22 7.81Z" fill={props.color || "#292D32"}/>
            <path d="M14.4411 12.7208L12.3611 11.5208C11.5911 11.0808 10.8511 11.0208 10.2711 11.3508C9.69109 11.6808 9.37109 12.3608 9.37109 13.2408V15.6408C9.37109 16.5208 9.69109 17.2008 10.2711 17.5308C10.5211 17.6708 10.8011 17.7408 11.0911 17.7408C11.4911 17.7408 11.9211 17.6108 12.3611 17.3608L14.4411 16.1608C15.2111 15.7208 15.6311 15.1008 15.6311 14.4308C15.6311 13.7608 15.2011 13.1708 14.4411 12.7208Z" fill={props.color || "#292D32"}/>
        </svg>
    );
}

export default SvgTemplateSectionVideo;