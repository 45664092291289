import React, {useEffect, useState} from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {useNavigate} from "react-router";
import {Icons} from "../../../assets/Svgs";
import {getFullPath} from "../../../router/Router";
import { Reorder } from "framer-motion";
import PortfolioReorderItem from "../Layouts/PortfolioReorderItem";
import TemplateService from "../../../services/Template/TemplateService";
import ButtonMobile from "../../../components/Buttons/ButtonMobile";

function PortfolioReorder() {
    const { t: _ } = useTranslation();
    const navigate = useNavigate();
    const [ components, setComponents ] = useState([]);

    const checkComponent = (type, title) => {
        switch (type) {
            case 'TEXT':
                return <>
                    <Icons.SvgProfileEditText className="h-5"/>
                    <p className="truncate xs:w-full w-44">{ title }</p>
                </>
            case "SOCIAL_MEDIA":
                return <>
                    <Icons.SvgProfileReorganizeMedia className="h-5"/>
                    <p className="truncate xs:w-full w-44">{ title }</p>
                </>
            case "PICTURE":
                return <>
                    <Icons.SvgProfileReorganizePicture className="h-5"/>
                    <p className="truncate xs:w-full w-44">{ title }</p>
                </>
            case "VIDEO":
                return <>
                    <Icons.SvgProfileReorganizeVideo className="h-5"/>
                    <p className="truncate xs:w-full w-44">{ title }</p>
                </>
            case "WEBSITE":
                return <>
                    <Icons.SvgProfileModelContactWeb className="h-5"/>
                    <p className="truncate xs:w-full w-44">{ title }</p>
                </>
            case "DOCUMENT":
                return <>
                    <Icons.SvgProfileEditDoc className="h-5" color="#9CA3AF" />
                    <p className="truncate xs:w-full w-44">{ title }</p>
                </>
            case "CONTACT":
                return <>
                    <Icons.SvgProfileModelContactTel className="h-5"/>
                    <p className="truncate xs:w-full w-44">{ title }</p>
                </>
            case "CTA":
                return <>
                    <Icons.SvgProfileReorganizeCta className="h-5"/>
                    <p className="truncate xs:w-full w-44">{ title }</p>
                </>
            default:
                return null
        }
    }
    //
    // useEffect(() => {
    //     const excludedTypes = ["PROFILE", "LOCATION", "FORM", "WHATSAPP_CTA", "PAYPAL_CTA", "CALENDLY", "GOOGLE_REVIEW", "APP_LINK", "INSTAGRAM_FEED"];
    //
    //     TemplateService.mockBackendCall()
    //         .then(data => { setComponents( data.filter(item => !excludedTypes.includes(item.type)).sort((a, b) => (a.order > b.order) ? 1 : -1) ); })
    //         .catch(err => { console.error(err); });
    // }, []);

    //reset order when drag to reorder lists
    useEffect(() => {
        components.map((component, index) => {
            component.order = index
        })
        console.log(components)
    }, [components]);

    return (
        <div className="m-4 grid gap-10">
            <div className="flex justify-between">
                <ButtonMobile
                    onClick={() => {
                        navigate(getFullPath(["ROOT", "PROFILE"]));
                    }}
                    className="px-2.5 py-1.5 bg-white border border-[#0400BD]"
                    textClass="text-[#0400BD] font-normal text-[1rem] leading-5"
                    label="Cancel"
                    icon={<Icons.SvgProfileEditHeaderCancel />}
                />
                <ButtonMobile
                    onClick={() => {
                        navigate(getFullPath(["ROOT", "PROFILE"]));
                    }}
                    className="px-2.5 py-1.5"
                    textClass="text-white font-normal text-[1rem] leading-5"
                    label="Save"
                    icon={<Icons.SvgProfileEditHeaderSave />}
                />
            </div>

            <div className="flex flex-col gap-8">
                <div className="grid gap-4">
                    <div className="text-[#111827] font-medium text-[1.25rem] leading-7 w-4/6">
                        { _("Rearrange all the sections of your profile") }
                    </div>

                    <Reorder.Group className="flex flex-col gap-4" axis="y" onReorder={setComponents} values={components}>
                        {
                            !!components
                                ? components.map((item, index) => (
                                    <PortfolioReorderItem
                                        key={item.id}
                                        index={index}
                                        currentItem={item}
                                        values={components}
                                        setValues={setComponents}
                                    >
                                        { checkComponent(item.type, item.data.title) }
                                    </PortfolioReorderItem>
                                ))
                                : null
                        }
                    </Reorder.Group>
                </div>
            </div>

        </div>
    );
}

export default PortfolioReorder;