import TokenService from "./Auth/TokenService";

export class APIService { 
    static async apiCall (apiRoute, method, body, setJsonContentType = true, header = {}, authorizationHeader) {
        const contentType = setJsonContentType ? {
            'Content-Type': 'application/json',
        } : {};

        const token = authorizationHeader!==undefined ? authorizationHeader : 'Bearer ' + await TokenService.getAccessToken(this.apiCall);
        const auth = !!token ? {
            Authorization: token,
        } : {}
    
        const headers = {
            ...contentType,
            ...auth,
            ...header,
        }
    
        let options = {
            method: method,
            headers: headers,
        };
    
        options = {
            ...options,
            ...(['POST', 'PUT', 'PATCH', "DELETE"].includes(method)
                ? { body: JSON.stringify(body) }
                : {}),
        };
    
        return fetch(`${process.env.REACT_APP_PREFIX_API_URL}` + apiRoute, options)
    }    
 };

export default APIService;
