import React, {useEffect, useState} from 'react';
import SquareView from "../../Layouts/SelectorSquareView";
import {Icons} from "../../../assets/Svgs";
import PopupBase from "../PopupBase";
import {useTranslation} from "../../../services/i18n/i18nService";
import {usePopup} from "../../../services/PopupService";
import PopupConfirmExport from "./PopupConfirmExport";

function PopupSelectorExportLeads({ listChecked, type, hasSelectorVef, selectedWayExport: selectedWayParent, onSelectedWayExport }) {
    const { t: _ } = useTranslation();
    const { removePopup, addPopup } = usePopup();
    const [ selectedWayExport, setSelectedWayExport ] = useState(selectedWayParent ?? "csv");

    useEffect(() => {
        onSelectedWayExport && onSelectedWayExport(selectedWayExport);
    }, [selectedWayExport, onSelectedWayExport]);

    return (
        <PopupBase
            title={ type === "leads" ? _("Choose the way to export the selected leads ("+ listChecked.length +" selected) : ") : _("Choose the way to export this lead : ") }
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                ]
            }>
            <div className="w-full flex justify-center align-center" >
                <div className="grid w-full items-center">
                    <div className="flex gap-7 px-48">
                        <SquareView
                            className="gap-7"
                            isSelected={ selectedWayExport === "csv" }
                            onClick={() => { setSelectedWayExport("csv"); addPopup(<PopupConfirmExport selectedWayExport="csv" listChecked={listChecked} />) }}
                            icon={<Icons.SvgSelectorCSV className="h-[3.125rem]"/>}
                            viewName={_("Export to CSV")}
                        />
                        {
                            (hasSelectorVef && hasSelectorVef === true)
                                ? <SquareView
                                    className="gap-7"
                                    isSelected={ selectedWayExport === "vcf" }
                                    onClick={() => { setSelectedWayExport("vcf"); addPopup(<PopupConfirmExport selectedWayExport="vcf" listChecked={listChecked} />) }}
                                    icon={<Icons.SvgSelectorCSV className="h-[3.125rem]"/>}
                                    viewName={_("Export to VCF")}
                                />
                                : null
                        }
                        <SquareView
                            className="gap-7"
                            isSelected={ selectedWayExport === "salesforce" }
                            onClick={() => { setSelectedWayExport("salesforce"); addPopup(<PopupConfirmExport selectedWayExport="salesforce" listChecked={listChecked} />) }}
                            icon={<Icons.SvgSelectorSalesforce className="h-[3.125rem]"/>}
                            viewName={_("Export to Salesforce")}
                        />
                    </div>
                </div>
            </div>
        </PopupBase>
    );
}

export default PopupSelectorExportLeads;