import React from 'react';
import ButtonBase from "../../Buttons/ButtonBase";
import { actionOf, injectUserData } from "./WidgetContactView";

function WidgetCTAView(
    {
        widget,
        displayEditAction,
        onEdit,
        userData = null,
    }
) {

    const widgetData = widget.data;
    const widgetTitle = widgetData?.title;

    const handleClick = () => {
        injectUserData([widgetData], userData || {info: []}).forEach(CTA => {
            actionOf(CTA)()
        })
    }

    return (
        widgetTitle
        && <ButtonBase onClick={handleClick} className="text-[1rem] leading-6 font-medium bg-black text-white py-3 px-4 rounded-full">
            <div className="truncate w-full">{ widgetTitle }</div>
        </ButtonBase>
    );
}

export default WidgetCTAView;