import React from 'react';
import { usePopup } from "../../../services/PopupService";
import { useTranslation } from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";
import PopupConfirmSave from "./PopupConfirmSave";
import PopupHaveBeenSaved from "./PopupHaveBeenSaved";

function PopupGoBackQuestion({openPopupEditAvatar, backPrev, save, id}) {
    const { clearAllPopups, addPopup } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            rows
            title={_("Are you sure you want to go back without saving your modifications ?")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[46rem]",
                        onClick: !!openPopupEditAvatar ? openPopupEditAvatar : clearAllPopups,
                    },
                    {
                        label: _("Go Back to the previous page without saving the modifications"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[46rem]",
                        onClick: backPrev,
                    },
                    {
                        label: _("Save the modifcations"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[46rem]",
                        onClick: () => {
                            addPopup(<PopupConfirmSave
                                openPopupHaveBeenSaved={
                                    () => {
                                        addPopup(<PopupHaveBeenSaved
                                            onHandleRedirectToPrev={ save }
                                        />)
                                    }
                                }
                            />)
                        }
                    }
                ]
            }>
        </PopupBase>
    );
}

export default PopupGoBackQuestion;