import React from 'react';

function SvgTeamWarningFilled(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-12" } `} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.6445 12.1868L28.2695 5.04102C26.2487 3.87435 23.7487 3.87435 21.707 5.04102L9.35286 12.1868C7.33203 13.3535 6.08203 15.5202 6.08203 17.8743V32.1243C6.08203 34.4577 7.33203 36.6243 9.35286 37.8118L21.7279 44.9577C23.7487 46.1243 26.2487 46.1243 28.2904 44.9577L40.6654 37.8118C42.6862 36.6452 43.9362 34.4785 43.9362 32.1243V17.8743C43.9154 15.5202 42.6654 13.3743 40.6445 12.1868ZM23.4362 16.1452C23.4362 15.291 24.1445 14.5827 24.9987 14.5827C25.8529 14.5827 26.5612 15.291 26.5612 16.1452V27.0827C26.5612 27.9368 25.8529 28.6452 24.9987 28.6452C24.1445 28.6452 23.4362 27.9368 23.4362 27.0827V16.1452ZM26.9154 34.6452C26.8112 34.8952 26.6654 35.1243 26.4779 35.3327C26.082 35.7285 25.5612 35.9368 24.9987 35.9368C24.7279 35.9368 24.457 35.8743 24.207 35.7702C23.9362 35.666 23.7279 35.5202 23.5195 35.3327C23.332 35.1243 23.1862 34.8952 23.0612 34.6452C22.957 34.3952 22.9154 34.1243 22.9154 33.8535C22.9154 33.3118 23.1237 32.7702 23.5195 32.3743C23.7279 32.1868 23.9362 32.041 24.207 31.9368C24.9779 31.6035 25.8945 31.791 26.4779 32.3743C26.6654 32.5827 26.8112 32.791 26.9154 33.0618C27.0195 33.3118 27.082 33.5827 27.082 33.8535C27.082 34.1243 27.0195 34.3952 26.9154 34.6452Z" fill="#EC0000"/>
        </svg>
    );
}

export default SvgTeamWarningFilled;