import React from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {usePopup} from "../../../services/PopupService";
import PopupBase from "../PopupBase";

function PopupDeleteAdminAccess({confirmDelete}) {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    return (
        <PopupBase
            title={_("Are you sure you want to remove the Admin access from this team member?")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                    {
                        label: _("Confirm and Delete"),
                        background: "bg-[#EC0000]",
                        color: "text-white",
                        onClick: confirmDelete,
                    }
                ]
            }>
        </PopupBase>
    );
}

export default PopupDeleteAdminAccess;