import React from 'react';

function SvgUserAddOutline(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.5013 7.00065C7.99707 7.00065 9.20963 5.78809 9.20963 4.29232C9.20963 2.79655 7.99707 1.58398 6.5013 1.58398C5.00553 1.58398 3.79297 2.79655 3.79297 4.29232C3.79297 5.78809 5.00553 7.00065 6.5013 7.00065Z" stroke={!!props.color?props.color:"white"} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.84766 12.4167C1.84766 10.3204 3.93307 8.625 6.50057 8.625C7.02057 8.625 7.52433 8.69541 7.99558 8.82541" stroke={!!props.color?props.color:"white"} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.9154 10.2507C11.9154 10.424 11.8937 10.5919 11.8504 10.7544C11.8016 10.9711 11.715 11.1823 11.6012 11.3665C11.2275 11.9948 10.5395 12.4173 9.7487 12.4173C9.19078 12.4173 8.68703 12.2061 8.30786 11.8594C8.14536 11.7186 8.00452 11.5506 7.89619 11.3665C7.69577 11.0415 7.58203 10.6569 7.58203 10.2507C7.58203 9.66565 7.81495 9.12941 8.19412 8.73941C8.58953 8.33316 9.14203 8.08398 9.7487 8.08398C10.3879 8.08398 10.9674 8.36024 11.3574 8.80441C11.7041 9.18899 11.9154 9.69815 11.9154 10.2507Z" stroke={!!props.color?props.color:"white"} strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.5556 10.2383H8.94141" stroke={!!props.color?props.color:"white"} strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.74609 9.44727V11.0668" stroke={!!props.color?props.color:"white"} strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export default SvgUserAddOutline;