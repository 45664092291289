import React from 'react';

function SvgSidebarCardOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33203 5.81641H14.6654" stroke={props.color || 'white'} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 11.1494H5.33333" stroke={props.color || 'white'} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 11.1494H9.66667" stroke={props.color || 'white'} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.29203 2.48242H11.6987C14.072 2.48242 14.6654 3.06909 14.6654 5.40909V10.8824C14.6654 13.2224 14.072 13.8091 11.7054 13.8091H4.29203C1.92536 13.8158 1.33203 13.2291 1.33203 10.8891V5.40909C1.33203 3.06909 1.92536 2.48242 4.29203 2.48242Z" stroke={props.color || 'white'} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgSidebarCardOutline;