import React, {useEffect, useState} from 'react';
import ButtonBase from "../../../components/Buttons/ButtonBase";
import FieldCopy from "../../../components/Layouts/FieldCopy";
import Table from "../../../components/Layouts/Table";
import {useTranslation} from "../../../services/i18n/i18nService";
import SettingsService from "../../../services/Settings/SettingsService";
import {useNavigate} from "react-router-dom";

function LayoutSubscription() {
    const { t: _ } = useTranslation();
    const [ listSetting, setListSetting ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        SettingsService.getOneListSettingById(1)
            .then(res => {
                setListSetting(res);
            })
            .catch(err => {
                console.error(err);
                navigate('../', { replace: true });
            })
            .finally(() => {
                setLoading(false);
            })
    }, [listSetting]);

    return (
        <div className="flex flex-col w-full gap-7">

            <div className="flex flex-col w-full gap-7 p-7 bg-white rounded-[1.25rem]">
                <div className="flex gap-4 w-full justify-between">
                    <label className="text-[0.875rem] leading-[0.875rem] font-medium">{_("Current Subscritpion Plan")}</label>
                    <ButtonBase className="bg-white text-black cursor-pointer py-3 px-6 border text-[0.813rem] leading-[0.813rem] font-medium rounded-full">
                        {_("Edit Subscription Plan")}
                    </ButtonBase>
                </div>

                <div className="flex gap-7 w-full">
                    <div className="flex flex-col gap-1.5 w-3/6">
                        <div className="text-[2rem] leading-8 font-semibold">CHF 180.-</div>
                        <div className="text-[#4B5563] text-[0.75rem] leading-3 font-medium">{_("Per month")}</div>
                    </div>

                    <div className="flex flex-col gap-1.5 w-3/6">
                        <div className="text-[2rem] leading-8 font-semibold">12</div>
                        <div className="text-[#4B5563] text-[0.75rem] leading-3 font-medium">{_("Team Members")}</div>
                    </div>
                </div>

                <div className="flex gap-7 w-full">
                    <div className="flex flex-col gap-1.5 w-3/6">
                        <div className="text-[#4B5563] text-[0.75rem] leading-3 font-medium">{_("Next billing date:")}</div>
                        <div className="text-[0.75rem] leading-3 font-semibold">15 January 2024</div>
                    </div>

                    <div className="flex flex-col gap-1.5 w-3/6">
                        <div className="text-[0.75rem] leading-3 font-medium flex justify-between">
                            <div className="text-[#4B5563] leading-3 font-medium">1-25 Plan</div>
                            <div className="leading-3 font-semibold">CHF 180.-</div>
                        </div>
                        <div className="text-[0.75rem] text-[#4B5563] leading-3 font-semibold flex justify-between">
                            <div className="leading-3 font-medium">Price per member (per month):</div>
                            <div className="leading-3 font-semibold">CHF 180.-</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-full gap-7 p-7 bg-white rounded-[1.25rem]">
                <table className="border bg-white">
                    <thead>
                    <tr className="bg-white">
                        <th className="border">
                            <div className="flex items-center justify-center">
                                {_("Subscription Plan")}
                            </div>
                        </th>
                        <th className="border">
                            <div className="flex items-center justify-center">
                                {_("Monthly Price")}
                            </div>
                            <div className="text-[#4B5563] font-medium text-[0.75rem] ">
                                {_("Per user, per month")}
                            </div>
                        </th>
                        <th className="border">
                            <div className="flex items-center justify-center">
                                {_("Yearly Price")}
                            </div>
                            <div className="text-[#4B5563] font-medium text-[0.75rem] ">
                                {_("Per user, per month")}
                            </div>
                        </th>
                    </tr>
                    </thead>
                    {
                        listSetting.subscription
                            ? <tbody>
                            <tr>
                                <td className="border">
                                    <div className="flex items-center justify-center text-[#4B5563]">
                                        { _("1-25 users plan") }
                                    </div>
                                </td>
                                <td className="border">
                                    <div className="flex items-center justify-center text-[#4B5563]">
                                        { listSetting.subscription[0].lowUsersPlanMonthlyPrice }
                                    </div>
                                </td>
                                <td className="border">
                                    <div className="flex items-center justify-center text-[#4B5563]">
                                        { listSetting.subscription[0].lowUsersPlanYearlyPrice }
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className="border">
                                    <div className="flex items-center justify-center text-[#4B5563]">
                                        { _("26-100 users plan") }
                                    </div>
                                </td>
                                <td className="border">
                                    <div className="flex items-center justify-center text-[#4B5563]">
                                        { listSetting.subscription[0].middleUsersPlanMonthlyPrice }
                                    </div>
                                </td>
                                <td className="border">
                                    <div className="flex items-center justify-center text-[#4B5563]">
                                        { listSetting.subscription[0].middleUsersPlanYearlyPrice }
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className="border">
                                    <div className="flex items-center justify-center text-[#4B5563]">
                                        { _("201-500 users plan") }
                                    </div>
                                </td>
                                <td className="border">
                                    <div className="flex items-center justify-center text-[#4B5563]">
                                        { listSetting.subscription[0].highUsersPlanMonthlyPrice }
                                    </div>
                                </td>
                                <td className="border">
                                    <div className="flex items-center justify-center text-[#4B5563]">
                                        { listSetting.subscription[0].highUsersPlanYearlyPrice }
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                            : null
                    }

                </table>

                <div className="flex items-center justify-center text-[0.75rem] leading-3 font-medium">
                    {_("Do you have more than 500 users ? Please")}
                    &nbsp;<span className="underline select-all">{_("contact us.")}</span>
                </div>
            </div>

            <div className="flex flex-col w-full gap-7 p-7 bg-white rounded-[1.25rem]">
                <div className="flex gap-4 w-full justify-between">
                    <label className="text-[0.875rem] leading-[0.875rem] font-medium flex items-center">
                        {_("Estimate your monthly costs if new members were planned to be added:")}
                    </label>
                    <ButtonBase className="bg-white text-black cursor-pointer py-3 px-6 border text-[0.813rem] leading-[0.813rem] font-medium rounded-full">
                        {_("Subscription Cost Calculator")}
                    </ButtonBase>
                </div>
            </div>

            <div className="flex flex-col w-full gap-7 p-7 bg-white rounded-[1.25rem]">
                <div className="flex gap-4 w-full justify-between">
                    <label className="text-[0.875rem] leading-[0.875rem] font-medium flex items-center">
                        {_("Billing Informations")}
                    </label>
                    <ButtonBase className="bg-white text-black cursor-pointer py-3 px-6 border text-[0.813rem] leading-[0.813rem] font-medium rounded-full">
                        {_("Edit Billing Informations")}
                    </ButtonBase>
                </div>

                <div className="md:flex md:flex-row xs:grid gap-7 rounded-[1.25rem] bg-white">

                    {
                        listSetting.billingInfo
                            ? <div className="w-full bg-white grid grid-rows-2 grid-flow-col gap-7">
                                <FieldCopy
                                    label="Business name:"
                                    canCopy={ false }
                                >
                                    { listSetting.billingInfo[0].business_name }
                                </FieldCopy>

                                <FieldCopy
                                    label="Business billing mail:"
                                    canCopy={ false }
                                >
                                    { listSetting.billingInfo[0].business_mail }
                                </FieldCopy>

                                <FieldCopy
                                    label="Adress:"
                                    canCopy={ false }
                                >
                                    { listSetting.billingInfo[0].adress }
                                </FieldCopy>

                                <FieldCopy
                                    label="City and ZIP:"
                                    canCopy={ false }
                                >
                                    { listSetting.billingInfo[0].city }
                                </FieldCopy>
                            </div>
                            : null
                    }
                </div>
            </div>

            <div className="flex flex-col w-full gap-7 p-7 bg-white rounded-[1.25rem]">
                <div className="flex gap-4 w-full justify-between">
                    <label className="text-[0.875rem] leading-[0.875rem] font-medium flex items-center">
                        {_("Payment Details")}
                    </label>
                    <ButtonBase className="bg-white text-black cursor-pointer py-3 px-6 border text-[0.813rem] leading-[0.813rem] font-medium rounded-full">
                        {_("Edit Payment Details")}
                    </ButtonBase>
                </div>

                <div className="md:flex md:flex-row xs:grid gap-7 rounded-[1.25rem] bg-white">

                    {
                        listSetting.paymentDetails
                            ? <div className="w-full bg-white grid grid-rows-1 grid-flow-col gap-7">
                                <FieldCopy
                                    label="Credit card number:"
                                    canCopy={ false }
                                >
                                    { listSetting.paymentDetails[0].creditCard }
                                </FieldCopy>

                                <FieldCopy
                                    label="Type:"
                                    canCopy={ false }
                                >
                                    { listSetting.paymentDetails[0].type }
                                </FieldCopy>
                            </div>
                            : null
                    }

                </div>
            </div>

            <div className="flex flex-col w-full gap-7 p-7 bg-white rounded-[1.25rem]">
                <div className="flex gap-4 w-full justify-between">
                    <label className="text-[0.875rem] leading-[0.875rem] font-medium flex items-center">
                        {_("Invoice History")}
                    </label>
                    <ButtonBase className="bg-white text-black cursor-pointer py-3 px-6 border text-[0.813rem] leading-[0.813rem] font-medium rounded-full">
                        {_("Download all invoices")}
                    </ButtonBase>
                </div>

                {
                    listSetting.invoiceHistory
                        ? <Table
                            bgColorClass="bg-white"
                            trColorClass="bg-[#F6F7F8]"
                            columns={[
                                {
                                    label: _("Team member"),
                                    sortable: true,
                                    sortOn: value => value.props.sort_on,
                                },
                                {
                                    label: _("Job position"),
                                    sortable: true,
                                    sortOn: value => value.props.sort_on,
                                },
                                {
                                    label: _("View"),
                                    sortable: false,
                                },
                            ]}
                            rows={listSetting.invoiceHistory.map( (invoice, index) =>
                                ({
                                    id: index,
                                    data: [
                                        {
                                            value: <div className="flex gap-[1.5rem]">
                                                <span>{ invoice.title }</span>
                                            </div>,
                                        },
                                        {
                                            value: <div className="flex items-center justify-center gap-[1.5rem]">
                                                <span>{ invoice.created_at }</span>
                                            </div>,
                                        },
                                        {
                                            value: <div className="grid justify-items-end">
                                                <ButtonBase className="bg-white color-black px-2.5 py-1 gap-[0.438rem] shadow-sm rounded-full cursor-pointer rounded-full">
                                                    {_("Download")}
                                                </ButtonBase>
                                            </div>
                                        },
                                    ]
                                })
                            )}
                        />
                        : null
                }
            </div>

        </div>
    );
}

export default LayoutSubscription;