import React from 'react';

function SvgTemplateSectionPaypalCTA(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 7C19 7.18 18.99 7.35 18.97 7.52C18.85 8.97 18.21 10.28 17.24 11.24C16.16 12.33 14.66 13 13 13H9.86996C9.36996 13 8.94997 13.37 8.87997 13.86L8.11996 19.14C8.05996 19.53 7.77997 19.85 7.40997 19.95C7.31997 19.99 7.22997 20 7.12997 20H5.17996C4.55996 20 4.08997 19.45 4.18997 18.84L6.57995 4.50999C6.81995 3.05999 8.06998 2 9.53998 2H14C16.76 2 19 4.24 19 7Z" fill="#292D32"/>
            <path opacity="0.4" d="M21 11C21 12.38 20.44 13.63 19.54 14.54C18.63 15.44 17.38 16 16 16H13.85C13.36 16 12.94 16.35 12.86 16.84L12.14 21.16C12.06 21.65 11.64 22 11.15 22H8.21999C7.58999 22 7.11998 21.42 7.23998 20.8L7.40999 19.95C7.77999 19.85 8.05998 19.53 8.11998 19.14L8.87999 13.86C8.94999 13.37 9.36998 13 9.86998 13H13C14.66 13 16.16 12.33 17.24 11.24C18.21 10.28 18.85 8.97002 18.97 7.52002C20.18 8.21002 21 9.51002 21 11Z" fill="#292D32"/>
        </svg>
    );
}

export default SvgTemplateSectionPaypalCTA;