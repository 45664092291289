import React from 'react';

function SvgSelectorGeneral(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M45.8343 12.3964V32.3548C45.8343 38.1048 41.1677 42.7714 35.4177 42.7714H14.5843C13.626 42.7714 12.7093 42.6464 11.8135 42.3964C10.5218 42.0423 10.1051 40.3964 11.0635 39.4381L33.2093 17.2923C33.6676 16.8339 34.3551 16.7298 35.001 16.8548C35.6676 16.9798 36.3968 16.7923 36.9176 16.2923L42.2718 10.9173C44.2302 8.95894 45.8343 9.60477 45.8343 12.3964Z"
                fill="#4B5563"/>
            <path opacity="0.4"
                  d="M30.5013 15.834L8.6888 37.6465C7.6888 38.6465 6.02214 38.3965 5.35547 37.1465C4.58464 35.7298 4.16797 34.084 4.16797 32.3548V12.3965C4.16797 9.60482 5.77214 8.95898 7.73047 10.9173L13.1055 16.3132C13.918 17.1048 15.2513 17.1048 16.0638 16.3132L23.5221 8.83398C24.3346 8.02148 25.668 8.02148 26.4805 8.83398L30.5221 12.8757C31.3138 13.6882 31.3138 15.0215 30.5013 15.834Z"
                  fill="#4B5563"/>
        </svg>
    );
}

export default SvgSelectorGeneral;