import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { WIDGET_TYPES } from '../../constants/Widgets';
import WidgetService from '../../services/Widget/WidgetService';
import WidgetSection from "../Layouts/WidgetSection";
import WidgetAppLinkView from "./View/WidgetAppLinkView";
import WidgetCalendlyView from "./View/WidgetCalendlyView";
import WidgetContactView from "./View/WidgetContactView";
import WidgetCTAView from "./View/WidgetCTAView";
import WidgetDocumentView from "./View/WidgetDocumentView";
import WidgetFormView from "./View/WidgetFormView";
import WidgetGoogleReviewView from "./View/WidgetGoogleReviewView";
import WidgetInstagramFeedView from "./View/WidgetInstagramFeedView";
import WidgetLocationView from "./View/WidgetLocationView";
import WidgetPaypalView from "./View/WidgetPaypalView";
import WidgetPictureView from "./View/WidgetPictureView";
import WidgetSocialMediaView from "./View/WidgetSocialMediaView";
import WidgetTextView from "./View/WidgetTextView";
import WidgetVideoView from "./View/WidgetVideoView";
import WidgetWebsiteView from "./View/WidgetWebsiteView";
import WidgetWhatsAppCtaView from "./View/WidgetWhatsAppCtaView";

const defaultOnEdit = () => {
    // default widget edit callback
}

const widgetsMap = {
    [WIDGET_TYPES.TEXT]: WidgetTextView,
    [WIDGET_TYPES.SOCIAL_MEDIA]: WidgetSocialMediaView,
    [WIDGET_TYPES.VIDEO]: WidgetVideoView,
    [WIDGET_TYPES.PICTURE]: WidgetPictureView,
    [WIDGET_TYPES.DOCUMENT]: WidgetDocumentView,
    [WIDGET_TYPES.WEBSITE]: WidgetWebsiteView,
    [WIDGET_TYPES.CONTACT]: WidgetContactView,
    [WIDGET_TYPES.CTA]: WidgetCTAView,
    [WIDGET_TYPES.LOCATION]: WidgetLocationView,
    [WIDGET_TYPES.LEAD_CREATION_FORM]: WidgetFormView,
    [WIDGET_TYPES.WHATSAPP_CTA]: WidgetWhatsAppCtaView,
    [WIDGET_TYPES.PAYPAL_CTA]: WidgetPaypalView,
    [WIDGET_TYPES.CALENDLY]: WidgetCalendlyView,
    [WIDGET_TYPES.GOOGLE_REVIEW]: WidgetGoogleReviewView,
    [WIDGET_TYPES.APP_LINK]: WidgetAppLinkView,
    [WIDGET_TYPES.INSTAGRAM_FEED]: WidgetInstagramFeedView,
    [WIDGET_TYPES.PROFILE]: WidgetSection,
};

const forwardedRefWidgets = [
    WIDGET_TYPES.LEAD_CREATION_FORM
]

const Widget = React.forwardRef((
    {
        widget,
        displayEditAction = false,
        userData = null,
        onEdit,
        ...rest
    },
    ref
) => {

    const [widgetType, setWidgetType] = useState(null);
    const innerWidgetRef = useRef();

    onEdit = onEdit || defaultOnEdit;

    useImperativeHandle(ref, () => {
        if (innerWidgetRef.current) {
            return {
                ...innerWidgetRef.current
            };
        }
        return {};
    });

    useEffect(() => {
        WidgetService.findWidgetTypeById(widget?.widget_type)
            .then(data => {
                setWidgetType(data);
            })
            .catch(err => {
                console.error(err);
            });
    }, [widget]);

    if (!widgetType) {
        return null;
    }
    
    const widgetTypeName = widgetType.name;
    const WidgetComponent = widgetsMap[widgetTypeName];
    if (!WidgetComponent) {
        return null;
    }

    return <WidgetComponent 
        ref={forwardedRefWidgets.includes(widgetTypeName) ? innerWidgetRef : null}
        widget={widget}
        displayEditAction={displayEditAction}
        userData={userData}
        onEdit={onEdit}
        {...rest}
    />;
});

export default Widget;