import React, {useState} from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {usePopup} from "../../../services/PopupService";
import PopupMobile from "../../../components/Popups/PopupMobile";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";

function PopupPicturesPreview({ pictures, startPictureIndex }) {
    /* Hooks */
    const { t: _ } = useTranslation();
    const { clearAllPopups } = usePopup();

    /* States */
    const [ selectedPictureIndex, setSelectedPictureIndex ] = useState(startPictureIndex ?? 0);

    /* Utils */
    const swipeConfidenceThreshold = 10000;
    const swipePower = (offset, velocity) => {
        return Math.abs(offset) * velocity;
    };

    return (
        <PopupMobile
            title={_(pictures[selectedPictureIndex].label)}
            className="flex flex-col gap-4 p-4 bg-black rounded-lg text-white"
            footerButtons={
                [
                    {
                        label: _("Discover"),
                        background: "bg-[#0400BD]",
                        color: "text-white",
                        className: "underline underline-offset-4",
                        onClick: () => {
                            window.open(pictures[selectedPictureIndex].discover_url, "_blank")
                            clearAllPopups()
                        },
                    },
                ]
            }>
            <div className="flex flex-col gap-4">
                <AnimatePresence mode="wait">
                    <motion.img
                        className="w-full h-60 py-6 img"
                        key={ selectedPictureIndex }
                        src={ pictures[selectedPictureIndex].img }
                        initial={{ opacity: 0, x: window.innerWidth }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ transition: { duration: 0 }, opacity: 0 }}
                        transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 }
                        }}
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={1}
                        onDragEnd={(e, { offset, velocity }) => {
                            const swipe = swipePower(offset.x, velocity.x);

                            if (swipe < -swipeConfidenceThreshold) {
                                setSelectedPictureIndex(Math.min(selectedPictureIndex + 1, pictures.length - 1));
                            } else if (swipe > swipeConfidenceThreshold) {
                                setSelectedPictureIndex(Math.max(selectedPictureIndex - 1, 0));
                            }
                        }}
                    />
                </AnimatePresence>

                <div className="wrapper-photo-model flex gap-1.5 overflow-x-auto">
                    {
                        pictures.sort((a, b) => (a.order > b.order) ? 1 : -1).map((picture, index) => (
                            <div onClick={() => setSelectedPictureIndex(index)} key={index} className="w-1/4 flex-shrink-0">
                                <img src={picture.img} alt="picture" className="w-full h-20 py-0.5" />
                            </div>
                        ))
                    }
                </div>
            </div>
        </PopupMobile>
    );
}

export default PopupPicturesPreview;