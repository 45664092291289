import React from 'react';

function SquareView({className, isSelected, onClick, icon, viewName, shapCustom}) {

    return (
        <div
            onClick={ onClick }
            className={`
                ${!!isSelected ? 'bg-gray-200' : 'bg-white' } 
                ${!!className ? className : ''}
                ${!!shapCustom ? shapCustom : "w-[9.5rem] h-[9.5rem]"}  
                hover:bg-gray-200 text-[0.813rem] font-medium rounded-[0.438rem] flex flex-col justify-center align-center items-center shadow-sm cursor-pointer`}
        >
            { icon }
            <p className="text-center text-[0.813rem] leading-3 font-medium">
                { viewName }
            </p>
        </div>
    )
}

export default SquareView;