import React from 'react';

function SvgTemplateSectionLocation(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 70 70" fill="none">
            <path opacity="0.4" d="M57.9781 61.0758C55.2073 63.1175 51.5614 64.1675 47.2156 64.1675H22.7739C22.0739 64.1675 21.374 64.1383 20.7031 64.0508L40.8281 43.9258L57.9781 61.0758Z" fill={props.color || "#292D32"}/>
            <path opacity="0.4" d="M64.1635 22.7798V47.2215C64.1635 51.5673 63.1136 55.2132 61.0719 57.984L43.9219 40.834L64.0469 20.709C64.1344 21.3798 64.1635 22.0798 64.1635 22.7798Z" fill={props.color || "#292D32"}/>
            <path opacity="0.4" d="M43.9276 40.832L61.0776 57.982C60.2318 59.207 59.211 60.2279 57.986 61.0737L40.8359 43.9237L20.711 64.0487C18.8443 63.932 17.1526 63.5529 15.6068 62.9695C9.36512 60.6945 5.83594 55.1529 5.83594 47.2195V22.7779C5.83594 12.1612 12.1651 5.83203 22.7818 5.83203H47.2234C55.1568 5.83203 60.6984 9.36119 62.9734 15.6029C63.5568 17.1487 63.9359 18.8404 64.0526 20.707L43.9276 40.832Z" fill={props.color || "#292D32"}/>
            <path d="M43.9263 40.8346L61.0763 57.9846C60.2305 59.2096 59.2096 60.2305 57.9846 61.0763L40.8346 43.9263L20.7096 64.0513C18.843 63.9346 17.1513 63.5555 15.6055 62.9721L16.7429 61.8346L62.9721 15.6055C63.5554 17.1513 63.9346 18.843 64.0513 20.7096L43.9263 40.8346Z" fill={props.color || "#292D32"}/>
            <path d="M35.6987 23.1289C34.5904 18.3164 30.332 16.1581 26.5987 16.1289C22.8654 16.1289 18.6071 18.2872 17.4987 23.0997C16.2737 28.4372 19.5404 32.8997 22.4862 35.6997C23.6529 36.8081 25.1112 37.3331 26.5987 37.3331C28.0862 37.3331 29.5445 36.7789 30.7112 35.6997C33.657 32.8997 36.9237 28.4372 35.6987 23.1289ZM26.6862 27.6789C25.082 27.6789 23.7695 26.3664 23.7695 24.7622C23.7695 23.1581 25.0529 21.8456 26.6862 21.8456H26.7154C28.3196 21.8456 29.6321 23.1581 29.6321 24.7622C29.6321 26.3664 28.2904 27.6789 26.6862 27.6789Z" fill={props.color || "#292D32"}/>
        </svg>
    );
}

export default SvgTemplateSectionLocation;