import React from 'react';

function SvgSidebarTemplates(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.9987 13.4138V2.88047C6.9987 1.88047 6.57203 1.48047 5.51203 1.48047H2.8187C1.7587 1.48047 1.33203 1.88047 1.33203 2.88047V13.4138C1.33203 14.4138 1.7587 14.8138 2.8187 14.8138H5.51203C6.57203 14.8138 6.9987 14.4138 6.9987 13.4138Z" stroke={props.color || "black"} />
            <path d="M14.6667 7.4138V2.88047C14.6667 1.88047 14.24 1.48047 13.18 1.48047H10.4867C9.42667 1.48047 9 1.88047 9 2.88047V7.4138C9 8.4138 9.42667 8.8138 10.4867 8.8138H13.18C14.24 8.8138 14.6667 8.4138 14.6667 7.4138Z" stroke={props.color || "black"} />
            <path d="M14.6667 13.4125V12.2125C14.6667 11.2125 14.24 10.8125 13.18 10.8125H10.4867C9.42667 10.8125 9 11.2125 9 12.2125V13.4125C9 14.4125 9.42667 14.8125 10.4867 14.8125H13.18C14.24 14.8125 14.6667 14.4125 14.6667 13.4125Z" stroke={props.color || "black"} />
        </svg>
}

export default SvgSidebarTemplates;