import React from 'react';

function SvgCardUnAssignOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.69922 5.01562H11.6992" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.47922 10.2645H3.92419C2.14919 10.2645 1.69922 9.82453 1.69922 8.06953V3.95953C1.69922 2.36953 2.0692 1.85953 3.4642 1.77953C3.6042 1.77453 3.75919 1.76953 3.92419 1.76953H9.47922C11.2542 1.76953 11.7042 2.20953 11.7042 3.96453V6.16953" stroke="black" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.69922 11.0156C10.8038 11.0156 11.6992 10.1202 11.6992 9.01562C11.6992 7.91106 10.8038 7.01562 9.69922 7.01562C8.59465 7.01562 7.69922 7.91106 7.69922 9.01562C7.69922 10.1202 8.59465 11.0156 9.69922 11.0156Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.2347 9.57562L9.17969 8.51562" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.2241 8.53027L9.16406 9.58527" stroke="#292D32" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.69922 8.01562H5.69922" stroke="#292D32" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgCardUnAssignOutline;