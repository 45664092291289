import React from 'react';
import ButtonBase from '../Buttons/ButtonBase';
import {useTranslation} from "../../services/i18n/i18nService";

function PopupBase({className, title, children, footerButtons, rows}) {
    const { t: _ } = useTranslation();

    return (
        <div className={`${!!className ? className: ''} z-60 flex flex-col gap-7 p-7 bg-[#F5F5F5] rounded-[1.25rem]`}>
            <div className="flex flex-col gap-7 overflow-hidden">
                {
                    !!title
                        ? <header className="flex text-[1.25rem] justify-center items-center font-medium">
                            { title }
                        </header>
                        : null

                }

                {
                    !!children
                        ? <div className="w-full flex gap-7 align-center justify-center overflow-hidden">
                            { children }
                        </div>
                        : null
                }
            </div>

            <div>
                {
                    !!footerButtons
                        ? <div className={`flex ${ !rows ? 'flex-row gap-7 align-center justify-center' : 'flex-col gap-3.5' }`}>
                            {
                                footerButtons.map((button, index) => (
                                    <div onClick={button.onClick} key={index} className="w-full flex flex-col justify-center align-center gap-7">
                                        <ButtonBase className={`${button.background} ${button.color} ${button.className} w-full text-sm font-medium py-3 px-6 rounded-full `}>
                                            { _(button.label) }
                                        </ButtonBase>
                                    </div>
                                ))
                            }
                        </div>
                        : null
                }
            </div>
        </div>
    );
}

export default PopupBase;