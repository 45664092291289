import APIService from "../APIService";
import { ACCOUNTS_CUSTOMERS_DATAS } from "../../constants/DatasConstants";

class CustomersService {
    static listCustomers = null;
    static listAccounts = ACCOUNTS_CUSTOMERS_DATAS; // Loading static data initially

    // Fetch customers with caching logic
    static async fetchCustomers() {
        if (!this.listCustomers) {
            const response = await APIService.apiCall("structures/companies/", "GET");
            if (!response.ok) throw new Error('Failed to fetch customers');
            const data = await response.json();
            this.listCustomers = data; // Cache data
        }
    }

    // Get all customers
    static async getCustomers() {
        await this.fetchCustomers(); // Ensure customers are loaded
        return this.listCustomers;
    }

    // Get a single customer by ID
    static async getCustomerById(id) {
        await this.fetchCustomers(); // Ensure customers are loaded
        const customer = this.listCustomers.find(c => c.id.toString() === id.toString());
        if (!customer) throw new Error('Customer not found');
        return customer;
    }

    // Create a new customer and invalidate cache
    static async createCustomer(customer) {
        const response = await APIService.apiCall("structures/companies/", "POST", customer, true, {});
        const data = await response.json();
        if (response.status !== 201) throw new Error('Failed to create customer');
        this.listCustomers = null; // Invalidate cache
        return data;
    }

    // Delete a customer and invalidate cache
    static async deleteCustomer(id) {
        const response = await APIService.apiCall(`structures/companies/${id}/`, "DELETE");
        if (response.status !== 204) throw new Error('Failed to delete customer');
        this.listCustomers = null; // Invalidate cache
    }

    // Add user to customer
    static async addUserToCustomer(membership) {
        const response = await APIService.apiCall(`structures/company-memberships/`, "POST", membership);
        const data = await response.json();
        if (response.status !== 201) throw new Error('Failed to add user to customer');
        return data;
    }
}

export const customersServiceInstance = new CustomersService();

export default CustomersService;