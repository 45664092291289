import React from 'react';

function SvgSelectorCalendarOutline(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 1V2.5" stroke={props.color || "black"} strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 1V2.5" stroke={props.color || "black"} strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.75 4.54492H10.25" stroke={props.color || "black"} strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.5 4.25V8.5C10.5 10 9.75 11 8 11H4C2.25 11 1.5 10 1.5 8.5V4.25C1.5 2.75 2.25 1.75 4 1.75H8C9.75 1.75 10.5 2.75 10.5 4.25Z" stroke={props.color || "black"} strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.84882 6.84961H7.85331" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.84882 8.34961H7.85331" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.99725 6.84961H6.00174" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.99725 8.34961H6.00174" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.14569 6.84961H4.15018" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.14569 8.34961H4.15018" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
}

export default SvgSelectorCalendarOutline;