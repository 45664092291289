import React from 'react';

function SvgProfileEditArrowSquareDown({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-8" } `} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0001 30.3332H12.0001C4.76008 30.3332 1.66675 27.2398 1.66675 19.9998V11.9998C1.66675 4.75984 4.76008 1.6665 12.0001 1.6665H20.0001C27.2401 1.6665 30.3334 4.75984 30.3334 11.9998V19.9998C30.3334 27.2398 27.2401 30.3332 20.0001 30.3332ZM12.0001 3.6665C5.85341 3.6665 3.66675 5.85317 3.66675 11.9998V19.9998C3.66675 26.1465 5.85341 28.3332 12.0001 28.3332H20.0001C26.1467 28.3332 28.3334 26.1465 28.3334 19.9998V11.9998C28.3334 5.85317 26.1467 3.6665 20.0001 3.6665H12.0001Z" fill={!!color ? color : "#0400BD"}/>
            <path d="M16 19.8801C15.7466 19.8801 15.4933 19.7868 15.2933 19.5868L10.5866 14.8801C10.2 14.4934 10.2 13.8534 10.5866 13.4668C10.9733 13.0801 11.6133 13.0801 12 13.4668L16 17.4668L20 13.4668C20.3866 13.0801 21.0266 13.0801 21.4133 13.4668C21.8 13.8534 21.8 14.4934 21.4133 14.8801L16.7066 19.5868C16.5066 19.7868 16.2533 19.8801 16 19.8801Z" fill={!!color ? color : "#0400BD"}/>
        </svg>
    );
}

export default SvgProfileEditArrowSquareDown;