import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { format } from "date-fns";
import LeadsService from "../../../services/Leads/LeadsService";
import { usePopup } from "../../../services/PopupService";
import { useTranslation } from "../../../services/i18n/i18nService";
import { Icons } from "../../../assets/Svgs";
import { getFullPath } from "../../../router/Router";
import PopupSelectMemberToReassign from "../../../components/Popups/ReassignLead/PopupSelectMemberToReassign";
import PopupSendMailToLeadSelected from "../../../components/Popups/Mailing/PopupSendMailToLeadSelected";
import PopupDeletePersonSelected from "../../../components/Popups/DeletePerson/PopupDeletePersonSelected";
import SelectorDate from "../../../components/Selectors/SelectorDate";
import ButtonDropDown from "../../../components/Buttons/ButtonDropDown";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import Table, { SelectionType } from "../../../components/Layouts/Table";
import ButtonViewDetails from "../../../components/Buttons/ButtonViewDetails";
import PopupSelectorExportLeads from "../../../components/Popups/ExportLead/PopupSelectorExportLeads";
import PopupSelectTag from "../../../components/Popups/AssignTags/PopupSelectTag";
import PopupFilterByView from "../../../components/Popups/TemplateFilter/PopupFilterByView";
import ButtonFlexible from "../../../components/Buttons/ButtonFlexible";
import InputSearch from "../../../components/Layouts/InputSearch";
import PopupSelectorCalendar from "../../../components/Selectors/PopupSelectorCalendar";

const onSearch = (listToSearch, keyword) => {
    const lowerKeyword = keyword.toLowerCase();
    return listToSearch.filter(item => {
        return item.data.some(dataItem =>
            dataItem.value.toLowerCase().includes(lowerKeyword)
        );
    });
}

function Leads() {
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const [ listLeads, setListLeads ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const [ isSelectAll, setIsSelectAll ] = useState(false);
    const [ selectedWayExport, setSelectedWayExport ] = useState("csv");
    const [ listChecked, setListChecked ] = useState([]);
    const [ selectedView, setSelectedView ] = useState("team");
    const [ toggle, setToggle ] = useState(false);
    const [ value, setValue ] = useState("");
    const [ listOriginal, setListOriginal ] = useState([]);

    const links = [
        {
            id: 0,
            icon: <Icons.SvgLeadsTagOutline className="h-3" />,
            iconColor: "#000000",
            label: "Add a tag",
            onClick: () => {
                addPopup( <PopupSelectTag /> )
            },
        },
        {
            id: 1,
            icon: <Icons.SvgLeadsReassign className="h-3" />,
            iconColor: "#000000",
            label: "Re-assign",
            onClick: () => {
                addPopup( <PopupSelectMemberToReassign members={listLeads} /> )
            },
        },
        {
            id: 2,
            icon: <Icons.SvgLeadsMailOutline className="h-3" />,
            iconColor: "#000000",
            label: "Mail Campain",
            onClick: () => {
                addPopup( <PopupSendMailToLeadSelected listChecked={listChecked} /> )
            },
        },
        {
            id: 3,
            icon: <Icons.SvgTrashOutline className="h-3" />,
            iconColor: "#FF0000",
            label: "Delete",
            onClick: () => {
                addPopup( <PopupDeletePersonSelected objectToDelete="leads" listChecked={listChecked} /> )
            },
        }
    ]

    useEffect(() => {
        setLoading(true);
        LeadsService.getLeads()
            .then(leads => {
                setListLeads(leads);
                setListOriginal(leads);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div className="flex flex-col h-screen">
            <header className=" z-10">
                <div className="p-7 flex justify-between">
                    <div className="text-[1.5rem] gap-3 flex items-center font-medium">
                        {_("Leads")}
                        {!loading && <span className="text-[#4B5563] text-[1.25rem] leading-5">({listLeads.length})</span>}
                    </div>

                    <div className="flex items-center gap-7">
                        <ButtonFlexible
                            icon={<Icons.SvgStatisticFilterOutline />}
                            onClick={() => addPopup(
                                <PopupFilterByView title="Filter the leads view by :" selectedView={selectedView} onSelectedViewChange={setSelectedView} />
                            )}
                        >
                            {_("Filter")}
                        </ButtonFlexible>

                        <SelectorDate isFlexible={true} onClick={() => addPopup(<PopupSelectorCalendar />)} />

                        {
                            !toggle && (
                                <ButtonFlexible onClick={() => { setToggle((prev) => !prev) }} icon={<Icons.SvgSearchView />}>
                                    {_("Search")}
                                </ButtonFlexible>
                            )
                        }

                        <InputSearch
                            onSearch={onSearch}
                            value={value}
                            setValue={setValue}
                            toggle={toggle}
                            setToggle={setToggle}
                            placeholder={_("Search a name, template, mail or job position")}
                            setList={setListLeads}
                            listToSearchOriginal={listOriginal}
                            icon={<Icons.SvgSearchView className="absolute top-1/2 left-3 -translate-y-1/2 h-3" />}
                        />

                        <ButtonFlexible onClick={() => setIsSelectAll(!isSelectAll)} icon={<Icons.SvgCheckboxFill />}>
                            {_("Select all")}
                        </ButtonFlexible>

                        <ButtonDropDown links={links} />

                        <ButtonBase
                            onClick={() => {
                                addPopup(
                                    <PopupSelectorExportLeads
                                        listChecked={listChecked}
                                        type="leads"
                                        selectedWayExport={selectedWayExport}
                                        onSelectedWayExport={setSelectedWayExport}
                                    />)
                            }}
                            className="bg-black text-white text-sm py-3.5 px-6 rounded-full"
                            iconColor="white"
                            icon={<Icons.SvgLeadsExportOutline className="h-3" />}
                        >
                            {_("Export")}
                        </ButtonBase>
                    </div>
                </div>
            </header>

            <div className="flex-1 overflow-auto gap-7 px-7">
                <Table
                    selectionType={SelectionType.MULTIPLE}
                    columns={[
                        {
                            label: _("Lead"),
                            sortable: true,
                            sortOn: value => value.props.sort_on,
                        },
                        {
                            label: _("Connected with"),
                            sortable: true,
                            sortOn: value => value.props.sort_on,
                        },
                        {
                            label: _("Date"),
                            sortable: true,
                            sortOn: value => value.props.sort_on,
                        },
                        {
                            label: _("Edit"),
                            sortable: false,
                        },
                    ]}
                    rows={listLeads.map((lead) => ({
                        id: lead.id,
                        data: [
                            {
                                value: <div className="flex flex-col items-start justify-center gap-1.5">
                                    {
                                        lead.data
                                            .filter((itemData) => itemData.key === "full_name" || itemData.key === "email" || itemData.key === "phone")
                                            .map((itemData, index) => (
                                                <span key={index} className={`${itemData.key === "full_name" ? "text-[1rem] leading-4 font-semibold text-black" : "text-[#4B5563] text-[0.813rem] font-medium"}`}>
                                            {itemData.value}
                                        </span>
                                            ))
                                    }
                                </div>,
                            },
                            {
                                value: <div sort_on={lead.connected} className="flex items-center justify-center gap-[1.5rem]">
                                    <img src={lead.avatar} className="rounded-full h-8 w-8" alt="avatar" />
                                    <span className="text-black text-[0.875rem] font-medium">{lead.connected}</span>
                                </div>,
                            },
                            {
                                value: <div sort_on={new Date(lead.created_at)} className="flex text-[0.75rem] font-medium flex-col text-[#4B5563] items-center justify-center gap-1.5">
                                    <p>
                                        {format(new Date(lead.created_at), 'dd/MM/yyyy')}
                                    </p>
                                    <p>
                                        {format(new Date(lead.created_at), 'hh:mm')}
                                    </p>
                                </div>,
                            },
                            {
                                value: <Link to={getFullPath(["ROOT", "LEADS", "VIEW_LEAD"]).replace(":id", lead.id)} className="flex justify-center gap-[0.438rem]">
                                    <ButtonViewDetails />
                                </Link>,
                            },
                        ]
                    }))}
                />
            </div>
        </div>
    );
}

export default Leads;