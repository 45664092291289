import { Reorder } from "framer-motion";
import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icons } from "../../../assets/Svgs";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import ButtonGradient from "../../../components/Buttons/ButtonGradient";
import InputField from "../../../components/Layouts/InputField";
import IPhoneWrapper from '../../../components/Layouts/IPhoneWrapper';
import RuleHorizontal from "../../../components/Layouts/RuleHorizontal";
import SquareView from "../../../components/Layouts/SelectorSquareView";
import PopupBase from "../../../components/Popups/PopupBase";
import PopupConfirmSave from "../../../components/Popups/SaveModification/PopupConfirmSave";
import WidgetFormEdit from "../../../components/Widgets/Edit/WidgetFormEdit";
import { WIDGET_TYPES } from "../../../constants/Widgets";
import { getFullPath } from "../../../router/Router";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import TemplateService from "../../../services/Template/TemplateService";
import WidgetService from "../../../services/Widget/WidgetService";
import Portfolio from '../../Mobile/Portfolio';
import TemplateSectionDraggable from "./TemplateSectionDraggable";
import TemplateSelectorTheme from "./TemplateSelectorTheme";
import FileService from "../../../services/FileService";
import WidgetEdit from "../../../components/Widgets/WidgetEdit";

const widgetIconOf = (type) => {
    const iconMapping = {
        [WIDGET_TYPES.TEXT]: <Icons.SvgTemplateSectionText />,
        [WIDGET_TYPES.SOCIAL_MEDIA]: <Icons.SvgTemplateSectionSocialMedia />,
        [WIDGET_TYPES.PICTURE]: <Icons.SvgTemplateSectionPicture />,
        [WIDGET_TYPES.DOCUMENT]: <Icons.SvgTemplateSectionFile />,
        [WIDGET_TYPES.WEBSITE]: <Icons.SvgTemplateSectionWebsiteLinks />,
        [WIDGET_TYPES.CONTACT]: <Icons.SvgTemplateSectionQuickContact />,
        [WIDGET_TYPES.CTA]: <Icons.SvgTemplateSectionCTA />,
        [WIDGET_TYPES.VIDEO]: <Icons.SvgTemplateSectionVideo />,
        [WIDGET_TYPES.GOOGLE_REVIEW]: <Icons.SvgTemplateSectionGoogleReview />,
        [WIDGET_TYPES.APP_LINK]: <Icons.SvgTemplateSectionAppLink />,
        [WIDGET_TYPES.CALENDLY]: <Icons.SvgTemplateSectionCalendly />,
        [WIDGET_TYPES.INSTAGRAM_FEED]: <Icons.SvgTemplateSectionInstagramFeed />,
        [WIDGET_TYPES.LOCATION]: <Icons.SvgTemplateSectionLocation />,
        [WIDGET_TYPES.WHATSAPP_CTA]: <Icons.SvgTemplateSectionWhatsAppCTA />,
        [WIDGET_TYPES.PAYPAL_CTA]: <Icons.SvgTemplateSectionPaypalCTA />,
        [WIDGET_TYPES.LEAD_CREATION_FORM]: <Icons.SvgTemplateSectionForm />
    };

    return iconMapping[type] || null;
}

function PopupSelectorNewSection({ onWidgetTypeSelected }) {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    const sections = [
        {
            label: _("Text"),
            items: [
                { widgetType: WIDGET_TYPES.TEXT, icon: <Icons.SvgTemplateSectionText className="w-16" />, label: _("Text") }
            ]
        },
        {
            label: _("Links"),
            items: [
                { widgetType: WIDGET_TYPES.WEBSITE, icon: <Icons.SvgTemplateSectionWebsiteLinks className="w-16" />, label: _("Websites Links") },
                { widgetType: WIDGET_TYPES.SOCIAL_MEDIA, icon: <Icons.SvgTemplateSectionSocialMedia className="w-16" />, label: _("Social Media") },
                // { widgetType: WIDGET_TYPES.CALENDLY, icon: <Icons.SvgTemplateSectionCalendly className="w-16" />, label: _("Calendly") },
                { widgetType: WIDGET_TYPES.CTA, icon: <Icons.SvgTemplateSectionCTA className="w-16" />, label: _("Call-to-Action") },
                // { widgetType: WIDGET_TYPES.GOOGLE_REVIEW, icon: <Icons.SvgTemplateSectionGoogleReview className="w-16" />, label: _("Google Reviews") },
                // { widgetType: WIDGET_TYPES.APP_LINK, icon: <Icons.SvgTemplateSectionAppLink className="w-16" />, label: _("App Link") }
            ]
        },
        {
            label: _("Media"),
            items: [
                { widgetType: WIDGET_TYPES.PICTURE, icon: <Icons.SvgTemplateSectionPicture className="w-16" />, label: _("Pictures") },
                { widgetType: WIDGET_TYPES.VIDEO, icon: <Icons.SvgTemplateSectionVideo className="w-16" />, label: _("Video") },
                // { widgetType: WIDGET_TYPES.INSTAGRAM_FEED, icon: <Icons.SvgTemplateSectionInstagramFeed className="w-16" />, label: _("Instagram Feed") }
            ]
        },
        {
            label: _("Contact"),
            items: [
                { widgetType: WIDGET_TYPES.CONTACT, icon: <Icons.SvgTemplateSectionQuickContact className="w-16" />, label: _("Quick Contact") },
                // { widgetType: WIDGET_TYPES.LOCATION, icon: <Icons.SvgTemplateSectionLocation className="w-16" />, label: _("Location") },
                // { widgetType: WIDGET_TYPES.WHATSAPP_CTA, icon: <Icons.SvgTemplateSectionWhatsAppCTA className="w-16" />, label: _("WhatsApp CTA") }
            ]
        },
        // {
        //     label: _("Payment"),
        //     items: [
        //         { widgetType: WIDGET_TYPES.PAYPAL_CTA, icon: <Icons.SvgTemplateSectionPaypalCTA className="w-16" />, label: _("Paypal") }
        //     ]
        // },
        {
            label: _("Other"),
            items: [
                { widgetType: WIDGET_TYPES.DOCUMENT, icon: <Icons.SvgTemplateSectionFile className="w-16" />, label: _("Files") },
                { widgetType: WIDGET_TYPES.LEAD_CREATION_FORM, icon: <Icons.SvgTemplateSectionForm className="w-16" />, label: _("Form") }
            ]
        }
    ];

    return (
        <PopupBase
            title={_("Choose a section to add to this template:")}
        >
            <div className="flex flex-col gap-7 overflow-y-scroll h-128 w-[32rem]">
                {sections.map((section, indexS) => (
                    <div key={indexS} className="flex flex-col gap-4 justify-center">
                        <label className="text-base">{section.label}</label>
                        <div className="flex flex-wrap gap-4">
                            {section.items.map((item, indexI) => (
                                <SquareView
                                    key={ indexS + "-" + indexI }
                                    onClick={() => {
                                        onWidgetTypeSelected(item.widgetType);
                                        removePopup();
                                    }}
                                    shapCustom="aspect-[1/1] gap-4 px-8 py-4 rounded-[1.25rem]"
                                    icon={item.icon}
                                    viewName={item.label}
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </PopupBase>
    );
}

const DisplayViewMainSettings = ({ template, setTemplate }) => {
    /* Hooks */
    const { t: _ } = useTranslation();

    /* States */
    const pictureSelectRef = useRef(null);
    const [ file, setFile ] = useState({});

    /* Effects */

    /* Handlers */
    const handleSelectFile = (e) => {
        const file = e.target.files[0];
        FileService.loadFileBase64(file)
            .then(fileContent => {
                console.log(file.name);
                setFile({
                    file_name: file.name,
                    file_content: fileContent,
                });
                setTemplate((t) => ({ ...t, background_image: fileContent }));
            })
            .catch(error => {
                console.error(error);
            });
    };

    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex justify-center overflow-hidden">
                <IPhoneWrapper className="h-[200%]">
                    <Portfolio page={{
                        widgets: [],
                        theme: {
                            color: template.color || "#000000",
                            background_image: file.file_content || template.background_image
                        } 
                    }}/>
                </IPhoneWrapper>
            </div>
            <label className="flex flex-col justify-center items-center mt-7">
                <input
                    ref={pictureSelectRef}
                    type="file"
                    className="hidden"
                    onChange={handleSelectFile}
                    accept="image/*"
                />
                <div className="flex flex-col gap-7">
                    <div className={`flex flex-col align-center gap-1.5`}>
                        <p className="text-[0.688rem] font-medium text-[#9CA3AF] text-center">
                            {file.file_name || _("No file selected")}
                        </p>
                        <ButtonBase
                            onClick={() => { pictureSelectRef.current?.click() }}
                            className={`bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full`}
                        >
                            {_("Select a backgroung picture")}
                        </ButtonBase>
                    </div>
                </div>
            </label>

            <div className="flex py-7 flex-grow-0 flex-shrink-0 basis-auto">
                <RuleHorizontal />
            </div>

            <div className="flex flex-col gap-7 flex-grow-0 flex-shrink-0 basis-auto">
                <InputField
                    name="name"
                    type="text"
                    widthInputClass="md:w-4/6 sm:w-full"
                    label="Template Name"
                    value={template.title || ""}
                    onChange={(e) => {
                        setTemplate((t) => ({ ...t, title: e.target.value }));
                    }}
                    required={true}
                />

                <TemplateSelectorTheme
                    required={true}
                    label="Template Theme Color"
                    selectedTheme={template.color || "#000000"}
                    setSelectedTheme={(color) => {
                        setTemplate((t) => ({ ...t, color: color }));
                    }}
                />
            </div>
        </div>
    );
}

const DisplayViewContent = ({ widgets, onReorderWidgets, onDeletedWidget }) => {
    /* Hooks */
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const navigate = useNavigate();
    const { id } = useParams();

    /* States */
    const [ widgetTypes, setWidgetTypes ] = useState([]);

    /* Effects */
    useEffect(() => {
        WidgetService.getWidgetTypes()
            .then(data => {
                setWidgetTypes(data);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    /* Handlers */
    const handleAddWidget = () => {
        addPopup(<PopupSelectorNewSection onWidgetTypeSelected={(widgetType) => {
            WidgetService.findWidgetTypeByName(widgetType)
                .then(type => {
                    navigate(getFullPath(["ROOT", "TEMPLATES", "EDIT_TEMPLATE", "CREATE_WIDGET"])
                        .replace(":id", id)
                        .replace(":idType", type.id));
                })
                .catch(err => {
                    console.error(err);
                });
        }} />)
    }

    const handleDeleteWidget = (widgetId) => {
        WidgetService.deleteWidget(widgetId)
            .then(() => {
                TemplateService.resetCache();
                onDeletedWidget();
            })
            .catch(err => {
                console.error(err);
            });
    }

    return (
        <div className="flex flex-col w-full h-full gap-7 overflow-hidden">
            <ButtonBase
                onClick={handleAddWidget}
                className="bg-black text-sm text-white whitespace-nowrap py-3.5 px-6 rounded-full"
                iconColor="white"
                icon={<Icons.SvgTemplateAddNewSection color="white" className="h-3" />}
            >
                {_("Add a new section")}
            </ButtonBase>

            {
                !!widgets
                    ? <div className="flex flex-col h-full overflow-y-auto">
                        <Reorder.Group className="flex flex-col gap-4" axis="y" onReorder={onReorderWidgets} values={widgets}>
                            {
                                widgets.map((widget) => {
                                    const widgetType = widgetTypes.find(wt => wt.id === widget.widget_type)?.name;
                                    if (!widgetType) return null;
                                    return <Reorder.Item key={widget.id} value={widget}>
                                        <TemplateSectionDraggable
                                            widgetId={widget.id}
                                            widget_type={widget.widget_type}
                                            icon={widgetIconOf(widgetType)}
                                            title={widget.data?.title || ""}
                                            displayButtonEdit={true}
                                            displayIconListDraggable={true}
                                            // isDisabled={toggle}
                                            // setToggle={() => setToggle(prev => !prev)}
                                            displayButtonDelete={true}
                                            handleDelete={() => handleDeleteWidget(widget.id)}
                                        />
                                    </Reorder.Item>
                                }).filter(w => w !== null)
                            }
                        </Reorder.Group>
                    </div>
                    : null
            }
        </div>
    );
}

function TemplateEdit() {
    /* Hooks */
    const { t: _ } = useTranslation();
    const { addPopup, removePopup } = usePopup();
    const { id } = useParams();
    const navigate = useNavigate();

    /* States */
    const [ isLoading, setIsLoading ] = useState(true);
    const [ selectedMode, setSelectedMode ] = useState("settings");
    const [ template, setTemplate ] = useState({});
    const [ exchangeContactWidget, setExchangeContactWidget ] = useState(null);
    const exchangeContactWidgetRef = useRef(null);
    const [ reorderedWidgets, setReorderedWidgets ] = useState({});
    
    /* Utils */
    const pendingChanges = Object.keys(reorderedWidgets).length > 0;

    const isNewTemplate = id == null;

    const buttons = [
        {
            label: "Main Settings",
            className: "bg-white text-[#9CA3AF]",
            iconColor: "#9CA3AF",
            mode: "settings",
            iconUnactive: <Icons.SvgTemplatesExport className="h-3" />,
            iconActive: <Icons.SvgTemplatesExportGradient className="h-3" />,
            onClick: () => setSelectedMode("settings"),
        },
        ...[
            {
                label: "Content",
                className: "bg-white text-[#9CA3AF]",
                iconColor: "#9CA3AF",
                mode: "content",
                iconUnactive: <Icons.SvgTemplatesExport className="h-3" />,
                iconActive: <Icons.SvgTemplatesExportGradient className="h-3" />,
                onClick: () => setSelectedMode("content"),
            },
            {
                label: "Capture Form",
                className: "bg-white text-[#9CA3AF] ",
                iconColor: "#9CA3AF",
                mode: "capture",
                iconUnactive: <Icons.SvgTemplatesExport className="h-3" />,
                iconActive: <Icons.SvgTemplatesExportGradient className="h-3" />,
                onClick: () => setSelectedMode("capture"),
            },
            {
                label: "Follow Up Mail",
                className: "bg-white text-[#9CA3AF]",
                iconColor: "#9CA3AF",
                mode: "mail",
                iconUnactive: <Icons.SvgTemplatesExport className="h-3" />,
                iconActive: <Icons.SvgTemplatesExportGradient className="h-3" />,
                onClick: () => setSelectedMode("mail"),
            }
        ].filter(_ => !isNewTemplate)
    ]

    const loadTemplate = () => {
        setIsLoading(true);
        TemplateService.getTemplateById(parseInt(id))
            .then(template => {
                // TODO: refactor all this
                WidgetService.getWidgetTypes()
                    .then(widgetTypes => {
                        const leadCreationFormWidgetType = widgetTypes.find(wt => wt.name === WIDGET_TYPES.LEAD_CREATION_FORM);
                        const exchangeLeadCreationFormWidget = template.widgets.find(w => 
                            w.widget_type === leadCreationFormWidgetType.id
                            && w.data?.isForExchangeContact
                        );
                        if (!!exchangeLeadCreationFormWidget) {
                            setExchangeContactWidget(exchangeLeadCreationFormWidget);
                        }
                        setTemplate({
                            ...template,
                            widgets: template.widgets.filter(w => 
                                w.id !== exchangeLeadCreationFormWidget?.id
                            )
                        });
                        setIsLoading(false);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            })
            .catch(err => {
                console.error(err);
                setIsLoading(false);
            });
    }

    /* Handlers */
    const onReorderWidgets = (orderedWidgets) => {
        const currentWidgetsOrdered = template.widgets.sort((a, b) => (a.order > b.order) ? 1 : -1);

        setReorderedWidgets(
            orderedWidgets
                .map((w, index) => ({
                    index,
                    w
                }))
                .filter(({ w, index }) => currentWidgetsOrdered[index].id !== w.id)
                .reduce((acc, { w, index }) => ({
                    ...acc,
                    [index]: w
                }), {})
        )
    }

    const onDeletedWidget = () => {
        loadTemplate();
    }

    const handleSave = () => {
        addPopup(<PopupConfirmSave openPopupHaveBeenSaved={() => {
            if (isNewTemplate) {
                TemplateService.createTemplate(template)
                    .then(newTemplate => {
                        navigate(getFullPath(["ROOT", "TEMPLATES", "EDIT_TEMPLATE"]).replace(":id", newTemplate.id));
                    })
                    .catch(err => {
                        console.error(err);
                    });
            } else {
                // Widgets reordering
                const orders = Object.keys(reorderedWidgets)
                    .sort((a, b) => reorderedWidgets[a].order - reorderedWidgets[b].order)
                    .map(i => reorderedWidgets[i].order);
                Object.keys(reorderedWidgets).forEach((i, index) => {
                    const widgetId = reorderedWidgets[i].id;
                    const order = orders[index];
                    WidgetService.updateWidget(widgetId, { order });
                });

                // Exchange Contact Widget update
                if (exchangeContactWidgetRef.current) {
                    exchangeContactWidgetRef.current.handleSave();
                }

                // Template update
                const payload = {
                    title: template.title,
                    description: template.description,
                    color: template.color,
                    ...(template.background_image?.startsWith("data:image")
                        ? { background_image: template.background_image }
                        : {}),
                };
                TemplateService.updateTemplate(template.id, payload)
                    .then(newTemplate => {
                        setTemplate(newTemplate);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
            removePopup();
        }} />)
    }

    useEffect(() => {
        if (isNewTemplate) {
            setIsLoading(false);
        } else {
            loadTemplate();
        }
    }, [id]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex flex-col p-7 gap-7 h-full">
            <header className=" flex justify-between items-center border-gray-200">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={getFullPath(["ROOT", "TEMPLATES"])} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Templates")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {isNewTemplate
                        ? _("New")
                        : _("Edit")
                    }
                </div>

                <div className="flex items-center gap-7">
                    <ButtonBase
                        onClick={handleSave}
                        className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full"
                        iconColor="white"
                        icon={<Icons.SvgAssignOutline color="white" className="h-3" />}
                    >
                        {_("Save the modifications")}
                    </ButtonBase>
                </div>
            </header>

            <div className="flex h-full overflow-hidden justify-between gap-7">
                <div className="flex flex-col flex-[2] gap-7">
                    <div className="md:flex w-full sm:flex-row gap-7 align-center justify-center bg-white py-3.5 px-8 rounded-[1.25rem]">
                        {
                            buttons.map((button, index) => (
                                <div key={index} onClick={button.onClick} className={`flex w-full justify-center align-center rounded-full`}>
                                    {
                                        selectedMode === button.mode
                                            ? <ButtonGradient heightClass="h-10" icon={button.iconActive} hasBorder={false} colorGradient={`hover:bg-orange-gradient bg-orange-gradient`} bgActive="bg-[#F6F7F8]" >
                                                {_(button.label)}
                                            </ButtonGradient>

                                            : <ButtonBase iconColor={button.iconColor} icon={button.iconUnactive} className={`${button.className} h-10 whitespace-nowrap w-full hover:bg-[#F6F7F8] text-[0.813rem] font-medium rounded-full`}>
                                                {_(button.label)}
                                            </ButtonBase>
                                    }
                                </div>
                            ))
                        }
                    </div>

                    <div className="flex w-full h-full overflow-hidden align-center justify-center bg-white p-7 rounded-[1.25rem]">
                        {
                            {
                                'settings': () => <DisplayViewMainSettings
                                    template={template}
                                    setTemplate={setTemplate}
                                />,
                                'content': () => <DisplayViewContent
                                    widgets={
                                        template.widgets?.sort((a, b) => 
                                                (a.order > b.order) ? 1 : -1
                                            )
                                            .map((w, index) => reorderedWidgets[index] ?? w)
                                    }
                                    onReorderWidgets={onReorderWidgets}
                                    onDeletedWidget={onDeletedWidget}
                                />,
                                'capture': () => exchangeContactWidget
                                    ? <WidgetEdit
                                        ref={exchangeContactWidgetRef}
                                        widget={exchangeContactWidget}
                                        onChange={setExchangeContactWidget}
                                        onSave={setExchangeContactWidget}
                                    />
                                    : null,
                                'mail': () => null,
                            }[selectedMode]()
                        }
                    </div>
                </div>

                {
                    isNewTemplate
                    ? null
                    : <div className="flex overflow-hidden flex-col flex-1 align-center gap-7">
                        <div className="flex justify-center mt-7 align-center text-sm">
                            {_("Template Live Preview")}
                        </div>
                        
                        <IPhoneWrapper>
                            <Portfolio page={{
                                widgets: [
                                    ...(template.widgets?.sort((a, b) => (a.order > b.order) ? 1 : -1)
                                        .map((w, index) => reorderedWidgets[index] ?? w) ?? []),
                                    ...(exchangeContactWidget ? [exchangeContactWidget] : [])
                                ],
                                theme: {
                                    color: template.color || "#000000",
                                    background_image: template.background_image
                                }
                            }} displayActionBar={true} />
                        </IPhoneWrapper>
                    </div>
                }
            </div>
        </div>
    );
}

export default TemplateEdit;