import React from 'react';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';

function ChartArea({ id, data, startColor, stopColor }) {
    return (
        <div className={`h-full w-full bg-white rounded-sm flex flex-col flex-1`}>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    data={data}
                    margin={{
                        top: 1,
                        right: 0,
                        left: 0,
                        bottom: 1,
                    }}
                >
                    <defs>
                        <linearGradient id={`colorUv${id}`} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor={startColor} stopOpacity={0.33} />
                            <stop offset="100%" stopColor={stopColor} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <Area
                        type="cardinal"
                        dataKey="uv"
                        stroke={startColor}
                        strokeWidth={2}
                        fillOpacity={1}
                        fill={`url(#colorUv${id})`}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}

export default ChartArea;