import { GENERAL_PERFORMERS } from "../../constants/DatasConstants";
let generalPerform = null;

export const getGeneralPerform = () => {
    return new Promise((resolve, reject) => {
        if (generalPerform === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            //data template
            generalPerform = GENERAL_PERFORMERS;
            resolve(generalPerform);
        } else {
            resolve(generalPerform);
        }
    });
}

export default {
    getGeneralPerform,
};