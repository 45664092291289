import { Reorder } from "framer-motion";
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Icons } from "../../../assets/Svgs";
import { CONTACT_TYPES, contactIconOf } from "../../../constants/ContactsTypes";
import PopupSelectorCTAType from "../../../pages/Admin/Templates/Popups/PopupSelectorCTAType";
import PopupItemDelete from "../../../pages/Mobile/Popups/PopupItemDelete";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import WidgetService from "../../../services/Widget/WidgetService";
import ButtonBase from "../../Buttons/ButtonBase";
import ButtonToggle from "../../Buttons/ButtonToggle";
import TextField from "../../Inputs/TextField";
import InputGroup from "../../Layouts/InputGroup";
import { labelPreviewOf } from "../View/WidgetContactView";

const WidgetContactEditSteps = {
    CONTACT_LIST: "CONTACT_LIST",
    CONTACT_DETAIL: "CONTACT_DETAIL",
}

const WidgetContactEdit = forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
) => {
    useImperativeHandle(ref, () => ({
        handleSave,
        handleCreate
    }));

    /* Hooks */
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();

    /* States */
    const [data, setData] = useState({
        title: widget?.data?.title || "",
        contacts: (widget?.data?.contacts || []).map((item, index) => ({ ...item, id: index })),
    });
    const [step, setStep] = useState(WidgetContactEditSteps.CONTACT_LIST);
    const [editedContact, setEditedContact] = useState(null);

    /* Utils */
    const buildPayload = (widgetData) => {
        return {
            widget: {
                ...widget,
                data: {
                    title: widgetData.title,
                    contacts: widgetData.contacts.map((item) => ({
                        type: item.type,
                        order: item.order,
                        details: item.details || {},
                    })),
                }
            },
        }
    }

    /* Handlers */
    const handleSave = () => {
        const payload = buildPayload(data);
        WidgetService.updateWidget(widget.id, payload.widget)
            .then((savedWidget) => {
                onSave(savedWidget);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleCreate = () => {
        const payload = buildPayload(data);
        WidgetService.createWidget(payload.widget)
            .then((savedWidget) => {
                onSave(savedWidget);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleChange = (updatedData) => {
        setData(updatedData);
        onChange({ ...widget, data: updatedData });
    };

    const handleReorder = (orderedItems) => {
        handleChange({
            ...data,
            contacts: orderedItems.map((item, index) => ({ ...item, order: index })),
        });
    };

    const handleContactChange = (updatedContact) => {
        updatedContact.id = editedContact == null
            ? data.contacts.reduce((maxId, item) => Math.max(maxId, item.id), 0) + 1
            : editedContact.id;
        if (editedContact === null) {
            handleReorder([
                ...data.contacts,
                updatedContact
            ]);
        } else {
            handleReorder(
                data.contacts.map((item) => (item.id === updatedContact.id ? updatedContact : item)),
            );
        }
        setEditedContact(updatedContact);
    };

    const handleContactDelete = (id) => {
        handleReorder(data.contacts.filter(item => item.id !== id));
    }

    /* Renders */
    const renderAddContactButton = () => {
        return <ButtonBase
            className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full w-fit"
            iconColor="white"
            icon={<Icons.SvgTemplateAddNewSection color="white" className="w-3" />}
            onClick={() => { setStep(WidgetContactEditSteps.CONTACT_DETAIL); }}
        >
            {_("Add a new quick contact")}
        </ButtonBase>
    }

    const renderPreviousButton = () => {
        return <ButtonBase
            className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full self-end w-fit"
            iconColor="white"
            icon={<Icons.SvgArrowReturnOutline color="white" className="w-3" />}
            onClick={() => { 
                setStep(WidgetContactEditSteps.CONTACT_LIST);
                setEditedContact(null);
            }}
        >
            {_("Previous")}
        </ButtonBase>
    }

    const renderContactIcon = (item) => {
        return contactIconOf({type: item.type});
    }

    return (
        <div className="flex flex-col w-full h-full gap-7">
            {
                {
                    [WidgetContactEditSteps.CONTACT_LIST]: renderAddContactButton,
                    [WidgetContactEditSteps.CONTACT_DETAIL]: renderPreviousButton,
                }[step]()
            }

            {
                {
                    [WidgetContactEditSteps.CONTACT_LIST]: (
                        <div className="flex flex-col h-full gap-7 overflow-hidden">
                            <InputGroup
                                label={_("Section Title")}
                                classGapLabelToField="gap-3.5"
                                name="title"
                                widthInputClass="w-4/6"
                                value={data.title}
                                onChange={(e) => handleChange({ ...data, title: e.target.value })}
                                fontClass="text-black text-[1rem] leading-6 font-normal"
                                required={true}
                                backgroundClass="bg-[#F6F7F8]"
                            />

                            <Reorder.Group className="flex flex-col h-full gap-4 overflow-y-auto" axis="y" onReorder={handleReorder} values={data.contacts}>
                                {
                                    data.contacts.map((item) => (
                                        <Reorder.Item key={item.id} value={item}>
                                            <div className="bg-[#F6F7F8] rounded-[0.625rem] flex justify-between px-5 py-2.5">
                                                <div className="flex justify-center items-center align-center gap-2.5">
                                                    <Icons.SvgTemplateSectionListDraggable />
                                                    {renderContactIcon(item)}
                                                    <div className="flex flex-col gap-1">
                                                        <p className="text-sm">{ labelPreviewOf(item) }</p>
                                                    </div>
                                                </div>

                                                <div className="flex justify-center items-center align-center gap-5">
                                                    <ButtonBase
                                                        onClick={() => addPopup(<PopupItemDelete handleDelete={() => handleContactDelete(item.id)} />)}
                                                        className="bg-white text-[#FF0000] gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-2"
                                                    >
                                                        <Icons.SvgTrashOutline className="h-2.5" color="#FF0000" />
                                                        {_("Delete")}
                                                    </ButtonBase>

                                                    <ButtonBase className="bg-black text-white gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-1.5"
                                                        onClick={() => {
                                                            setEditedContact(item);
                                                            setStep(WidgetContactEditSteps.CONTACT_DETAIL);
                                                        }}
                                                    >
                                                        {_("Edit")}
                                                    </ButtonBase>
                                                </div>
                                            </div>
                                        </Reorder.Item>
                                    ))
                                }
                            </Reorder.Group>
                        </div>
                    ),
                    [WidgetContactEditSteps.CONTACT_DETAIL]: <ContactDetailEdit contact={editedContact} onChange={handleContactChange} />,
                }[step]
            }
        </div>
    );
})

const ContactDetailEdit = ({ contact, onChange }) => {
    /* Hooks */
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    
    /* States */
    const [data, setData] = useState({
        ...contact,
        type: contact?.type,
        details: contact?.details || {},
    });

    /* Effects */
    useEffect(() => {
        if (!!data.type) {
            onChange(data);
        }
    }, [data]);

    /* Handlers */
    const handleDataChange = (key, value, newValueLambda) => {
        setData((prevData) => {
            if (newValueLambda) {
                return newValueLambda(prevData);
            }
            return {
                ...prevData,
                [key]: value,
            }
        });
    };

    const handleChangeType = () => {
        addPopup(<PopupSelectorCTAType
            selectedType={data.type}
            setSelectedType={ (type) => handleDataChange("type", type) }
        />)
    }

    /* Renders */
    const renderThumbnail = () => {
        return contactIconOf({type: data.type, props: {className: "w-16 h-16 rounded-[0.875rem]"}})
               || <div className="w-16 h-16 rounded-[0.875rem] bg-gray-100"></div>
    }

    const renderContactDetails = () => {
        if (!data.type) return null;

        const defaultContactDetails = null;

        const contactDetails = {
            [CONTACT_TYPES.TEL]: <>
                <div className="flex gap-3.5 items-center">
                    <p className="text-gray-900 text-sm font-medium">{_("Do you want to use a unique phone number for all the team members ?")}</p>
                    <ButtonToggle
                        onChange={() => {
                            handleDataChange(null, null, (prevData) => ({
                                ...prevData,
                                details: {
                                    ...prevData.details || {},
                                    commonValue: !prevData.details.commonValue,
                                }
                            }))
                        }}
                        isToggled={data.details.commonValue || false}
                    />
                </div>
                {data.details.commonValue && (
                    <TextField
                        name="phoneNumber"
                        type="text"
                        label="Phone Number"
                        value={data.details.value || ""}
                        onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                            ...prevData,
                            details: {
                                ...prevData.details || {},
                                value: e.target.value,
                            }
                        }))}
                        required={true}
                    />
                )}
            </>,
            [CONTACT_TYPES.MSG]: <>
                <div className="flex gap-3.5 items-center">
                    <p className="text-gray-900 text-sm font-medium">{_("Do you want to use a unique phone number for all the team members ?")}</p>
                    <ButtonToggle
                        onChange={() => {
                            handleDataChange(null, null, (prevData) => ({
                                ...prevData,
                                details: {
                                    ...prevData.details || {},
                                    commonValue: !prevData.details.commonValue,
                                }
                            }))
                        }}
                        isToggled={data.details.commonValue || false}
                    />
                </div>
                {data.details.commonValue && (
                    <TextField
                        name="phoneNumber"
                        type="text"
                        label="Phone Number"
                        value={data.details.value || ""}
                        onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                            ...prevData,
                            details: {
                                ...prevData.details || {},
                                value: e.target.value,
                            }
                        }))}
                        required={true}
                    />
                )}
            </>,
            [CONTACT_TYPES.MAIL]: <>
                <div className="flex gap-3.5 items-center">
                    <p className="text-gray-900 text-sm font-medium">{_("Do you want to use a unique mail address for all the team members ?")}</p>
                    <ButtonToggle
                        onChange={() => {
                            handleDataChange(null, null, (prevData) => ({
                                ...prevData,
                                details: {
                                    ...prevData.details || {},
                                    commonValue: !prevData.details.commonValue,
                                }
                            }))
                        }}
                        isToggled={data.details.commonValue || false}
                    />
                </div>
                {data.details.commonValue && (
                    <TextField
                        name="mailAddress"
                        type="text"
                        label="Mail Address"
                        value={data.details.value || ""}
                        onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                            ...prevData,
                            details: {
                                ...prevData.details || {},
                                value: e.target.value,
                            }
                        }))}
                        required={true}
                    />
                )}
            </>,
            [CONTACT_TYPES.WEB]: <>
                <TextField
                    name="websiteLabel"
                    type="text"
                    label="Website Label"
                    value={data.details.label || ""}
                    onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                        ...prevData,
                        details: {
                            ...prevData.details || {},
                            label: e.target.value,
                        }
                    }))}
                    required={true}
                />
                <TextField
                    name="websiteLink"
                    type="text"
                    label="Website Link"
                    value={data.details.link || ""}
                    onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                        ...prevData,
                        details: {
                            ...prevData.details || {},
                            link: e.target.value,
                        }
                    }))}
                    required={true}
                />
            </>,
            [CONTACT_TYPES.LOCATION]: <>
                <TextField
                    name="address"
                    type="text"
                    label="Address"
                    value={data.details.address || ""}
                    onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                        ...prevData,
                        details: {
                            ...prevData.details || {},
                            address: e.target.value,
                        }
                    }))}
                    required={true}
                />
                <TextField
                    name="cityAndZip"
                    type="text"
                    label="City and ZIP"
                    value={data.details.cityAndZip || ""}
                    onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                        ...prevData,
                        details: {
                            ...prevData.details || {},
                            cityAndZip: e.target.value,
                        }
                    }))}
                    required={true}
                />
            </>,
        };

        return contactDetails[data.type]
               || defaultContactDetails;
    }

    return (
        <div className="w-full h-full gap-7 flex flex-col">
            <label className="flex flex-col">
                <span className="flex text-sm font-medium after:content-['*'] after:ml-0 after:text-red-500">
                    {_("Quick Contact")}
                </span>
                <div className="flex flex-col m-2">
                    {
                        renderThumbnail()
                    }
                </div>
                <ButtonBase onClick={ handleChangeType } className="bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full">
                    {
                        _("Change type")
                    }
                </ButtonBase>
            </label>
            { renderContactDetails() }
        </div>
    );
}

export default WidgetContactEdit;