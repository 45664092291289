import React from 'react';

function MenuDropdown({ toggle, links, position }) {
    return (
        <div className={`absolute ${toggle ? 'flex' : 'hidden'} ${position} bg-white min-w-[17.5rem] rounded-[1.25rem] shadow-lg z-30`}>
            <ul className="list-none flex flex-col justify-end items-center flex-1 shadow-sm">
                {
                    links.map((link, index) => (
                        <li
                            onClick={link.onClick}
                            className={`
                              ${index === links.length - 1 ? "rounded-bl-[1.25rem] rounded-br-[1.25rem]" : ""} 
                              ${index === 0 ? "rounded-tl-[1.25rem] rounded-tr-[1.25rem]" : ""} 
                              font-normal select-none text-sm w-full flex justify-center gap-2 px-4 py-3.5 hover:border hover:border-2
                            `}
                            key={index}
                        >
                            {!!link.icon ? React.cloneElement(link.icon, { color: link.iconColor }) : null}
                            <p className={`text-[${link.iconColor}]`}>{link.label}</p>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

export default MenuDropdown;