import React, {useEffect, useState} from 'react';
import PopupBase from "../PopupBase";
import InputSearch from "../../Layouts/InputSearch";
import {Icons} from "../../../assets/Svgs";
import Table from "../../Layouts/Table";
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupFilterViewByDivisionConfirm from "./PopupFilterViewByDivisionConfirm";
import DivisionChartsService from "../../../services/Division/DivisionChartsService";

function PopupFilterViewByDivision({isTeamAssign, listChecked, selectedDivision: selectedDivisionParent, onSelectedDivision}) {
    const { removePopup, addPopup } = usePopup();
    const { t: _ } = useTranslation();
    const [ loading, setLoading ] = useState(true);
    const [ selectedDivision, setSelectedDivision ] = useState(selectedDivisionParent ?? "");
    const [ value, setValue ] = useState("");
    const [ divisions, setDivisions ] = useState([]);
    const [ connectionsOriginal, setConnectionsOriginal ] = useState([]);

    const onSearch = (listToSearch, keyword) => {
        if (!keyword) return listToSearch;
        return listToSearch.filter(item => {
            return item.template[0].name.toLowerCase().includes(keyword.toLowerCase());
        });
    }    

    useEffect(() => {
        onSelectedDivision && onSelectedDivision(selectedDivision);
    }, [selectedDivision, onSelectedDivision]);

    useEffect(() => {
        setLoading(true);
        DivisionChartsService.getListCharts()
            .then(res => {
                setDivisions(res);
                setConnectionsOriginal(res);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <PopupBase
            title={
                !!isTeamAssign
                    ? listChecked.length > 1
                        ? _("Choose a division to assign to the selected members to:")
                        : _("Choose a division to assign to this team member to:")
                    : _("Filter your view by divisions :")
            }
            footerButtons={
                [
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                ]
            }>
            <div className="w-full flex flex-col justify-center align-center">
                <div className="grid w-full items-center">
                    <div className="grid gap-7 px-48">

                        <InputSearch
                            onSearch={onSearch}
                            value={value}
                            setValue={setValue}
                            setList={setDivisions}
                            listToSearchOriginal={connectionsOriginal}
                            icon={<Icons.SvgTeamUserSearch color="#D1D5DB" className="absolute top-1/2 left-3 -translate-y-1/2 h-4"/>}
                            placeholder={"Search division"}
                            isFlexible={false}
                        />

                        <div className="w-96 h-fit overflow-auto">
                            <Table
                                roundedClass="rounded-[0.625rem]"
                                tdheightClass="h-14"
                                trColorClass="bg-white"
                                columns={[
                                    {
                                        label: _(""),
                                        sortable: false,
                                    },
                                ]}
                                rows={divisions.map((division, index) =>
                                    ({
                                        id: index,
                                        data: [
                                            {
                                                value: <div
                                                    onClick={() => addPopup(
                                                        <PopupFilterViewByDivisionConfirm
                                                            isTeamAssign={true}
                                                            listChecked={listChecked}
                                                            division={division.template[0].name}
                                                            onConfirmDivision={setSelectedDivision}
                                                        />)
                                                    }
                                                    className={`text-[#4B5563] flex items-center justify-center gap-[1.5rem]`}
                                                >
                                                    <span>{_(division.template[0].name)}</span>
                                                </div>,
                                            },
                                        ]
                                    })
                                )}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </PopupBase>
    );
}

export default PopupFilterViewByDivision;