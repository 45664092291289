import React from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";
import { usePopup } from '../../../services/PopupService';

function PopupConfirmDeleteRefCard({handleBack, handleDelete}) {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    return (
        <PopupBase
            title={_("Are you sure you want to delete this card?")}
            footerButtons={
                [
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[23.375rem]",
                        onClick: handleBack,
                    },
                    {
                        label: _("Yes, delete this card"),
                        background: "bg-[#FF0000]",
                        color: "text-white",
                        className: "w-[23.375rem]",
                        onClick: () => {
                            handleDelete();
                            removePopup();
                        },
                    }
                ]
            }>
        </PopupBase>
    );
}

export default PopupConfirmDeleteRefCard;