import React from 'react';

function SvgProfileModelContactMail({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-5" } `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1665 17.7084H5.83317C2.7915 17.7084 1.0415 15.9584 1.0415 12.9167V7.08342C1.0415 4.04175 2.7915 2.29175 5.83317 2.29175H14.1665C17.2082 2.29175 18.9582 4.04175 18.9582 7.08342V12.9167C18.9582 15.9584 17.2082 17.7084 14.1665 17.7084ZM5.83317 3.54175C3.44984 3.54175 2.2915 4.70008 2.2915 7.08342V12.9167C2.2915 15.3001 3.44984 16.4584 5.83317 16.4584H14.1665C16.5498 16.4584 17.7082 15.3001 17.7082 12.9167V7.08342C17.7082 4.70008 16.5498 3.54175 14.1665 3.54175H5.83317Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M9.99998 10.725C9.29998 10.725 8.59165 10.5083 8.04998 10.0666L5.44164 7.98331C5.17498 7.76664 5.12498 7.37497 5.34165 7.10831C5.55831 6.84164 5.94998 6.79164 6.21665 7.00831L8.82497 9.09164C9.45831 9.59998 10.5333 9.59998 11.1666 9.09164L13.775 7.00831C14.0416 6.79164 14.4416 6.83331 14.65 7.10831C14.8666 7.37497 14.825 7.77498 14.55 7.98331L11.9416 10.0666C11.4083 10.5083 10.7 10.725 9.99998 10.725Z" fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgProfileModelContactMail;