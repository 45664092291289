import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import { Icons } from "../../../assets/Svgs";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import { useTranslation } from "../../../services/i18n/i18nService";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import FieldCopy from "../../../components/Layouts/FieldCopy";
import { format } from "date-fns";
import UserService from '../../../services/User/UserService';

function AccountView() {
    const { t: _ } = useTranslation();
    const navigate = useNavigate();
    const { acc, custom } = useParams();
    const [loading, setLoading] = useState(true);
    const [account, setAccount] = useState({});

    const deleteAccount = () => {
        UserService.deleteUser(parseInt(acc))
            .then(() => {
                navigate(getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER"]).replace(":custom", custom), { replace: true });
            })
            .catch(err => {
                console.error(err);
            });
    }

    const buttons = [
        {
            label: "Edit",
            className: "bg-white text-black cursor-pointer",
            iconColor: "#000000",
            icon: <Icons.SvgBrushOutline className="h-3" />,
            onClick: () => navigate('./edit', { replace: true }),
        },
        {
            label: "Send a new password invitation",
            className: "bg-white text-black",
            iconColor: "#000000",
            icon: <Icons.SvgAccountKeyOutline className="h-3" />,
        },
        {
            label: "Delete",
            className: "bg-white text-[#FF0000]",
            iconColor: "#FF0000",
            icon: <Icons.SvgTrashOutline className="h-3" />,
            onClick: deleteAccount,
        }
    ];

    const inputs = [
        {
            id: 1,
            name: "full_name",
            label: "Name",
            canCopy: true,
            formatField: (data) => `${data.first_name} ${data.last_name}`
        },
        {
            id: 3,
            name: "email",
            label: "Business Mail",
            canCopy: true,
        },
        {
            id: 4,
            name: "role",
            label: "Role",
            canCopy: true,
            formatField: (data) => data.membership.job_title
        },
        {
            id: 2,
            name: "date_joined",
            label: "Created Date",
            canCopy: true,
            formatField: (data) => format(new Date(data.date_joined), 'dd/MM/yyyy')
        },
        {
            id: 5,
            name: "note",
            label: "Note",
            canCopy: true,
        },
    ];

    useEffect(() => {
        setLoading(true);
        UserService.getUserById(acc)
            .then(resAcc => {
                setAccount(resAcc);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [acc]);

    return (
        <div className="flex flex-col h-screen p-7 gap-7">
            <header className=" z-10">
                <div className="flex justify-between">
                    <Link to={getFullPath(["ROOT", "CUSTOMERS"])} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Customers")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    <Link to={getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER"]).replace(":custom", custom)} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("View")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    <Link to={getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER"]).replace(":custom", custom)} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Accounts")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("View")}
                </div>
                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER"]).replace(":custom", custom)} className="flex text-sm justify-center gap-[0.438rem]">
                        <ButtonBack isFlexible={false} />
                    </Link>
                </div>
            </header>
            <div className="flex-1 min-h-0 overflow-auto">
                <div className="md:flex w-full sm:flex-row gap-7 align-center justify-center rounded-[1.25rem]">
                    {buttons.map((button, index) => (
                        <ButtonBase
                            key={index}
                            iconColor={button.iconColor}
                            icon={button.icon}
                            className={`${button.className} border border-[#D1D5DB] w-full text-sm font-medium py-3 px-6 rounded-full`}
                            onClick={button.onClick}
                        >
                            {_(button.label)}
                        </ButtonBase>
                    ))}
                </div>
                <div className="w-full bg-white grid grid-rows-3 grid-flow-col gap-7">
                    {
                        !loading && inputs.map((input) => (
                            <FieldCopy key={input.id} label={_(input.label)} canCopy={input.canCopy}>
                                {input.formatField ? input.formatField(account) : account[input.name]}
                            </FieldCopy>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default AccountView;