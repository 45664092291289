import React from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";

function PopupUpdatedAvatar() {
    const { clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={_("The profile picture has been updated.")}
            footerButtons={
                [
                    {
                        label: _("Confirm and close"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: clearAllPopups,
                    }
                ]
            }>
        </PopupBase>
    );
}

export default PopupUpdatedAvatar;