import React from 'react';

function ButtonFlexible({onClick, icon, children}) {
    return (
        <button onClick={onClick} className="group h-10 rounded-full text-sm">
            <div className=" shadow-sm flex justify-center group-hover:border group-hover:border-2
                group-hover:gap-[0.438rem] items-center right-0 bg-white w-16 top-0 h-full rounded-full
                transition-all duration-200 ease-in group-hover:w-28 group-hover:-right-0"
            >
                { !!icon && React.cloneElement(icon, { className: "h-3 group-hover:w-3" }) }
                <div className="hidden group-hover:flex group-hover:w-fit">
                    {children}
                </div>
            </div>
        </button>
    );
}

export default ButtonFlexible;