import React from 'react';

function SvgConnectionPopupContactAdd({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-12" } `} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" strokeWidth="8"/>
            <path
                d="M28 28.75C24.83 28.75 22.25 26.17 22.25 23C22.25 19.83 24.83 17.25 28 17.25C31.17 17.25 33.75 19.83 33.75 23C33.75 26.17 31.17 28.75 28 28.75ZM28 18.75C25.66 18.75 23.75 20.66 23.75 23C23.75 25.34 25.66 27.25 28 27.25C30.34 27.25 32.25 25.34 32.25 23C32.25 20.66 30.34 18.75 28 18.75Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M19.4102 38.75C19.0002 38.75 18.6602 38.41 18.6602 38C18.6602 33.73 22.8502 30.25 28.0002 30.25C29.0102 30.25 30.0001 30.38 30.9601 30.65C31.3601 30.76 31.5902 31.17 31.4802 31.57C31.3702 31.97 30.9601 32.2 30.5602 32.09C29.7402 31.86 28.8802 31.75 28.0002 31.75C23.6802 31.75 20.1602 34.55 20.1602 38C20.1602 38.41 19.8202 38.75 19.4102 38.75Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M34 38.75C32.82 38.75 31.7 38.31 30.83 37.52C30.48 37.22 30.17 36.85 29.93 36.44C29.49 35.72 29.25 34.87 29.25 34C29.25 32.75 29.73 31.58 30.59 30.69C31.49 29.76 32.7 29.25 34 29.25C35.36 29.25 36.65 29.83 37.53 30.83C38.31 31.7 38.75 32.82 38.75 34C38.75 34.38 38.7 34.76 38.6 35.12C38.5 35.57 38.31 36.04 38.05 36.45C37.22 37.87 35.66 38.75 34 38.75ZM34 30.75C33.11 30.75 32.29 31.1 31.67 31.73C31.08 32.34 30.75 33.14 30.75 34C30.75 34.59 30.91 35.17 31.22 35.67C31.38 35.95 31.59 36.2 31.83 36.41C32.43 36.96 33.2 37.26 34 37.26C35.13 37.26 36.2 36.66 36.78 35.69C36.95 35.41 37.08 35.09 37.15 34.78C37.22 34.52 37.25 34.27 37.25 34.01C37.25 33.21 36.95 32.44 36.41 31.84C35.81 31.14 34.93 30.75 34 30.75Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M35.4998 34.73H32.5098C32.0998 34.73 31.7598 34.39 31.7598 33.98C31.7598 33.57 32.0998 33.23 32.5098 33.23H35.4998C35.9098 33.23 36.2498 33.57 36.2498 33.98C36.2498 34.39 35.9098 34.73 35.4998 34.73Z"
                fill={!!color ? color : "#0400BD"}/>
            <path
                d="M34 36.26C33.59 36.26 33.25 35.92 33.25 35.51V32.52C33.25 32.11 33.59 31.77 34 31.77C34.41 31.77 34.75 32.11 34.75 32.52V35.51C34.75 35.93 34.41 36.26 34 36.26Z"
                fill={!!color ? color : "#0400BD"}/>
        </svg>
    );
}

export default SvgConnectionPopupContactAdd;