import React from 'react';

function SvgFooterProfile({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 15.9375C11.0375 15.9375 7.8125 12.7125 7.8125 8.75C7.8125 4.7875 11.0375 1.5625 15 1.5625C18.9625 1.5625 22.1875 4.7875 22.1875 8.75C22.1875 12.7125 18.9625 15.9375 15 15.9375ZM15 3.4375C12.075 3.4375 9.6875 5.825 9.6875 8.75C9.6875 11.675 12.075 14.0625 15 14.0625C17.925 14.0625 20.3125 11.675 20.3125 8.75C20.3125 5.825 17.925 3.4375 15 3.4375Z" fill={!!color ? color : "#0400BD"}/>
            <path d="M19.7749 28.4375C19.2999 28.4375 18.8499 28.2624 18.5249 27.9374C18.1374 27.5499 17.9624 26.9875 18.0499 26.4L18.2874 24.7125C18.3499 24.275 18.6125 23.7625 18.925 23.4375L23.3499 19.0125C25.1249 17.2375 26.6875 18.25 27.45 19.0125C28.1 19.6625 28.4374 20.3624 28.4374 21.0624C28.4374 21.7749 28.1125 22.4374 27.45 23.0999L23.0249 27.525C22.7124 27.8375 22.1875 28.1 21.75 28.1625L20.0624 28.3999C19.9624 28.4249 19.8749 28.4375 19.7749 28.4375ZM25.3874 19.8999C25.1624 19.8999 24.9624 20.05 24.6749 20.3375L20.25 24.7625C20.2125 24.8 20.1499 24.9249 20.1499 24.9749L19.9249 26.5374L21.4874 26.3125C21.5374 26.3 21.6624 26.2375 21.6999 26.2L26.125 21.7749C26.325 21.5749 26.5624 21.2874 26.5624 21.0624C26.5624 20.8749 26.4125 20.6125 26.125 20.3375C25.825 20.0375 25.5999 19.8999 25.3874 19.8999Z" fill={!!color ? color : "#0400BD"}/>
            <path d="M26.1502 24.0249C26.0627 24.0249 25.9752 24.0125 25.9002 23.9875C24.2502 23.525 22.9377 22.2125 22.4752 20.5625C22.3377 20.0625 22.6253 19.55 23.1253 19.4125C23.6253 19.275 24.1377 19.5624 24.2752 20.0624C24.5627 21.0874 25.3753 21.9 26.4003 22.1875C26.9003 22.325 27.1878 22.8499 27.0503 23.3374C26.9378 23.7499 26.5627 24.0249 26.1502 24.0249Z" fill={!!color ? color : "#0400BD"}/>
            <path d="M4.2627 28.4375C3.7502 28.4375 3.3252 28.0125 3.3252 27.5C3.3252 22.1625 8.56273 17.8125 15.0002 17.8125C16.3627 17.8125 17.7127 18.0125 18.9752 18.3875C19.4752 18.5375 19.7502 19.0625 19.6002 19.55C19.4502 20.05 18.9252 20.325 18.4377 20.175C17.3377 19.85 16.1877 19.675 15.0002 19.675C9.60023 19.675 5.2002 23.175 5.2002 27.4875C5.2002 28.0125 4.7752 28.4375 4.2627 28.4375Z" fill={!!color ? color : "#0400BD"}/>
        </svg>
    );
}

export default SvgFooterProfile;