import React, {useEffect, useState} from 'react';
import {useTranslation} from "../../../../services/i18n/i18nService";
import {usePopup} from "../../../../services/PopupService";
import PopupBase from "../../../../components/Popups/PopupBase";
import SquareView from "../../../../components/Layouts/SelectorSquareView";
import {Icons} from "../../../../assets/Svgs";
import PopupAssignPersonSelected from "../../../../components/Popups/TemplateFilter/PopupAssignPersonSelected";
import PopupShowAllTeamMembers from "../../../../components/Popups/TemplateFilter/PopupShowAllTeamMembers";
import PopupFilterViewByDivision from "../../../../components/Popups/FilterView/PopupFilterViewByDivision";

function PopupTemplateFilterPersonView({ title, selectedView: selectedViewParent, onSelectedViewChange }) {
    const { t: _ } = useTranslation();
    const { removePopup, addPopup } = usePopup();
    const [ selectedView, setSelectedView ] = useState( selectedViewParent ?? "templates" );

    useEffect(() => {
        onSelectedViewChange && onSelectedViewChange(selectedView);
    }, [selectedView, onSelectedViewChange]);

    return (
        <PopupBase
            title={ _(title) }
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                ]
            }>

            <div className="flex justify-center align-center items-center">
                <div className="grid gap-4 w-full items-center">
                    <div className="grid grid-cols-3 gap-7 px-24">

                        <SquareView
                            className="gap-7"
                            isSelected={ selectedView === "templates" }
                            onClick={() => { setSelectedView("templates"); addPopup(<PopupAssignPersonSelected title="Filter your view by templates :" />)  }}
                            icon={<Icons.SvgSelectorTemplate className="h-[3rem]"/>}
                            viewName={_("Templates")}
                        />

                        <SquareView
                            className="gap-7"
                            isSelected={ selectedView === "Division" }
                            onClick={() => { setSelectedView("Division"); addPopup(<PopupFilterViewByDivision  />) }}
                            icon={<Icons.SvgTemplateTwoPersonFill className="h-[3rem]"/>}
                            viewName={_("Division")}
                        />

                        <SquareView
                            className="gap-7"
                            isSelected={ selectedView === "general" }
                            onClick={() => {setSelectedView("general"); addPopup(<PopupShowAllTeamMembers />) }}
                            icon={<Icons.SvgSelectorGeneral className="h-[3rem]"/>}
                            viewName={_("General")}
                        />

                    </div>
                </div>
            </div>
        </PopupBase>
    );
}

export default PopupTemplateFilterPersonView;