import React from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import { Icons } from "../../../assets/Svgs";
import PopupBase from "../PopupBase";
import SvgTeamWarningFilled from "../../../assets/Svgs/Svg.TeamWarningFilled";

function PopupErrorPinCode() {
    const { clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: clearAllPopups,
                    }
                ]
            }>
            <div className="flex flex-col justify-center align-center" >
                <Icons.SvgTeamWarningFilled />
                <header className="flex justify-center items-center text-lg font-medium">
                    {_("Not matching refefrence number or PIN code. Please check if the card is not already assigned or if there isn’t any mistake.")}
                </header>
            </div>
        </PopupBase>
    );
}

export default PopupErrorPinCode;