import React from 'react';
import WidgetSection from "../../Layouts/WidgetSection";

function WidgetPaypalView(
    {
        widget,
        displayEditAction,
        onEdit,
    }) {

    const widgetData = widget.data;
    const widgetTitle = widgetData?.title;

    return (
        <WidgetSection title={widgetTitle} displayEditAction={displayEditAction} onEdit={onEdit}>
            A venir
        </WidgetSection>
    );
}

export default WidgetPaypalView;