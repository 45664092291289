import React from 'react';

function SvgProfilePopupPicture({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-12" } `} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" strokeWidth="8"/>
            <path d="M25 26.75C23.48 26.75 22.25 25.52 22.25 24C22.25 22.48 23.48 21.25 25 21.25C26.52 21.25 27.75 22.48 27.75 24C27.75 25.52 26.52 26.75 25 26.75ZM25 22.75C24.31 22.75 23.75 23.31 23.75 24C23.75 24.69 24.31 25.25 25 25.25C25.69 25.25 26.25 24.69 26.25 24C26.25 23.31 25.69 22.75 25 22.75Z" fill={!!color ? color : "#0400BD"}/>
            <path d="M31 38.75H25C19.57 38.75 17.25 36.43 17.25 31V25C17.25 19.57 19.57 17.25 25 17.25H29C29.41 17.25 29.75 17.59 29.75 18C29.75 18.41 29.41 18.75 29 18.75H25C20.39 18.75 18.75 20.39 18.75 25V31C18.75 35.61 20.39 37.25 25 37.25H31C35.61 37.25 37.25 35.61 37.25 31V26C37.25 25.59 37.59 25.25 38 25.25C38.41 25.25 38.75 25.59 38.75 26V31C38.75 36.43 36.43 38.75 31 38.75Z" fill={!!color ? color : "#0400BD"}/>
            <path d="M31.67 25.7499C31.28 25.7499 30.92 25.6099 30.66 25.3399C30.35 25.0299 30.2 24.5699 30.27 24.0999L30.47 22.7099C30.52 22.3599 30.73 21.9299 30.99 21.6799L34.62 18.0499C36.07 16.5999 37.33 17.4299 37.95 18.0499C38.54 18.6399 38.81 19.2599 38.75 19.8999C38.7 20.4099 38.44 20.8999 37.95 21.3799L34.32 25.0099C34.07 25.2599 33.64 25.4699 33.29 25.5299L31.9 25.7299C31.82 25.7499 31.74 25.7499 31.67 25.7499ZM35.67 19.1199L32.04 22.7499C32.01 22.7799 31.96 22.8899 31.95 22.9399L31.76 24.2399L33.07 24.0599C33.11 24.0499 33.22 23.9999 33.26 23.9599L36.89 20.3299C37.11 20.1099 37.24 19.9099 37.25 19.7599C37.27 19.5499 37.06 19.2899 36.89 19.1199C36.37 18.5999 36.13 18.6599 35.67 19.1199Z" fill={!!color ? color : "#0400BD"}/>
            <path d="M36.9 22.1298C36.83 22.1298 36.76 22.1198 36.7 22.0998C35.36 21.7198 34.29 20.6498 33.91 19.3098C33.8 18.9098 34.03 18.4998 34.43 18.3898C34.83 18.2798 35.24 18.5098 35.35 18.9098C35.59 19.7498 36.26 20.4298 37.11 20.6698C37.51 20.7798 37.74 21.1998 37.63 21.5898C37.52 21.9098 37.22 22.1298 36.9 22.1298Z" fill={!!color ? color : "#0400BD"}/>
            <path d="M18.67 35.7001C18.43 35.7001 18.19 35.5801 18.05 35.3701C17.82 35.0301 17.91 34.5601 18.25 34.3301L23.18 31.0201C24.26 30.3001 25.75 30.3801 26.73 31.2101L27.06 31.5001C27.56 31.9301 28.41 31.9301 28.9 31.5001L33.06 27.9301C34.12 27.0201 35.79 27.0201 36.86 27.9301L38.49 29.3301C38.8 29.6001 38.84 30.0701 38.57 30.3901C38.3 30.7001 37.83 30.7401 37.51 30.4701L35.88 29.0701C35.38 28.6401 34.53 28.6401 34.04 29.0701L29.88 32.6401C28.82 33.5501 27.15 33.5501 26.08 32.6401L25.75 32.3501C25.29 31.9601 24.53 31.9201 24.02 32.2701L19.1 35.5801C18.96 35.6601 18.81 35.7001 18.67 35.7001Z" fill={!!color ? color : "#0400BD"}/>
        </svg>
    );
}

export default SvgProfilePopupPicture;