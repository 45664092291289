import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "../../services/i18n/i18nService";
import ButtonGradient from "./ButtonGradient";
import MenuDropdown from "../Layouts/MenuDropdown";

function ButtonTranslate() {
    const { t: _, language, languageLabels, updateLanguage } = useTranslation();
    const [toggle, setToggle] = useState(false);
    const [position, setPosition] = useState('top-full');
    const buttonRef = useRef(null);

    useEffect(() => {
        // Lorsque le rouleau de menu sort de l'écran, nous changeons la direction de l'affichage.
        if (toggle && buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            if (rect.bottom + 200 > viewportHeight) {
                setPosition('bottom-full');
            } else {
                setPosition('top-full');
            }
        }
    }, [toggle]);

    const links = Object.keys(languageLabels()).map((lang) => ({
        label: languageLabels()[lang].label,
        icon: languageLabels()[lang].icon,
        iconColor: '#000000',
        onClick: () => {
            updateLanguage(lang).catch((err) => {
                console.error(err);
            });
        }
    }));

    return (
        <div className="relative inline-block" ref={buttonRef}>
            <ButtonGradient
                roundedClass="rounded-full"
                textColor="text-black"
                icon={languageLabels()[language].icon}
                onClick={() => { setToggle((prev) => !prev) }}
                hasBorder={true}
                colorGradient={`bg-[#9CA3AF]`}
            >
                {languageLabels()[language].label}
            </ButtonGradient>
            <MenuDropdown links={links} toggle={toggle} position={position} />
        </div>
    );
}

export default ButtonTranslate;