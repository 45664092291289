import React, {useState} from 'react';
import InputSearch from "../../Layouts/InputSearch";
import PopupBase from "../PopupBase";
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import ButtonBase from "../../Buttons/ButtonBase";
import {Icons} from "../../../assets/Svgs";
import PopupConfirmAssignTag from "./PopupConfirmAssignTag";
import PopupCreateNewTag from "./PopupCreateNewTag";

const onSearch = (listToSearch, keyword) => {
    if (!keyword) return listToSearch;
    return listToSearch.filter(item => {
        return item.toLowerCase().includes(keyword.toLowerCase());
    });
}

function PopupSelectTag() {
    const { clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const [ value, setValue ] = useState("");
    const [ listOriginal, setListOriginal ] = useState([
        "Tag 1", "Tag 2", "Tag 3", "Tag 4", "Tag 5", "Tag 6"
    ]);
    const [ listTags, setListTags ] = useState([
        "Tag 1", "Tag 2", "Tag 3", "Tag 4", "Tag 5", "Tag 6"
    ]);

    return (
        <PopupBase
            title={_("Select the tag you want to assign to the selected leads:")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[46.375rem]",
                        onClick: clearAllPopups,
                    },
                ]
            }>
            <div className="flex flex-col w-full items-center gap-7">
                <div className="w-4/6 flex gap-7 align-center px-7">
                    <InputSearch
                        className="w-full text-sm focus:outline-none active:outline-none h-10 pl-9 pr-4 rounded-full shadow-lg text-[0.813rem] leading-3 font-medium"
                        onSearch={onSearch}
                        value={value}
                        setValue={setValue}
                        setList={setListTags}
                        listToSearchOriginal={listOriginal}
                        icon={<Icons.SvgLeadsTagOutline color="#D1D5DB" className="absolute top-1/2 left-3 -translate-y-1/2 h-4" />}
                        placeholder={"Search tag or create new one"}
                        isFlexible={false}
                    />

                    <ButtonBase onClick={() => { addPopup( <PopupCreateNewTag  /> )} } className="bg-black text-white text-sm py-3.5 px-6 rounded-full" >
                        {_("Create a new tag")}
                    </ButtonBase>
                </div>

                <div className="flex w-6/12 gap-2.5 flex-wrap">
                    {
                        listTags.map((item, index) => (
                            <ButtonBase key={index} onClick={() => { addPopup( <PopupConfirmAssignTag item={item} /> )}} className="text-sm leading-3 bg-[#D1D5DB] px-3.5 py-1.5 text-[#4B5563] gap-[0.438rem] shadow-sm flex justify-center items-center rounded-[0.625rem] cursor-pointer">
                                {_(item)}
                            </ButtonBase>
                        ))
                    }
                </div>
            </div>
        </PopupBase>
    );
}

export default PopupSelectTag;