import React from 'react';

function TagTemplate(template, bgClass) {
    switch (template) {
        case 'Direction':
            return (
                <span className={`capitalize py-1 px-2 rounded-[0.625rem] text-sm text-[#4B5563] ${!!bgClass ? bgClass : "bg-[#33D933]"}`}>
					{ template }
				</span>
            )
        case 'Vente':
            return (
                <span className="capitalize py-1 px-2 rounded-[0.625rem] text-sm text-[#4B5563] bg-[#EEEFF2]">
					{ template }
				</span>
            )
        default:
            return (
                <span className="capitalize py-1 px-2 rounded-[0.625rem] text-sm text-gray-600 bg-gray-100">
					{ template }
				</span>
            )
    }
}

export default TagTemplate;