import React from 'react';

function SvgProfileReorganizePicture({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5001 18.9584H7.50008C2.97508 18.9584 1.04175 17.0251 1.04175 12.5001V7.50008C1.04175 2.97508 2.97508 1.04175 7.50008 1.04175H12.5001C17.0251 1.04175 18.9584 2.97508 18.9584 7.50008V12.5001C18.9584 17.0251 17.0251 18.9584 12.5001 18.9584ZM7.50008 2.29175C3.65841 2.29175 2.29175 3.65841 2.29175 7.50008V12.5001C2.29175 16.3417 3.65841 17.7084 7.50008 17.7084H12.5001C16.3417 17.7084 17.7084 16.3417 17.7084 12.5001V7.50008C17.7084 3.65841 16.3417 2.29175 12.5001 2.29175H7.50008Z"
                fill={!!color ? color : "#9CA3AF"}/>
            <path
                d="M7.49992 8.95833C6.23325 8.95833 5.20825 7.93333 5.20825 6.66667C5.20825 5.4 6.23325 4.375 7.49992 4.375C8.76659 4.375 9.79159 5.4 9.79159 6.66667C9.79159 7.93333 8.76659 8.95833 7.49992 8.95833ZM7.49992 5.625C6.92492 5.625 6.45825 6.09167 6.45825 6.66667C6.45825 7.24167 6.92492 7.70833 7.49992 7.70833C8.07492 7.70833 8.54159 7.24167 8.54159 6.66667C8.54159 6.09167 8.07492 5.625 7.49992 5.625Z"
                fill={!!color ? color : "#9CA3AF"}/>
            <path
                d="M2.22497 16.4168C2.02497 16.4168 1.82497 16.3168 1.70831 16.1418C1.51664 15.8585 1.59164 15.4668 1.88331 15.2751L5.99164 12.5168C6.89164 11.9085 8.13331 11.9835 8.94997 12.6751L9.22497 12.9168C9.64164 13.2751 10.35 13.2751 10.7583 12.9168L14.225 9.9418C15.1083 9.18346 16.5 9.18346 17.3916 9.9418L18.75 11.1085C19.0083 11.3335 19.0416 11.7251 18.8166 11.9918C18.5916 12.2501 18.2 12.2835 17.9333 12.0585L16.575 10.8918C16.1583 10.5335 15.45 10.5335 15.0333 10.8918L11.5666 13.8668C10.6833 14.6251 9.29164 14.6251 8.39997 13.8668L8.12497 13.6251C7.74164 13.3001 7.10831 13.2668 6.68331 13.5585L2.57497 16.3168C2.46664 16.3835 2.34164 16.4168 2.22497 16.4168Z"
                fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgProfileReorganizePicture;