import React from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {Icons} from "../../../assets/Svgs";

function TemplateSelectorTheme({required, label, selectedTheme, setSelectedTheme}) {
    const { t: _ } = useTranslation();
    const themes = [ "#3867ad", "#000000", "#9CA3AF", "#FFFFFF", "#3AA63E", "#8FD6CA", "#1081FF", "#6922FF", "#F4AFFF", "#FF2D34", "#FFC700" ];

    return (
        <label className="flex flex-col gap-3.5">
            <span
                className={`${required ? 'after:content-[\'*\'] after:ml-0 after:text-red-500' : ''} text-sm font-medium flex text-[#4B5563]`}>
                { _(label) }
            </span>

            <div className="flex gap-3.5">
                {
                    themes.map((theme, index) => (
                        <div key={index}>
                            {
                                selectedTheme === theme ? (
                                    <div className="w-[1.88rem] h-[1.88rem] rounded-full p-0.5 bg-orange-gradient flex items-center justify-center mx-auto">
                                        <div className={`${theme === "#3867ad" ? "bg-[#D1D5DB]" : `bg-[${theme}]`} flex w-full h-full rounded-full justify-center items-center`}>
                                            {theme === "#3867ad" && <Icons.SvgBrushOutline className="h-3 w-4" />}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => { setSelectedTheme(theme) }}
                                        className={`${theme === "#3867ad" ? "bg-[#D1D5DB]" : `${theme === "#FFFFFF" ? "border border-[#9CA3AF]" : `bg-[${theme}]`}`} flex justify-center items-center w-[1.88rem] h-[1.88rem] rounded-full`}
                                    >
                                        {theme === "#3867ad" && <Icons.SvgBrushOutline className="h-3 w-4" />}
                                    </div>

                                )
                            }
                        </div>
                    ))
                }
            </div>
        </label>
    );
}

export default TemplateSelectorTheme;