import React from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {usePopup} from "../../../services/PopupService";
import PopupBase from "../PopupBase";

function PopupSendMailToLeadSelected({listChecked}) {
    const { t: _ } = useTranslation();
    const { clearAllPopups } = usePopup();

    return (

        <PopupBase
            title={
                !!listChecked && listChecked.length > 1
                    ? _(`Are you sure you want to send a mail to the selected leads (${!!listChecked ? listChecked.length : ""} selected) ?`)
                    : _("Are you sure you want to send a mail to this lead ?")
            }
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[24rem]",
                        onClick: clearAllPopups,
                    },
                    {
                        label: _("Confirm and Open the Mailbox"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[24rem]",
                        onClick: clearAllPopups,
                    },
                ]
            }>
        </PopupBase>
    );
}

export default PopupSendMailToLeadSelected;