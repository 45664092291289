import React from 'react';

function SvgAssignOutline({className, color}) {
    return <svg className={`${!!className ? className : "h-3" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.25 9.95V2.05C5.25 1.3 4.93 1 4.135 1H2.115C1.32 1 1 1.3 1 2.05V9.95C1 10.7 1.32 11 2.115 11H4.135C4.93 11 5.25 10.7 5.25 9.95Z" stroke={color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 5.45V2.05C11 1.3 10.68 1 9.885 1H7.865C7.07 1 6.75 1.3 6.75 2.05V5.45C6.75 6.2 7.07 6.5 7.865 6.5H9.885C10.68 6.5 11 6.2 11 5.45Z" stroke={color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 9.95V9.05C11 8.3 10.68 8 9.885 8H7.865C7.07 8 6.75 8.3 6.75 9.05V9.95C6.75 10.7 7.07 11 7.865 11H9.885C10.68 11 11 10.7 11 9.95Z" stroke={color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export default SvgAssignOutline;