import React from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";
import PopupConfirmDelete from "./PopupConfirmDelete";

function PopupDeletePersonSelected({objectToDelete, listChecked}) {
    const { addPopup, clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={
                objectToDelete === "leads"
                    ? listChecked.length > 1
                        ? _("Are you sure you want to delete the selected leads from your database?")
                        : _("Are you sure you want to delete this lead from your database ?")
                    : objectToDelete === "teams"
                        ? listChecked.length > 1
                            ? _("Are you sure you want to delete the selected team members?")
                            : _("Are you sure you want to delete this team member?")
                        : objectToDelete === "division"
                            ? _("Are you sure you want to delete the selected team members from the Division?")
                            : null
            }
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[23rem]",
                        onClick: clearAllPopups,
                    },
                    {
                        label: objectToDelete === "leads"
                            ? listChecked.length > 1
                                ? _("Yes, delete the selected leads")
                                : _("Yes, delete this lead")
                            : objectToDelete === "teams"
                                ? listChecked.length > 1
                                    ? _("Yes, delete the selected team members")
                                    : _("Yes, delete this team member")
                                : objectToDelete === "division"
                                    ? _("Yes, delete the selected team members")
                                    : _("Yes, delete this team member"),
                        background: "bg-[#FF0000]",
                        color: "text-white",
                        className: "w-[23rem]",
                        onClick: () => {
                            addPopup( <PopupConfirmDelete objectToDelete={objectToDelete} listChecked={listChecked} /> )
                        },
                    },
                ]
            }>
        </PopupBase>
    );
}

export default PopupDeletePersonSelected;