import React from 'react';

function SvgTemplateInstagramWhite(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-3" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 11H7.5C10 11 11 10 11 7.5V4.5C11 2 10 1 7.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 7.75C6.9665 7.75 7.75 6.9665 7.75 6C7.75 5.0335 6.9665 4.25 6 4.25C5.0335 4.25 4.25 5.0335 4.25 6C4.25 6.9665 5.0335 7.75 6 7.75Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.81806 3.5H8.82384" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgTemplateInstagramWhite;