import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Icons } from "../../../assets/Svgs";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import PopupSelectorCalendar from "../../../components/Selectors/PopupSelectorCalendar";
import SelectorDate from "../../../components/Selectors/SelectorDate";
import { getFullPath } from "../../../router/Router";
import DivisionChartsService from "../../../services/Division/DivisionChartsService";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import StatisGrid from "../Statistics/components/StatisGrid";
import StatisTopContacts from "../Statistics/components/StatisTopContacts";
import StatisTopFiles from "../Statistics/components/StatisTopFiles";
import StatisTopMedias from "../Statistics/components/StatisTopMedias";
import StatisTopPictures from "../Statistics/components/StatisTopPictures";
import StatisTopVideos from "../Statistics/components/StatisTopVideos";
import StatisTopWebsite from "../Statistics/components/StatisTopWebsite";
import { TopPerfomersCard } from "../Statistics/components/TopPerfomersCard";
import ViewsOverTime from "../Statistics/components/ViewsOverTime";

function DivisionStatistics() {
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const [ generalPerform, setGeneralPerform ] = useState([]);
    const [ divisionName, setDivisionName ] = useState("");
    const [ loading, setLoading ] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        setLoading(true);
        DivisionChartsService.getDivisionChartById(id)
            .then(res => {
                setDivisionName(res.divisionName);
                setGeneralPerform(res.GENERAL_PERFORMERS);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [generalPerform]);

    return (
        <div className="p-7 flex flex-col gap-7">
            <header className=" flex justify-between items-center border-gray-200">
                <div className="text-xl gap-3 flex items-center">
                    <div className="text-xl">{_("Statistics")}</div>
                    <Icons.SvgArrowRightOutline color="#000000" />

                    <Link to={ getFullPath(["ROOT", "DIVISIONS"]) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Division")}</p>
                    </Link>

                    <Icons.SvgArrowRightOutline color="#000000" />
                    <div className="text-xl">{_(divisionName)}</div>
                </div>


                <div className="flex items-center gap-7 text-sm">
                    <SelectorDate isFlexible={true} onClick={() => addPopup(<PopupSelectorCalendar />)}/>

                    <Link to={ getFullPath(["ROOT", "DIVISIONS", "EDIT_DIVISION"]).replace(":id", id) }>
                        <ButtonBack isFlexible={false} />
                    </Link>
                </div>
            </header>

            <div className="flex-1 overflow-y-auto gap-7 flex-col md:flex-row">
                {
                    generalPerform[0] && (
                        <div className="flex flex-col gap-7">
                            <StatisGrid datas={ generalPerform[0].chart } grids={ generalPerform[0].grids } />

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-7 h-auto">
                                <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                    <ViewsOverTime
                                        row
                                        datas={ generalPerform[0].viewsOverTime }
                                        value={ generalPerform[0].value }
                                        increase={ generalPerform[0].increase }
                                        title={_("Profile Views Over Time")}
                                        displayViewSelector={true}
                                    />
                                </div>

                                <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                    <TopPerfomersCard datas={ generalPerform[0].performs.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5) } />
                                </div>
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 h-auto">
                                <StatisTopWebsite datas={ generalPerform[0].websites.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5) } />
                                <StatisTopPictures datas={ generalPerform[0].pictures.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5) } />
                                <StatisTopMedias datas={ generalPerform[0].medias.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5) } />
                                <StatisTopFiles datas={ generalPerform[0].downloads.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5) } />
                                <StatisTopVideos datas={ generalPerform[0].videos.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5) } />
                                <StatisTopContacts datas={ generalPerform[0].contacts.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5) } />
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default DivisionStatistics;