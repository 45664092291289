import React from 'react';

function SvgLeadsTagClose({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-2" } `} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00002 6.92971L1.07031 1" stroke={!!color ? color : "black"} strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M6.92971 1.07031L1 7.00002" stroke={!!color ? color : "black"} strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgLeadsTagClose;