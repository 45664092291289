import React from 'react';

function SvgSidebarStatisticsAdmin({className, color}) {
    return <svg className={`${!!className ? className : "h-4 w-4" } `} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.33594 14.8125H14.6693" stroke={!!color ? color : "#4B5563"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.5 2.8138V14.8138H9.5V2.8138C9.5 2.08047 9.2 1.48047 8.3 1.48047H7.7C6.8 1.48047 6.5 2.08047 6.5 2.8138Z" stroke={!!color ? color : "#4B5563"} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 6.8138V14.8138H4.66667V6.8138C4.66667 6.08047 4.4 5.48047 3.6 5.48047H3.06667C2.26667 5.48047 2 6.08047 2 6.8138Z" stroke={!!color ? color : "#4B5563"} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.3359 10.1458V14.8125H14.0026V10.1458C14.0026 9.4125 13.7359 8.8125 12.9359 8.8125H12.4026C11.6026 8.8125 11.3359 9.4125 11.3359 10.1458Z" stroke={!!color ? color : "#4B5563"} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export default SvgSidebarStatisticsAdmin;