import React from 'react';
import { Icons } from "../../../assets/Svgs";

function StatisAdminCurrentWrapper({children, value, increase}) {

    return (
        <>
            <header className="flex justify-between items-center">
                {children}
            </header>

            <div className={`flex gap-3 items-end justify-center align-center`}>
                <p className={`text-[1.8rem] font-medium`}>
                    {value}
                </p>

                {
                    increase > 0
                        ? <span className="text-sm text-green-500 flex"><Icons.SvgStatisticCaretUpFill color="#25D366"/>+{increase}%</span>
                        : <span className="text-sm text-red-500 flex"><Icons.SvgStatisticCaretDownFill color="#FF0000"/>{increase}%</span>
                }
            </div>
        </>
    );
}

export default StatisAdminCurrentWrapper;