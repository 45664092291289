import React from 'react';

function SvgTemplatePersonAdd(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.6875 42.5841V43.1882C31.6042 45.1674 27.9167 46.334 23.9583 46.334C19.9375 46.334 16.1875 45.1257 13.0625 43.0632V42.5841C13.0625 37.7299 17.9167 33.7715 23.875 33.7715C29.8542 33.7715 34.6875 37.7299 34.6875 42.5841Z" fill="#4B5563"/>
            <path opacity="0.4" d="M43.7513 26.5417C43.7513 33.5208 40.1471 39.6458 34.6888 43.1875V42.5834C34.6888 37.7292 29.8555 33.7708 23.8763 33.7708C17.918 33.7708 13.0638 37.7292 13.0638 42.5834V43.0625C7.70964 39.5208 4.16797 33.4583 4.16797 26.5417C4.16797 15.6042 13.0221 6.75 23.9596 6.75C26.6888 6.75 29.293 7.29165 31.668 8.29165C31.3971 9.12498 31.2513 10 31.2513 10.9167C31.2513 12.4792 31.6888 13.9583 32.4596 15.2083C32.8763 15.9167 33.418 16.5624 34.043 17.1041C35.5013 18.4374 37.4388 19.25 39.5846 19.25C40.5013 19.25 41.3763 19.1041 42.1888 18.8125C43.1888 21.1875 43.7513 23.8125 43.7513 26.5417Z" fill="#292D32"/>
            <path d="M45.7708 5.35485C44.2708 3.64652 42.0417 2.58398 39.5833 2.58398C37.25 2.58398 35.125 3.54236 33.6042 5.10486C32.7292 6.0007 32.0625 7.08396 31.6667 8.2923C31.3958 9.12563 31.25 10.0007 31.25 10.9173C31.25 12.4798 31.6875 13.959 32.4583 15.209C32.875 15.9173 33.4167 16.5631 34.0417 17.1048C35.5 18.4381 37.4375 19.2507 39.5833 19.2507C40.5 19.2507 41.375 19.1048 42.1875 18.8131C44.1042 18.2089 45.7083 16.8965 46.7083 15.209C47.1458 14.5006 47.4792 13.6881 47.6667 12.8548C47.8333 12.2298 47.9167 11.584 47.9167 10.9173C47.9167 8.79232 47.1042 6.83402 45.7708 5.35485ZM42.6875 12.4381H41.1458V14.0632C41.1458 14.9173 40.4375 15.6257 39.5833 15.6257C38.7292 15.6257 38.0208 14.9173 38.0208 14.0632V12.4381H36.4792C35.625 12.4381 34.9167 11.7298 34.9167 10.8756C34.9167 10.0214 35.625 9.31311 36.4792 9.31311H38.0208V7.83402C38.0208 6.97986 38.7292 6.27152 39.5833 6.27152C40.4375 6.27152 41.1458 6.97986 41.1458 7.83402V9.31311H42.6875C43.5417 9.31311 44.25 10.0214 44.25 10.8756C44.25 11.7298 43.5625 12.4381 42.6875 12.4381Z" fill="#292D32"/>
            <path d="M23.8737 31.1868C27.1069 31.1868 29.7279 28.5658 29.7279 25.3327C29.7279 22.0995 27.1069 19.4785 23.8737 19.4785C20.6405 19.4785 18.0195 22.0995 18.0195 25.3327C18.0195 28.5658 20.6405 31.1868 23.8737 31.1868Z" fill="#292D32"/>
        </svg>
}

export default SvgTemplatePersonAdd;