import React from 'react';
import {useTranslation} from "../../services/i18n/i18nService";
import ButtonFlexible from "./ButtonFlexible";
import {Icons} from "../../assets/Svgs";

function ButtonStatistics({onClick}) {
    const { t: _ } = useTranslation();

    return (
        <ButtonFlexible onClick={onClick} icon={<Icons.SvgSidebarStatistics />}>
            {_("Statistics")}
        </ButtonFlexible>
    );
}

export default ButtonStatistics;