import React from 'react';
import {useTranslation} from "../../services/i18n/i18nService";
import ButtonBase from "./ButtonBase";
import {Icons} from "../../assets/Svgs";
import ButtonFlexible from "./ButtonFlexible";

function ButtonTemplateDelete({isFlexible}) {
    const { t: _ } = useTranslation();

    return (
        <>
            {
                !isFlexible
                    ? <ButtonBase className="bg-white text-[#FF0000] cursor-pointer py-3.5 px-6 rounded-full"
                                  icon={<Icons.SvgTrashOutline color="#FF0000" />}>
                        {_("Delete")}
                    </ButtonBase>
                    : <ButtonFlexible  icon={<Icons.SvgTrashOutline color="#FF0000"/>}>
                        <div className="text-[#FF0000]">{_("Delete")}</div>
                    </ButtonFlexible>
            }
        </>
    );
}

export default ButtonTemplateDelete;