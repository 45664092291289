import React from 'react';

function SvgProfileSectionEdit({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.2666 15.1667H2.73325C1.45325 15.1667 0.833252 14.5133 0.833252 13.18V10.4867C0.833252 9.14667 1.45325 8.5 2.73325 8.5H13.2666C14.5466 8.5 15.1666 9.15333 15.1666 10.4867V13.18C15.1666 14.5133 14.5466 15.1667 13.2666 15.1667ZM2.73325 9.5C2.05992 9.5 1.83325 9.64 1.83325 10.4867V13.18C1.83325 14.0267 2.05992 14.1667 2.73325 14.1667H13.2666C13.9399 14.1667 14.1666 14.0267 14.1666 13.18V10.4867C14.1666 9.64 13.9399 9.5 13.2666 9.5H2.73325Z"
                fill={!!color ? color : "white"}/>
            <path
                d="M8.59992 7.50016H2.73325C1.45325 7.50016 0.833252 6.84683 0.833252 5.5135V2.82016C0.833252 1.48016 1.45325 0.833496 2.73325 0.833496H8.59992C9.87992 0.833496 10.4999 1.48683 10.4999 2.82016V5.5135C10.4999 6.84683 9.87992 7.50016 8.59992 7.50016ZM2.73325 1.8335C2.05992 1.8335 1.83325 1.9735 1.83325 2.82016V5.5135C1.83325 6.36016 2.05992 6.50016 2.73325 6.50016H8.59992C9.27325 6.50016 9.49992 6.36016 9.49992 5.5135V2.82016C9.49992 1.9735 9.27325 1.8335 8.59992 1.8335H2.73325Z"
                fill={!!color ? color : "white"}/>
        </svg>
    );
}

export default SvgProfileSectionEdit;