const SelectField = ({
    label,
    labelNote,
    labelClassName,
    onChange,
    selectClassName,
    containerClassName,
    options,
    ...selectProps
}) => {
    return (
        <label className={`flex flex-col ${containerClassName || ""} ${selectProps.className || ""}`}>
            <div className="flex items-center">
                <span className={`${labelClassName || "text-gray-900"} text-sm font-medium flex`}>
                    {label}
                </span>
                {selectProps.required && <span className="ml-0 text-red-500">*</span>}
                {labelNote && <p className="text-[0.625rem] ms-1">({labelNote})</p>}
            </div>

            <select
                className={`${selectClassName || ""} ${selectProps.disabled ? 'opacity-50 cursor-not-allowed' : ''} peer h-10 text-sm px-5 py-2.5 bg-[#F6F7F8] rounded-[0.625rem] text-gray-900 focus:outline-none focus:border-rose-600`}
                {...selectProps}
                onChange={onChange}
            >
                {options && options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.content}
                    </option>
                ))}
            </select>
        </label>
    );
}

export default SelectField;