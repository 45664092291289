import React from 'react';
import {Icons} from "../../../assets/Svgs";
import {useNavigate} from "react-router";
import {getFullPath} from "../../../router/Router";

function PortfolioFooter({routerSelected}) {
    const navigate = useNavigate();

    return (
        <footer className="absolute bottom-0 w-full py-6 px-10 shadow-lg text-center bg-white text-black flex justify-between">
            <div
                className={`pb-1 ${ routerSelected === "profile" ? "border-b-2 border-[#0400BD]" : "" }`}
                onClick={() => {
                    navigate(getFullPath(["ROOT", "PROFILE"]));
                }}>
                <Icons.SvgFooterProfile className={`h-[1.875rem]`} color={`${routerSelected === "profile" ? "#0400BD" : "#9CA3AF"}`} />
            </div>

            <div
                className={`pb-1 ${ routerSelected === "connection" ? "border-b-2 border-[#0400BD]" : "" }`}
                onClick={() => {
                    navigate(getFullPath(["ROOT", "CONNECTIONS"]));
                }}>
                <Icons.SvgFooterConnection className={`h-[1.875rem]`} color={`${routerSelected === "connection" ? "#0400BD" : "#9CA3AF"}`} />
            </div>

            <div
                className={`pb-1 ${ routerSelected === "statistics" ? "border-b-2 border-[#0400BD]" : "" }`}
                onClick={() => {
                    navigate(getFullPath(["ROOT", "STATISTICS"]));
                }}>
                <Icons.SvgFooterStatistic className={`h-[1.875rem]`} color={`${routerSelected === "statistics" ? "#0400BD" : "#9CA3AF"}`} />
            </div>

            <div
                className={`pb-1 ${ routerSelected === "settings" ? "border-b-2 border-[#0400BD]" : "" }`}
                onClick={() => {
                    navigate(getFullPath(["ROOT", "SETTINGS"]));
                }}>
                <Icons.SvgFooterSetting className={`h-[1.875rem]`} color={`${routerSelected === "settings" ? "#0400BD" : "#9CA3AF"}`} />
            </div>
        </footer>
    );
}

export default PortfolioFooter;