export const SOCIAL_MEDIA_TYPES = {
    WHATSAPP: 'wa',
    LINKEDIN: 'li',
    YOUTUBE: 'yb',
    FACEBOOK: 'fb',
    TIKTOK: 'tk',
    INSTAGRAM: 'ig',
    TWITTER: 'tt',
};

export const MEDIAS = [
    { id: 0, type: SOCIAL_MEDIA_TYPES.WHATSAPP, name: "WhatsApp", order: 0 },
    { id: 1, type: SOCIAL_MEDIA_TYPES.LINKEDIN, name: "LinkedIn", order: 3 },
    { id: 2, type: SOCIAL_MEDIA_TYPES.YOUTUBE, name: "YouTube", order: 1 },
    { id: 3, type: SOCIAL_MEDIA_TYPES.FACEBOOK, name: "Facebook", order: 4 },
    { id: 4, type: SOCIAL_MEDIA_TYPES.TWITTER, name: "X", order: 2 },
    { id: 5, type: SOCIAL_MEDIA_TYPES.TIKTOK, name: "TikTok", order: 5 },
    { id: 6, type: SOCIAL_MEDIA_TYPES.INSTAGRAM, name: "Instagram", order: 6 },
]