import React from 'react';
import ButtonBase from "./ButtonBase";
import {useTranslation} from "../../services/i18n/i18nService";

function ButtonMobile({icon, label, className, onClick, textClass, isFlexReverse}) {
    const { t: _ } = useTranslation();

    return (
        <ButtonBase onClick={onClick} className={`${!!className ? className : ""} ${!!isFlexReverse ? "flex-row-reverse" : "" } text-sm leading-3 bg-[#0400BD] gap-[0.313rem] shadow-sm flex justify-center items-center rounded-full `}>
            { !!icon ? React.cloneElement(icon) : null }
            { !!label ? <p className={`${!!textClass ? textClass : "text-white"}`}>{_(label)}</p> : null }
        </ButtonBase>
    );
}

export default ButtonMobile;