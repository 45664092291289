import React from 'react';
import { Icons } from "../../../assets/Svgs";
import PopupDocDownload from "../../../pages/Mobile/Popups/PopupDocDownload";
import { usePopup } from "../../../services/PopupService";
import WidgetSection from "../../Layouts/WidgetSection";

function WidgetDocumentView(
    {
        widget,
        displayEditAction,
        onEdit,
    }) {

    const { addPopup } = usePopup();
    const widgetData = widget.data;

    const widgetTitle = widgetData.title || "";
    const widgetFiles = widget.files || [];

    const widgetDocuments = widgetData.documents?.map((item) => ({ 
        ...item,
        file_content: widgetFiles.find((file) => file.id === parseInt(item.widget_file_id))?.file 
            || item.file_content
            || item.file_url,
        file_name: item.file_name
            || item.file_url.substring(item.file_url.lastIndexOf("/") + 1),
    })) || [];

    return (
        <WidgetSection title={widgetTitle} displayEditAction={displayEditAction} onEdit={onEdit} >
            <div className="flex flex-col gap-3">
                {
                    widgetDocuments.sort((a, b) => (a.order > b.order) ? 1 : -1).map((document, index) => {
                        if (!document.file_content) return null;
                        
                        return <div key={index} className="shadow-lg h-16 w-full rounded-lg">
                            <div className="flex px-2.5 h-full justify-between items-center align-center">
                                <div className="flex gap-2 pl-1.5">
                                    <div className="grid items-center justify-center gap-1">
                                        <div className="text-[0.875rem] leading-4 font-normal text-[#111827] truncate w-64 xs:w-full">{document.label}</div>
                                        <div className="text-[0.625rem] leading-3 font-normal text-[#6B7280] truncate w-64 xs:w-full">{document.file_url}</div>
                                    </div>
                                </div>
                                <div onClick={() => addPopup(<PopupDocDownload name={document.file_name} link={document.file_content} />)} className="flex p-2.5">
                                    <Icons.SvgProfileDocDownload />
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </WidgetSection>
    );
}

export default WidgetDocumentView;