import React from 'react';
import {Icons} from "../../../assets/Svgs";
import {useTranslation} from "../../../services/i18n/i18nService";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import {SOCIAL_MEDIA_TYPES} from "../../../constants/SocialMediasTypes";

function TemplateSelectorSocialMedia({label, selectedType, openPopupSelectorMedia}) {
    const { t: _ } = useTranslation();

    const switchMediaSelected = () => {
        switch (selectedType) {
            case SOCIAL_MEDIA_TYPES.WHATSAPP:
                return <Icons.SvgMediaWhatsappFill className="w-16 h-16 rounded-[0.875rem]"/>
            case SOCIAL_MEDIA_TYPES.LINKEDIN:
                return <Icons.SvgMediaLinkedinFill className="w-16 h-16 rounded-[0.875rem]"/>
            case SOCIAL_MEDIA_TYPES.YOUTUBE:
                return <Icons.SvgMediaYoutubeFill className="w-16 h-16 rounded-[0.875rem]"/>
            case SOCIAL_MEDIA_TYPES.INSTAGRAM:
                return <Icons.SvgMediaInstagram className="w-16 h-16 rounded-[0.875rem]"/>
            case SOCIAL_MEDIA_TYPES.FACEBOOK:
                return <Icons.SvgMediaFacebookFill className="w-16 h-16 rounded-[0.875rem]"/>
            case SOCIAL_MEDIA_TYPES.TWITTER:
                return <Icons.SvgMediaX className="w-16 h-16 rounded-[0.875rem]"/>
            case SOCIAL_MEDIA_TYPES.TIKTOK:
                return <Icons.SvgMediaTikTok className="w-16 h-16 rounded-[0.875rem]"/>
            default:
                return null
        }
    }

    return (
        <div className="flex flex-col gap-7 overflow-y-scroll">
            <label className="flex flex-col gap-3.5">
                <span className="block text-sm font-medium after:content-['*'] after:ml-0 after:text-red-500">
                    {_(label)}
                </span>

                <div className="flex flex-col gap-7">
                    {
                        switchMediaSelected()
                    }
                </div>

                <ButtonBase onClick={ openPopupSelectorMedia } className="bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full">
                    {
                        _("Change type")
                    }
                </ButtonBase>

            </label>
        </div>
    );
}

export default TemplateSelectorSocialMedia;