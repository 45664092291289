import APIService from "../APIService";
import { CHARTS_TEMPLATES, TEMPLATE_LIST_SECTIONS } from "../../constants/DatasConstants";

class TemplateService {

    static cachedTemplates = null;

    static async createTemplate(template) {
        const response = await APIService.apiCall("portfolios/templates/", "POST", template);
        if (!response.ok) throw new Error('Failed to create template');
        const data = await response.json();
        this.cachedTemplates = null; // Invalidate cache
        return data;
    }

    static async getTemplates() {
        if (this.cachedTemplates === null) {
            const response = await APIService.apiCall("portfolios/templates/", "GET");
            if (!response.ok) throw new Error('Failed to fetch templates');
            this.cachedTemplates = await response.json();
        }
        return this.cachedTemplates;
    }

    static async getTemplateById(id) {
        const templates = await this.getTemplates();
        return templates.find(template => template.id === id);
    }

    static async updateTemplate(id, template) {
        const response = await APIService.apiCall(`portfolios/templates/${id}/`, "PATCH", template);
        if (!response.ok) throw new Error('Failed to update template');
        const data = await response.json();
        this.cachedTemplates = null; // Invalidate cache
        return data;
    }

    static async deleteTemplate(id) {
        const response = await APIService.apiCall(`portfolios/templates/${id}/`, "DELETE");
        if (!response.ok) throw new Error('Failed to delete template');
        this.cachedTemplates = null; // Invalidate cache
    }

    static resetCache = () => {
        this.cachedTemplates = null;
    }

    // The following will be removed after the backend is implemented
    static listCharts = null;
    static sections = null;
    static widget = null;

    // static async getListCharts() {
    //     if (this.cachedTemplates === null) {
    //         const response = await APIService.apiCall("portfolios/templates/", "GET");
    //         if (!response.ok) throw new Error('Failed to fetch templates');
    //         this.cachedTemplates = await response.json();
    //     }
    //     return this.cachedTemplates;
    // }
    
    static getListCharts = async () => {
        return new Promise((resolve, reject) => {
            if (this.listCharts === null) {
                // APIService.apiCall("products", "GET")
                //     .then(result => {
                //         console.log(result);
                //         resolve(result.data);
                //     })
                //     .catch(error => {
                //         reject(error);
                //     })

                //data template
                this.listCharts = CHARTS_TEMPLATES;
                resolve(this.listCharts);
            } else {
                resolve(this.listCharts);
            }
        });
    }

    static getTemplateChartById = async (id) => {
        return new Promise((resolve, reject) => {
            if ((this.listCharts === null)) {
                this.getListCharts()
                    .then(resTmp => {
                        const tmp = resTmp.find((item) => item.id.toString() === id.toString());
                        if(tmp){
                            resolve(tmp);
                        }else{
                            reject(new Error("Chart not found"));
                        }

                    })
                    .catch(err => reject(err))
            } else {
                const tmp = this.listCharts.find((item) => item.id.toString() === id.toString());
                if(tmp){
                    resolve(tmp);
                }else{
                    reject(new Error("Chart not found"));
                }
            }
        });
    }

    static getSections = async () => {
        return new Promise((resolve, reject) => {
            if (this.sections === null) {
                // APIService.apiCall("products", "GET")
                //     .then(result => {
                //         console.log(result);
                //         resolve(result.data);
                //     })
                //     .catch(error => {
                //         reject(error);
                //     })

                this.sections = TEMPLATE_LIST_SECTIONS
                resolve(this.sections)
            } else {
                resolve(this.sections)
            }
        });
    }

    static getWidget = async (payloadId) => {
        return new Promise((resolve, reject) => {
            if (this.widget === null) {
                // APIService.apiCall("/card/components/" + payloadId + "/", "GET", )
                //     .then(result => {
                //         console.log(result);
                //         resolve(result.data);
                //     })
                //     .catch(error => {
                //         reject(error);
                //     })
                this.widget = TEMPLATE_LIST_SECTIONS.find((item) => item.section === parseInt(payloadId))
                resolve(this.widget)
                this.widget = null
            } else {
                resolve(this.widget)
                this.widget = null
            }
        });
    }
}

export default TemplateService;