import React from 'react';

function SvgArrangeAscOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 4.25V7.25" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.5 6.25L6 7.75L7.5 6.25" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgArrangeAscOutline;