import React from 'react';

function SvgSelectorTemplate(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.9154 41.9577V9.04102C22.9154 5.91602 21.582 4.66602 18.2695 4.66602H9.85286C6.54036 4.66602 5.20703 5.91602 5.20703 9.04102V41.9577C5.20703 45.0827 6.54036 46.3327 9.85286 46.3327H18.2695C21.582 46.3327 22.9154 45.0827 22.9154 41.9577Z" fill="#4B5563"/>
            <path opacity="0.4" d="M44.7904 23.2077V9.04102C44.7904 5.91602 43.457 4.66602 40.1445 4.66602H31.7279C28.4154 4.66602 27.082 5.91602 27.082 9.04102V23.2077C27.082 26.3327 28.4154 27.5827 31.7279 27.5827H40.1445C43.457 27.5827 44.7904 26.3327 44.7904 23.2077Z" fill="#4B5563"/>
            <path opacity="0.4" d="M44.7904 41.9583V36.125C44.7904 33 43.457 31.75 40.1445 31.75H31.7279C28.4154 31.75 27.082 33 27.082 36.125V41.9583C27.082 45.0833 28.4154 46.3333 31.7279 46.3333H40.1445C43.457 46.3333 44.7904 45.0833 44.7904 41.9583Z" fill="#292D32"/>
        </svg>
    );
}

export default SvgSelectorTemplate;