import {
    QueryClient,
    QueryClientProvider
} from '@tanstack/react-query';
import React from "react";
import './App.css';
import { LocaleProvider } from './contexts/LocaleContext';
import { LocalStorageProvider } from './contexts/LocalStorageUpdateContext';
import { PopupProvider } from './contexts/PopupContext';
import Router from "./router/Router";

export const queryClient = new QueryClient()

const ProviderComposer = ({ providers, children }) => {
    return providers.reduceRight(
        (children, parent) =>
            React.cloneElement(parent, {
                children: children
            }),
            children
    );
}

const App = () => (
    <div className="bg-white w-full overflow-hidden">
        <ProviderComposer
            providers={[
                <QueryClientProvider client={queryClient} />,
                <LocalStorageProvider />,
                <LocaleProvider />,
                <PopupProvider className={''} />,
            ]}
        >
            <Router />
        </ProviderComposer>
    </div>
);

export default App;
