import React from 'react';

function SvgStatisticGlassOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83594 10.208H8.16927" stroke={props.color || "black"} strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.16406 10.208V4.37467C1.16406 2.04134 1.7474 1.45801 4.08073 1.45801" stroke={props.color || "black"} strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.8307 10.208V4.37467C12.8307 2.04134 12.2474 1.45801 9.91406 1.45801" stroke={props.color || "black"} strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.83073 9.28079V11.2C5.83073 12.3666 5.36406 12.8333 4.1974 12.8333H2.7974C1.63073 12.8333 1.16406 12.3666 1.16406 11.2V9.28079C1.16406 8.11413 1.63073 7.64746 2.7974 7.64746H4.1974C5.36406 7.64746 5.83073 8.11413 5.83073 9.28079Z" stroke={props.color || "black"} strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.8307 9.28079V11.2C12.8307 12.3666 12.3641 12.8333 11.1974 12.8333H9.7974C8.63073 12.8333 8.16406 12.3666 8.16406 11.2V9.28079C8.16406 8.11413 8.63073 7.64746 9.7974 7.64746H11.1974C12.3641 7.64746 12.8307 8.11413 12.8307 9.28079Z" stroke={props.color || "black"} strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgStatisticGlassOutline;