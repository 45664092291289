import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Icons } from "../../../assets/Svgs";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import ButtonViewDetails from "../../../components/Buttons/ButtonViewDetails";
import Table from "../../../components/Layouts/Table";
import PopupSelectorCalendar from "../../../components/Selectors/PopupSelectorCalendar";
import SelectorDate from "../../../components/Selectors/SelectorDate";
import TagTemplate from "../../../components/Tags/TagTemplate";
import { getFullPath } from '../../../router/Router';
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import { StatisticsService } from '../../../services/Statistics/StatisticsService';
import PopupTemplateFilterPersonView from "../Templates/Popups/PopupTemplateFilterPersonView";
import TeamService from '../../../services/Team/TeamService';
import { subMonths } from 'date-fns';

export const StatisticsTopPerformers = () => {
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    
    const [ selectedView, setSelectedView ] = useState("templates");
    const [ selectedRange, setSelectedRange ] = useState({
        from: subMonths(new Date(), 1),
        to: new Date(),
    });
    const { data, isLoading: isLoadingData, error: errorData } = StatisticsService.useTeamPerformance({
        start_date: selectedRange.from.toISOString(),
        end_date: selectedRange.to.toISOString(),
    });
    const { data: members, isLoading: isLoadingMembers, error: errorMembers } = TeamService.useTeamMembersQuery();

    const isLoading = isLoadingData || isLoadingMembers;
    const error = errorData || errorMembers;

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    return (
        <div className="p-7 flex flex-col gap-7">
            <header className=" flex justify-between items-center border-gray-200">

                <div className="flex text-xl whitespace-nowrap items-center gap-3">
                    <Link to={ getFullPath(["ROOT", "STATISTICS"]) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Statistics")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("Top Perfomers")}
                </div>

                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "STATISTICS"])} className="flex justify-center gap-[0.438rem]">
                        <ButtonBack isFlexible={true} />
                    </Link>

                    <SelectorDate isFlexible={true} onClick={() => 
                        addPopup(<PopupSelectorCalendar initialRange={selectedRange} onConfirm={setSelectedRange} />)
                    } />

                    <ButtonBase onClick={() => { addPopup(<PopupTemplateFilterPersonView title="Filter the statistics view by :" selectedView={ selectedView } onSelectedViewChange={ setSelectedView } />) }} className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={ <Icons.SvgStatisticFilterOutline /> } >
                        {_("Filter")}
                    </ButtonBase>
                </div>

            </header>

            <div className="flex-1 min-h-0 overflow-auto">
                <div className="flex">
                    <Table
                        tdpaddingClass="py-3.5 px-8"
                        displayHeaders={true}
                        columns={[
                            {
                                label: _("Team member"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Views"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Contacts"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Leads"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Template"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("View"),
                                sortable: false,
                            },
                        ]}
                        rows={data
                            .sort((a, b) => b.views - a.views)
                            .filter(performance => {
                                const member = members.find(member => member.id === performance.user_id);
                                return !!member;
                            })
                            .map( performance => {
                                const member = members.find(member => member.id === performance.user_id);
                                
                                return ({
                                    data: [
                                        {
                                            value: <div sort_on={ member.full_name } className="flex items-center gap-[1.5rem]">
                                                <img src={ member.avatar } className="rounded-full h-8 w-8"  alt="avatar" />
                                                <span>{ member.full_name }</span>
                                            </div>,
                                        },
                                        {
                                            value: <div sort_on={ performance.views } className="flex items-center justify-center gap-[1.5rem]">
                                                <span className="flex gap-[0.438rem]">
                                                    { Math.round(performance.views) }
                                                    <Icons.SvgStatisticEyeFill />
                                                </span>
                                            </div>,
                                        },
                                        {
                                            value: <div sort_on={ performance.contacts } className="flex items-center justify-center gap-[1.5rem]">
                                                <span className="flex gap-[0.438rem]">
                                                    { Math.round(performance.contacts) }
                                                    <Icons.SvgProfileTwoUser />
                                                </span>
                                            </div>,
                                        },
                                        {
                                            value: <div sort_on={ performance.leads } className="flex items-center justify-center gap-[1.5rem]">
                                                <span className="flex gap-[0.438rem]">
                                                    { Math.round(performance.leads) }
                                                    <Icons.SvgStatisticUserCircleAdd />
                                                </span>
                                            </div>,
                                        },
                                        {
                                            value: <div sort_on={ member.template } className="flex items-center justify-center gap-[1.5rem]">
                                                <span>{ TagTemplate(member.template, "bg-[#EEEFF2]") }</span>
                                            </div>,
                                        },
                                        {
                                            value: <Link to={getFullPath(["ROOT", "STATISTICS", "PERFORMERS", "VIEW_PERFORM"]).replace(":id", member.id)} className="flex justify-center gap-[0.438rem]">
                                                <ButtonViewDetails />
                                            </Link>,
                                        },
                                    ]
                                })
                            })
                        }
                    />
                </div>
            </div>
        </div>
    );
}
