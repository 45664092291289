import React from 'react';

function SvgProfileEditText({className, color}) {
    return (
        <svg className={`${!!className ? className : "" } `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7751 6.60007C17.4334 6.60007 17.1501 6.31673 17.1501 5.97507V4.4584C17.1501 3.85007 16.6584 3.3584 16.0501 3.3584H3.9501C3.34176 3.3584 2.8501 3.85007 2.8501 4.4584V5.9834C2.8501 6.32507 2.56676 6.6084 2.2251 6.6084C1.88343 6.6084 1.6001 6.32507 1.6001 5.97507V4.4584C1.6001 3.1584 2.65843 2.1084 3.9501 2.1084H16.0501C17.3501 2.1084 18.4001 3.16673 18.4001 4.4584V5.9834C18.4001 6.32507 18.1251 6.60007 17.7751 6.60007Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M10 17.8915C9.65833 17.8915 9.375 17.6081 9.375 17.2665V3.4248C9.375 3.08314 9.65833 2.7998 10 2.7998C10.3417 2.7998 10.625 3.08314 10.625 3.4248V17.2665C10.625 17.6165 10.3417 17.8915 10 17.8915Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M13.2832 17.8916H6.71655C6.37489 17.8916 6.09155 17.6083 6.09155 17.2666C6.09155 16.9249 6.37489 16.6416 6.71655 16.6416H13.2832C13.6249 16.6416 13.9082 16.9249 13.9082 17.2666C13.9082 17.6083 13.6249 17.8916 13.2832 17.8916Z" fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgProfileEditText;