import { Reorder } from "framer-motion";
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { Icons } from "../../../assets/Svgs";
import PopupItemDelete from "../../../pages/Mobile/Popups/PopupItemDelete";
import FileService from "../../../services/FileService";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import WidgetService from "../../../services/Widget/WidgetService";
import ButtonBase from "../../Buttons/ButtonBase";
import InputGroup from "../../Layouts/InputGroup";

const WidgetWebsiteEditSteps = {
    PICTURE_LIST: "PICTURE_LIST",
    PICTURE_DETAIL: "PICTURE_DETAIL",
}

const WidgetWebsiteEdit = React.forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
) => {
    useImperativeHandle(ref, () => ({
        handleSave,
        handleCreate
    }));

    /* Hooks */
    const { widgetId } = useParams();
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();

    /* States */
    const [data, setData] = useState({
        title: widget?.data?.title || "",
        websites: (widget?.data?.websites || []).map((item, index) => ({ ...item, id: index })),
    });
    const [step, setStep] = useState(WidgetWebsiteEditSteps.PICTURE_LIST);
    const [editedWebsite, setEditedWebsite] = useState(null);

    /* Utils */
    const getWebsiteIconImg = (website) => {
        return website.file_content ? website.file_content : widget.pictures?.find(p => p.id === parseInt(website.icon))?.picture;
    }

    const buildPayload = (widgetData) => {
        return {
            widget: {
                ...widget,
                data: {
                    title: widgetData.title,
                    websites: widgetData.websites.map((item) => ({
                        label: item.label,
                        order: item.order,
                        link: item.link,
                        icon: item.file_content ? ("l_" + item.id) : item.icon,
                    })),
                }
            },
            picturesToAdd: widgetData.websites.reduce((acc, item) => {
                if (!item.file_content) return acc;
                return { ...acc, ["l_" + item.id]: item.file_content };
            }, {}),
        }
    }

    const deleteUnusedPictures = (widget, pictures) => {
        pictures.forEach(picture => {
            if (!widget.data.websites.find(item => item.icon === picture.id.toString())) {
                WidgetService.deleteWidgetPicture(picture.id);
            }
        });
    }

    /* Handlers */
    const handleSave = () => {
        const payload = buildPayload(data);
        deleteUnusedPictures(payload.widget, payload.widget.pictures ?? []);
        WidgetService.updateWidgetWithBlobs(payload.widget, null, payload.picturesToAdd, (savedWidget, _files, pictures) => {
            savedWidget.data.websites = savedWidget.data.websites.map((item) => ({
                ...item,
                icon: item.icon?.startsWith("l_")
                    ? pictures[item.icon].id.toString()
                    : item.icon,
            }));
            return savedWidget;
        })
            .then((savedWidget) => {
                onSave(savedWidget);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleCreate = () => {
        const payload = buildPayload(data);
        deleteUnusedPictures(payload.widget, payload.widget.pictures ?? []);
        WidgetService.createWidgetWithBlobs(payload.widget, null, payload.picturesToAdd, (savedWidget, _files, pictures) => {
            savedWidget.data.websites = savedWidget.data.websites.map((item) => ({
                ...item,
                icon: item.icon?.startsWith("l_")
                    ? pictures[item.icon].id.toString()
                    : item.icon,
            }));
            return savedWidget;
        })
            .then((savedWidget) => {
                onSave(savedWidget);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleChange = (updatedData) => {
        setData(updatedData);
        onChange({ ...widget, data: updatedData });
    };

    const handleReorder = (orderedItems) => {
        handleChange({
            ...data,
            websites: orderedItems.map((item, index) => ({ ...item, order: index })),
        });
    };

    const handleWebsiteChange = (updatedWebsite) => {
        console.log(updatedWebsite);
        updatedWebsite.id = editedWebsite == null
            ? data.websites.reduce((maxId, item) => Math.max(maxId, item.id), 0) + 1
            : editedWebsite.id;
        if (editedWebsite === null) {
            handleReorder([
                ...data.websites,
                updatedWebsite
            ]);
        } else {
            handleReorder(
                data.websites.map((item) => (item.id === updatedWebsite.id ? updatedWebsite : item)),
            );
        }
        setEditedWebsite(updatedWebsite);
    };

    const handleWebsiteDelete = (id) => {
        handleReorder(data.websites.filter(item => item.id !== id));
    }

    /* Renders */
    const renderAddWebsiteButton = () => {
        return <ButtonBase
            className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full w-40"
            iconColor="white"
            icon={<Icons.SvgTemplateAddNewSection color="white" className="w-3" />}
            onClick={() => { setStep(WidgetWebsiteEditSteps.PICTURE_DETAIL); }}
        >
            {_("Add a new website")}
        </ButtonBase>
    }

    const renderPreviousButton = () => {
        return <ButtonBase
            className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full w-40 self-end"
            iconColor="white"
            icon={<Icons.SvgArrowReturnOutline color="white" className="w-3" />}
            onClick={() => { 
                setStep(WidgetWebsiteEditSteps.PICTURE_LIST);
                setEditedWebsite(null);
            }}
        >
            {_("Previous")}
        </ButtonBase>
    }

    return (
        <div className="flex flex-col w-full h-full gap-7">
            {
                {
                    [WidgetWebsiteEditSteps.PICTURE_LIST]: renderAddWebsiteButton,
                    [WidgetWebsiteEditSteps.PICTURE_DETAIL]: renderPreviousButton,
                }[step]()
            }

            {
                {
                    [WidgetWebsiteEditSteps.PICTURE_LIST]: (
                        <div className="flex flex-col h-full gap-7 overflow-hidden">
                            <InputGroup
                                label={_("Section Title")}
                                classGapLabelToField="gap-3.5"
                                name="title"
                                widthInputClass="w-4/6"
                                value={data.title}
                                onChange={(e) => handleChange({ ...data, title: e.target.value })}
                                fontClass="text-black text-[1rem] leading-6 font-normal"
                                required={true}
                                backgroundClass="bg-[#F6F7F8]"
                            />

                            <Reorder.Group className="flex flex-col h-full gap-4 overflow-y-auto" axis="y" onReorder={handleReorder} values={data.websites}>
                                {
                                    data.websites.map((item) => (
                                        <Reorder.Item key={item.id} value={item}>
                                            <div className="bg-[#F6F7F8] rounded-[0.625rem] flex justify-between px-5 py-2.5">
                                                <div className="flex justify-center items-center align-center gap-2.5">
                                                    <Icons.SvgTemplateSectionListDraggable />
                                                    <div className="flex h12 w-12 justify-center items-center">
                                                        {!!getWebsiteIconImg(item)
                                                            ? <img src={ getWebsiteIconImg(item) } alt={item.label} className="object-cover rounded" />
                                                            : <Icons.SvgTemplateSectionWebsiteLinks />
                                                        }
                                                    </div>
                                                    <div className="flex flex-col gap-1">
                                                        { item.label && <p className="text-sm">{ item.label }</p> }
                                                        { item.link && <p className="text-sm text-[#9CA3AF]">{ item.link }</p> }
                                                    </div>
                                                </div>

                                                <div className="flex justify-center items-center align-center gap-5">
                                                    <ButtonBase
                                                        onClick={() => addPopup(<PopupItemDelete handleDelete={() => handleWebsiteDelete(item.id)} />)}
                                                        className="bg-white text-[#FF0000] gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-2"
                                                    >
                                                        <Icons.SvgTrashOutline className="h-2.5" color="#FF0000" />
                                                        {_("Delete")}
                                                    </ButtonBase>

                                                    <ButtonBase className="bg-black text-white gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-1.5"
                                                        onClick={() => {
                                                            setEditedWebsite(item);
                                                            setStep(WidgetWebsiteEditSteps.PICTURE_DETAIL);
                                                        }}
                                                    >
                                                        {_("Edit")}
                                                    </ButtonBase>
                                                </div>
                                            </div>
                                        </Reorder.Item>
                                    ))
                                }
                            </Reorder.Group>
                        </div>
                    ),
                    [WidgetWebsiteEditSteps.PICTURE_DETAIL]: <WebsiteDetailEdit website={editedWebsite} onChange={handleWebsiteChange} />,
                }[step]
            }
        </div>
    );
})

const WebsiteDetailEdit = ({ website, onChange }) => {
    /* Hooks */
    const { t: _ } = useTranslation();
    const iconSelectRef = useRef(null);
    
    /* States */
    const [data, setData] = useState({
        ...website,
        label: website?.label || "",
        file_content: website?.file_content || null,
        file_name: website?.file_name || null,
        link: website?.link || "",
    });

    /* Effects */
    useEffect(() => {
        onChange(data);
    }, [data]);

    /* Handlers */
    const handleDataChange = (key, value) => {
        setData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }

    const handleSelectFile = (e) => {
        const file = e.target.files[0];
        FileService.loadFileBase64(file)
            .then(fileContent => {
                handleDataChange("file_content", fileContent);
                handleDataChange("file_name", file.name);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleRemoveFile = () => {
        handleDataChange("file_content", null);
        handleDataChange("file_name", null);
    }

    return (
        <div className="w-full h-full gap-7 flex flex-col">
            <InputGroup
                fontClass="text-sm font-medium flex"
                classGapLabelToField="gap-3.5"
                widthInputClass="w-full"
                name="label"
                placeholder="Website Label"
                value={data.label}
                label={_("Website Label")}
                required={true}
                onChange={(e) => handleDataChange("label", e.target.value)}
            />

            <label className="flex flex-col">
                <input
                    ref={iconSelectRef}
                    type="file"
                    className="hidden"
                    onChange={handleSelectFile}
                    accept="image/*"
                />
                <div className="flex flex-col gap-7">
                    <div className={`flex flex-col align-center gap-1.5`}>
                        <p className="text-[0.688rem] font-medium text-[#9CA3AF]">
                            {data.file_name || _("No file selected")}
                        </p>
                        <ButtonBase
                            onClick={() => { iconSelectRef.current?.click() }}
                            className={`bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full`}
                        >
                            {_("Select an icon")}
                        </ButtonBase>
                    </div>
                </div>
            </label>
            {
                data.file_content && (
                    <div className="flex gap-7 justify-center items-center">
                        <p className="text-sm">{data.file_name}</p>
                        <ButtonBase
                            onClick={handleRemoveFile}
                            className={`bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full`}
                        >
                            {_("Remove the icon")}
                        </ButtonBase>
                    </div>
                )
            }

            <InputGroup
                label={_("Website Link")}
                classGapLabelToField="gap-3.5"
                widthInputClass="w-full"
                name="link"
                placeholder="Website URL"
                value={data.link}
                onChange={(e) => handleDataChange("link", e.target.value)}
                fontClass="text-black text-[1rem] leading-6 font-normal"
                required={false}
                backgroundClass="bg-[#F6F7F8]"
            />
        </div>
    );
}

export default WidgetWebsiteEdit;