import React, {useEffect, useState} from 'react';
import PortfolioFooter from "../Layouts/PortfolioFooter";
import {useTranslation} from "../../../services/i18n/i18nService";
import ButtonMobile from "../../../components/Buttons/ButtonMobile";
import {Icons} from "../../../assets/Svgs";
import InputSearch from "../../../components/Layouts/InputSearch";
import InputCheckbox from "../../../components/Layouts/InputCheckbox";
import ConnectionService from "../../../services/Connections/ConnectionService";
import {usePopup} from "../../../services/PopupService";
import PopupContactAdd from "./Popups/PopupContactAdd";
import PopupStickerFilter from "./Popups/PopupStickerFilter";
import PopupSectionDelete from "./Popups/PopupSectionDelete";
import {useNavigate} from "react-router";
import {getFullPath} from "../../../router/Router";

function ConnectionSelect() {
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const [ toggleAssign, setToggleAssign ] = useState(true);
    const [ toggleDelete, setToggleDelete ] = useState(true);
    const [ toggleExport, setToggleExport ] = useState(true);
    const [ isSelectAll, setIsSelectAll ] = useState(true);
    const [ listChecked, setListChecked ] = useState([]);
    const [ connections, setConnections ] = useState([]);
    const [ connectionsOriginal, setConnectionsOriginal ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const navigate = useNavigate();
    const [ value, setValue ] = useState("");

    const [ stickerSelected, setStickerSelected] = useState({
        Business : true,
        ToContact : true,
        Private : true,
        Invite : true,
        Cocktail : true,
    });

    const onSearch = (listToSearch, keyword) => {
        if (!keyword) return listToSearch;
        const lowerCaseKeyword = keyword.toLowerCase();
        return listToSearch.filter(item => item.name.toLowerCase().includes(lowerCaseKeyword));
    };    

    const handleSelectAll = () => {
        if (isSelectAll === true) {
            setListChecked(connections.map((connection) => connection.id));
        } else {
            setListChecked([]);
        }
        setIsSelectAll(!isSelectAll);
    }

    const handleClick = (e) => {
        const { id, checked } = e.target;
        setListChecked([...listChecked, parseInt(id)]);
        if (!checked) {
            setListChecked(listChecked.filter(item => item !== e.target.id));
        }
        //remove when checkbox already selected
        if(listChecked.includes( parseInt(id) )){
            setListChecked(listChecked.filter(item => item !== parseInt(id)));
        }
    }

    const setTagButton = (tag, index) => {
        switch (tag) {
            case 'Business':
                return <ButtonMobile key={index} isFlexReverse={true} className="px-2 py-0.5 bg-[#FF3F61]" textClass="text-white font-normal text-[0.75rem] leading-5" label={tag} />

            case 'Cocktail Evening':
                return <ButtonMobile key={index} isFlexReverse={true} className="px-2 py-0.5 bg-[#FFC266]" textClass="text-white font-normal text-[0.75rem] leading-5" label={tag} />

            case 'To contact':
            return <ButtonMobile key={index} isFlexReverse={true} className="px-2 py-0.5 bg-[#B63FFF]" textClass="text-white font-normal text-[0.75rem] leading-5" label={tag} />

            case 'Invite for the next event':
                return <ButtonMobile key={index} isFlexReverse={true} className="px-2 py-0.5 bg-[#FF6B00]" textClass="text-white font-normal text-[0.75rem] leading-5" label={tag} />

            case 'Private':
                return <ButtonMobile key={index} isFlexReverse={true} className="px-2 py-0.5 bg-[#FF3FB2]" textClass="text-white font-normal text-[0.75rem] leading-5" label={tag} />
        }
    }

    useEffect(() => {
        setLoading(true);
        ConnectionService.getConnections()
            .then(res => {
                setConnections(res);
                setConnectionsOriginal(res);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div>
            <div className="m-4 grid gap-10 mb-24">
                <div className="flex flex-col gap-8">
                    <div className="grid gap-4">
                        <div className="text-[#111827] font-medium text-[1.25rem] leading-7 w-7/12">
                            { _("Select the connections to export or delete") }
                        </div>

                        <div className="flex gap-4">
                            <ButtonMobile
                                onClick={() => { addPopup( <PopupContactAdd connections={connections} setConnections={setConnections} /> )} }
                                isFlexReverse={true}
                                className="px-2.5 py-1.5 gap-1.5"
                                textClass="text-white font-normal text-[1rem] leading-5"
                                label="Add"
                                icon={<Icons.SvgUserAddOutline className="h-5" /> }
                            />
                            <ButtonMobile
                                onClick={() => { addPopup(
                                    <PopupStickerFilter
                                        stickerSelected={stickerSelected}
                                        onSelectedStickerChange={setStickerSelected}
                                        connections={connections}
                                        setConnections={setConnections}
                                        connectionsOriginal={connectionsOriginal}
                                    /> )} }
                                isFlexReverse={true}
                                className="px-2.5 py-1.5 gap-1.5 bg-white border border-[#0400BD]"
                                textClass="text-[#0400BD] font-normal text-[1rem] leading-5"
                                label="Filter"
                                icon={<Icons.SvgFilterSearchOutline className="h-5" color="#0400BD" />}
                            />
                            <ButtonMobile
                                onClick={() => {
                                    navigate(getFullPath(["ROOT", "CONNECTIONS", "STICKER_MANAGE"]));
                                }}
                                isFlexReverse={true}
                                className="px-2.5 py-1.5 gap-1.5"
                                textClass="text-white font-normal text-[1rem] leading-5"
                                label="All Stickers"
                                icon={<Icons.SvgConnectionFlag />}
                            />
                        </div>

                        <div>
                            <InputSearch
                                onSearch={onSearch}
                                value={value}
                                setValue={setValue}
                                setList={setConnections}
                                listToSearchOriginal={connectionsOriginal}
                                className="w-full border rounded-lg focus:outline-none active:outline-none h-10 pl-10 pr-4 text-[#6B7280]"
                                icon={<Icons.SvgSearchView color="#9CA3AF" className="absolute top-1/2 left-3 -translate-y-1/2 h-5"/>}
                                placeholder={"Search connections"}
                                isFlexible={false}
                            />
                        </div>

                        <div className="flex gap-4 justify-between items-center">
                            <ButtonMobile
                                onClick={ () => {
                                    setToggleAssign((prev) => !prev)
                                } }
                                isFlexReverse={true}
                                className={`px-2.5 py-1.5 gap-1.5 ${ toggleAssign ? "bg-[#D1D5DB]" : "" }`}
                                textClass="text-white font-normal text-[1rem] leading-5"
                                label="Assign"
                                icon={<Icons.SvgConnectionFlag />}
                            />

                            <ButtonMobile
                                onClick={ () => {
                                    setToggleDelete((prev) => !prev);
                                    if(toggleDelete === true){
                                        addPopup( <PopupSectionDelete listSelected={listChecked} listConnection={connections} setListConnection={setConnections} />)
                                    }
                                }}
                                isFlexReverse={true}
                                className={`px-2.5 py-1.5 gap-1.5 ${ toggleDelete ? "bg-[#D1D5DB]" : "bg-[#EC0000]" } `}
                                textClass="text-white font-normal text-[1rem] leading-5"
                                label="Delete"
                            />

                            <ButtonMobile
                                onClick={ () => {
                                    setToggleExport((prev) => !prev)
                                } }
                                isFlexReverse={true}
                                className={`px-2.5 py-1.5 gap-1.5 ${ toggleExport ? "bg-[#D1D5DB]" : "" } `}
                                textClass="text-white font-normal text-[1rem] leading-5"
                                label="Export"
                            />

                            <div className="px-4 blue-checkbox-container" >
                                <InputCheckbox
                                    classRadius="rounded-lg" id="selectAll"
                                    handleClick={ handleSelectAll }
                                    isChecked={ !isSelectAll }
                                />
                            </div>
                        </div>

                        <div className="flex flex-col gap-2">
                            {
                                !!connections
                                    ? connections.map((connection, index) => (
                                        <div key={index} className="blue-checkbox-container border border-[#EEEFF2] py-2 px-4 rounded-lg flex justify-between">
                                            <div className="flex flex-col gap-2">
                                                <div className="font-normal text-[1rem] leading-6">{ connection.name }</div>
                                                <div className="flex gap-1">
                                                    {
                                                        !!connection.tags
                                                            ? connection.tags.map((tag, index) => (
                                                                setTagButton(tag, index)
                                                            ))
                                                            :null
                                                    }
                                                </div>
                                            </div>
                                            <InputCheckbox
                                                classRadius="rounded-lg"
                                                id={ connection.id }
                                                handleClick={ handleClick }
                                                isChecked={ listChecked.includes( parseInt( connection.id ) ) }
                                            />
                                        </div>
                                    ))
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <PortfolioFooter routerSelected="connection" />
        </div>
    );
}

export default ConnectionSelect;