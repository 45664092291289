import React from 'react';

function SvgProfileClientLogo({className}) {
    return (
        <svg className={`${!!className ? className : "h-5" } `} viewBox="0 0 100 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1458_3049)">
                <path
                    d="M22.0768 10.0923C22.0768 8.18489 20.9006 6.91442 19.2817 6.91442C17.6066 6.91442 16.3742 8.18489 16.3742 10.1127C16.3742 12.0406 17.6066 13.3111 19.2817 13.3111C20.9006 13.3111 22.0768 12.0202 22.0768 10.0923ZM24.3499 10.0923C24.3499 13.0726 22.3477 15.3956 19.6881 15.3956C19.1075 15.4158 18.5317 15.282 18.0157 15.0071C17.4997 14.7322 17.0608 14.3253 16.741 13.8254V19.1491H14.4282V5.14665H16.5857V6.6862C16.8917 6.11426 17.3455 5.64089 17.8966 5.31863C18.4477 4.99637 19.0747 4.83781 19.7079 4.86054C22.351 4.86054 24.3499 7.12559 24.3499 10.1025"
                    fill="white"/>
                <path
                    d="M32.5171 5.00391V7.286C32.0985 7.10719 31.6487 7.01908 31.1956 7.02713C29.8277 7.02713 28.6912 7.84119 28.6912 9.76904V15.0928H26.3784V5.16059H28.5359V7.26556C28.9588 5.90312 30.059 4.86426 31.5094 4.86426C31.8443 4.86581 32.1776 4.91276 32.5006 5.00391"
                    fill="white"/>
                <path
                    d="M41.3984 10.1262C41.3984 8.19839 40.1661 6.94835 38.491 6.94835C36.872 6.94835 35.5802 8.19839 35.5802 10.1262C35.5802 12.0541 36.872 13.3246 38.491 13.3246C40.1661 13.3246 41.3984 12.0337 41.3984 10.1262ZM33.3467 10.1262C33.3441 9.43095 33.4756 8.74205 33.7337 8.09966C33.9917 7.45728 34.3712 6.87424 34.8499 6.38449C35.3287 5.89475 35.8972 5.50808 36.5222 5.24701C37.1473 4.98594 37.8166 4.85568 38.491 4.86382C39.1666 4.85386 39.8374 4.98276 40.4643 5.24297C41.0911 5.50318 41.6613 5.88951 42.1418 6.37939C42.6222 6.86927 43.0031 7.4529 43.2624 8.09621C43.5216 8.73952 43.654 9.42963 43.6517 10.1262C43.6544 10.8239 43.5225 11.5152 43.2636 12.1598C43.0047 12.8045 42.6241 13.3896 42.1439 13.8812C41.6637 14.3728 41.0934 14.7611 40.4663 15.0234C39.8392 15.2857 39.1677 15.4168 38.491 15.4091C37.8155 15.415 37.1456 15.2825 36.5202 15.0193C35.8948 14.7562 35.3263 14.3676 34.8478 13.8761C34.3692 13.3847 33.9901 12.8001 33.7324 12.1564C33.4747 11.5127 33.3436 10.8226 33.3467 10.1262Z"
                    fill="white"/>
                <path
                    d="M48.8355 3.6717V5.16016H51.4125V7.2481H48.8322V15.0821C48.8322 17.7219 47.6956 19.35 45.3069 19.35C44.8779 19.3616 44.4513 19.2804 44.0547 19.1116L44.0943 17.0679C44.3813 17.1896 44.6893 17.2498 44.9996 17.245C46.2485 17.245 46.5591 16.4514 46.5591 15.1026V7.23788H44.5536V5.14994H46.5591V3.62061C46.5591 1.39642 47.8806 -0.0137053 50.1207 -0.0137053C50.6004 -0.0294295 51.0785 0.0515655 51.5282 0.224726V2.16961C51.1666 2.05511 50.7896 2.00107 50.4114 2.00952C49.3311 2.00952 48.8322 2.60559 48.8322 3.65807"
                    fill="white"/>
                <path
                    d="M62.3783 5.16016L57.9478 16.7C57.254 18.4882 56.1538 19.3602 54.5943 19.3602C54.0435 19.3822 53.4994 19.2301 53.0349 18.9242V16.8805C53.4233 17.1221 53.8698 17.2461 54.3234 17.2382C55.232 17.2382 55.8465 16.7818 56.2694 15.6101L52.3608 5.16357H54.8256L57.33 12.5923L59.9731 5.16357L62.3783 5.16016Z"
                    fill="white"/>
                <path
                    d="M63.8618 12.9877V0.214844H66.1746V12.7255C66.1746 13.1819 66.4058 13.3794 66.7528 13.3794C66.9067 13.3952 67.0616 13.3607 67.1955 13.2806V15.1302C66.8639 15.3327 66.4839 15.4354 66.0985 15.4265C64.6911 15.4265 63.8651 14.4319 63.8651 13.0047"
                    fill="white"/>
                <path
                    d="M70.7967 9.09416H75.5313C75.4354 7.58526 74.6062 6.84954 73.2185 6.84954C71.9498 6.84954 71.0611 7.62272 70.7934 9.09416M77.5533 10.9811H70.7736C71.0445 12.6297 72.0654 13.345 73.7471 13.345C74.9649 13.3835 76.1636 13.026 77.1733 12.3231V14.3906C76.1821 15.123 74.768 15.4125 73.572 15.4125C70.4894 15.4125 68.4873 13.4063 68.4873 10.1501C68.4873 7.0505 70.5093 4.86719 73.2449 4.86719C75.7493 4.86719 77.6557 6.61452 77.6557 9.75153C77.6623 10.1657 77.6235 10.5793 77.54 10.9845"
                    fill="white"/>
                <path
                    d="M86.7282 10.1468C86.7282 8.19854 85.552 6.94849 83.9727 6.94849C82.3934 6.94849 81.2569 8.23941 81.2569 10.1673C81.2569 12.0951 82.3934 13.3247 83.9727 13.3247C85.552 13.3247 86.7282 12.0713 86.7282 10.1468ZM88.6907 5.16029V15.0925H86.5167V13.6619C86.2214 14.2038 85.7887 14.6527 85.2649 14.9605C84.7411 15.2683 84.1459 15.4234 83.5432 15.4092C80.9793 15.4092 79.0366 13.1646 79.0366 10.1673C79.0366 7.16989 80.9794 4.86396 83.563 4.86396C84.125 4.84567 84.6819 4.97904 85.1785 5.25087C85.6752 5.5227 86.0943 5.92356 86.3945 6.41374V5.16029H88.6907Z"
                    fill="white"/>
                <path
                    d="M98.0344 10.1465C98.0344 8.19819 96.8615 6.94815 95.2789 6.94815C93.6963 6.94815 92.5631 8.23906 92.5631 10.1669C92.5631 12.0948 93.6996 13.3244 95.2789 13.3244C96.8582 13.3244 98.0344 12.0709 98.0344 10.1465ZM100.017 0.197266V15.0921H97.8395V13.6616C97.5458 14.205 97.1135 14.6551 96.5893 14.9631C96.0651 15.2712 95.4691 15.4253 94.8659 15.4089C92.3054 15.4089 90.3594 13.1643 90.3594 10.1669C90.3594 7.16955 92.3054 4.86362 94.8857 4.86362C95.4478 4.84498 96.0048 4.97821 96.5015 5.25007C96.9982 5.52193 97.4173 5.92297 97.7172 6.4134V0.214292L100.017 0.197266Z"
                    fill="white"/>
                <path
                    d="M5.07483 7.48014C5.07483 8.17392 4.80751 8.83928 4.33165 9.32986C3.85579 9.82043 3.21038 10.096 2.53741 10.096C1.86445 10.096 1.21904 9.82043 0.743182 9.32986C0.267324 8.83928 0 8.17392 0 7.48014C0 6.78636 0.267324 6.12101 0.743182 5.63043C1.21904 5.13986 1.86445 4.86426 2.53741 4.86426C3.21038 4.86426 3.85579 5.13986 4.33165 5.63043C4.80751 6.12101 5.07483 6.78636 5.07483 7.48014Z"
                    fill="black"/>
                <path
                    d="M11.154 7.48014C11.154 8.17392 10.8866 8.83928 10.4107 9.32986C9.93489 9.82043 9.28948 10.096 8.61652 10.096C7.94355 10.096 7.29816 9.82043 6.8223 9.32986C6.34645 8.83928 6.0791 8.17392 6.0791 7.48014C6.0791 6.78636 6.34645 6.12101 6.8223 5.63043C7.29816 5.13986 7.94355 4.86426 8.61652 4.86426C9.28948 4.86426 9.93489 5.13986 10.4107 5.63043C10.8866 6.12101 11.154 6.78636 11.154 7.48014Z"
                    fill="black"/>
                <path
                    d="M5.07483 13.4299C5.07483 14.1236 4.80751 14.789 4.33165 15.2796C3.85579 15.7702 3.21038 16.0458 2.53741 16.0458C1.86445 16.0458 1.21904 15.7702 0.743182 15.2796C0.267324 14.789 0 14.1236 0 13.4299C-2.83077e-07 13.0865 0.0656488 12.7465 0.193206 12.4293C0.320764 12.1121 0.50772 11.824 0.743384 11.5814C0.979048 11.3387 1.25881 11.1464 1.56664 11.0153C1.87448 10.8842 2.20435 10.8169 2.53741 10.8174C2.87048 10.8169 3.20035 10.8842 3.50819 11.0153C3.81602 11.1464 4.09578 11.3387 4.33145 11.5814C4.56711 11.824 4.75407 12.1121 4.88162 12.4293C5.00918 12.7465 5.07483 13.0865 5.07483 13.4299Z"
                    fill="black"/>
                <path
                    d="M11.154 13.4299C11.154 14.1236 10.8866 14.789 10.4107 15.2796C9.93489 15.7702 9.28948 16.0458 8.61652 16.0458C7.94355 16.0458 7.29816 15.7702 6.8223 15.2796C6.34645 14.789 6.0791 14.1236 6.0791 13.4299C6.0791 13.0865 6.14477 12.7465 6.27233 12.4293C6.39989 12.1121 6.58684 11.824 6.82251 11.5814C7.05817 11.3387 7.33791 11.1464 7.64575 11.0153C7.95358 10.8842 8.28345 10.8169 8.61652 10.8174C8.94958 10.8169 9.27947 10.8842 9.58731 11.0153C9.89514 11.1464 10.1749 11.3387 10.4105 11.5814C10.6462 11.824 10.8332 12.1121 10.9607 12.4293C11.0883 12.7465 11.154 13.0865 11.154 13.4299Z"
                    fill="black"/>
                <path
                    d="M5.07483 19.3844C5.07483 20.0782 4.80751 20.7436 4.33165 21.2341C3.85579 21.7247 3.21038 22.0003 2.53741 22.0003C1.86445 22.0003 1.21904 21.7247 0.743182 21.2341C0.267324 20.7436 0 20.0782 0 19.3844C0 18.6907 0.267324 18.0253 0.743182 17.5347C1.21904 17.0442 1.86445 16.7686 2.53741 16.7686C3.21038 16.7686 3.85579 17.0442 4.33165 17.5347C4.80751 18.0253 5.07483 18.6907 5.07483 19.3844Z"
                    fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_1458_3049">
                    <rect width="100" height="22" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgProfileClientLogo;