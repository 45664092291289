import React from 'react';

function SvgSidebarLeads(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0929 14.561C11.5062 14.7343 10.8129 14.8143 9.99958 14.8143H5.99958C5.18625 14.8143 4.49292 14.7343 3.90625 14.561C4.05292 12.8276 5.83292 11.4609 7.99958 11.4609C10.1662 11.4609 11.9462 12.8276 12.0929 14.561Z" stroke={props.color || "black"} />
            <path d="M9.9987 1.48047H5.9987C2.66536 1.48047 1.33203 2.8138 1.33203 6.14714V10.1471C1.33203 12.6671 2.09203 14.0471 3.90536 14.5605C4.05203 12.8271 5.83203 11.4604 7.9987 11.4604C10.1654 11.4604 11.9454 12.8271 12.092 14.5605C13.9054 14.0471 14.6654 12.6671 14.6654 10.1471V6.14714C14.6654 2.8138 13.332 1.48047 9.9987 1.48047ZM7.9987 9.59379C6.6787 9.59379 5.61203 8.52048 5.61203 7.20048C5.61203 5.88048 6.6787 4.8138 7.9987 4.8138C9.3187 4.8138 10.3854 5.88048 10.3854 7.20048C10.3854 8.52048 9.3187 9.59379 7.9987 9.59379Z" stroke={props.color || "black"} />
            <path d="M10.3866 7.19918C10.3866 8.51918 9.31995 9.59249 7.99995 9.59249C6.67995 9.59249 5.61328 8.51918 5.61328 7.19918C5.61328 5.87918 6.67995 4.8125 7.99995 4.8125C9.31995 4.8125 10.3866 5.87918 10.3866 7.19918Z" stroke={props.color || "black"} />
        </svg>
}

export default SvgSidebarLeads;