import React from 'react';

function InputCheckbox({id, handleClick, isChecked, classRadius}) {

    return (
        <div className="flex items-center justify-center relative">
            <input
                type="checkbox"
                className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 invisible"
                id={`${ id }`}
                onChange={ handleClick }
                checked={ isChecked }
            />
            <label
                htmlFor={`${id}`}
                className={`${!!classRadius ? classRadius : "" } w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 bg-[#FFFFFF] rounded-full cursor-pointer absolute border border-[#cccccc] border-solid
                after:border-[0.1rem] after:border-solid after:border-[#ffffff] after:border-t-0 after:border-r-0 after:content-['']
                after:h-1 after:w-2.5 after:absolute after:top-2 after:left-1.5 after:opacity-0 after:rotate-[-45deg]`}
            />
        </div>
    );
}

export default InputCheckbox;