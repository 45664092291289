import React from 'react';

function SvgFooterConnection({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.9999 9.88746C22.9624 9.88746 22.9374 9.88746 22.8999 9.88746H22.8374C20.4749 9.81246 18.7124 7.98745 18.7124 5.73745C18.7124 3.43745 20.5874 1.57495 22.8749 1.57495C25.1624 1.57495 27.0374 3.44995 27.0374 5.73745C27.0249 7.99995 25.2624 9.82496 23.0124 9.89996C23.0124 9.88746 23.0124 9.88746 22.9999 9.88746ZM22.8749 3.43746C21.6124 3.43746 20.5874 4.46246 20.5874 5.72496C20.5874 6.96246 21.5499 7.96246 22.7874 8.01246C22.7999 7.99996 22.8999 7.99996 23.0124 8.01246C24.2249 7.94996 25.1624 6.94996 25.1749 5.72496C25.1749 4.46246 24.1499 3.43746 22.8749 3.43746Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M23.0126 19.1001C22.5251 19.1001 22.0376 19.0626 21.5501 18.9751C21.0376 18.8876 20.7001 18.4001 20.7876 17.8876C20.8751 17.3751 21.3626 17.0376 21.8751 17.1251C23.4126 17.3876 25.0376 17.1001 26.1251 16.3751C26.7126 15.9876 27.0251 15.5001 27.0251 15.0126C27.0251 14.5251 26.7001 14.0501 26.1251 13.6626C25.0376 12.9376 23.3876 12.6501 21.8376 12.9251C21.3251 13.0251 20.8376 12.6751 20.7501 12.1626C20.6626 11.6501 21.0001 11.1626 21.5126 11.0751C23.5501 10.7126 25.6626 11.1001 27.1626 12.1001C28.2626 12.8376 28.9001 13.8876 28.9001 15.0126C28.9001 16.1251 28.2751 17.1876 27.1626 17.9376C26.0251 18.6876 24.5501 19.1001 23.0126 19.1001Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M7.9626 9.8875C7.9501 9.8875 7.9376 9.8875 7.9376 9.8875C5.6876 9.8125 3.9251 7.9875 3.9126 5.7375C3.9126 3.4375 5.7876 1.5625 8.0751 1.5625C10.3626 1.5625 12.2376 3.4375 12.2376 5.725C12.2376 7.9875 10.4751 9.8125 8.2251 9.8875L7.9626 8.95L8.0501 9.8875C8.0251 9.8875 7.9876 9.8875 7.9626 9.8875ZM8.0876 8.0125C8.1626 8.0125 8.2251 8.0125 8.3001 8.025C9.4126 7.975 10.3876 6.975 10.3876 5.7375C10.3876 4.475 9.3626 3.44999 8.1001 3.44999C6.8376 3.44999 5.8126 4.475 5.8126 5.7375C5.8126 6.9625 6.7626 7.95 7.9751 8.025C7.9876 8.0125 8.0376 8.0125 8.0876 8.0125Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M7.95 19.1001C6.4125 19.1001 4.9375 18.6876 3.8 17.9376C2.7 17.2001 2.0625 16.1376 2.0625 15.0126C2.0625 13.9001 2.7 12.8376 3.8 12.1001C5.3 11.1001 7.4125 10.7126 9.45 11.0751C9.9625 11.1626 10.3 11.6501 10.2125 12.1626C10.125 12.6751 9.6375 13.0251 9.125 12.9251C7.575 12.6501 5.9375 12.9376 4.8375 13.6626C4.25 14.0501 3.9375 14.5251 3.9375 15.0126C3.9375 15.5001 4.2625 15.9876 4.8375 16.3751C5.925 17.1001 7.55 17.3876 9.0875 17.1251C9.6 17.0376 10.0875 17.3876 10.175 17.8876C10.2625 18.4001 9.925 18.8876 9.4125 18.9751C8.925 19.0626 8.4375 19.1001 7.95 19.1001Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M15.4999 19.2251C15.4624 19.2251 15.4374 19.2251 15.3999 19.2251H15.3374C12.9749 19.1501 11.2124 17.3251 11.2124 15.0751C11.2124 12.7751 13.0874 10.9126 15.3749 10.9126C17.6624 10.9126 19.5374 12.7876 19.5374 15.0751C19.5249 17.3376 17.7624 19.1626 15.5124 19.2376C15.5124 19.2251 15.5124 19.2251 15.4999 19.2251ZM15.3749 12.7751C14.1124 12.7751 13.0874 13.8001 13.0874 15.0626C13.0874 16.3001 14.0499 17.3001 15.2874 17.3501C15.2999 17.3376 15.3999 17.3376 15.5124 17.3501C16.7249 17.2876 17.6624 16.2876 17.6749 15.0626C17.6749 13.8126 16.6499 12.7751 15.3749 12.7751Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M15.4996 28.4499C13.9996 28.4499 12.4996 28.0624 11.3371 27.2749C10.2371 26.5374 9.59961 25.4874 9.59961 24.3624C9.59961 23.2499 10.2246 22.1749 11.3371 21.4374C13.6746 19.8874 17.3371 19.8874 19.6621 21.4374C20.7621 22.1749 21.3996 23.2249 21.3996 24.3499C21.3996 25.4624 20.7746 26.5374 19.6621 27.2749C18.4996 28.0499 16.9996 28.4499 15.4996 28.4499ZM12.3746 23.0124C11.7871 23.3999 11.4746 23.8874 11.4746 24.3749C11.4746 24.8624 11.7996 25.3374 12.3746 25.7249C14.0621 26.8624 16.9246 26.8624 18.6121 25.7249C19.1996 25.3374 19.5121 24.8499 19.5121 24.3624C19.5121 23.8749 19.1871 23.3999 18.6121 23.0124C16.9371 21.8749 14.0746 21.8874 12.3746 23.0124Z" fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgFooterConnection;