import React from 'react';

function SvgStatisticTeamMemberThree(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M36.5221 16.6869C36.3763 16.666 36.2305 16.666 36.0846 16.6869C32.8555 16.5827 30.293 13.9368 30.293 10.6868C30.293 7.37435 32.9805 4.66602 36.3138 4.66602C39.6263 4.66602 42.3346 7.35351 42.3346 10.6868C42.3138 13.9368 39.7513 16.5827 36.5221 16.6869Z" fill="#4B5563"/>
            <path opacity="0.4" d="M43.3112 31.1258C40.9779 32.6883 37.707 33.2716 34.6862 32.8758C35.4779 31.1675 35.8945 29.2717 35.9154 27.2717C35.9154 25.1883 35.457 23.2092 34.582 21.48C37.6654 21.0633 40.9362 21.6466 43.2904 23.2091C46.582 25.3758 46.582 28.9383 43.3112 31.1258Z" fill="#373742"/>
            <path opacity="0.4" d="M13.418 16.6869C13.5638 16.666 13.7096 16.666 13.8555 16.6869C17.0846 16.5827 19.6471 13.9368 19.6471 10.6868C19.6471 7.37435 16.9596 4.66602 13.6263 4.66602C10.3138 4.66602 7.60547 7.35351 7.60547 10.6868C7.6263 13.9368 10.1888 16.5827 13.418 16.6869Z" fill="#373742"/>
            <path opacity="0.4" d="M13.6458 27.2701C13.6458 29.291 14.0833 31.2076 14.875 32.9368C11.9375 33.2493 8.875 32.6243 6.625 31.1451C3.33333 28.9576 3.33333 25.3951 6.625 23.2076C8.85417 21.7076 12 21.1034 14.9583 21.4368C14.1042 23.1868 13.6458 25.166 13.6458 27.2701Z" fill="#373742"/>
            <path d="M25.25 33.5625C25.0833 33.5417 24.8958 33.5417 24.7083 33.5625C20.875 33.4375 17.8125 30.2917 17.8125 26.4167C17.8125 22.4583 21 19.25 24.9792 19.25C28.9375 19.25 32.1458 22.4583 32.1458 26.4167C32.1458 30.2917 29.1042 33.4375 25.25 33.5625Z" fill="#373742"/>
            <path d="M18.4805 37.875C15.3346 39.9792 15.3346 43.4375 18.4805 45.5208C22.0638 47.9167 27.9388 47.9167 31.5221 45.5208C34.668 43.4167 34.668 39.9583 31.5221 37.875C27.9596 35.4792 22.0846 35.4792 18.4805 37.875Z" fill="#373742"/>
        </svg>
    );
}

export default SvgStatisticTeamMemberThree;