import React, {useEffect, useState} from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Icons} from "../../../assets/Svgs";
import {getFullPath} from "../../../router/Router";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import ContractsService from "../../../services/Contract/ContractsService";
import FieldCopy from "../../../components/Layouts/FieldCopy";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import {format} from "date-fns";

function ContractView() {

    const { t: _ } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ contract, setContract ] = useState({});

    const buttons = [
        {
            label: "Edit",
            className: "bg-white text-black cursor-pointer",
            iconColor: "#000000",
            icon: <Icons.SvgBrushOutline className="h-3" />,
            onClick: () => navigate('./edit', { replace: true }),
        },
        {
            label: "Files",
            className: "bg-white text-black",
            iconColor: "#000000",
            icon: <Icons.SvgCardUnAssignOutline className="h-3" />,
            onClick: () => navigate('./files/' + contract.files_id, { replace: true }),
        },
        {
            label: "Reassign",
            className: "bg-white text-black",
            iconColor: "#000000",
            icon: <Icons.SvgArrowLeftRightOutline className="h-3" />,
        },
        {
            label: "Remove",
            className: "bg-white text-[#FF0000]",
            iconColor: "#FF0000",
            icon: <Icons.SvgTrashOutline className="h-3" />,
        }
    ]

    const inputs = [
        {
            id: 1,
            name: "contract_ref",
            type: "text",
            placeholder: "",
            label: "Contract Reference",
            canCopy: true,
        },
        {
            id: 2,
            name: "business_name",
            type: "text",
            placeholder: "",
            label: "Business Name",
            canCopy: true,
        },
        {
            id: 3,
            name: "manager_name",
            type: "text",
            placeholder: "",
            label: "Manager Name",
            canCopy: true,
        },
        {
            id: 4,
            name: "business_mail",
            type: "text",
            placeholder: "",
            label: "Business Mail",
            canCopy: true,
        },
        {
            id: 5,
            name: "business_tel",
            type: "text",
            placeholder: "",
            label: "Business Phone Number",
            canCopy: true,
        },
        {
            id: 6,
            name: "currency",
            type: "text",
            placeholder: "",
            label: "Currency",
            canCopy: false,
        },
        {
            id: 7,
            name: "pay_plan",
            type: "text",
            placeholder: "",
            label: "Pay Plan",
            canCopy: false,
        },
        {
            id: 8,
            name: "monthly_payment",
            type: "text",
            placeholder: "",
            label: "Monthly Recurrent Payment",
            canCopy: true,
        },
        {
            id: 9,
            name: "annual_payment",
            type: "text",
            placeholder: "",
            label: "Annual Recurrent Payment",
            canCopy: true,
        },
        {
            id: 10,
            name: "total_earnings",
            type: "text",
            placeholder: "",
            label: "Total earnings from the beginning",
            canCopy: true,
        },
        {
            id: 11,
            name: "adress",
            type: "text",
            placeholder: "",
            label: "Adress",
            canCopy: true,
        },
        {
            id: 12,
            name: "country",
            type: "text",
            placeholder: "",
            label: "Country",
            canCopy: true,
        },
        {
            id: 13,
            name: "added_at",
            type: "text",
            placeholder: "",
            label: "Added Date",
            canCopy: true,
        },
        {
            id: 14,
            name: "city",
            type: "text",
            placeholder: "",
            label: "City",
            canCopy: true,
        },
        {
            id: 15,
            name: "postal_code",
            type: "text",
            placeholder: "",
            label: "ZIP/Postal Code",
            canCopy: true,
        },
        {
            id: 16,
            name: "started_at",
            type: "text",
            placeholder: "",
            label: "Started Contract Date",
            canCopy: true,
        },
        {
            id: 17,
            name: "note",
            type: "text",
            placeholder: "",
            label: "Note",
            canCopy: true,
        },
    ]

    useEffect(() => {
        setLoading(true);
        ContractsService.getContractById(id)
            .then(resContract => {
                setContract(resContract);
            })
            .catch(err => {
                console.error(err);
                navigate('../', { replace: true });
            })
            .finally(() => {
                setLoading(false);
            })
    }, [contract]);

    return (
        <div className="p-7 flex flex-col gap-7">
            <header className=" flex justify-between items-center border-gray-200">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={ getFullPath(["ROOT", "CONTRACTS"]) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Contracts")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("View")}
                </div>

                <div className="flex items-center gap-7">
                    <Link to={ getFullPath(["ROOT", "CONTRACTS"]) } className="flex justify-center text-sm gap-[0.438rem]">
                        <ButtonBack isFlexible={false}/>
                    </Link>
                </div>
            </header>

            <div className="flex-1 min-h-0 overflow-auto">
                <div className="flex gap-7 flex-col overflow-x-scroll">
                    <div className="flex flex-col gap-7 p-7 rounded-[1.25rem] bg-white">

                        <div className="md:flex w-full sm:flex-row gap-7 align-center justify-center">
                            {
                                buttons.map((button, index) => (
                                    <div key={index} onClick={button.onClick} className="w-full md:grid lg:flex justify-center align-center gap-7">
                                        <ButtonBase iconColor={button.iconColor} icon={button.icon} className={`${button.className}  border border-[#D1D5DB] py-3 px-6 w-full text-sm font-medium rounded-full`}>
                                            { _(button.label) }
                                        </ButtonBase>
                                    </div>
                                ))
                            }
                        </div>

                        <div className="w-full bg-white grid grid-rows-10 grid-flow-col gap-2">
                            {
                                inputs.map((input) => (
                                    input.name === "added_at"
                                        ? <FieldCopy key={ input.id } label={ _(input.label) } canCopy={ input.canCopy }>
                                            { contract.added_at ? format(new Date(contract.added_at), 'dd/MM/yyyy') : '-' }
                                        </FieldCopy>
                                        : input.name === "started_at"
                                        ? <FieldCopy key={ input.id } label={ _(input.label) } canCopy={ input.canCopy }>
                                            { contract.started_at ? format(new Date(contract.started_at), 'dd/MM/yyyy') : '-' }
                                        </FieldCopy>
                                        : <FieldCopy key={ input.id } label={ _(input.label) } canCopy={ input.canCopy }>
                                            { contract[input.name] }
                                        </FieldCopy>
                                ))
                            }

                            {
                                contract.assigned_to
                                    ? <FieldCopy label="Assigned to" canCopy={false}>
                                        <div className="flex items-center justify-center gap-[1.5rem]">
                                            <img src={ contract.assigned_to[0].avatar } className="rounded-full h-8 w-8"  alt="avatar" />
                                            <span>{ contract.assigned_to[0].full_name }</span>
                                        </div>
                                    </FieldCopy>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContractView;