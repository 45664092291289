import React, {useEffect, useState} from 'react';
import {Icons} from "../../assets/Svgs";
import ButtonBase from "../Buttons/ButtonBase";

function InputTel({label, onChange, setValues, telLocaleCurrent, fontClass, gapClass, ...inputProps}) {
    const [ valid, setValid ] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [ telCurrent, setLanguageCurrent ] = useState(!!telLocaleCurrent ? telLocaleCurrent : "fr");

    const handleChange = (e) => {
        setValid(e.target.validity.valid);
        onChange(e);
    }

    const telLocale = {
        "us": {
            label: "+340",
            locale: "us",
            country: "United_States",
            icon: <Icons.SvgTranslateEnglish />,
        },
        "fr": {
            label: "+33",
            locale: "fr",
            country: "France",
            icon: <Icons.SvgTranslateFrench />,
        },
        "it": {
            label: "+39",
            locale: "it",
            country: "Italy",
            icon: <Icons.SvgTranslateItalian />,
        },
    }

    useEffect(() => {
        const updateLocalStorage = () => {
            setValues(prevValues => ({
                ...prevValues,
                tel: inputProps.value || "",
                country: telLocale[telCurrent].locale || "",
            }));
        };
        updateLocalStorage();
    }, [telCurrent]);

    return (
        <div className="overflow-hidden">
            <label className={`flex flex-col ${!!gapClass ? gapClass : ""}`}>
                <div className="flex">
                    <span className={`${!!inputProps.required ? 'after:content-[\'*\'] after:ml-0 after:text-red-500' : ''} text-sm font-medium flex text-[#4B5563]`}>
                        { label }
                    </span>
                </div>

                <div className="relative">
                    <input
                        className={` ${ !!fontClass ? fontClass : "text-sm text-gray-900" } focus:outline-none w-full bg-white border border-[#EEEFF2] pl-28 py-3 rounded-lg`}
                        type={ inputProps.type }
                        name={ inputProps.name }
                        placeholder={ inputProps.placeholder }
                        disabled={ inputProps.disabled }
                        pattern={ inputProps.pattern }
                        required={ inputProps.required }
                        value={ inputProps.value }
                        onChange={ handleChange }
                    />
                    <ButtonBase onClick={() => { setToggle((prev) => !prev)} } className="text-sm border-r absolute inset-y-0 left-0 flex items-center gap-2 p-4">
                        { telLocale[telCurrent].icon }
                        { telLocale[telCurrent].label }
                    </ButtonBase>
                </div>
            </label>

            <div className="flex flex-1 justify-end items-center cursor-pointer">
                <div className={`${toggle ? 'flex' : 'hidden'} top-48 left-5 bg-white absolute min-w-[17.5rem] sidebar z-30`}>
                    <ul className="list-none flex flex-col justify-end items-center flex-1 shadow-lg rounded-[1.25rem]">
                        {
                            Object.keys(telLocale).map((tel, index) => (
                                <li
                                    value={tel}
                                    onClick={(e) => {
                                        const newLanguage = e.target.getAttribute('value')
                                        if(Object.keys(telLocale).includes(newLanguage)){
                                            setLanguageCurrent(newLanguage);
                                            setValues(prevValues => ({
                                                ...prevValues, country: telLocale[telCurrent].locale || "",
                                            }));
                                        }
                                    }}
                                    className={`font-normal select-none text-sm w-full flex justify-center gap-2 px-4 py-3.5 hover:border hover:border-2`} key={index}
                                >
                                    { telLocale[tel].icon } { telLocale[tel].label }
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default InputTel;