import React from 'react';

function SvgSelectorCSV(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.8765 25.125L43.5432 24.6667C42.9599 23.9583 42.2724 23.3958 41.4807 22.9792C40.4182 22.375 39.2099 22.0625 37.9599 22.0625H12.0224C10.7724 22.0625 9.58488 22.375 8.50155 22.9792C7.68905 23.4167 6.95988 24.0208 6.35572 24.7708C5.16822 26.2917 4.60572 28.1667 4.79322 30.0417L5.56405 39.7708C5.83488 42.7083 6.18905 46.3333 12.7932 46.3333H37.2099C43.814 46.3333 44.1474 42.7083 44.439 39.75L45.2099 30.0625C45.3974 28.3125 44.939 26.5625 43.8765 25.125ZM29.9807 36.625H20.0016C19.1891 36.625 18.5432 35.9583 18.5432 35.1667C18.5432 34.375 19.1891 33.7083 20.0016 33.7083H29.9807C30.7932 33.7083 31.4391 34.375 31.4391 35.1667C31.4391 35.9792 30.7932 36.625 29.9807 36.625Z" fill="#4B5563"/>
            <path opacity="0.4" d="M7.04297 24.0618C7.5013 23.6452 7.95964 23.2702 8.5013 22.9785C9.5638 22.3743 10.7721 22.0618 12.0221 22.0618H37.9805C39.2305 22.0618 40.418 22.3743 41.5013 22.9785C42.043 23.2702 42.5221 23.6452 42.9596 24.0827V22.9785V20.9577C42.9596 13.5202 40.6888 11.2493 33.2513 11.2493H28.293C27.3763 11.2493 27.3555 11.2285 26.8138 10.5202L24.3138 7.16602C23.1263 5.62435 22.1888 4.66602 19.2096 4.66602H16.7513C9.3138 4.66602 7.04297 6.93685 7.04297 14.3743V22.9993V24.0618Z" fill="#4B5563"/>
        </svg>
    );
}

export default SvgSelectorCSV;