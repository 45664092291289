import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icons } from "../../../assets/Svgs";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import ButtonTemplateDelete from "../../../components/Buttons/ButtonTemplateDelete";
import IPhoneWrapper from '../../../components/Layouts/IPhoneWrapper';
import PopupGoBackQuestion from "../../../components/Popups/SaveModification/PopupGoBackQuestion";
import PopupSaveModification from "../../../components/Popups/SaveModification/PopupSaveModification";
import WidgetEdit from "../../../components/Widgets/WidgetEdit";
import { getFullPath } from "../../../router/Router";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import TemplateService from '../../../services/Template/TemplateService';
import WidgetService from "../../../services/Widget/WidgetService";
import Portfolio from '../../Mobile/Portfolio';

function TemplateDistributorSection() {
    const { t: _ } = useTranslation();
    const { addPopup, clearAllPopups } = usePopup();
    const { id, idType, widgetId } = useParams();
    const navigate = useNavigate();
    const [ widget, setWidget ] = useState({
        portfolio: parseInt(id),
        widget_type: parseInt(idType),
        data: {},
    });
    const widgetEditRef = useRef();

    const isLoading = !widget.widget_type || (widgetId !== undefined && !widget.id);

    const handleWidgetChange = (updatedWidget) => {
        setWidget(updatedWidget);
        // Optionally update other states if the updatedWidget affects them
    };

    const onWidgetSaved = (savedWidget) => {
        setWidget(savedWidget);
        TemplateService.resetCache();
        if (widgetId === undefined) {
            navigate(getFullPath(["ROOT", "TEMPLATES", "EDIT_TEMPLATE", "EDIT_WIDGET"]).replace(":id", id).replace(":idType", idType).replace(":widgetId", savedWidget.id));
        }
    };

    const handleWidgetSave = () => {
        // Save the widget
        if (widgetEditRef.current && widget.id != null) {
            widgetEditRef.current.handleSave();
        } else {
            widgetEditRef.current.handleCreate();
        }
    };

    useEffect(() => {
        if(!!widgetId){
            WidgetService.getWidgetById(parseInt(widgetId))
                .then(data => {
                    setWidget(data);
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            TemplateService.getTemplateById(parseInt(id))
                .then(template => {
                    setWidget(prev => ({
                        ...prev,
                        order: template.widgets.reduce((acc, w) => (w.order > acc) ? w.order : acc, 0) + 1,
                    }));
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }, [widgetId]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return <div className="flex flex-col flex-1 overflow-hidden p-7 gap-7">
        <header className="flex justify-between items-center border-gray-200">
            <div className="text-xl gap-3 flex items-center">
                <Link to={getFullPath(["ROOT", "TEMPLATES"])} className="flex justify-center">
                    <p className="hover:underline hover:select-all">{_("Templates")}</p>
                </Link>

                <Icons.SvgArrowRightOutline color="#000000" />

                <Link to={getFullPath(["ROOT", "TEMPLATES", "EDIT_TEMPLATE"]).replace(":id", id)} className="flex justify-center">
                    <p className="hover:underline hover:select-all">{_("Edit")}</p>
                </Link>

                <Icons.SvgArrowRightOutline color="#000000" />

                <p className="whitespace-nowrap">{_("Section")}</p>
            </div>

            <div className="flex items-center gap-7">
                <ButtonTemplateDelete isFlexible={true} />

                <ButtonBack
                    onClick={() => {
                        navigate('../', { replace: true });
                        return;
                        // TODO: add back the popup
                        addPopup(
                            <PopupGoBackQuestion
                                backPrev={() => { navigate('../', { replace: true }); clearAllPopups(); }}
                                save={() => { navigate('../', { replace: true }); clearAllPopups() }}
                            />
                        )
                    }}
                    isFlexible={true}
                />

                <ButtonBase
                    onClick={() => {
                        addPopup(
                            <PopupSaveModification
                                onHandleRedirectToPrev={handleWidgetSave}
                            />
                        )
                    }}
                    className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full"
                    iconColor="white"
                    icon={<Icons.SvgAssignOutline color="white" />}
                >
                    {_("Save the modifications")}
                </ButtonBase>
            </div>
        </header>

        <div className="flex flex-1 overflow-hidden justify-between gap-7">
            <div className="flex-[2] align-center bg-white p-7 rounded-[1.25rem]">
                <WidgetEdit ref={widgetEditRef} widget={widget} onChange={handleWidgetChange} onSave={onWidgetSaved} />
            </div>

            <div className="flex flex-col flex-1 align-center py-7 gap-7">
                <div className="flex justify-center align-center text-sm">
                    {_("Template Live Preview")}
                </div>
                
                <IPhoneWrapper>
                    <Portfolio page={{ widgets:[widget] }} displayActionBar={false} />
                </IPhoneWrapper>
            </div>
        </div>
    </div>
}

export default TemplateDistributorSection;
