import {
    TOP_COMPLETE_PERFORMERS_DATAS,
} from "../../constants/DatasConstants";

let topCompletePerformers = null;

export const getTopPerforms = () => {
    return new Promise((resolve, reject) => {
        if (topCompletePerformers === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            //data template
            topCompletePerformers = TOP_COMPLETE_PERFORMERS_DATAS;
            resolve(topCompletePerformers);
        } else {
            resolve(topCompletePerformers);
        }
    });
}

export const getPerfomById = (id) => {
    return new Promise((resolve, reject) => {
        if ((topCompletePerformers === null)) {
            getTopPerforms()
                .then(performs => {
                    const perform = performs.find((item) => item.id.toString() === id.toString());
                    if(perform){
                        resolve(perform)
                    }else{
                        reject(new Error("Perform not found"));
                    }

                })
                .catch(err => reject(err))
        } else {
            const perform = topCompletePerformers.find((item) => item.id.toString() === id.toString());
            if(perform){
                resolve(perform)
            }else{
                reject(new Error("Lead not found"));
            }
        }
    });
}


export default {
    getTopPerforms,
    getPerfomById,
};