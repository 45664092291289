import React, {useEffect} from 'react';
import {Icons} from "../../assets/Svgs";
import InputGroup from "../Layouts/InputGroup";
import {useParams} from "react-router-dom";
import InputUploadImage from "../Layouts/InputUploadImage";

function WidgetWebsite(
    {
        classContainerList,
        fontClass,
        widthInputClass,
        hasInnerLabel,
        classGapLabelToField,
        hasIcon,
        formSubmit,
        websiteValues,
        setWebsiteValues,
        onChange,
    }) {

    const { idContent } = useParams();

    const inputs = [
        {
            id: 1,
            name: "title",
            type: "text",
            placeholder: "",
            label: "Website Title",
            errorMsg: "",
            pattern: "",
            required: true,
            icon: <Icons.SvgProfileEditText className="absolute top-1/2 left-3 -translate-y-1/2 h-4" />
        },
        {
            id: 2,
            name: "link",
            type: "text",
            placeholder: "",
            label: "Website Link",
            errorMsg: "",
            pattern: "",
            required: true,
            icon: <Icons.SvgProfilePopupMediaLink className="absolute top-1/2 left-3 -translate-y-1/2 h-4" />
        },
    ]

    useEffect(() => {
        // Add new Website Widget in Admin
        if(window.location.href.includes("content")){
            setWebsiteValues(
                {
                    ...websiteValues,
                    ["img"]: "",
                    ["title"]: "",
                    ["link"]: "",
                }
            )
        }
        // Edit Website in Mobile
        if(idContent == null){
            setWebsiteValues(
                {
                    ...websiteValues,
                    ["title"]: !!websiteValues ? websiteValues.title : "",
                    ["link"]: !!websiteValues ? websiteValues.link : "",
                    ["img"]: !!websiteValues ? websiteValues.img : "",
                }
            )
        }
    }, []);

    // useEffect(() => {
    //     console.log(websiteValues);
    // }, [websiteValues]);

    return (
        <form className="grid w-full h-full gap-7" onSubmit={ formSubmit }>
            {
                // Template Website
                window.location.href.includes("content") && (
                    <div className="w-full">
                        <InputUploadImage
                            isWebsite={true}
                            buttonStyleClass="bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full"
                            buttonInnerText="Add a new icon"
                            values={websiteValues}
                            setValues={setWebsiteValues}
                            label="Website Icon"
                            previewImageClass="w-16 h-16 rounded-[0.875rem]"
                        />
                    </div>
                )
            }

            <div className={`${!!classContainerList ? classContainerList : ""}`}>
                {
                    inputs.map((input, index) => (
                        <InputGroup
                            key={index}
                            fontClass={!!fontClass ? fontClass : ""}
                            widthInputClass={!!widthInputClass ? widthInputClass : ""}
                            name={input.name}
                            label={!!hasInnerLabel && hasInnerLabel == true ? input.label : ""}
                            placeholder={!!hasInnerLabel && hasInnerLabel == true ? input.label : ""}
                            value={ !!websiteValues ? websiteValues[input.name] : "" }
                            onChange={ onChange }
                            icon={ !!hasIcon && hasIcon == true ? input.icon : null }
                            classGapLabelToField={!!classGapLabelToField ? classGapLabelToField : ""}
                        />
                    ))
                }
            </div>
        </form>
    );
}

export default WidgetWebsite;