import React from 'react';

function SvgTemplateSectionListDraggable(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-3" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.84766 3.5H10.1553" stroke={props.color || "black"} strokeLinecap="round"/>
            <path d="M1.84766 6H10.1553" stroke={props.color || "black"} strokeLinecap="round"/>
            <path d="M1.84766 8.5H10.1553" stroke={props.color || "black"} strokeLinecap="round"/>
        </svg>
    );
}

export default SvgTemplateSectionListDraggable;