import React from 'react';
import SelectorTutorialVideo from "./SelectorTutorialVideo";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import {useTranslation} from "../../../services/i18n/i18nService";

function LayoutTutorialVideos() {
    const { t: _ } = useTranslation();

    const sections = [
        {
            title: "Setup something",
            videos: [
                {
                    id: 1,
                    src: "https://www.youtube.com/embed/5xuZxGirWQI", /* replace youtube link watch?v= with embed/ */
                    title: "How to ABC ?",
                },
                {
                    id: 2,
                    src: "https://www.youtube.com/embed/kKvK2foOTJM",
                    title: "How to ABC ?",
                }
            ]
        },
        {
            title: "Setup some other ones",
            videos: [
                {
                    id: 1,
                    src: "https://www.youtube.com/embed/5MgBikgcWnY",
                    title: "How to ABC ?",
                },
                {
                    id: 2,
                    src: "https://www.youtube.com/embed/P6FORpg0KVo",
                    title: "How to ABC ?",
                },
                {
                    id: 3,
                    src: "https://www.youtube.com/embed/9S3FRENg43I",
                    title: "How to ABC ?",
                },
                {
                    id: 4,
                    src: "https://www.youtube.com/embed/5xuZxGirWQI",
                    title: "How to ABC ?",
                }
            ]
        }
    ]

    return (
        <div className="flex flex-col w-full gap-7">
            {
                sections.map((section, index) => (
                    <div className="flex flex-col gap-4 w-full" key={index}>
                        <label className="text-base">{_(section.title)}</label>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-7 h-auto">
                            {
                                section.videos.map((video) => (
                                    <SelectorTutorialVideo key={video.id}>
                                        <iframe
                                            className="w-3/6 h-fit"
                                            src={video.src}
                                        />
                                        <div className="text-[0.875rem] leading-[0.875rem] font-medium">{_(video.title)}</div>
                                        <ButtonBase className="bg-white text-black text-sm rounded-full cursor-pointer px-3.5 py-2 rounded-full">
                                            {_("Watch")}
                                        </ButtonBase>
                                    </SelectorTutorialVideo>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default LayoutTutorialVideos;