import { Reorder } from "framer-motion";
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Icons } from "../../../assets/Svgs";
import { contactIconOf } from "../../../constants/ContactsTypes";
import { WIDGET_FIELD_TYPES } from "../../../constants/Widgets";
import PopupFieldTypeSelector from "../../../pages/Admin/Templates/Popups/PopupFieldTypeSelector";
import PopupItemDelete from "../../../pages/Mobile/Popups/PopupItemDelete";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import WidgetService from "../../../services/Widget/WidgetService";
import { widgetFieldIconOfType } from "../../../utils/Widgets";
import ButtonBase from "../../Buttons/ButtonBase";
import ButtonToggle from "../../Buttons/ButtonToggle";
import TextField from "../../Inputs/TextField";
import PopupBase from "../../Popups/PopupBase";

const WidgetFormEditSteps = {
    FORM_MAIN: "FORM_MAIN",
    FORM_DETAIL: "FORM_DETAIL",
}

const WidgetFormEdit = forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
) => {
    useImperativeHandle(ref, () => ({
        handleSave,
        handleCreate
    }));

    /* Hooks */
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();

    /* States */
    const [data, setData] = useState({
        ctaLabel: widget?.data?.ctaLabel || "",
        title: widget?.data?.title || "",
        form: (widget?.data?.form || []).map((item, index) => ({ ...item, id: index })),
        isForExchangeContact: widget?.data?.isForExchangeContact || false
    });
    const [step, setStep] = useState(WidgetFormEditSteps.FORM_MAIN);
    const [editedField, setEditedField] = useState(null);

    /* Utils */
    const buildPayload = (widgetData) => {
        return {
            widget: {
                ...widget,
                data: {
                    ctaLabel: widgetData.ctaLabel,
                    title: widgetData.title,
                    form: widgetData.form.map((item) => ({
                        key: item.key,
                        type: item.type,
                        details: item.details,
                        required: !!item.required,
                        order: item.order,
                    })),
                    isForExchangeContact: widgetData.isForExchangeContact || false
                }
            },
        }
    }

    /* Handlers */
    const handleSave = () => {
        const payload = buildPayload(data);
        WidgetService.updateWidget(widget.id, payload.widget)
            .then((savedWidget) => {
                onSave(savedWidget);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleCreate = () => {
        const payload = buildPayload(data);
        WidgetService.createWidget(payload.widget)
            .then((savedWidget) => {
                onSave(savedWidget);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleChange = (updatedData) => {
        setData(updatedData);
        onChange({ ...widget, data: updatedData });
    };

    const handleReorder = (orderedItems) => {
        handleChange({
            ...data,
            form: orderedItems.map((item, index) => ({ ...item, order: index })),
        });
    };

    const handleFieldChange = (updatedField) => {
        updatedField.id = editedField == null
            ? data.form.reduce((maxId, item) => Math.max(maxId, item.id), 0) + 1
            : editedField.id;
        if (editedField === null) {
            handleReorder([
                ...data.form,
                updatedField
            ]);
        } else {
            handleReorder(
                data.form.map((item) => (item.id === updatedField.id ? updatedField : item)),
            );
        }
        setEditedField(updatedField);
    };

    const handleFieldDelete = (id) => {
        handleReorder(data.form.filter(item => item.id !== id));
    }

    /* Renders */
    const renderAddFieldButton = () => {
        return <ButtonBase
            className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full w-fit"
            iconColor="white"
            icon={<Icons.SvgTemplateAddNewSection color="white" className="w-3" />}
            onClick={() => { setStep(WidgetFormEditSteps.FORM_DETAIL); }}
        >
            {_("Add a new form field")}
        </ButtonBase>
    }

    const renderPreviousButton = () => {
        return <ButtonBase
            className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full self-end w-fit"
            iconColor="white"
            icon={<Icons.SvgArrowReturnOutline color="white" className="w-3" />}
            onClick={() => { 
                setStep(WidgetFormEditSteps.FORM_MAIN);
                setEditedField(null);
            }}
        >
            {_("Previous")}
        </ButtonBase>
    }

    const renderFieldIcon = (item) => {
        return contactIconOf({type: item.type});
    }

    return (
        <div className="flex flex-col w-full h-full gap-7">
            {
                {
                    [WidgetFormEditSteps.FORM_MAIN]: renderAddFieldButton,
                    [WidgetFormEditSteps.FORM_DETAIL]: renderPreviousButton,
                }[step]()
            }

            {
                {
                    [WidgetFormEditSteps.FORM_MAIN]: (
                        <div className="flex flex-col h-full gap-7 overflow-hidden">
                            <TextField
                                name="ctaLabel"
                                type="text"
                                label="Call-to-Action Button label"
                                value={data.ctaLabel || ""}
                                onChange={ (e) => handleChange({ ...data, ctaLabel: e.target.value })}
                                required={true}
                            />

                            <TextField
                                name="formTitle"
                                type="text"
                                label="Form Title"
                                value={data.title || ""}
                                onChange={ (e) => handleChange({ ...data, title: e.target.value })}
                                required={true}
                            />

                            <Reorder.Group className="flex flex-col h-full gap-4 overflow-y-auto" axis="y" onReorder={handleReorder} values={data.form}>
                                {
                                    data.form.map((item) => (
                                        <Reorder.Item key={item.id} value={item}>
                                            <div className="bg-[#F6F7F8] rounded-[0.625rem] flex justify-between px-5 py-2.5">
                                                <div className="flex justify-center items-center align-center gap-2.5">
                                                    <Icons.SvgTemplateSectionListDraggable />
                                                    <div className="flex flex-col gap-1">
                                                        <p className="text-sm">{ item.details?.label || "" }</p>
                                                    </div>
                                                </div>

                                                <div className="flex justify-center items-center align-center gap-5">
                                                    <ButtonBase
                                                        onClick={() => addPopup(<PopupItemDelete handleDelete={() => handleFieldDelete(item.id)} />)}
                                                        className="bg-white text-[#FF0000] gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-2"
                                                    >
                                                        <Icons.SvgTrashOutline className="h-2.5" color="#FF0000" />
                                                        {_("Delete")}
                                                    </ButtonBase>

                                                    <ButtonBase className="bg-black text-white gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-1.5"
                                                        onClick={() => {
                                                            setEditedField(item);
                                                            setStep(WidgetFormEditSteps.FORM_DETAIL);
                                                        }}
                                                    >
                                                        {_("Edit")}
                                                    </ButtonBase>
                                                </div>
                                            </div>
                                        </Reorder.Item>
                                    ))
                                }
                            </Reorder.Group>
                        </div>
                    ),
                    [WidgetFormEditSteps.FORM_DETAIL]: <FieldDetailEdit field={editedField} onChange={handleFieldChange} />,
                }[step]
            }
        </div>
    );
})

const FieldDetailEdit = ({ field, onChange }) => {
    /* Hooks */
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    
    /* States */
    const [data, setData] = useState({
        ...field,
        key: field?.key || "",
        type: field?.type || "",
        details: field?.details || {},
        required: field?.required == null ? true : field?.required,
    });

    /* Effects */
    useEffect(() => {
        if (!!data.type) {
            onChange(data);
        }
    }, [data]);

    /* Handlers */
    const handleDataChange = (key, value, newValueLambda) => {
        setData((prevData) => {
            if (newValueLambda) {
                return newValueLambda(prevData);
            }
            return {
                ...prevData,
                [key]: value,
            }
        });
    };

    const handleChangeType = () => {
        addPopup(<PopupFieldTypeSelector
            selectedType={data.type} 
            setSelectedType={ (type) => handleDataChange("type", type) }
            title={_("Choose a field type:")}
        />)
    }

    /* Renders */
    const renderThumbnail = () => {
        return widgetFieldIconOfType({type: data.type, props: {className: "w-16 h-16 rounded-[0.875rem]"}})
               || <div className="w-16 h-16 rounded-[0.875rem] bg-gray-100"></div>
    }

    const renderFieldDetails = () => {
        if (!data.type) return null;

        const defaultFieldDetails = <>
            <TextField
                name="fieldTitle"
                type="text"
                label="Field Title"
                value={data.details.label || ""}
                onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                    ...prevData,
                    key: e.target.value,
                    details: {
                        ...prevData.details || {},
                        label: e.target.value,
                    }
                }))}
                required={true}
            />
            <div className="flex gap-3.5 items-center">
                <p className="text-gray-900 text-sm font-medium">{_("Mandatory field ?")}</p>
                <ButtonToggle
                    onChange={(e) => {
                        handleDataChange(null, null, (prevData) => ({
                            ...prevData,
                            required: e.target.checked,
                        }))
                    }}
                    isToggled={data.required}
                />
            </div>
        </>;

        const fieldDetails = {
            [WIDGET_FIELD_TYPES.SELECT]: <>
                <TextField
                    name="fieldTitle"
                    type="text"
                    label="Field Title"
                    value={data.details.label || ""}
                    onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                        ...prevData,
                        key: e.target.value,
                        details: {
                            ...prevData.details || {},
                            label: e.target.value,
                        }
                    }))}
                    required={true}
                />
                <ButtonBase
                    className="bg-black text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full w-fit"
                    iconColor="white"
                    icon={<Icons.SvgTemplateAddNewSection color="white" className="w-3" />}
                    onClick={() => {
                        addPopup(<PopupSelectAnswer onValidateAnswer={(answer) => {
                            handleDataChange(null, null, (prevData) => ({
                                ...prevData,
                                details: {
                                    ...prevData.details || {},
                                    options: [
                                        ...(prevData.details.options || []),
                                        {
                                            value: (prevData.details.options?.reduce((acc, item) => Math.max(acc, item.value), 0) + 1) || 0,
                                            label: answer,
                                            order: prevData.details.options?.length || 0,
                                        }
                                    ],
                                }
                            }))
                        }} />)
                    }}
                >
                    {_("Add a new answer")}
                </ButtonBase>
                <Reorder.Group
                    className="flex flex-col gap-4 overflow-y-auto"
                    axis="y" 
                    onReorder={
                        (orderedItems) => {
                            handleDataChange(null, null, (prevData) => ({
                                ...prevData,
                                details: {
                                    ...prevData.details || {},
                                    options: orderedItems.map((item, index) => ({ ...item, order: index })),
                                }
                            }))
                        }
                    }
                    values={data.details.options || []}
                >
                    {
                        data.details.options?.map((item) => (
                            <Reorder.Item key={item.value} value={item}>
                                <div className="bg-[#F6F7F8] rounded-[0.625rem] flex justify-between px-5 py-2.5">
                                    <div className="flex justify-center items-center align-center gap-2.5">
                                        <Icons.SvgTemplateSectionListDraggable />
                                        <div className="flex flex-col gap-1">
                                            <p className="text-sm">{ item.label }</p>
                                        </div>
                                    </div>

                                    <div className="flex justify-center items-center align-center gap-5">
                                        <ButtonBase
                                            onClick={() => addPopup(<PopupItemDelete handleDelete={() => {
                                                handleDataChange(null, null, (prevData) => ({
                                                    ...prevData,
                                                    details: {
                                                        ...prevData.details || {},
                                                        options: prevData.details.options.filter(i => i.value !== item.value),
                                                    }
                                                }))
                                            }} />)}
                                            className="bg-white text-[#FF0000] gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-2"
                                        >
                                            <Icons.SvgTrashOutline className="h-2.5" color="#FF0000" />
                                            {_("Delete")}
                                        </ButtonBase>

                                        <ButtonBase className="bg-black text-white gap-[0.438rem] text-sm flex justify-center items-center rounded-full cursor-pointer px-3.5 py-1.5"
                                            onClick={() => {
                                                addPopup(<PopupSelectAnswer initialAnswer={item.label} onValidateAnswer={(answer) => {
                                                    handleDataChange(null, null, (prevData) => ({
                                                        ...prevData,
                                                        details: {
                                                            ...prevData.details,
                                                            options: prevData.details.options.map(i =>
                                                                i.value === item.value 
                                                                    ? { ...i, label: answer }
                                                                    : i
                                                            ),
                                                        }
                                                    }))
                                                }} title={_("Edit answer")} validateLabel={_("Edit this answer")} />)
                                            }}
                                        >
                                            {_("Edit")}
                                        </ButtonBase>
                                    </div>
                                </div>
                            </Reorder.Item>
                        ))
                    }
                </Reorder.Group>
                <div className="flex gap-3.5 items-center">
                    <p className="text-gray-900 text-sm font-medium">{_("Mandatory field ?")}</p>
                    <ButtonToggle
                        onChange={(e) => {
                            handleDataChange(null, null, (prevData) => ({
                                ...prevData,
                                required: e.target.checked,
                            }))
                        }}
                        isToggled={data.required}
                    />
                </div>
            </>,
        };

        return fieldDetails[data.type]
               || defaultFieldDetails;
    }

    return (
        <div className="w-full h-full gap-7 flex flex-col overflow-hidden">
            <label className="flex flex-col">
                <span className="flex text-sm font-medium after:content-['*'] after:ml-0 after:text-red-500">
                    {_("Form Field")}
                </span>
                <div className="flex flex-col m-2">
                    {
                        renderThumbnail()
                    }
                </div>
                <ButtonBase onClick={ handleChangeType } className="bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full">
                    {
                        _("Change type")
                    }
                </ButtonBase>
            </label>
            { renderFieldDetails() }
        </div>
    );
}

const PopupSelectAnswer = ({ initialAnswer, onValidateAnswer, title, validateLabel }) => {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    const [ answer, setAnswer ] = useState(initialAnswer || "");
    
    return (
        <PopupBase title={title || _("Add a new answer")} footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                    {
                        label: validateLabel || _("Add this answer"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: () => {
                            onValidateAnswer(answer);
                            removePopup();
                        },
                    }
                ]
            }
        >
            <div className="flex flex-col gap-3.5">
                <TextField
                    name="answer"
                    type="text"
                    label="Answer"
                    value={answer}
                    onChange={(e) => {
                        setAnswer(e.target.value);
                    }}
                    required={true}
                />
            </div>
        </PopupBase>
    );
}

export default WidgetFormEdit;