import React from 'react';

function ButtonBase({onClick, className, icon, iconColor, children}) {

    return (
        <button onClick={onClick} className={`${!!className ? className : null } whitespace-nowrap gap-3 flex justify-center items-center`}>
            { !!icon ? React.cloneElement(icon, { color: iconColor }) : null }
            { children }
        </button>
    );
}

export default ButtonBase;