import React, {useEffect, useState} from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupMobile from "../../../components/Popups/PopupMobile";
import PopupItemDelete from "./PopupItemDelete";
import {Icons} from "../../../assets/Svgs";
import InputGroup from "../../../components/Layouts/InputGroup";
import InputTel from "../../../components/Layouts/InputTel";
import { CONTACT_TYPES } from "../../../constants/ContactsTypes"

function PopupCta({mode, itemSelected, list, setList}) {
    const { clearAllPopups, addPopup } = usePopup();
    const { t: _ } = useTranslation();

    const initialValues = mode === 'edit'
        ? { ...itemSelected }
        : itemSelected.widget_type === "22"
            ? { id: 0, type: CONTACT_TYPES.TEL, label: "", value: "" || {city: "", address: ""}, isDisabled: false}
            : { id: 0, type: CONTACT_TYPES.TEL, value: "" || {city: "", address: ""}, isDisabled: false}

    const [values, setValues] = useState(initialValues);
    const [ typeSelect, setTypeSelect ] = useState(itemSelected.type || values.type);

    const LIST_TYPES = [
        { id: 0, type: CONTACT_TYPES.TEL },
        { id: 1, type: CONTACT_TYPES.MSG },
        { id: 2, type: CONTACT_TYPES.MAIL },
        { id: 3, type: CONTACT_TYPES.WEB },
        { id: 4, type: CONTACT_TYPES.LOCATION },
    ]

    const setCTA = (type) => {
        switch (type) {
            case CONTACT_TYPES.TEL:
                return <Icons.SvgProfileModelContactTel className="h-5" color={`${ typeSelect === type ? "#0400BD" : ""}`} />
            case CONTACT_TYPES.MSG:
                return <Icons.SvgProfileModelContactMsg className="h-5" color={`${ typeSelect === type ? "#0400BD" : ""}`} />
            case CONTACT_TYPES.MAIL:
                return <Icons.SvgProfileModelContactMail className="h-5" color={`${ typeSelect === type ? "#0400BD" : ""}`} />
            case CONTACT_TYPES.WEB:
                return <Icons.SvgProfileModelContactWeb className="h-5" color={`${ typeSelect === type ? "#0400BD" : ""}`} />
            case CONTACT_TYPES.LOCATION:
                return <Icons.SvgProfileModelContactLocation className="h-5" color={`${ typeSelect === type ? "#0400BD" : ""}`} />
        }
    }

    const handleSave = () => {
        if (mode === 'edit') {
            let ctaToUpdate = list.list.find((data) => data.id === itemSelected.id)
            ctaToUpdate.label = values["label"]
            ctaToUpdate.value = values["value"]
            ctaToUpdate.type = values["type"]
            ctaToUpdate.address = values["address"]
            ctaToUpdate.city = values["city"]
            setList({...list, list: list.list})
        } else {
            let orderMax = Math.max(...list.list.map(data => data.order), -1);

            list.list.push(
                {
                    id: orderMax + 1,
                    label: values["label"],
                    type: values["type"],
                    value: values["value"],
                    address: values["address"],
                    city: values["city"],
                })
            setList({...list, list: list.list})
        }
        clearAllPopups();
    }

    return (
        <PopupMobile
            cols
            hasBtnClose={true}
            icon={<Icons.SvgProfilePopupCta />}
            title={ itemSelected.widget_type === "22"
                ? (mode === 'edit' ? _("Do you want to modify or delete this CTA ?") : _("Which CTA do you want to add ?"))
                : (mode === 'edit' ? _("Do you want to modify or delete this link ?") : _("Which contact link do you want to add ?")) }
            footerButtons={
                [
                    ...(mode === 'edit' ? [
                        {
                            label: _(itemSelected.widget_type === "22" ? "Delete this CTA" : "Delete this contact link"),
                            background: "bg-[#EC0000]",
                            color: "text-white",
                            onClick: () => {
                                addPopup(<PopupItemDelete />)
                            }
                        }
                    ] : []),
                    {
                        label: mode === 'edit' ? _("Save the modifications") : _("Add this contact link"),
                        background: "bg-[#0400BD]",
                        color: "text-white",
                        onClick: handleSave,
                    }
                ]
            }>
            <div className="flex flex-col gap-4 w-full">
                <InputGroup
                    fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                    widthInputClass="w-full"
                    name="label"
                    placeholder="Title"
                    value={ values.label }
                    onChange={ (e) => {
                        setValues({ ...values, [e.target.name]: e.target.value });
                    } }
                    icon={ <Icons.SvgProfileEditText className="absolute top-1/2 left-3 -translate-y-1/2 h-4" /> }
                />

                <div className="w-full flex gap-2">
                    {
                        LIST_TYPES.map((contact, index) => (
                            <div key={index} onClick={() => { setTypeSelect(contact.type); setValues({ ...values, ["type"]: contact.type }); }}
                                 className={` ${typeSelect === contact.type ? "bg-[#D6D6FF] border border-[#0400BD]" : "" } flex item-social-media-popup justify-center items-center p-3 rounded-lg shadow-lg`}
                            >
                                { setCTA(contact.type) }
                            </div>
                        ))
                    }
                </div>

                {
                    typeSelect === CONTACT_TYPES.TEL && (
                        <InputTel
                            name="value"
                            placeholder="Link"
                            value={ values.value }
                            setValues={setValues}
                            onChange={ (e) => {
                                setValues({ ...values, [e.target.name]: e.target.value });
                            } }
                            telLocaleCurrent={ values["country"] }
                        />
                    )
                }

                {
                    typeSelect === CONTACT_TYPES.MSG && (
                        <InputTel
                            name="value"
                            placeholder="Link"
                            value={ values.value }
                            setValues={setValues}
                            onChange={ (e) => {
                                setValues({ ...values, [e.target.name]: e.target.value });
                            } }
                            telLocaleCurrent="fr"
                        />
                    )
                }

                {
                    typeSelect === CONTACT_TYPES.MAIL && (
                        <InputGroup
                            fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                            widthInputClass="w-full"
                            name="value"
                            placeholder="Link"
                            value={ values.value }
                            onChange={ (e) => {
                                setValues({ ...values, [e.target.name]: e.target.value });
                            } }
                            icon={ <Icons.SvgProfilePopupMediaLink className="absolute top-1/2 left-3 -translate-y-1/2 h-4" /> }
                        />
                    )
                }

                {
                    typeSelect === CONTACT_TYPES.WEB && (
                        <InputGroup
                            fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                            widthInputClass="w-full"
                            name="value"
                            placeholder="Link"
                            value={ values.value }
                            onChange={ (e) => {
                                setValues({ ...values, [e.target.name]: e.target.value });
                            } }
                            icon={ <Icons.SvgProfilePopupMediaLink className="absolute top-1/2 left-3 -translate-y-1/2 h-4" /> }
                        />
                    )
                }

                {
                    typeSelect === CONTACT_TYPES.LOCATION && (
                        <>
                            <InputGroup
                                fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                                name="address"
                                widthInputClass="w-full"
                                type="text"
                                value={ values.value.address }
                                onChange={ (e) => {
                                    setValues({ ...values, [e.target.name]: e.target.value });
                                } }
                                icon={ <Icons.SvgProfilePopupMediaLink className="absolute top-1/2 left-3 -translate-y-1/2 h-4" /> }
                            />
                            <InputGroup
                                fontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"
                                name="city"
                                widthInputClass="w-full"
                                type="text"
                                value={ values.value.city }
                                onChange={ (e) => {
                                    setValues({ ...values, [e.target.name]: e.target.value });
                                } }
                                icon={ <Icons.SvgProfilePopupMediaLink className="absolute top-1/2 left-3 -translate-y-1/2 h-4" /> }
                            />
                        </>
                    )
                }
            </div>
        </PopupMobile>
    );
}

export default PopupCta;