import React from 'react';

function SvgProfileTwoUser(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M8.41536 1.66602C6.23203 1.66602 4.45703 3.44102 4.45703 5.62435C4.45703 7.76602 6.13203 9.49935 8.31536 9.57435C8.38203 9.56602 8.4487 9.56602 8.4987 9.57435C8.51536 9.57435 8.5237 9.57435 8.54036 9.57435C8.5487 9.57435 8.5487 9.57435 8.55703 9.57435C10.6904 9.49935 12.3654 7.76602 12.3737 5.62435C12.3737 3.44102 10.5987 1.66602 8.41536 1.66602Z" fill={props.color || "#292D32"}/>
            <path d="M12.6469 11.7914C10.3219 10.2414 6.53021 10.2414 4.18854 11.7914C3.13021 12.4997 2.54688 13.4581 2.54688 14.4831C2.54688 15.5081 3.13021 16.4581 4.18021 17.1581C5.34688 17.9414 6.88021 18.3331 8.41354 18.3331C9.94688 18.3331 11.4802 17.9414 12.6469 17.1581C13.6969 16.4497 14.2802 15.4997 14.2802 14.4664C14.2719 13.4414 13.6969 12.4914 12.6469 11.7914Z" fill={props.color || "#292D32"}/>
            <path opacity="0.4" d="M17.5719 6.11708C17.7052 7.73374 16.5552 9.15041 14.9635 9.34207C14.9552 9.34207 14.9552 9.34207 14.9469 9.34207H14.9219C14.8719 9.34207 14.8219 9.34207 14.7802 9.35874C13.9719 9.40041 13.2302 9.14207 12.6719 8.66707C13.5302 7.90041 14.0219 6.75041 13.9219 5.50041C13.8635 4.82541 13.6302 4.20874 13.2802 3.68374C13.5969 3.52541 13.9635 3.42541 14.3385 3.39208C15.9719 3.25041 17.4302 4.46708 17.5719 6.11708Z" fill={props.color || "#292D32"}/>
            <path d="M19.2409 13.8247C19.1742 14.633 18.6576 15.333 17.7909 15.808C16.9576 16.2663 15.9076 16.483 14.8659 16.458C15.4659 15.9163 15.8159 15.2413 15.8826 14.5247C15.9659 13.4913 15.4742 12.4997 14.4909 11.708C13.9326 11.2663 13.2826 10.9163 12.5742 10.658C14.4159 10.1247 16.7326 10.483 18.1576 11.633C18.9242 12.2497 19.3159 13.0247 19.2409 13.8247Z" fill={props.color || "#292D32"}/>
        </svg>
}

export default SvgProfileTwoUser;