export class CSVService {

    /**
     * Convert CSV content string to JSON.
     * @param {string} csvContent - The content of the CSV file as a string.
     * @param {string} delimiter - The delimiter used in the CSV file (default is comma).
     * @returns {Object[]} - An array of objects representing the CSV data.
     */
    static parseCSV(csvContent, delimiter = ',', checkHeaders = null) {
        const lines = csvContent.split('\n').filter(line => line.trim() !== '');
        const headers = lines[0].split(delimiter).map(header => header.trim());
        const jsonData = lines.slice(1).map(line => {
            const values = line.split(delimiter).map(value => value.trim());
            const obj = {};
            headers.forEach((header, index) => {
                obj[header] = values[index];
            });
            return obj;
        });
        if (checkHeaders) {
            const headersMatch = headers.every(header => checkHeaders.includes(header));
            if (!headersMatch) {
                throw new Error('Headers do not match');
            }
        }
        return jsonData;
    }
}

export const csvServiceInstance = new CSVService();

export default CSVService;