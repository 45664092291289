import React from 'react';
import SquareView from "../../../../components/Layouts/SelectorSquareView";
import { Icons } from "../../../../assets/Svgs";
import PopupBase from "../../../../components/Popups/PopupBase";
import { useTranslation } from "../../../../services/i18n/i18nService";
import { usePopup } from "../../../../services/PopupService";

const CTA_TYPES = [
    { type: 'tel', icon: <Icons.SvgContactCallFill className="w-16" />, label: "Call" },
    { type: 'msg', icon: <Icons.SvgTemplateCTAMsg className="w-16" />, label: "Message" },
    { type: 'mail', icon: <Icons.SvgContactMailFill className="w-16" />, label: "Mail" },
    { type: 'location', icon: <Icons.SvgTemplateSectionLocation className="w-16" />, label: "Location" },
    { type: 'web', icon: <Icons.SvgTemplateSectionWebsiteLinks className="w-16" />, label: "Website" },
];

function PopupSelectorCTAType({ selectedType, setSelectedType }) {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    return (
        <PopupBase
            title={_("Choose a Call-to-Action type:")}
            footerButtons={[
                {
                    label: _("Cancel"),
                    background: "bg-white",
                    color: "text-black",
                    onClick: removePopup,
                },
            ]}
        >
            <div className="grid gap-7">
                <div className="grid grid-cols-3 gap-7 px-16">
                    {CTA_TYPES.map(({ type, icon, label }) => (
                        <SquareView
                            key={type}
                            shapCustom="gap-4 px-12 py-4 rounded-[1.25rem]"
                            isSelected={selectedType === type}
                            onClick={() => {
                                setSelectedType(type);
                                removePopup();
                            }}
                            icon={icon}
                            viewName={_(label)}
                        />
                    ))}
                </div>
            </div>
        </PopupBase>
    );
}

export default PopupSelectorCTAType;