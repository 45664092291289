import React from 'react';
import {motion, Reorder, useMotionValue} from "framer-motion";
import PortfolioMoveUpDown from "./PortfolioMoveUpDown";

function PortfolioReorderItem({ index, currentItem, values, setValues, children, onMove }) {
    const y = useMotionValue(0);

    return (
        <Reorder.Item value={currentItem} id={currentItem} style={{y}}>
            <motion.div className="flex justify-between px-4 py-2 border border-[#EEEFF2] rounded-lg">
                <div className="flex items-center gap-3">
                    { !!children ? children : null }
                </div>

                <PortfolioMoveUpDown
                    values={!!values ? values : ""}
                    setValues={!!setValues ? setValues : null}
                    item={!!currentItem ? currentItem : null}
                    index={index}
                    handleEdit={!!onMove ? onMove : null}
                />
            </motion.div>
        </Reorder.Item>
    );
}

export default PortfolioReorderItem;