import React from 'react';

function SvgMediaTikTok(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="4" fill="black"/>
            <path d="M9.02242 8.93778V8.54849C8.88905 8.53067 8.75345 8.51953 8.61342 8.51953C6.94408 8.51734 5.58594 9.8766 5.58594 11.5496C5.58594 12.5752 6.09719 13.4828 6.87962 14.0323C6.37504 13.4917 6.06607 12.7643 6.06607 11.9678C6.06607 10.3193 7.38642 8.9756 9.02242 8.93778Z" fill="#25F4EE"/>
            <path d="M9.09686 13.3515C9.84151 13.3515 10.4506 12.7575 10.4772 12.0189L10.4795 5.41824H11.6842C11.6598 5.28254 11.6464 5.14238 11.6464 5H10.0015L9.99932 11.6007C9.97267 12.3393 9.36359 12.9332 8.61896 12.9332C8.38778 12.9332 8.16995 12.8754 7.97656 12.7731C8.22552 13.1223 8.63452 13.3515 9.09686 13.3515Z" fill="#25F4EE"/>
            <path d="M13.9345 7.65852V7.29147C13.4744 7.29147 13.0476 7.15575 12.6875 6.91992C13.0076 7.28701 13.441 7.55398 13.9345 7.65852Z" fill="#25F4EE"/>
            <path d="M12.69 6.91962C12.3388 6.51697 12.1276 5.99194 12.1276 5.41797H11.6875C11.8008 6.04533 12.1743 6.58371 12.69 6.91962Z" fill="#FE2C55"/>
            <path d="M8.61697 10.1636C7.85454 10.1636 7.23438 10.7843 7.23438 11.5473C7.23438 12.0791 7.53668 12.5418 7.97679 12.7732C7.81231 12.5462 7.7145 12.2682 7.7145 11.9656C7.7145 11.2025 8.33467 10.5818 9.09711 10.5818C9.23937 10.5818 9.37717 10.6063 9.5061 10.6463V8.9645C9.37271 8.94668 9.23714 8.93555 9.09711 8.93555C9.07265 8.93555 9.05042 8.93777 9.02596 8.93777V10.2281C8.89482 10.188 8.75923 10.1636 8.61697 10.1636Z" fill="#FE2C55"/>
            <path d="M13.9302 7.6582V8.93739C13.0767 8.93739 12.2854 8.66377 11.6407 8.20102V11.5492C11.6407 13.2199 10.2826 14.5815 8.61102 14.5815C7.9664 14.5815 7.36624 14.3768 6.875 14.032C7.42848 14.6259 8.21758 14.9997 9.09116 14.9997C10.7605 14.9997 12.1209 13.6404 12.1209 11.9674V8.61927C12.7655 9.08202 13.5568 9.35564 14.4104 9.35564V7.70939C14.2436 7.70939 14.0836 7.69157 13.9302 7.6582Z" fill="#FE2C55"/>
            <path d="M11.6496 11.5492V8.20104C12.2942 8.66379 13.0855 8.93741 13.9391 8.93741V7.65822C13.4456 7.55369 13.0122 7.28671 12.6921 6.91962C12.1764 6.58371 11.8052 6.04533 11.6874 5.41797H10.4826L10.4804 12.0186C10.4537 12.7572 9.84465 13.3512 9.10003 13.3512C8.63767 13.3512 8.23089 13.1221 7.97749 12.775C7.53737 12.5414 7.23507 12.0809 7.23507 11.5492C7.23507 10.7862 7.85523 10.1655 8.61766 10.1655C8.75992 10.1655 8.89774 10.1899 9.02666 10.23V8.93964C7.39067 8.97524 6.07031 10.3189 6.07031 11.9675C6.07031 12.7639 6.37928 13.4892 6.88386 14.032C7.37511 14.3768 7.97527 14.5815 8.61988 14.5815C10.2892 14.5815 11.6496 13.22 11.6496 11.5492Z" fill="white"/>
        </svg>
    );
}

export default SvgMediaTikTok;