import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import { Icons } from "../../../assets/Svgs";
import { useTranslation } from "../../../services/i18n/i18nService";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import InputField from "../../../components/Layouts/InputField";
import InputSelected from "../../../components/Layouts/InputSelected";
import InputTextarea from "../../../components/Layouts/InputTextarea";
import CustomersService from '../../../services/Customer/CustomersService';

function CustomersCreate() {
    const { t: _ } = useTranslation();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        name: "",
        manager_name: "",
        business_mail: "",
        business_phone: "",
        address: "",
        city: "",
        zip_code: "",
        country: "",
        started_contract_date: "",
        assigned_to: "",
        note: ""
    });

    const fields = [
        { name: "name", type: "text", label: "Business Name" },
        { name: "manager_name", type: "text", label: "Manager Name" },
        { name: "business_mail", type: "text", label: "Business Mail" },
        { name: "business_phone", type: "text", label: "Business Phone Number" },
        { name: "address", type: "text", label: "Address" },
        { name: "city", type: "text", label: "City" },
        { name: "zip_code", type: "text", label: "ZIP/Postal Code" },
        { name: "country", type: "text", label: "Country" },
        { name: "started_contract_date", type: "date", label: "Started Contract Date" },
        { name: "assigned_to", type: "select", label: "Assigned to", options: ["Edwin Bossy"] },
        { name: "note", type: "textarea", label: "Note" }
    ];

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const submitForm = () => {
        CustomersService.createCustomer(values)
            .then((newCustomer) => {
                navigate(getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER"]).replace(":custom", newCustomer.id));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className="flex flex-col h-screen p-7 gap-7">
            <header className=" flex justify-between items-center">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={getFullPath(["ROOT", "CUSTOMERS"])} className="hover:underline hover:select-all">
                        {_("Customers")}
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("Create")}
                </div>
                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "CUSTOMERS"])} className="text-sm">
                        <ButtonBack isFlexible={false} />
                    </Link>
                    <ButtonBase onClick={submitForm} className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={<Icons.SvgUserAddOutline className="h-3" />}>
                        {_("Create this Customer")}
                    </ButtonBase>
                </div>
            </header>

            <div className="flex-1 overflow-auto">
                <div className="bg-white p-7 rounded-[1.25rem] grid gap-7 md:grid-cols-2">
                    {
                        fields.map((field, index) => (
                            <div key={index}>
                                {field.type === "textarea" ? (
                                    <InputTextarea
                                        rows={3}
                                        name={field.name}
                                        label={field.label}
                                        value={values[field.name]}
                                        onChange={onChange}
                                        widthInputClass="w-full"
                                    />
                                ) : field.type === "select" ? (
                                    <InputSelected
                                        name={field.name}
                                        label={field.label}
                                        options={field.options}
                                        value={values[field.name]}
                                        onChange={onChange}
                                        widthInputClass="w-full"
                                        bgColorClass="bg-[#F6F7F8] border-l-0"
                                    >
                                        <img src="https://source.unsplash.com/100x100?macbook" className="rounded-full h-7 w-7" alt="avatar" />
                                    </InputSelected>
                                ) : (
                                    <InputField
                                        name={field.name}
                                        type={field.type}
                                        label={field.label}
                                        value={values[field.name]}
                                        onChange={onChange}
                                        widthInputClass="w-full"
                                    />
                                )}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default CustomersCreate;