import React from 'react';

function SvgArrowLeftRightOutline(props) {
    return <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.14062 5.22391L11.0006 3.36389L9.14062 1.50391" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 3.36523H11" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.85999 6.77344L2 8.63345L3.85999 10.4934" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 8.63477H2" stroke={props.color || "black"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
}

export default SvgArrowLeftRightOutline;