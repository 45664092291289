import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import InputGroup from "../../Layouts/InputGroup";
import TemplateSectionDraggable from "../../../pages/Admin/Templates/TemplateSectionDraggable";
import InputField from "../../Layouts/InputField";
import WidgetService from "../../../services/Widget/WidgetService";
import {useParams} from "react-router-dom";

const WidgetWhatsAppCtaEdit = React.forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
) => {
    useImperativeHandle(ref, () => ({
        handleSave,
        handleCreate
    }))

    const { idType } = useParams();

    const [data, setData] = useState({
        title: widget?.data?.title || "",
        toggleMail: widget?.data?.toggleMail || false,
        toggleNote: widget?.data?.toggleNote || false,
        tel: widget?.data?.tel || "",
        note: widget?.data?.note || "",
    });

    const handleSave = () => {
        const updatedWidget = { ...widget, data };
        WidgetService.updateWidget(widget.id, widget)
            .then(() => {
                onSave(updatedWidget);
            })
            .catch((error) => {
                console.log('Failed to save widget');
                throw error;
            });
    }

    const handleCreate = () => {
        const updatedWidget = {
            portfolio: 2, // fix
            section: 1, // fix
            widget_type: idType,
            order: 27, // fix
            data
        };

        WidgetService.createWidget(updatedWidget)
            .then((newWidget) => {
                onSave(newWidget); // Pass the new widget to the onSave callback
            })
            .catch((error) => {
                console.log('Failed to create widget');
                throw error;
            });
    }

    const handleChanges = (data) => {
        setData(data);
        const updatedWidget = { ...widget, data };
        onChange(updatedWidget);
    }

    useEffect(() => {
        if (widget?.data) {
            setData({
                title: widget?.data?.title || "",
                toggleMail: widget?.data?.toggleMail || false,
                toggleNote: widget?.data?.toggleNote || false,
                tel: widget?.data?.tel || "",
                note: widget?.data?.note || "",
            });
        }
    }, [widget]);

    return (
        <div className="grid w-full gap-7">
            <div className="grid grid-col gap-7">
                <InputGroup
                    name="title"
                    widthInputClass="w-3/6"
                    classGapLabelToField="gap-3.5"
                    label="WhatsApp button Title"
                    value={ data.title }
                    onChange={(e) => handleChanges({ ...data, title: e.target.value })}
                    backgroundClass="bg-[#F6F7F8]"
                    required={true}
                />

                <TemplateSectionDraggable
                    canRedirToContent={false}
                    displayButtonEdit={false}
                    displayIconListDraggable={false}
                    toggle={data.toggleMail}
                    setToggle={() => handleChanges({ ...data, toggleMail: !data.toggleMail })}
                    title="Do you want to use a unique mail address for all the team members?"
                    isDisabled={data.toggleMail}
                />

                <InputGroup
                    name="tel"
                    widthInputClass="w-3/6"
                    classGapLabelToField="gap-3.5"
                    label="Phone Number"
                    value={ data.tel }
                    onChange={(e) => handleChanges({...data, tel: e.target.value})}
                    backgroundClass="bg-[#F6F7F8]"
                    required={true}
                />

                <TemplateSectionDraggable
                    canRedirToContent={false}
                    displayButtonEdit={false}
                    displayIconListDraggable={false}
                    toggle={data.toggleNote}
                    setToggle={() => handleChanges({ ...data, toggleNote: !data.toggleNote })}
                    title="Do you want to send a personal note?"
                    isDisabled={data.toggleNote}
                />

                <InputField
                    name="note"
                    widthInputClass="w-3/6"
                    classGapLabelToField="gap-3.5"
                    label="WhatsApp Note"
                    value={ data.note }
                    onChange={(e) => handleChanges({...data, note: e.target.value})}
                    backgroundClass="bg-[#F6F7F8]"
                    required={true}
                />
            </div>
        </div>
    );
})

export default WidgetWhatsAppCtaEdit;