import React from 'react';

function SvgTemplateSectionInstagramFeed(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-6" } `} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z" fill={props.color || "#292D32"}/>
            <path d="M12.0011 15.8791C14.144 15.8791 15.8811 14.142 15.8811 11.9991C15.8811 9.85628 14.144 8.11914 12.0011 8.11914C9.85823 8.11914 8.12109 9.85628 8.12109 11.9991C8.12109 14.142 9.85823 15.8791 12.0011 15.8791Z" fill={props.color || "#292D32"}/>
            <path d="M17 7.50088C16.73 7.50088 16.48 7.40088 16.29 7.21088C16.2 7.11088 16.13 7.00088 16.08 6.88088C16.03 6.76088 16 6.63088 16 6.50088C16 6.37088 16.03 6.24088 16.08 6.12088C16.13 5.99088 16.2 5.89088 16.29 5.79088C16.52 5.56088 16.87 5.45088 17.19 5.52088C17.26 5.53088 17.32 5.55088 17.38 5.58088C17.44 5.60088 17.5 5.63088 17.56 5.67088C17.61 5.70088 17.66 5.75088 17.71 5.79088C17.8 5.89088 17.87 5.99088 17.92 6.12088C17.97 6.24088 18 6.37088 18 6.50088C18 6.63088 17.97 6.76088 17.92 6.88088C17.87 7.00088 17.8 7.11088 17.71 7.21088C17.61 7.30088 17.5 7.37088 17.38 7.42088C17.26 7.47088 17.13 7.50088 17 7.50088Z" fill={props.color || "#292D32"}/>
        </svg>
    );
}

export default SvgTemplateSectionInstagramFeed;