import React, {useEffect, useState} from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {usePopup} from "../../../services/PopupService";
import SquareView from "../../Layouts/SelectorSquareView";
import {Icons} from "../../../assets/Svgs";
import PopupBase from "../PopupBase";
import LeadsService from "../../../services/Leads/LeadsService";
import PopupFilterViewByMembers from "../FilterView/PopupFilterViewByMembers";
import PopupAssignPersonSelected from "./PopupAssignPersonSelected";
import PopupShowGenStatistics from "../../../pages/Admin/Statistics/PopupShowGenStatistics";
import PopupFilterViewByDivision from "../FilterView/PopupFilterViewByDivision";

function PopupFilterByView({ selectedView: selectedViewParent, onSelectedViewChange, title }) {
    const { t: _ } = useTranslation();
    const { removePopup, addPopup } = usePopup();

    const [ listLeads, setListLeads ] = useState([]);
    const [ selectedView, setSelectedView ] = useState(selectedViewParent ?? "team");
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        onSelectedViewChange && onSelectedViewChange(selectedView);
    }, [selectedView, onSelectedViewChange]);

    useEffect(() => {
        setLoading(true);
        LeadsService.getLeads()
            .then(leads => {
                setListLeads(leads);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <PopupBase
            title={ !!title ? title : _("Filter the statistics view by :")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[46.5rem]",
                        onClick: removePopup,
                    },
                ]
            }>

            <SquareView
                shapCustom="gap-7 w-[8.5rem] h-[8.5rem]"
                isSelected={ selectedView === "team" }
                onClick={() => { setSelectedView("team"); addPopup( <PopupFilterViewByMembers members={listLeads} /> );  }}
                icon={<Icons.SvgStatisticTeamMemberThree className="h-[3.125rem]"/>}
                viewName={_("Team Member")}
            />

            <SquareView
                shapCustom="gap-7 w-[8.5rem] h-[8.5rem]"
                isSelected={ selectedView === "templates" }
                onClick={() => {  setSelectedView("templates"); addPopup(<PopupAssignPersonSelected title="Filter your view by templates :" />); }}
                icon={<Icons.SvgSelectorTemplate className="h-[3.125rem]" />}
                viewName={_("Templates")}
            />

            <SquareView
                shapCustom="gap-7 w-[8.5rem] h-[8.5rem]"
                isSelected={ selectedView === "division" }
                onClick={() => { setSelectedView("division"); addPopup( <PopupFilterViewByDivision /> ) }}
                icon={<Icons.SvgTemplateTwoPersonFill className="h-[3.125rem]"/>}
                viewName={_("Division")}
            />

            <SquareView
                shapCustom="gap-7 w-[8.5rem] h-[8.5rem]"
                isSelected={ selectedView === "general" }
                onClick={() => { setSelectedView("general"); addPopup(<PopupShowGenStatistics />) }}
                icon={<Icons.SvgSelectorGeneral className="h-[3.125rem]" />}
                viewName={_("General")}
            />
        </PopupBase>
    );
}

export default PopupFilterByView;