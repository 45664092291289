import React from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";

function PopupUploadAvatar({onEditPicture, title}) {
    const { removePopup } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={_(title)}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                    {
                        label: _("Upload a background picture"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: onEditPicture
                    }
                ]
            }>
        </PopupBase>
    );
}

export default PopupUploadAvatar;