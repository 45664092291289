import React from 'react';
import PopupBase from "../../../../components/Popups/PopupBase";
import {useTranslation} from "../../../../services/i18n/i18nService";
import {usePopup} from "../../../../services/PopupService";

function PopupDeleteConfirm() {
    const { t: _ } = useTranslation();
    const { clearAllPopups } = usePopup();

    return (
        <PopupBase
            title={_("The division has been deleted.")}
            footerButtons={
                [
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[47rem]",
                        onClick: clearAllPopups,
                    },
                ]
            }>
        </PopupBase>
    );
}

export default PopupDeleteConfirm;