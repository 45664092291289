import React from 'react';

function SvgFilterSearchOutline({className, color}) {
    return <svg className={`${!!className ? className : "h-3"} `} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.75672 10.8287C7.75672 11.1591 7.54004 11.5925 7.26379 11.7604L6.50005 12.2533C5.79047 12.6921 4.80463 12.1991 4.80463 11.3216V8.42372C4.80463 8.03914 4.58797 7.54622 4.36589 7.27539L2.28587 5.08705C2.00962 4.8108 1.79297 4.32331 1.79297 3.99289V2.73622C1.79297 2.0808 2.2859 1.58789 2.88715 1.58789H10.113C10.7142 1.58789 11.2071 2.0808 11.2071 2.68205V3.88455C11.2071 4.3233 10.9309 4.87039 10.66 5.14122" stroke={color || "black"} />
        <path d="M8.706 9.44909C9.66329 9.44909 10.4393 8.67306 10.4393 7.71576C10.4393 6.75847 9.66329 5.98242 8.706 5.98242C7.7487 5.98242 6.97266 6.75847 6.97266 7.71576C6.97266 8.67306 7.7487 9.44909 8.706 9.44909Z" stroke={color || "black"} />
        <path d="M10.7643 9.77409L10.2227 9.23242" stroke={color || "black"} />
    </svg>
}

export default SvgFilterSearchOutline;