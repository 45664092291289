import { MOBILE_STATISTICS } from "../../constants/DatasConstants";
import APIService from "../APIService";

let statistics = null;

export const getStatistics = () => {
    return new Promise((resolve, reject) => {
        if (statistics === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            statistics = MOBILE_STATISTICS
            resolve(statistics)
        } else {
            resolve(statistics)
        }
    });
}

export default {
    getStatistics,
};