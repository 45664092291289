import React from 'react';
import {Icons} from "../../assets/Svgs";
import {useTranslation} from "../../services/i18n/i18nService";

function ButtonLoginPrevious({onClick}) {
    const { t: _ } = useTranslation();

    return (
        <div onClick={onClick} className="absolute top-6 left-6 border border-[#0400BD] rounded-full">
            <div className="flex gap-1.5 items-center px-2.5 py-1.5">
                <Icons.SvgLoginArrowCircleLeft/>
                <span className="text-[#0400BD] text-[1rem] leading-5 font-normal">{_("Previous")}</span>
            </div>
        </div>
    );
}

export default ButtonLoginPrevious;