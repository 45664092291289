import React, {useEffect, useState} from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupMobile from "../../../components/Popups/PopupMobile";
import {Icons} from "../../../assets/Svgs";
import PopupItemDelete from "./PopupItemDelete";
import WidgetPicture from "../../../components/Widgets/WidgetPicture";

function PopupPicture({mode, itemSelected, list, setList}) {
    const { clearAllPopups, addPopup } = usePopup();
    const { t: _ } = useTranslation();

    const initialValues = mode === 'edit'
        ? { ...itemSelected }
        : { id: 0, img: "", title: "", discover: "", freeText: "", displayButtonDelete: false, isDisabled: false, order: 0 };

    const [values, setValues] = useState(initialValues);
    const [isUploadedImage, setIsUploadedImage] = useState(mode === 'edit' ? !!itemSelected.img : false);

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setIsUploadedImage(values["img"] === "");
    }, [values]);

    const handleSave = () => {
        if (mode === 'edit') {
            let pictureToUpdate = list.datas.find((data) => data.id === itemSelected.id);
            pictureToUpdate.title = values["title"];
            pictureToUpdate.img = values["img"];
            pictureToUpdate.order = values["order"];
            pictureToUpdate.freeText = values["freeText"];
            pictureToUpdate.discover = values["discover"];
            setList({...list, datas: list.datas});
        } else {
            let orderMax = Math.max(...list.datas.map(data => data.order), -1);

            list.datas.push({
                id: orderMax + 1,
                title: values["title"],
                img: values["img"],
                order: orderMax + 1,
                freeText: values["freeText"],
                displayButtonDelete: values["displayButtonDelete"],
                isDisabled: values["isDisabled"],
                discover: values["discover"],
            });
            setList({...list, datas: list.datas});
        }
        clearAllPopups();
    };


    return (
        <PopupMobile
            cols
            hasBtnClose={true}
            icon={<Icons.SvgProfilePopupPicture />}
            title={mode === 'edit' ? _("Do you want to modify or delete this picture ?") : _("Do you want to add a new picture ?")}
            footerButtons={
                [
                    ...(mode === 'edit' ? [
                        {
                            label: _("Delete this picture"),
                            background: "bg-[#EC0000]",
                            color: "text-white",
                            onClick: () => {
                                addPopup(<PopupItemDelete />);
                            }
                        }
                    ] : []),
                    {
                        label: mode === 'edit' ? _("Save the modifications") : _("Add this picture"),
                        background: "bg-[#0400BD]",
                        color: "text-white",
                        onClick: handleSave,
                    }
                ]
            }>

            {/*<WidgetPicture*/}
            {/*    isPortfolioPictureEdit={mode === 'edit' ? true : !isUploadedImage}*/}
            {/*    classContainerInput="flex flex-col gap-4"*/}
            {/*    titleFontClass="text-[#6B7280] text-[1rem] leading-6 font-normal"*/}
            {/*    widthInputClass="w-full"*/}
            {/*    iconTitle={<Icons.SvgProfileEditText className="absolute top-1/2 left-3 -translate-y-1/2 h-4" />}*/}
            {/*    iconDiscover={<Icons.SvgProfilePopupMediaLink className="absolute top-1/2 left-3 -translate-y-1/2 h-4" />}*/}
            {/*    iconFreeText={<Icons.SvgProfileEditText className="absolute top-1/2 left-3 -translate-y-1/2 h-4" />}*/}
            {/*    classImgDisplayJustifyCenter={false}*/}
            {/*    classPreviewImg="w-full h-[13rem] rounded-lg"*/}
            {/*    classButtonUploadImage="w-full rounded-lg py-2.5 px-[1.125rem] bg-[#0400BD] text-white"*/}

            {/*    values={values}*/}
            {/*    setValues={setValues}*/}
            {/*    onChange={onChange}*/}
            {/*/>*/}
        </PopupMobile>
    );
}

export default PopupPicture;