const refreshToken = async (apiCall) => {
    const refreshToken = localStorage.getItem("refresh-token");
    return new Promise((resolve, reject) => {
        return apiCall("token/refresh/", "POST", {
            refresh: refreshToken
        }, true, {}, null)
        .then((response) => {
            response.json().then((data) => {
                localStorage.setItem("access-token", data.access);
                resolve(data.access);
            })
            .catch((error) => {
                reject(error);
            })
        })
        .catch(error => {
            reject(error);
        })
    });
};

const getAccessToken = async (apiCall) => {
    const token = localStorage.getItem("access-token");

    let expirationDate = 0;
    if (!!token) {
        try {
            expirationDate = new Date(JSON.parse(atob(token.split(".")[1])).exp * 1000);
        } catch (error) {
            console.log("Error parsing token: " + error);
        }
    }
    const currentDate = new Date();

    if (expirationDate < currentDate) {
        console.log("Token expired. Refreshing...");
        return refreshToken(apiCall);
    } else {
        return Promise.resolve(token);
    }
};

const TokenService = {
    getAccessToken,
};

export default TokenService;
