import React, {useEffect, useState} from 'react';
import { useTranslation } from "../../services/i18n/i18nService";
import ButtonBase from "../Buttons/ButtonBase";
import Widget from "../Widgets/Widget";
import WidgetFormView from "../Widgets/View/WidgetFormView";
import {useParams} from "react-router-dom";

function IPhone(
    {
        isHalfPhone,
        isEnlarged,
        selectedTheme,
        displayPopupForm,
        iPhoneWidget,
        setIPhoneWidget,
        backgroundValue,
    }) {

    const { t: _ } = useTranslation();
    const { widgetId } = useParams();

    return (
        <div className={`${!isHalfPhone ? 'rounded-[3.29rem] py-2' : 'rounded-tl-[3.29rem] rounded-tr-[3.239rem] pt-2'} ${isEnlarged ? 'w-[22rem]' : 'w-[18rem]'} bg-[#4B5563] h-fit px-2 overflow-hidden`}>

            {
                !!displayPopupForm && widgetId !== undefined && (
                    <WidgetFormView
                        widget={iPhoneWidget.widgets.find((widget) => widget.id === parseInt(widgetId))}
                        setWidget={(updatedWidget) => {
                            const updatedWidgets = iPhoneWidget.widgets.map(widget =>
                                widget.id === updatedWidget.id ? updatedWidget : widget
                            );
                            setIPhoneWidget({...iPhoneWidget, widgets: updatedWidgets });
                        }}
                    />
                )
            }

            <div className={`${!isHalfPhone ? "overflow-scroll h-128" : ""}`}>
                <div className="h-[17rem] before:w-full before:h-[8.5rem] before:bg-[#D1D5DB] before:absolute before:top-0 w-full relative bg-white flex flex-col items-center justify-center text-center rounded-tl-[3.29rem] rounded-tr-[3.239rem] overflow-hidden">
                    <div className="w-[5.688rem] h-[1.625rem] bg-[#4B5563] rounded-[0.913rem] absolute top-3 z-10" />

                    {
                        backgroundValue && (
                            <div
                                className="w-full h-[8.5rem] absolute top-0"
                                style={{
                                    backgroundImage: `url(${backgroundValue})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                            />
                        )
                    }

                    {
                        iPhoneWidget?.user_data ? (
                            <img className="w-32 h-32 rounded-full z-5 border-4 border-white bg-[#D1D5DB]" src={iPhoneWidget.user_data.avatar} alt="" />
                        ) : (
                            <div className="w-32 h-32 rounded-full z-5 bg-[#D1D5DB] border-4 border-white" />
                        )
                    }

                    {
                        !isHalfPhone && (
                            <div className="absolute bottom-3 grid gap-1.5">
                                {
                                    !!iPhoneWidget?.user_data
                                        ? <div className="text-base">{ iPhoneWidget.user_data.first_name  + " " + iPhoneWidget.user_data.last_name }</div>
                                        : <div className="text-base">First Last</div>
                                }

                                <div className="text-sm text-[#4B5563] leading-4">
                                    {_("Position ")} {_("at")} {_(" Company")}
                                </div>
                            </div>
                        )
                    }
                </div>

                {
                    !isHalfPhone && (
                        <div className="relative bg-white">
                            <div className="grid gap-2.5 p-3">
                                {
                                    (!!iPhoneWidget && !!widgetId)
                                        ? (
                                            iPhoneWidget.widgets?.filter(item => item.id === parseInt(widgetId)).map((widget, index) => (
                                                <Widget
                                                    key={index}
                                                    widget={widget}
                                                    displayEditAction={false}
                                                />
                                            ))
                                        )
                                        : (
                                            iPhoneWidget.widgets?.map((widget, index) => (
                                                <Widget
                                                    key={index}
                                                    widget={widget}
                                                    displayEditAction={false}
                                                />
                                            ))
                                        )
                                }
                                <div className="bg-[#D1D5DB] w-2/6 h-3.5 rounded-[3.239rem]" />
                                <div className="bg-[#D1D5DB] w-full h-24 rounded-2xl" />
                            </div>

                            <div className="grid gap-2.5 p-3">
                                <div className="bg-[#D1D5DB] w-2/6 h-3.5 rounded-[3.239rem]" />
                                <div className="bg-[#D1D5DB] w-full h-24 rounded-2xl" />
                            </div>
                        </div>
                    )
                }
            </div>

            {
                !isHalfPhone && (
                    <div className="relative p-3 w-full bg-white rounded-bl-[3.29rem] rounded-br-[3.239rem] shadow-lg">
                        <ButtonBase className={`${selectedTheme ? selectedTheme + " shadow-lg" : "bg-black text-white"} w-full py-2.5 px-6 rounded-full`} iconColor="#ffffff">
                            <p className="text-sm">{_("Exchange Contact")}</p>
                        </ButtonBase>
                    </div>
                )
            }
        </div>
    );
}

export default IPhone;
