import React from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {usePopup} from "../../../services/PopupService";
import PopupBase from "../PopupBase";

function PopupAssignNewAdminAccess({assignNewAdmin}) {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    return (
        <PopupBase
            title={_("Are you sure you want to assign an Admin access to this team member?")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                    {
                        label: _("Confirm and Send a Password Invitation"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: assignNewAdmin,
                    }
                ]
            }>
        </PopupBase>
    );
}

export default PopupAssignNewAdminAccess;