import React from 'react';

function SvgTeamSaveUserOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-3" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6C7.38071 6 8.5 4.88071 8.5 3.5C8.5 2.11929 7.38071 1 6 1C4.61929 1 3.5 2.11929 3.5 3.5C3.5 4.88071 4.61929 6 6 6Z" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.70312 11C1.70312 9.065 3.62812 7.5 5.99812 7.5C6.47812 7.5 6.94313 7.565 7.37813 7.685" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 9C11 9.375 10.895 9.73 10.71 10.03C10.605 10.21 10.47 10.37 10.315 10.5C9.965 10.815 9.505 11 9 11C8.27 11 7.63499 10.61 7.28999 10.03C7.10499 9.73 7 9.375 7 9C7 8.37 7.29 7.805 7.75 7.44C8.095 7.165 8.53 7 9 7C10.105 7 11 7.895 11 9Z" stroke={props.color || "white"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.21875 8.99976L8.71375 9.49475L9.77875 8.50977" stroke={props.color || "white"} strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgTeamSaveUserOutline;