import { useState } from 'react';
import SquareView from "../../../../components/Layouts/SelectorSquareView";
import {Icons} from "../../../../assets/Svgs";
import PopupBase from "../../../../components/Popups/PopupBase";
import {useTranslation} from "../../../../services/i18n/i18nService";
import {usePopup} from "../../../../services/PopupService";
import { SOCIAL_MEDIA_TYPES } from '../../../../constants/SocialMediasTypes';

function PopupSelectorSocialMediaType({ selectedType, setSelectedType }) {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    const handleSelectedTypeChange = (type) => {
        setSelectedType(type);
        removePopup();
    };

    return (
        <PopupBase
            title={_("Choose a social media type:")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                ]
            }>
            <div className="grid gap-7">
                <div className="flex">
                    <div className="grid gap-4 w-full items-center">
                        <div className="grid grid-cols-3 gap-7 px-16">
                            <SquareView
                                shapCustom="gap-4 px-12 py-4 rounded-[1.25rem]"
                                isSelected={ selectedType === "wa" }
                                onClick={() => handleSelectedTypeChange(SOCIAL_MEDIA_TYPES.WHATSAPP)}
                                icon={<Icons.SvgMediaWhatsappFill className="w-16"/>}
                                viewName={_("WhatsApp")}
                            />

                            <SquareView
                                shapCustom="gap-4 px-12 py-4 rounded-[1.25rem]"
                                isSelected={ selectedType === "li" }
                                onClick={() => handleSelectedTypeChange(SOCIAL_MEDIA_TYPES.LINKEDIN)}
                                icon={<Icons.SvgMediaLinkedinFill className="w-16" />}
                                viewName={_("LinkedIn")}
                            />

                            <SquareView
                                shapCustom="gap-4 px-12 py-4 rounded-[1.25rem]"
                                isSelected={ selectedType === "yb" }
                                onClick={() => handleSelectedTypeChange(SOCIAL_MEDIA_TYPES.YOUTUBE)}
                                icon={<Icons.SvgMediaYoutubeFill className="w-16" />}
                                viewName={_("YouTube")}
                            />

                            <SquareView
                                shapCustom="gap-4 px-12 py-4 rounded-[1.25rem]"
                                isSelected={ selectedType === "ig" }
                                onClick={() => handleSelectedTypeChange(SOCIAL_MEDIA_TYPES.INSTAGRAM)}
                                icon={<Icons.SvgMediaInstagram className="w-16" />}
                                viewName={_("Instagram")}
                            />

                            <SquareView
                                shapCustom="gap-4 px-12 py-4 rounded-[1.25rem]"
                                isSelected={ selectedType === "fb" }
                                onClick={() => handleSelectedTypeChange(SOCIAL_MEDIA_TYPES.FACEBOOK)}
                                icon={<Icons.SvgMediaFacebookFill className="w-16" />}
                                viewName={_("Facebook")}
                            />

                            <SquareView
                                shapCustom="gap-4 px-12 py-4 rounded-[1.25rem]"
                                isSelected={ selectedType === "tt" }
                                onClick={() => handleSelectedTypeChange(SOCIAL_MEDIA_TYPES.TWITTER)}
                                icon={<Icons.SvgMediaX className="w-16" />}
                                viewName={_("X")}
                            />

                            <SquareView
                                shapCustom="gap-4 px-12 py-4 rounded-[1.25rem]"
                                isSelected={ selectedType === "tk" }
                                onClick={() => handleSelectedTypeChange(SOCIAL_MEDIA_TYPES.TIKTOK)}
                                icon={<Icons.SvgMediaTikTok className="w-16" />}
                                viewName={_("TikTok")}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </PopupBase>
    );
}

export default PopupSelectorSocialMediaType;