import { Reorder } from "framer-motion";
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Icons } from "../../../assets/Svgs";
import { CONTACT_TYPES, contactIconOf } from "../../../constants/ContactsTypes";
import PopupSelectorCTAType from "../../../pages/Admin/Templates/Popups/PopupSelectorCTAType";
import PopupItemDelete from "../../../pages/Mobile/Popups/PopupItemDelete";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import WidgetService from "../../../services/Widget/WidgetService";
import ButtonBase from "../../Buttons/ButtonBase";
import ButtonToggle from "../../Buttons/ButtonToggle";
import TextField from "../../Inputs/TextField";
import InputGroup from "../../Layouts/InputGroup";
import { labelPreviewOf } from "../View/WidgetContactView";

const WidgetCTAEdit = forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
) => {
    useImperativeHandle(ref, () => ({
        handleSave,
        handleCreate
    }));

    /* Hooks */
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();

    /* States */
    const [data, setData] = useState({
        title: widget?.data?.title || "",
        type: widget?.data?.type || "",
        details: widget?.data?.details || {},
    });

    /* Utils */
    const buildPayload = (widgetData) => {
        return {
            widget: {
                ...widget,
                data: {
                    ...widgetData,
                }
            },
        }
    }

    /* Handlers */
    const handleSave = () => {
        const payload = buildPayload(data);
        WidgetService.updateWidget(widget.id, payload.widget)
            .then((savedWidget) => {
                onSave(savedWidget);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleCreate = () => {
        const payload = buildPayload(data);
        WidgetService.createWidget(payload.widget)
            .then((savedWidget) => {
                onSave(savedWidget);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleDataChange = (key, value, newValueLambda) => {
        setData((prevData) => {
            if (newValueLambda) {
                return newValueLambda(prevData);
            }
            return {
                ...prevData,
                [key]: value,
            }
        });
    };

    const handleChangeType = () => {
        addPopup(<PopupSelectorCTAType
            selectedType={data.type}
            setSelectedType={ (type) => handleDataChange("type", type) }
        />)
    }

    /* Effects */
    useEffect(() => {
        onChange({ ...widget, data: data });
    }, [data]);

    /* Renders */
    const renderThumbnail = () => {
        return contactIconOf({type: data.type, props: {className: "w-16 h-16 rounded-[0.875rem]"}})
               || <div className="w-16 h-16 rounded-[0.875rem] bg-gray-100"></div>
    }

    const renderContactDetails = () => {
        if (!data.type) return null;

        const defaultContactDetails = null;

        const contactDetails = {
            [CONTACT_TYPES.TEL]: <>
                <div className="flex gap-3.5 items-center">
                    <p className="text-gray-900 text-sm font-medium">{_("Do you want to use a unique phone number for all the team members ?")}</p>
                    <ButtonToggle
                        onChange={() => {
                            handleDataChange(null, null, (prevData) => ({
                                ...prevData,
                                details: {
                                    ...prevData.details || {},
                                    commonValue: !prevData.details.commonValue,
                                }
                            }))
                        }}
                        isToggled={data.details.commonValue || false}
                    />
                </div>
                {data.details.commonValue && (
                    <TextField
                        name="phoneNumber"
                        type="text"
                        label="Phone Number"
                        value={data.details.value || ""}
                        onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                            ...prevData,
                            details: {
                                ...prevData.details || {},
                                value: e.target.value,
                            }
                        }))}
                        required={true}
                    />
                )}
            </>,
            [CONTACT_TYPES.MSG]: <>
                <div className="flex gap-3.5 items-center">
                    <p className="text-gray-900 text-sm font-medium">{_("Do you want to use a unique phone number for all the team members ?")}</p>
                    <ButtonToggle
                        onChange={() => {
                            handleDataChange(null, null, (prevData) => ({
                                ...prevData,
                                details: {
                                    ...prevData.details || {},
                                    commonValue: !prevData.details.commonValue,
                                }
                            }))
                        }}
                        isToggled={data.details.commonValue || false}
                    />
                </div>
                {data.details.commonValue && (
                    <TextField
                        name="phoneNumber"
                        type="text"
                        label="Phone Number"
                        value={data.details.value || ""}
                        onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                            ...prevData,
                            details: {
                                ...prevData.details || {},
                                value: e.target.value,
                            }
                        }))}
                        required={true}
                    />
                )}
            </>,
            [CONTACT_TYPES.MAIL]: <>
                <div className="flex gap-3.5 items-center">
                    <p className="text-gray-900 text-sm font-medium">{_("Do you want to use a unique mail address for all the team members ?")}</p>
                    <ButtonToggle
                        onChange={() => {
                            handleDataChange(null, null, (prevData) => ({
                                ...prevData,
                                details: {
                                    ...prevData.details || {},
                                    commonValue: !prevData.details.commonValue,
                                }
                            }))
                        }}
                        isToggled={data.details.commonValue || false}
                    />
                </div>
                {data.details.commonValue && (
                    <TextField
                        name="mailAddress"
                        type="text"
                        label="Mail Address"
                        value={data.details.value || ""}
                        onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                            ...prevData,
                            details: {
                                ...prevData.details || {},
                                value: e.target.value,
                            }
                        }))}
                        required={true}
                    />
                )}
            </>,
            [CONTACT_TYPES.WEB]: <>
                <TextField
                    name="websiteLink"
                    type="text"
                    label="Website Link"
                    value={data.details.link || ""}
                    onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                        ...prevData,
                        details: {
                            ...prevData.details || {},
                            link: e.target.value,
                        }
                    }))}
                    required={true}
                />
            </>,
            [CONTACT_TYPES.LOCATION]: <>
                <TextField
                    name="address"
                    type="text"
                    label="Address"
                    value={data.details.address || ""}
                    onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                        ...prevData,
                        details: {
                            ...prevData.details || {},
                            address: e.target.value,
                        }
                    }))}
                    required={true}
                />
                <TextField
                    name="cityAndZip"
                    type="text"
                    label="City and ZIP"
                    value={data.details.cityAndZip || ""}
                    onChange={ (e) => handleDataChange(null, null, (prevData) => ({
                        ...prevData,
                        details: {
                            ...prevData.details || {},
                            cityAndZip: e.target.value,
                        }
                    }))}
                    required={true}
                />
            </>,
        };

        return contactDetails[data.type]
               || defaultContactDetails;
    }

    return (
        <div className="flex flex-col w-full h-full gap-7">
            <label className="flex flex-col">
                <span className="flex text-sm font-medium after:content-['*'] after:ml-0 after:text-red-500">
                    {_("Click-to-Action")}
                </span>
                <div className="flex flex-col m-2">
                    {
                        renderThumbnail()
                    }
                </div>
                <ButtonBase onClick={ handleChangeType } className="bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full">
                    {
                        _("Change type")
                    }
                </ButtonBase>
            </label>
            <TextField
                name="title"
                type="text"
                label="Call-to-Action Button Title"
                value={data.title || ""}
                onChange={ (e) => handleDataChange("title", e.target.value)}
                required={true}
            />
            { renderContactDetails() }
        </div>
    );
})

export default WidgetCTAEdit;