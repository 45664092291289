import APIService from "../APIService";

class LeadsService {

    static cachedLeads = null;

    static async createLeadFromForm(payload) {
        const response = await APIService.apiCall("leads/from-form/", "POST", payload, true, {}, null);
        if (!response.ok) throw new Error('Failed to exchange contact');
        const data = await response.json();
        return data;
    }

    static async fetchLeads() {
        const response = await APIService.apiCall("leads/", "GET");
        if (!response.ok) throw new Error('Failed to fetch leads');
        this.cachedLeads = await response.json();
        return this.cachedLeads;
    }

    static async getLeads() {
        if (this.cachedLeads === null) {
            await this.fetchLeads();
        }
        return this.cachedLeads;
    }

    static async getLeadById(id) {
        const leads = await this.getLeads();
        return leads.find(lead => lead.id === id);
    }

    static async createLead(lead) {
        const response = await APIService.apiCall("leads/", "POST", lead);
        if (!response.ok) throw new Error('Failed to create lead');
        const data = await response.json();
        this.cachedLeads = null; // Invalidate cache
        return data;
    }
}

export default LeadsService;
