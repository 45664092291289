import React, {useEffect, useState} from 'react';
import {Icons} from "../../../../assets/Svgs";
import {useTranslation} from "../../../../services/i18n/i18nService";
import {usePopup} from "../../../../services/PopupService";
import PopupBase from "../../../../components/Popups/PopupBase";
import SquareView from "../../../../components/Layouts/SelectorSquareView";

function PopupTemplateFilterProfileViewOverTime({ selectedView: selectedViewParent, onSelectedViewChange }) {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();
    const [ selectedView, setSelectedView ] = useState(selectedViewParent ?? "profileViews");

    useEffect(() => {
        onSelectedViewChange && onSelectedViewChange(selectedView);
    }, [selectedView, onSelectedViewChange]);

    return (
        <PopupBase
            title={_("Filter the statistics view by :")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[45.5rem]",
                        onClick: removePopup,
                    },
                ]
            }>
            <div className="w-full flex flex-col justify-center align-center" >
                <div className="grid w-full items-center">
                    <div className="grid gap-7">
                        <div className="flex flex-row w-full gap-7 justify-center">
                            <SquareView
                                className="gap-4"
                                isSelected={ selectedView === "profileViews" }
                                onClick={() => setSelectedView("profileViews")}
                                icon={<Icons.SvgStatisticEyeFill className="h-[3.125rem]"/>}
                                viewName={_("Profile Views")}
                            />

                            <SquareView
                                className="gap-4"
                                isSelected={ selectedView === "contactsDownloaded" }
                                onClick={() => setSelectedView("contactsDownloaded")}
                                icon={<Icons.SvgTemplateTwoPersonFill className="h-[3.125rem]" />}
                                viewName={_("Contacts Download")}
                            />

                            <SquareView
                                className="gap-4"
                                isSelected={ selectedView === "leadsGenerated" }
                                onClick={() => setSelectedView("leadsGenerated")}
                                icon={<Icons.SvgTemplatePersonAdd className="h-[3.125rem]" />}
                                viewName={_("Leads Generated")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PopupBase>
    );
}

export default PopupTemplateFilterProfileViewOverTime;