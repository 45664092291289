import React from 'react';
import { Icons } from "../../../../assets/Svgs";
import {Link} from "react-router-dom";
import {getFullPath} from "../../../../router/Router";
import {useTranslation} from "../../../../services/i18n/i18nService";
import Table from "../../../../components/Layouts/Table";
import TagMedias from "../../../../components/Tags/TagMedias";

function StatisTopMedias({datas}) {
    const { t: _ } = useTranslation();

    return (
        <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm w-full">
            <header className="flex justify-between items-center">
                <div className="text-sm flex gap-1.5 items-center">
                    <p>{_("Top 5 Clicked Social Links")}</p>
                    <Icons.SvgStatisticClickFill />
                </div>

                <Link to={getFullPath(["ROOT", "STATISTICS", "MEDIAS"])} className="flex justify-center gap-[0.438rem]">
                    <div className="text-sm flex gap-[0.188rem] items-center hover:underline hover:select-all">
                        {_("View")}
                        <Icons.SvgStatisticCirclePlusFill color="#292D32" />
                    </div>
                </Link>
            </header>

            <Table
                displayHeaders={false}
                bgColorClass="bg-white"
                columns={[{}, {}, {}]}
                rows={datas.map((perform, index) => ({
                    data: [
                        { value: <div className="flex justify-center items-center">{index + 1}</div> },
                        { value: <span>{TagMedias(perform, "h-6")}</span> },
                        { value: <div className="flex justify-center items-center">{perform.clicks}</div> },
                    ],
                }))}
            />
        </div>
    );
}

export default StatisTopMedias;