import React from 'react';

function SvgProfilePopupMediaLink({className, color}) {
    return (
        <svg className={`${!!className ? className : "h-4" } `} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.4357 18.1393C7.05237 18.1393 5.6607 17.6143 4.60237 16.5559C2.49403 14.4393 2.49403 11.0059 4.60237 8.89759C4.84403 8.65592 5.24403 8.65592 5.4857 8.89759C5.72737 9.13926 5.72737 9.53926 5.4857 9.78092C3.8607 11.4059 3.8607 14.0476 5.4857 15.6726C7.1107 17.2976 9.75237 17.2976 11.3774 15.6726C12.1607 14.8893 12.594 13.8393 12.594 12.7226C12.594 11.6143 12.1607 10.5643 11.3774 9.77259C11.1357 9.53092 11.1357 9.13092 11.3774 8.88926C11.619 8.64759 12.019 8.64759 12.2607 8.88926C13.2857 9.91426 13.844 11.2726 13.844 12.7226C13.844 14.1726 13.2774 15.5309 12.2607 16.5559C11.2107 17.6143 9.82737 18.1393 8.4357 18.1393Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M16.8357 12.0227C16.6774 12.0227 16.519 11.9644 16.394 11.8394C16.1524 11.5977 16.1524 11.1977 16.394 10.956C18.1024 9.24769 18.1024 6.47269 16.394 4.77269C14.6857 3.06436 11.9107 3.06436 10.2107 4.77269C9.3857 5.59769 8.92737 6.69769 8.92737 7.86436C8.92737 9.03102 9.3857 10.131 10.2107 10.956C10.4524 11.1977 10.4524 11.5977 10.2107 11.8394C9.96903 12.081 9.56904 12.081 9.32737 11.8394C8.26904 10.781 7.67737 9.36436 7.67737 7.86436C7.67737 6.36436 8.2607 4.94769 9.32737 3.88936C11.519 1.69769 15.0857 1.69769 17.2857 3.88936C19.4774 6.08102 19.4774 9.65602 17.2857 11.8477C17.1607 11.9644 16.994 12.0227 16.8357 12.0227Z" fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgProfilePopupMediaLink;