import React, {useRef} from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {useLocation, useNavigate} from "react-router-dom";
import ButtonLoginPrevious from "../../../components/Buttons/ButtonLoginPrevious";
import {getFullPath} from "../../../router/Router";
import {Icons} from "../../../assets/Svgs";
import StorageService from "../../../services/StorageService";
import Transition from "../../../transition";

function AvatarPicture() {

    const { t: _ } = useTranslation();
    const navigate = useNavigate();
    const hiddenFileInput = useRef(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const transitionType = searchParams.get('transition');

    const [personalInfoValues] = StorageService.useLocalStorage("personalInfo", {
        first_name: "",
        last_name: "",
    });

    const [positionValues] = StorageService.useLocalStorage("position", {
        position: "",
        company: "",
    });

    const [values, setValues] = StorageService.useLocalStorage("profilePicture", {
        avatar: "",
    });

    const onChangePicture = () => {
        hiddenFileInput.current.click();
    }

    const handleSelectImage = (event) => {
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            setValues({ ...values, [event.target.name]: fileReader.result });
        });
        fileReader.readAsDataURL(file);

        document.querySelector("#innerUpload").innerHTML = "Change this picture";
        document.querySelector("#innerPass").innerHTML = "Next step";
    }

    return (
        <Transition transitionType={transitionType}>
            <div className="flex w-full h-full bg-white">
                <ButtonLoginPrevious onClick={() => {
                    navigate(getFullPath(["ROOT", "POSITION"]) + "?transition=turn-right");
                }} />

                <div className="absolute top-32 w-full">
                    <div className="ss:p-20 w-full px-6 gap-4 ss:border-0 bg-white xxs:rounded-[0.914rem] flex flex-col align-center items-center justify-center">

                        <div className="flex flex-col w-full gap-8">
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-4">
                                    <div className="w-full gap-6">
                                        <div className="text-[#111827] font-bold ss:text-[2.5rem] xxs:text-[1.25rem] leading-7 font-medium">
                                            { _("Profile Picture") }
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col w-full gap-4">
                                    <div className="flex flex-col h-fit">
                                        <div className="w-full px-7 flex flex-col">

                                            <div className="h-[17rem] before:w-full border border-b-0 before:h-[8.5rem] before:bg-gray-gradient before:absolute before:top-0 w-full relative bg-white flex flex-col items-center justify-center gap-0 text-center rounded-t-[1.1rem] overflow-hidden">
                                                <div className="absolute top-3 right-3">
                                                    <div className="h-[1.438rem] relative bg-gray-gradient w-[3.7rem] rounded-[0.913rem]" />
                                                </div>

                                                <div className="absolute top-3 left-3">
                                                    <Icons.SvgLoginProfyleadLogo/>
                                                </div>

                                                {
                                                    !!values["avatar"]
                                                        ? <img className="w-32 h-32 rounded-full z-5 border-4 border-white" src={ values["avatar"] } alt="preview"/>
                                                        : <div className="w-32 h-32 rounded-full z-5 bg-blue-gradient border-4 border-white"/>
                                                }

                                                <div className="absolute bottom-3">
                                                    <div className="grid gap-1.5">
                                                        <div className="text-[1.2rem] leading-6 font-semibold">{personalInfoValues.first_name + " " + personalInfoValues.last_name || _("First Last")}</div>
                                                        <span className="text-sm text-[#6B7280] leading-4">
                                                        { positionValues.position || _("Position")}
                                                            {_(" at")} {positionValues.company || _("Company")}
                                                    </span>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="w-full flex border rounded-b-[1.1rem] border-t-0 p-3 pt-0">
                                                <div className="h-[8.3rem] bg-gray-gradient w-full rounded-[0.364rem]" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className={
                                        `flex flex-row items-center hover:no-underline gap-2 px-4 py-3 hover:bg-blue-gradient rounded-full text-base font-semibold
                                        text-white bg-[#0400BD] justify-center items-center`}
                                         onClick={ () => {
                                             onChangePicture();
                                         }}
                                    >

                                        <input
                                            name="avatar"
                                            type="file"
                                            className="hidden"
                                            ref={ hiddenFileInput }
                                            onChange={ handleSelectImage }
                                        />
                                        <Icons.SvgLoginEditPicture />
                                        <p id="innerUpload">{ _("Upload a picture") }</p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col w-full gap-3">
                                <div className={
                                    `flex flex-row items-center hover:no-underline gap-2 px-4 py-3 hover:bg-blue-gradient rounded-full text-base font-semibold
                                     text-white bg-[#0400BD] justify-center items-center`}
                                     onClick={() => {
                                         navigate(getFullPath(["ROOT", "BG_PICTURE"]) + "?transition=turn-left");
                                     }}
                                >
                                    <p id="innerPass">{ _("I don’t want to add a picture yet") }</p>
                                    <Icons.SvgLoginArrowCircleRight/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
}

export default AvatarPicture;