import { useState } from 'react';
import { usePopup } from "../../../services/PopupService";
import { useTranslation } from "../../../services/i18n/i18nService";
import TextField from '../../Inputs/TextField';
import PopupBase from "../PopupBase";

function PopupAddNewRefCard({ handleAssignCard }) {
    const { removePopup } = usePopup();
    const { t: _ } = useTranslation();

    const [ cardCredentials, setCardCredentials ] = useState({});

    return (
        <PopupBase
            title={_("Choose a template to assign to the selected members:")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                    {
                        label: _("Add this new card"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: () => {
                            handleAssignCard(cardCredentials);
                            removePopup();
                        },
                    }
                ]
            }>
            <div className="w-full flex flex-col justify-center align-center" >
                <TextField
                    name="numRef"
                    type="text"
                    label="Reference Number"
                    value={cardCredentials.ref_number || ""}
                    onChange={(e) => {
                        setCardCredentials(prevCardCredentials => ({ ...prevCardCredentials, ref_number: e.target.value }));
                    }}
                />
                <TextField
                    name="pin"
                    type="text"
                    label="PIN Code"
                    value={cardCredentials.pin_code || ""}
                    onChange={(e) => {
                        setCardCredentials(prevCardCredentials => ({ ...prevCardCredentials, pin_code: e.target.value }));
                    }}
                />
            </div>
        </PopupBase>
    );
}

export default PopupAddNewRefCard;