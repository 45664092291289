import { useEffect, useState } from 'react';
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import PopupConfirmDeleteRefCard from "../../../components/Popups/AssignSelectedTeamMember/PopupConfirmDeleteRefCard";
import PopupBeenDeletedRefCard from "../../../components/Popups/AssignSelectedTeamMember/PopupBeenDeletedRefCard";
import PopupErrorPinCode from "../../../components/Popups/AssignSelectedTeamMember/PopupErrorPINCode";
import PopupBeenUpdatedRefCard from "../../../components/Popups/AssignSelectedTeamMember/PopupBeenUpdatedRefCard";
import PopupEditRefCard from "../../../components/Popups/AssignSelectedTeamMember/PopupEditRefCard";
import TextField from "../../../components/Inputs/TextField";
import PopupAddNewRefCard from "../../../components/Popups/AssignSelectedTeamMember/PopupAddNewRefCard";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import CardsService from '../../../services/Card/CardsService';
import { getFullPath, SUBDOMAINS } from '../../../router/Router';
import TeamService from '../../../services/Team/TeamService';
import RedirectService from '../../../services/Redirect/RedirectService';
import UserService from '../../../services/User/UserService';

function TeamFormReference({ teamMemberId, onChange }) {
    /* Hooks */
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();

    /* States */
    const [ card, setCard ] = useState(null);
    const [ redirect, setRedirect ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    /* Utils */
    const isLoading = !teamMemberId || loading;
    const profileViewBaseUrl = SUBDOMAINS.APP.buildFullURL(getFullPath(["ROOT", "PROFILE", "VIEW"])).replace(":link", "");

    /* Handlers */
    const handleLoadCard = () => {
        CardsService.getCards()
            .then(cards => {
                const cardOwnedByMember = cards
                    .find(card => card.owner === teamMemberId);
                if (!cardOwnedByMember) {
                    setCard(null);
                    return;
                };
                setCard(cardOwnedByMember);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleLoadTeamMember = () => {
        TeamService.getTeamMemberById(teamMemberId)
            .then(teamMember => {
                setRedirect(teamMember.portfolios?.find(p => true)?.redirects?.find(r => true));
            })
            .catch(err => {
                console.error(err);
            })
    }

    const handleProfileLinkChange = (e) => {
        const newValue = e.target.value;
        if (!newValue.startsWith(profileViewBaseUrl)) return;
        setRedirect((prev) => ({ ...prev, clean_label: newValue.replace(profileViewBaseUrl, "") }));
    }

    const handleAssignCard = () => {
        addPopup(
            <PopupAddNewRefCard handleAssignCard={ (cardCredentials) => {
                const payload = {
                    card_ref: cardCredentials.ref_number,
                    pin_code: cardCredentials.pin_code,
                    owner: teamMemberId,
                }
                CardsService.assignCard(payload)
                    .then(() => {
                        addPopup(<PopupBeenUpdatedRefCard />)
                        handleLoadCard();
                        UserService.invalidateCache();
                        handleLoadTeamMember();
                    })
                    .catch(err => {
                        addPopup(<PopupErrorPinCode />)
                    })
            }}/>
        )
    }

    const handleUnassignCard = () => {
        addPopup(
            <PopupConfirmDeleteRefCard handleDelete={ () => {
                CardsService.unassignCard(teamMemberId)
                    .then(() => {
                        handleLoadCard();
                    })
                    .catch(err => {
                        console.error(err);
                    })
            } } />
        )
    }

    const handleUpdateRedirect = () => {
        const payload = {
            portfolio: redirect.portfolio,
            clean_label: redirect.clean_label,
        }
        RedirectService.updateRedirect(payload)
            .then(() => {
                console.log("Redirect updated successfully");
            })
            .catch(err => {
                console.error(err);
            })
    }

    /* Effects */
    useEffect(() => {
        handleLoadCard();
        if (teamMemberId !== undefined) {
            handleLoadTeamMember();
        }
    }, [teamMemberId]);

    /* Render */
    if (isLoading) {
        return <div className="flex-1 bg-white p-7">Loading...</div>
    }

    return (
        <div className="flex flex-1 flex-col gap-7">
            <TextField
                name="numRef"
                type="text"
                placeholder="No card registered yet"
                label="Reference Number"
                value={card?.ref_number || ""}
                disabled
            />
            <TextField
                name="path"
                type="text"
                placeholder="No card registered yet"
                label="Path"
                value={card?.link || ""}
                disabled
            />
            <TextField
                name="linkProfile"
                type="text"
                placeholder="No card registered yet"
                label="Profile Link"
                errorMsg="Profile link should be 8-20 characters include number and alphabet and shouldn't include any special character"
                pattern={"^" + profileViewBaseUrl + "[A-Za-z0-9]{8,20}$"}
                showCopyButton
                value={!!card ? profileViewBaseUrl + (redirect?.clean_label || "") : ""}
                onChange={handleProfileLinkChange}
                disabled={!card}
            />
            <div className="flex justify-around items-center">
                <ButtonBase onClick={
                    !!card
                        ? handleUnassignCard
                        : handleAssignCard
                } className="bg-black md:w-auto text-white text-sm py-3.5 px-6 rounded-full">
                    {!!card
                        ? _("Unassign this card")
                        : _("Assign a new card")}
                </ButtonBase>
                {!!card &&
                    <ButtonBase onClick={handleUpdateRedirect} className="bg-black md:w-auto text-white text-sm py-3.5 px-6 rounded-full">
                        {_("Update the Profile Link")}
                    </ButtonBase>
                }
            </div>
        </div>
    );
}

export default TeamFormReference;