import { AnimatePresence } from "framer-motion";
import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import Layout from "../components/Layouts/Layout";
import PopupLayout from '../components/Popups/PopupLayout';
import CardCreate from "../pages/Admin/Cards/CardCreate";
import CardEdit from "../pages/Admin/Cards/CardEdit";
import Cards from "../pages/Admin/Cards/Cards";
import CardView from "../pages/Admin/Cards/CardView";
import ContractEdit from "../pages/Admin/Contracts/ContractEdit";
import ContractFiles from "../pages/Admin/Contracts/ContractFiles";
import Contracts from "../pages/Admin/Contracts/Contracts";
import ContractView from "../pages/Admin/Contracts/ContractView";
import CreateNewContract from "../pages/Admin/Contracts/CreateNewContract";
import AccountCreate from "../pages/Admin/Customers/AccountCreate";
import AccountEdit from "../pages/Admin/Customers/AccountEdit";
import AccountView from "../pages/Admin/Customers/AccountView";
import CustomerAccounts from "../pages/Admin/Customers/CustomerAccounts";
import CustomerEdit from "../pages/Admin/Customers/CustomerEdit";
import Customers from "../pages/Admin/Customers/Customers";
import CustomersCreate from "../pages/Admin/Customers/CustomersCreate";
import CustomerView from "../pages/Admin/Customers/CustomerView";
import DivisionEdit from "../pages/Admin/Divisions/DivisionEdit";
import DivisionsChart from "../pages/Admin/Divisions/DivisionsChart";
import DivisionStatistics from "../pages/Admin/Divisions/DivisionStatistics";
import LeadEdit from "../pages/Admin/Leads/LeadEdit";
import Leads from "../pages/Admin/Leads/Leads";
import LeadView from "../pages/Admin/Leads/LeadView";
import Settings from "../pages/Admin/Settings/Settings";
import Statistics from "../pages/Admin/Statistics/Statistics";
import StatisticsPerformMemberView from "../pages/Admin/Statistics/StatisticsPerformMemberView";
import StatisticsTopContacts from "../pages/Admin/Statistics/StatisticsTopContacts";
import StatisticsTopFiles from "../pages/Admin/Statistics/StatisticsTopFiles";
import StatisticsTopMedias from "../pages/Admin/Statistics/StatisticsTopMedias";
import { StatisticsTopPerformers } from "../pages/Admin/Statistics/StatisticsTopPerformers";
import StatisticsTopVideos from "../pages/Admin/Statistics/StatisticsTopVideos";
import StatisticsTopViewsPicture from "../pages/Admin/Statistics/StatisticsTopViewsPicture";
import StatisticsTopWebsites from "../pages/Admin/Statistics/StatisticsTopWebsites";
import StatisticsAdmin from "../pages/Admin/StatisticsAdmin/StatisticsAdmin";
import Team from "../pages/Admin/Team/Team";
import TeamMemberView from "../pages/Admin/Team/TeamMemberView";
import TeamStatisticsView from "../pages/Admin/Team/TeamStatisticsView";
import TemplateCharts from "../pages/Admin/Templates/TemplateCharts";
import TemplateDistributorSection from "../pages/Admin/Templates/TemplateDistributorSection";
import TemplateEdit from "../pages/Admin/Templates/TemplateEdit";
import Login from '../pages/Login';
import AvatarPicture from "../pages/Mobile/Account/AvatarPicture";
import BackgroundPicture from "../pages/Mobile/Account/BackgroundPicture";
import ContactInfos from "../pages/Mobile/Account/ContactInfos";
import Password from "../pages/Mobile/Account/Password";
import PersoInfos from "../pages/Mobile/Account/PersoInfos";
import Position from "../pages/Mobile/Account/Position";
import ConnectionSelect from "../pages/Mobile/Connection/ConnectionSelect";
import StickerManage from "../pages/Mobile/Connection/StickerManage";
import PortfolioSection from "../pages/Mobile/Layouts/PortfolioSection";
import Portfolio from "../pages/Mobile/Portfolio";
import PortfolioAddSection from "../pages/Mobile/Portfolio/PortfolioAddSection";
import PortfolioReorder from "../pages/Mobile/Portfolio/PortfolioReorder";
import PortfolioEdit from "../pages/Mobile/PortfolioEdit";
import SettingsMobile from "../pages/Mobile/Settings/SettingsMobile";
import StatisticsMobile from "../pages/Mobile/Statistics/StatisticsMobile";
import { ResetPassword } from "../pages/ResetPassword";
import AuthService from "../services/Auth/AuthService";

const COMMON_ROUTES = {
    PROFILE: {
        path: "p",
        element: <PortfolioEdit />,
        displayOnlyIfLeafNode: true,
        VIEW: {
            path: ":link",
            element: <Portfolio />,
        },
        SECTION: {
            path: ":widgetType/:widgetId",
            element: <PortfolioSection />,
        },
        SECTION_REORGANIZE: {
            path: "reorganize",
            element: <PortfolioReorder/>
        },
        SECTION_ADD: {
            path: "create",
            element: <PortfolioAddSection/>
        },
    },
}

const APP_ROUTES = {
    PERSO_INFOS: {
        path: "perso-infos",
        element: <PersoInfos/>,
    },
    CONTACT_INFOS: {
        path: "contact-infos",
        element: <ContactInfos/>,
    },
    POSITION: {
        path: "position",
        element: <Position/>,
    },
    PROFILE_PICTURE: {
        path: "prof-picture",
        element: <AvatarPicture/>,
    },
    BG_PICTURE: {
        path: "bg-picture",
        element: <BackgroundPicture/>,
    },
    PASSWD: {
        path: "passwd",
        element: <Password/>
    },
    CONNECTIONS: {
        path: "connections",
        element: <ConnectionSelect/>,
        displayOnlyIfLeafNode: true,
        STICKER_MANAGE: {
            path: "sticker-manage",
            element: <StickerManage />
        },
    },
    SETTINGS: {
        path: "settings",
        element: <SettingsMobile />
    },
    STATISTICS: {
        path: "statistics",
        element: <StatisticsMobile />
    },
}

const DASHBOARD_ROUTES = {
    CUSTOMERS: {
        path: "customers",
        element: <Customers />,
        displayOnlyIfLeafNode: true,
        VIEW_CUSTOMER: {
            path: "view/:custom",
            element: <CustomerView />,
            displayOnlyIfLeafNode: true,
            ACCOUNT_CUSTOMER: {
                path: "account",
                element: <CustomerAccounts />,
                displayOnlyIfLeafNode: true,
                CREATE_ACCOUNT: {
                    path: "create",
                    element: <AccountCreate />,
                },
                VIEW_ACCOUNT: {
                    path: "view/:acc",
                    element: <AccountView />,
                    displayOnlyIfLeafNode: true,
                    EDIT_ACCOUNT: {
                        path: "edit",
                        element: <AccountEdit />,
                    },
                },
            },
        },
        EDIT_CUSTOMER: {
            path: "edit/:custom",
            element: <CustomerEdit />,
        },
        ADD_CUSTOMER: {
            path: "add",
            element: <CustomersCreate />,
        }
    },
    CARDS: {
        path: "cards",
        element: <Cards />,
        displayOnlyIfLeafNode: true,
        VIEW_CARD: {
            path: "view/:id",
            element: <CardView />,
            displayOnlyIfLeafNode: true,
            EDIT_CARD: {
                path: "edit",
                element: <CardEdit />,
            },
        },
        CREATE_CARD: {
            path: "create",
            element: <CardCreate />,
        }
    },
    CONTRACTS: {
        path: "contracts",
        element: <Contracts />,
        displayOnlyIfLeafNode: true,
        VIEW_CONTRACT: {
            path: "view/:id",
            element: <ContractView />,
            displayOnlyIfLeafNode: true,
            EDIT_CONTRACT: {
                path: "edit",
                element: <ContractEdit />,
            },
            FILES: {
                path: "files/:idfiles",
                element: <ContractFiles />,
            },
        },
        CREATE_CONTRACT: {
            path: "create",
            element: <CreateNewContract />,
        }
    },
    LEADS: {
        path: "leads",
        element: <Leads />,
        displayOnlyIfLeafNode: true,
        VIEW_LEAD: {
            path: "view/:id",
            element: <LeadView />,
        },
        EDIT_LEAD: {
            path: "edit/:id",
            element: <LeadEdit />,
        },
    },
    ADMINSTATISTICS: {
        path: "adminstatistics",
        element: <StatisticsAdmin />,
    },
    DIVISIONS: {
        path: "divisions",
        element: <DivisionsChart />,
        displayOnlyIfLeafNode: true,
        EDIT_DIVISION: {
            path: "edit/:id",
            element: <DivisionEdit />,
            displayOnlyIfLeafNode: true,
            STATISTICS_DIVISION: {
                path: "statistics",
                element: <DivisionStatistics />,
            }
        },
    },
    STATISTICS: {
        path: "statistics",
        element: <Statistics />,
        displayOnlyIfLeafNode: true,
        PERFORMERS: {
            path: "performers",
            element: <StatisticsTopPerformers />,
            displayOnlyIfLeafNode: true,
            VIEW_PERFORM: {
                path: "view/:id",
                element: <StatisticsPerformMemberView />,
            },
        },
        WEBSITES: {
            path: "websites",
            element: <StatisticsTopWebsites />,
        },
        PICTURES: {
            path: "pictures",
            element: <StatisticsTopViewsPicture />,
        },
        MEDIAS: {
            path: "medias",
            element: <StatisticsTopMedias />,
        },
        VIDEOS: {
            path: "videos",
            element: <StatisticsTopVideos />,
        },
        DOWNLOADS: {
            path: "downloads",
            element: <StatisticsTopFiles />,
        },
        CONTACTS: {
            path: "contacts",
            element: <StatisticsTopContacts />,
        },
    },
    TEAM: {
        path: "team",
        element: <Team />,
        displayOnlyIfLeafNode: true,
        VIEW_TEAM_MEMBER: {
            path: "view/:id",
            element: <TeamMemberView />,
        },
        ADD_TEAM_MEMBER: {
            path: "add",
            element: <TeamMemberView />,
        },
        VIEW_TEAM_MEMBER_STATISTICS: {
            path: "view/statistics/:id",
            element: <TeamStatisticsView />,
        }
    },
    TEMPLATES: {
        path: "templates",
        element: <TemplateCharts />,
        displayOnlyIfLeafNode: true,
        EDIT_TEMPLATE: {
            path: ":id/edit",
            element: <TemplateEdit />,
            displayOnlyIfLeafNode: true,
            EDIT_WIDGET: {
                path: ":idType/:widgetId",
                element: <TemplateDistributorSection />,
            },
            CREATE_WIDGET: {
                path: ":idType",
                element: <TemplateDistributorSection />,
            }
        },
        CREATE_TEMPLATE: {
            path: "new",
            element: <TemplateEdit />,
        },
    },
    SETTINGS: {
        path: "settings",
        element: <Settings />,
    },
}

export const SUBDOMAINS = {
    APP: {
        path: "app",
        home: ["PROFILE"],
        routes: APP_ROUTES,
        buildFullURL: (path) => `https://app.${window.location.host.split(".").slice(1).join(".")}${path}`,
    },
    DASHBOARD: {
        path: "dashboard",
        home: ["STATISTICS"],
        routes: DASHBOARD_ROUTES,
        buildFullURL: (path) => `https://dashboard.${window.location.host.split(".").slice(1).join(".")}${path}`,
    },
}

export const SUBDOMAIN = SUBDOMAINS[Object.keys(SUBDOMAINS).find(k => SUBDOMAINS[k].path === window.location.host.split(".")[0].toLowerCase())]
                         ?? SUBDOMAINS.APP;

const ROUTES_PATHS = {
    ROOT: {
        path: "",
        redirect: ["ROOT", "HOME"],
        LOGIN: {
            path: "login",
            element: <Login />,
        },
        RESET_PASSWORD: {
            path: "reset-password",
            element: <ResetPassword />,
        },
        HOME: {
            path: "-",
            redirect: [
                "ROOT", ...SUBDOMAIN.home
            ],
        },
        ...COMMON_ROUTES,
        ...(SUBDOMAIN.routes)
    },
    DEFAULT: {
        path: "*",
        redirect: ["ROOT", "LOGIN"],
    }
}

export const getFullPath = (pathSegments) => { // TODO: handle urlparams + relative path
    let currentPath = ROUTES_PATHS;
    let outputPath = "";

    for (let segment of pathSegments) {
        if (currentPath[segment]) {
            if (currentPath[segment].path) {
                outputPath = `${outputPath}/${currentPath[segment].path}`;
            }
            currentPath = currentPath[segment];
        } else {
            throw new Error(`Path segment "${segment}" not found in ROUTES_PATHS`);
        }
    }

    return outputPath;
}

const RequireAuth = ({ children }) => {
    const isAuthenticated = async () => {
        return await AuthService.isLoggedIn();
    };

    if (!isAuthenticated()) {
        return <Navigate to={getFullPath(["ROOT", "LOGIN"])} replace />;
    }

    return children;
};

const NestedRoute = ({ routes, level }) => {
    return Object.keys(routes).map((routeName, index) => {
        const route = routes[routeName];
        const routeKey = level+String(index);
        const redirectNode = ()=><Navigate to={getFullPath(route.redirect)} replace={true} />;

        if (route.path!==undefined) {
            return (
                <Route key={routeKey} path={route.path} element={(route.displayOnlyIfLeafNode || !route.element) ? ((route.path==="*" && route.redirect) ? redirectNode() : <Outlet />) : route.element}>
                    { route.displayOnlyIfLeafNode ? <Route key={routeKey+"-index"} index element={ route.element } /> : null }
                    { route.redirect ? <Route key={routeKey+"-index"} index element={redirectNode()} /> : null }
                    { NestedRoute({routes: route, level: routeKey}) }
                </Route>
            );
        }
        return null;
    })
}

export const Router = () => {
    return (
        <BrowserRouter>
            <PopupLayout />
            <AnimatePresence mode="wait">
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        { NestedRoute({routes: ROUTES_PATHS, level: ""}) }
                    </Route>
                </Routes>
            </AnimatePresence>
        </BrowserRouter>
    );
}

export default Router;
