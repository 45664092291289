import React from 'react';

function SvgLeadsExportOutline(props) {
    return (
        <svg className={`${!!props.className ? props.className : "h-4" } `} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 5.50039L10.6 1.40039" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.0016 3.4V1H8.60156" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11H7.5C10 11 11 10 11 7.5V6.5" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgLeadsExportOutline;