import React, { createContext, useContext, useState, useRef, useImperativeHandle, forwardRef } from 'react';

const ToastContext = createContext();

const Toast = forwardRef((props, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('');

    useImperativeHandle(ref, () => ({
        showToast(message, type = 'info', duration = 3000) {
            setMessage(message);
            setType(type);
            setIsVisible(true);

            setTimeout(() => {
                setIsVisible(false);
            }, duration);
        },
    }));

    return (
        <div
            className={`fixed bottom-5 right-0 px-6 py-4 rounded-md text-white z-50 transition-transform duration-500 ${
                isVisible ? 'translate-x-0' : 'translate-x-full'
            } ${
                type === 'success' ? 'bg-green-500' : type === 'error' ? 'bg-red-500' : 'bg-blue-500'
            }`}
        >
            {message}
        </div>
    );
});

export const ToastProvider = ({ children }) => {
    const toastRef = useRef();

    return (
        <ToastContext.Provider value={toastRef}>
            {children}
            <Toast ref={toastRef} />
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    const toastRef = useContext(ToastContext);
    return {
        showToast: (message, type, duration) => {
            if (toastRef.current) {
                toastRef.current.showToast(message, type, duration);
            }
        },
    };
};