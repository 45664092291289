import React, {useEffect, useState} from 'react';
import ButtonMobile from "../../../components/Buttons/ButtonMobile";
import {Icons} from "../../../assets/Svgs";
import {getFullPath} from "../../../router/Router";
import {useNavigate} from "react-router";
import {useTranslation} from "../../../services/i18n/i18nService";
import InputSearch from "../../../components/Layouts/InputSearch";
import PopupStickerAdd from "./Popups/PopupStickerAdd";
import {usePopup} from "../../../services/PopupService";
import TagsService from "../../../services/Connections/TagsService";
import PopupStickerEdit from "./Popups/PopupStickerEdit";
import PopupSave from "./Popups/PopupSave";

function StickerManage() {
    const navigate = useNavigate();
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const [ tags, setTags ] = useState([]);
    const [ tagsOriginal, setTagsOriginal ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ value, setValue ] = useState("");

    const onSearch = (listToSearch, keyword) => {
        if (!keyword) return listToSearch;
        const lowerCaseKeyword = keyword.toLowerCase();
        return listToSearch.filter(item => item.name.toLowerCase().includes(lowerCaseKeyword));
    };    

    const handleCancel = () => {
        navigate(getFullPath(["ROOT", "CONNECTIONS"]));
    }

    const handleSave = () => {
        addPopup(<PopupSave/>);
    }

    useEffect(() => {
        setLoading(true);
        TagsService.getTags()
            .then(res => {
                setTags(res);
                setTagsOriginal(res);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div className="m-4 grid gap-10">
            <div className="flex justify-between">
                <ButtonMobile onClick={handleCancel} className="px-2.5 py-1.5 bg-white border border-[#0400BD]" textClass="text-[#0400BD] font-normal text-[1rem] leading-5" label="Back" />
                <ButtonMobile onClick={handleSave} className="px-2.5 py-1.5" textClass="text-white font-normal text-[1rem] leading-5" label="Save" icon={<Icons.SvgProfileEditHeaderSave />} />
            </div>

            <div className="flex flex-col gap-8">
                <div className="grid gap-4">
                    <div className="text-[#111827] font-medium text-[1.25rem] leading-7 w-4/6">
                        { _("Manage your stickers") }
                    </div>

                    <div>
                        <ButtonMobile
                            onClick={() => { addPopup( <PopupStickerAdd tags={tags} /> )} }
                            isFlexReverse={true}
                            className="px-2.5 py-1.5 gap-1.5"
                            textClass="text-white font-normal text-[1rem] leading-5"
                            label="Create a new sticker"
                            icon={<Icons.SvgConnectionFlag />}
                        />
                    </div>

                    <div>
                        <InputSearch
                            onSearch={onSearch}
                            value={value}
                            setValue={setValue}
                            setList={setTags}
                            listToSearchOriginal={tagsOriginal}
                            className="w-full border rounded-lg focus:outline-none active:outline-none h-10 pl-10 pr-4 text-[#6B7280]"
                            icon={<Icons.SvgSearchView color="#9CA3AF" className="absolute top-1/2 left-3 -translate-y-1/2 h-5"/>}
                            placeholder={"Search stickers"}
                            isFlexible={false}
                        />
                    </div>

                    <div className="flex flex-col gap-2">
                        {
                            !!tags
                                ? tags.map((tag, index) => (
                                    <div key={index} className="border border-[#EEEFF2] py-2 px-4 rounded-lg flex justify-between">
                                        <div className="flex gap-1">
                                            <ButtonMobile className={`px-2 py-1 ${tag.bgClass}`} textClass="text-white font-normal text-[1rem] leading-5" label={tag.name} />
                                        </div>

                                        <div onClick={() => { addPopup( <PopupStickerEdit tags={tags} setTags={setTags} tagSelected={tag} /> )} }>
                                            <Icons.SvgProfileEditArrowSquare />
                                        </div>
                                    </div>
                                ))
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StickerManage;