import React from 'react';
import PopupBase from "../PopupBase";
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import Table from "../../Layouts/Table";

function PopupConfirmAssignPersonSelected({template: templateParent, onConfirmTemplate}) {
    const { removePopup, clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={_("Are you sure you want to filter by this template :")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: clearAllPopups,
                    },
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: () => { onConfirmTemplate(templateParent); clearAllPopups(); },
                    },
                ]
            }>
            <div className="w-full flex flex-col justify-center align-center" >
                <div className="grid w-full items-center">
                    <div className="grid gap-7 px-48">
                        {
                            templateParent
                                ? <Table
                                    roundedClass="rounded-[0.625rem]"
                                    tdheightClass="h-14 w-96"
                                    columns={[
                                        {
                                            label: _(""),
                                            sortable: false,
                                        },
                                    ]}
                                    rows={[templateParent].map( (template, index) =>
                                        ({
                                            id: index,
                                            data: [
                                                {
                                                    value: <div className={`text-[#4B5563] flex items-center justify-center gap-[1.5rem]`}>
                                                        <span>{ _(template.name) }</span>
                                                    </div>,
                                                },
                                            ]
                                        })
                                    )}
                                />
                                : null
                        }
                    </div>
                </div>
            </div>
        </PopupBase>
    );
}

export default PopupConfirmAssignPersonSelected;