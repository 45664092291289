import React from 'react';

function InputSelected({ label, widthInputClass, children, options, bgColorClass, ...inputProps }) {

    return (
        <label className="flex flex-col gap-3.5">
            <span className={`${inputProps.required ? 'after:content-[\'*\'] after:ml-0 after:text-red-500' : ''} text-sm font-medium flex text-[#4B5563]`}>
                { label }
            </span>

            <div className={`${ widthInputClass } flex rounded-md shadow-sm`}>
                {
                    !!children
                        ?   <span className={`${children ? "border" : ""} px-3 inline-flex items-center min-w-fit rounded-l-md border-r-0 text-sm`} >
                            { children }
                        </span>
                        : null
                }

                <select className={`${ !!children ? "rounded-r-lg border border-l-0" : `rounded-lg ${ bgColorClass ? bgColorClass : '' }` } w-full  text-gray-900 text-sm block p-2.5 pr-9`}>
                    {
                        options
                            ? options.map((option, index) => (
                                <option key={ index }>{ option }</option>
                            ))
                            : null
                    }
                </select>
            </div>
        </label>
    )
}

export default InputSelected;