import React from 'react';

function SvgProfileEditPersoInfosPosition({className, color}) {
    return (
        <svg className={`${!!className ? className : "" } absolute top-1/2 left-3 -translate-y-1/2 h-4 `} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3334 18.9583H6.66678C2.81677 18.9583 2.10011 17.1666 1.91677 15.425L1.29177 8.74997C1.20011 7.87497 1.17511 6.5833 2.04177 5.61663C2.79177 4.7833 4.03344 4.3833 5.83344 4.3833H14.1668C15.9751 4.3833 17.2168 4.79163 17.9584 5.61663C18.8251 6.5833 18.8001 7.87497 18.7084 8.7583L18.0834 15.4166C17.9001 17.1666 17.1834 18.9583 13.3334 18.9583ZM5.83344 5.62497C4.42511 5.62497 3.45844 5.89997 2.96677 6.44997C2.55844 6.89997 2.42511 7.59163 2.53344 8.62497L3.15844 15.3C3.30011 16.6166 3.65844 17.7083 6.66678 17.7083H13.3334C16.3334 17.7083 16.7001 16.6166 16.8418 15.2916L17.4668 8.6333C17.5751 7.59163 17.4418 6.89997 17.0334 6.44997C16.5418 5.89997 15.5751 5.62497 14.1668 5.62497H5.83344Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M13.3334 5.62484C12.9917 5.62484 12.7084 5.3415 12.7084 4.99984V4.33317C12.7084 2.84984 12.7084 2.2915 10.6667 2.2915H9.33341C7.29175 2.2915 7.29175 2.84984 7.29175 4.33317V4.99984C7.29175 5.3415 7.00841 5.62484 6.66675 5.62484C6.32508 5.62484 6.04175 5.3415 6.04175 4.99984V4.33317C6.04175 2.8665 6.04175 1.0415 9.33341 1.0415H10.6667C13.9584 1.0415 13.9584 2.8665 13.9584 4.33317V4.99984C13.9584 5.3415 13.6751 5.62484 13.3334 5.62484Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M9.99992 13.9583C7.70825 13.9583 7.70825 12.5417 7.70825 11.6917V10.8333C7.70825 9.65833 7.99159 9.375 9.16659 9.375H10.8333C12.0083 9.375 12.2916 9.65833 12.2916 10.8333V11.6667C12.2916 12.5333 12.2916 13.9583 9.99992 13.9583ZM8.95825 10.625C8.95825 10.6917 8.95825 10.7667 8.95825 10.8333V11.6917C8.95825 12.55 8.95825 12.7083 9.99992 12.7083C11.0416 12.7083 11.0416 12.575 11.0416 11.6833V10.8333C11.0416 10.7667 11.0416 10.6917 11.0416 10.625C10.9749 10.625 10.8999 10.625 10.8333 10.625H9.16659C9.09992 10.625 9.02492 10.625 8.95825 10.625Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M11.6667 12.3084C11.3584 12.3084 11.0834 12.075 11.0501 11.7584C11.0084 11.4167 11.2501 11.1 11.5917 11.0584C13.7917 10.7834 15.9001 9.95004 17.6751 8.65837C17.9501 8.45004 18.3417 8.5167 18.5501 8.80004C18.7501 9.07504 18.6917 9.4667 18.4084 9.67504C16.4584 11.0917 14.1584 12 11.7417 12.3084C11.7167 12.3084 11.6917 12.3084 11.6667 12.3084Z" fill={!!color ? color : "#9CA3AF"}/>
            <path d="M8.33345 12.3164C8.30845 12.3164 8.28345 12.3164 8.25845 12.3164C5.97511 12.0581 3.75011 11.2248 1.82511 9.90809C1.54178 9.71642 1.46678 9.32476 1.65845 9.04142C1.85011 8.75809 2.24178 8.68309 2.52511 8.87476C4.28345 10.0748 6.30845 10.8331 8.39178 11.0748C8.73345 11.1164 8.98345 11.4248 8.94178 11.7664C8.91678 12.0831 8.65011 12.3164 8.33345 12.3164Z" fill={!!color ? color : "#9CA3AF"}/>
        </svg>
    );
}

export default SvgProfileEditPersoInfosPosition;