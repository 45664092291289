import APIService from "../APIService";

class RedirectService {
    static async updateRedirect(payload) {
        const response = await APIService.apiCall(`redirects/update/`, "POST", payload);
        if (response.status !== 200) throw new Error('Failed to update redirect');
    }
}

export default RedirectService;
