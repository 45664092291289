import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { WIDGET_TYPES } from '../../constants/Widgets';
import WidgetService from '../../services/Widget/WidgetService';
import WidgetDocumentEdit from "./Edit/WidgetDocumentEdit";
import GenaralFormEdit from "./Edit/WidgetFormEdit";
import WidgetPictureEdit from "./Edit/WidgetPictureEdit";
import WidgetAppLinkEdit from "./Edit/WidgetAppLinkEdit";
import WidgetCalendlyEdit from "./Edit/WidgetCalendlyEdit";
import WidgetGoogleReviewEdit from "./Edit/WidgetGoogleReviewEdit";
import WidgetInstagramFeedEdit from "./Edit/WidgetInstagramFeedEdit";
import WidgetLocationEdit from "./Edit/WidgetLocationEdit";
import WidgetPaypalEdit from "./Edit/WidgetPaypalEdit";
import WidgetPortfolioEdit from "./Edit/WidgetPortfolioEdit";
import WidgetTextEdit from "./Edit/WidgetTextEdit";
import WidgetVideoEdit from "./Edit/WidgetVideoEdit";
import WidgetWhatsAppCtaEdit from "./Edit/WidgetWhatsAppCtaEdit";
import WidgetWebsiteEdit from './Edit/WidgetWebsiteEdit';
import WidgetSocialMediaEdit from './Edit/WidgetSocialMediaEdit';
import WidgetContactEdit from './Edit/WidgetContactEdit';
import WidgetCTAEdit from './Edit/WidgetCTAEdit';

const defaultOnSave = () => {
    // default widget after save callback
}

const defaultOnDelete = () => {
    // default widget after delete callback
}

const defaultOnChange = () => {
    // default widget after change callback
}

const widgetsEditMap = {
    [WIDGET_TYPES.TEXT]: WidgetTextEdit,
    [WIDGET_TYPES.SOCIAL_MEDIA]: WidgetSocialMediaEdit,
    [WIDGET_TYPES.VIDEO]: WidgetVideoEdit,
    [WIDGET_TYPES.PICTURE]: WidgetPictureEdit,
    [WIDGET_TYPES.DOCUMENT]: WidgetDocumentEdit,
    [WIDGET_TYPES.WEBSITE]: WidgetWebsiteEdit,
    [WIDGET_TYPES.CONTACT]: WidgetContactEdit,
    [WIDGET_TYPES.CTA]: WidgetCTAEdit,
    [WIDGET_TYPES.LEAD_CREATION_FORM]: GenaralFormEdit,
    [WIDGET_TYPES.LOCATION]: WidgetLocationEdit,
    [WIDGET_TYPES.WHATSAPP_CTA]: WidgetWhatsAppCtaEdit,
    [WIDGET_TYPES.PAYPAL_CTA]: WidgetPaypalEdit,
    [WIDGET_TYPES.CALENDLY]: WidgetCalendlyEdit,
    [WIDGET_TYPES.GOOGLE_REVIEW]: WidgetGoogleReviewEdit,
    [WIDGET_TYPES.APP_LINK]: WidgetAppLinkEdit,
    [WIDGET_TYPES.INSTAGRAM_FEED]: WidgetInstagramFeedEdit,
    [WIDGET_TYPES.PROFILE]: WidgetPortfolioEdit,
}

const WidgetEdit = React.forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
) => {

    const innerWidgetRef = useRef();

    useImperativeHandle(ref, () => {
        if (innerWidgetRef.current) {
            return {
                ...innerWidgetRef.current
            };
        }
        return {};
    });

    onSave = onSave || defaultOnSave;
    onDelete = onDelete || defaultOnDelete;
    onChange = onChange || defaultOnChange;

    const [widgetType, setWidgetType] = useState(null);

    useEffect(() => {
        WidgetService.findWidgetTypeById(widget.widget_type)
            .then(data => {
                setWidgetType(data);
            })
            .catch(err => {
                console.error(err);
            });
    }, [widget]);
    
    if (!widgetType) {
        return null;
    }

    const widgetTypeName = widgetType.name;
    const WidgetEditComponent = widgetsEditMap[widgetTypeName];

    if (!WidgetEditComponent) {
        return null;
    }

    return <WidgetEditComponent ref={innerWidgetRef} widget={widget} onChange={onChange} onSave={onSave} onDelete={onDelete} />;
});

export default WidgetEdit;