import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {Icons} from "../../../assets/Svgs";
import InputGroup from "../../Layouts/InputGroup";
import InputTextarea from "../../Layouts/InputTextarea";
import WidgetService from '../../../services/Widget/WidgetService';
import {useParams} from "react-router-dom";

const WidgetTextEdit = React.forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
    ) => {
    useImperativeHandle(ref, () => ({
        handleSave,
        handleCreate
    }));

    const { t: _ } = useTranslation();
    const { idType } = useParams();

    const [data, setData] = useState({
        title: widget?.data?.title || "",
        text: widget?.data?.text || "",
    });

    const handleSave = () => {
        const updatedWidget = { ...widget, data };

        WidgetService.updateWidget(widget.id, widget)
            .then(() => {
                onSave(updatedWidget);
            })
            .catch((error) => {
                console.log('Failed to save widget');
                throw error;
            });
    }

    const handleCreate = () => {
        const updatedWidget = { ...widget, data };

        WidgetService.createWidget(updatedWidget)
            .then((newWidget) => {
                onSave(newWidget); // Pass the new widget to the onSave callback
            })
            .catch((error) => {
                console.log('Failed to create widget');
                throw error;
            });
    }

    const handleChanges = (newData) => {
        setData(newData);
        onChange({ ...widget, data: newData });
    };

    useEffect(() => {
        if (widget?.data) {
            setData({ title: widget.data.title || "", text: widget.data.text || "" });
        }
    }, [widget]);

    const isTemplate = !!window.location.href.includes("templates");

    return (
        <div className="grid gap-10">
            <div className="grid w-full h-full gap-7">
                <div className="grid grid-col gap-7">
                    <div className="grid gap-4">
                        <InputGroup
                            name="title"
                            widthInputClass={isTemplate ? "w-3/6" : "w-full"}
                            value={ data.title }
                            classFontLabel={isTemplate ? "" : "text-[#111827] font-medium text-[1.25rem] leading-7"}
                            label={ isTemplate ? _("Section Title") : _("Modify the section title") }
                            onChange={(e) => handleChanges({ ...data, title: e.target.value })}
                            required={true}
                            backgroundClass={ isTemplate ? "bg-[#F6F7F8]" : "border text-[#6B7280]" }
                            classGapLabelToField="grid gap-4"
                            fontClass={ isTemplate ? "text-sm" : "text-[#6B7280] text-[1rem] leading-6 font-normal" }
                            icon={ isTemplate ? null : <Icons.SvgProfileEditText className="absolute top-1/2 left-4 -translate-y-1/2 h-4" /> }
                        />
                    </div>

                    <div className="grid gap-4">
                        <InputTextarea
                            rows={ 10 }
                            name="text"
                            widthInputClass={isTemplate ? "w-3/6" : "w-full"}
                            value={ data.text }
                            classFontLabel={ isTemplate ? "" : "text-[#111827] font-medium text-[1.25rem] leading-7" }
                            label={ isTemplate ? _("Free Text") : _("Modify your text zone") }
                            onChange={(e) => handleChanges({ ...data, text: e.target.value })}
                            required={true}
                            backgroundClass={ isTemplate ? "bg-[#F6F7F8]" : "bg-white border" }
                            fontClass={ isTemplate ? "text-sm" : "text-[#6B7280] text-[1rem] leading-6 font-normal border-[#EEEFF2]" }
                            gapClass="gap-4"
                            labelColorClass="text-[#111827] font-medium text-[1.25rem]"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default WidgetTextEdit;