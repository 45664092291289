import { createContext, useState } from "react";

export const LocalStorageUpdateContext = createContext();

export const LocalStorageProvider = ({ children }) => {
  const [storageUpdates, setStorageUpdates] = useState({});

  // This function will update the context, effectively notifying components of the change
  const updateStorage = (key) => {
    setStorageUpdates(prev => ({ ...prev, [key]: Date.now() }));
  };

  return (
    <LocalStorageUpdateContext.Provider value={{storageUpdates, updateStorage}}>
      {children}
    </LocalStorageUpdateContext.Provider>
  );
};

export default { LocalStorageProvider, LocalStorageUpdateContext }