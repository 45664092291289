import React from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";

function PopupConfirmDelete({objectToDelete, listChecked}) {
    const { clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={
                objectToDelete === "leads"
                    ? _("The selected leads have been deleted.")
                    : objectToDelete === "teams"
                        ? listChecked.length > 1
                            ? _("The selected team members have been deleted.")
                            : _("This team member has been deleted.")
                        : objectToDelete === "division"
                            ? _("The selected team members have been deleted form the Division.")
                            : null
            }
            footerButtons={
                [
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[47rem]",
                        onClick: clearAllPopups,
                    },
                ]
            }>
        </PopupBase>
    );
}

export default PopupConfirmDelete;