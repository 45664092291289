import React from 'react';
import ButtonBase from "../../../components/Buttons/ButtonBase";
import {useTranslation} from "../../../services/i18n/i18nService";

function SelectorIntergrationConnect({isSelected, onClick, icon, viewName, shapCustom, displayButton}) {
    const { t: _ } = useTranslation();

    return (
        <div onClick={ onClick } className={`${!isSelected ? 'bg-gray-100' : 'bg-gray-200'} ${!!shapCustom ? shapCustom : "w-full h-[11.5rem]"} gap-4 hover:bg-gray-200 text-[0.813rem] font-medium p-7 rounded-[0.438rem] flex flex-col justify-center align-center items-center shadow-sm cursor-pointer`}>
            { icon }
            <p className="flex align-center justify-center">{ viewName }</p>
            {
                !!displayButton
                    ?  <ButtonBase className="text-[0.75rem] leading-3 font-medium bg-black text-white flex justify-center items-center rounded-full cursor-pointer py-1.5 px-4 rounded-full">
                        {_("Connect")}
                    </ButtonBase>
                    : null
            }

        </div>
    );
}

export default SelectorIntergrationConnect;