import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import { LocaleContext } from '../../contexts/LocaleContext';
import {Icons} from "../../assets/Svgs";

const localeConfig = {
    defaultLanguage: "en",
    authorizedLanguages: ["en", "fr", "de", "it"],
    languageLocale: {
        "en": {
            label: "English",
            locale: "en",
            icon: <Icons.SvgTranslateEnglish />,
        },
        "fr": {
            label: "Français",
            locale: "fr",
            icon: <Icons.SvgTranslateFrench />,
        },
        "de": {
            label: "Deustch",
            locale: "de",
            icon: <Icons.SvgTranslateDeustch />,
        },
        "it": {
            label: "Italiano",
            locale: "it",
            icon: <Icons.SvgTranslateItalian />,
        },
    }
}

const getLanguageFile = async({ language }) => {
    let module;
    if(!localeConfig.authorizedLanguages.includes(language)){
        throw new Error("Language not supported");
    }
    module = await import(`../../assets/Langs/${language}.json`);
    return module.default
};

export const useTranslation = () => {
    const {locale, setLocale} = useContext(LocaleContext);

    const [{ language, data }, setLanguage] = useState({
        language: localeConfig.defaultLanguage,
        data: {}
    });

    const isJsonFetched = useRef(false);
    const updateLanguage = useCallback(async (newLanguage) => {
        if (isJsonFetched.current && newLanguage === language) return;
        getLanguageFile({ language: newLanguage }).then((fetchedData) => {
            isJsonFetched.current = true;
            setLanguage({
                language: newLanguage,
                data: fetchedData,
            });
        }).catch((error) => {
            console.error(error);
        });
    }, [language]);

    const t = (key, params = {}) => {
        const translation = data[key];
        if (!translation) {
            return key;
        }
        return translation.replace(/{(\w+)}/g, (_, k) => params[k] || '');

        // return (data[key] || key).format(params);
    };

    const languageLabels = () => {
        return Object.fromEntries(Object.keys(localeConfig.languageLocale).map(l =>
            [l, {label: localeConfig.languageLocale[l].label, icon: localeConfig.languageLocale[l].icon}]
        ));
    };

    useEffect(() => {
        updateLanguage(language)
        setLocale(localeConfig.languageLocale[language].locale)
    }, [language, updateLanguage])

    useEffect(() => {
        const newLanguage = Object.keys(localeConfig.languageLocale).find(key => localeConfig.languageLocale[key].locale === locale)
        if (newLanguage){
            updateLanguage(newLanguage);
        }
    }, [locale])

    return { t, language, languageLabels, updateLanguage }
}

export default { useTranslation };