import React from 'react';
import PopupBase from "../PopupBase";
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import ButtonBase from "../../Buttons/ButtonBase";

function PopupConfirmAssignTag({item}) {
    const { clearAllPopups, removePopup } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={_("Are you sure you want to assign this tag to the selected leads:")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[15rem]",
                        onClick: clearAllPopups,
                    },
                    {
                        label: _("Back"),
                        background: "bg-white",
                        color: "text-black",
                        className: "w-[15rem]",
                        onClick: removePopup,
                    },
                    {
                        label: _("Confirm"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-[15rem]",
                        onClick: clearAllPopups,
                    },
                ]
            }>
            <ButtonBase className="text-sm leading-3 bg-[#D1D5DB] px-3.5 py-1.5 text-[#4B5563] gap-[0.438rem] shadow-sm flex justify-center items-center rounded-[0.625rem] cursor-pointer">
                {_(item)}
            </ButtonBase>
        </PopupBase>
    );
}

export default PopupConfirmAssignTag;