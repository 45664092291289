import { Icons } from "../assets/Svgs";

export const WIDGET_TYPES = {
    TEXT: 'TEXT',
    SOCIAL_MEDIA: 'SOCIAL_MEDIA',
    VIDEO: 'VIDEO',
    PICTURE: 'PICTURE',
    DOCUMENT: 'DOCUMENT',
    WEBSITE: 'WEBSITE',
    CONTACT: 'CONTACT',
    CTA: 'CTA',
    LOCATION: 'LOCATION',
    WHATSAPP_CTA: 'WHATSAPP_CTA',
    PAYPAL_CTA: 'PAYPAL_CTA',
    CALENDLY: 'CALENDLY',
    GOOGLE_REVIEW: 'GOOGLE_REVIEW',
    APP_LINK: 'APP_LINK',
    INSTAGRAM_FEED: 'INSTAGRAM_FEED',
    PROFILE: 'PROFILE',
    LEAD_CREATION_FORM: 'LEAD_CREATION_FORM',
};

export const WIDGET_FIELD_TYPES = {
    PHONE: 'tel',
    MAIL: 'email',
    TEXT: 'text',
    SELECT: 'select',
};

export const WIDGET_FIELDS = {
    [WIDGET_FIELD_TYPES.PHONE]: {
        label: "Phone Number",
        icon: Icons.SvgContactCallFill,
    },
    [WIDGET_FIELD_TYPES.MAIL]: {
        label: "Mail",
        icon: Icons.SvgContactMailFill,
    },
    [WIDGET_FIELD_TYPES.TEXT]: {
        label: "Text",
        icon: Icons.SvgTemplateSectionText,
    },
    [WIDGET_FIELD_TYPES.SELECT]: {
        label: "Select",
        icon: Icons.SvgTemplateSectionListDraggable,
    },
}

export const WIDGET_CONTACT_TYPES = {
    TEL: 'tel',
    MSG: 'msg',
    MAIL: 'mail',
    WEB: 'web',
    LOCATION: 'location',
};

export const WIDGET_CONTACTS = {
    [WIDGET_CONTACT_TYPES.TEL]: {
        label: "Call",
        icon: Icons.SvgContactCallFill,
    },
    [WIDGET_CONTACT_TYPES.MSG]: {
        label: "Message",
        icon: Icons.SvgTemplateCTAMsg,
    },
    [WIDGET_CONTACT_TYPES.MAIL]: {
        label: "Mail",
        icon: Icons.SvgContactMailFill,
    },
    [WIDGET_CONTACT_TYPES.WEB]: {
        label: "Website",
        icon: Icons.SvgTemplateSectionWebsiteLinks,
    },
    [WIDGET_CONTACT_TYPES.LOCATION]: {
        label: "Location",
        icon: Icons.SvgTemplateSectionLocation,
    },
}
