import React from 'react';

function ButtonGradient(
    {
        onClick,
        icon,
        iconColor,
        hasBorder,
        roundedClass,
        colorGradient,
        bgActive,
        children,
        textColor,
        textStyle,
        heightClass,
    }) {
    return (
        <div
            onClick={onClick}
            className={`
            cursor-pointer
            ${ !!colorGradient ? colorGradient : "" } 
            ${ !!hasBorder ? "p-[0.1rem]" : "w-full h-full" }
            ${ !!roundedClass ? roundedClass : "rounded-full" }
        `}>
            <div className={`
                ${ !!bgActive ? bgActive : "bg-white" } 
                ${ !!heightClass ? heightClass : "" }
                ${ !!roundedClass ? roundedClass : "rounded-full" }
                
                flex justify-center items-center flex-col w-full `}
            >
                <div className={`
                    flex gap-3 whitespace-nowrap rounded-full items-center justify-center bg-clip-text 
                    ${ !!hasBorder ? "leading-4 py-4 px-2.5 font-semibold" : "hover:bg-[#F6F7F8] w-full text-[0.813rem] font-medium" } 
                    ${!!textColor ? textColor : "text-transparent"} 
                    ${!!colorGradient ? colorGradient : ""}
                    ${!!textStyle ? textStyle : "" }
                `}>
                    { !!icon ? React.cloneElement(icon, { color: iconColor}) : null }
                    { !!children ? children : null }
                </div>
            </div>
        </div>
    );
}

export default ButtonGradient;