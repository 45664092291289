import { TAGS } from "../../constants/DatasConstants";
import APIService from "../APIService";

let tags = null;

export const getTags = () => {
    return new Promise((resolve, reject) => {
        if (tags === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            tags = TAGS
            resolve(tags)
        } else {
            resolve(tags)
        }
    });
}

export default {
    getTags,
};