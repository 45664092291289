import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import InputGroup from "../../Layouts/InputGroup";
import ButtonBase from "../../Buttons/ButtonBase";
import PopupAssignNewAdminAccess from "../../Popups/AdminAccess/PopupAssignNewAdminAccess";
import PopupConfirmAssignNewAdminAccess from "../../Popups/AdminAccess/PopupConfirmAssignNewAdminAccess";
import {Icons} from "../../../assets/Svgs";
import {useTranslation} from "../../../services/i18n/i18nService";
import {usePopup} from "../../../services/PopupService";
import WidgetService from "../../../services/Widget/WidgetService";
import {useParams} from "react-router-dom";

const WidgetInstagramFeedEdit = React.forwardRef((
    {
        widget,
        onSave,
        onDelete,
        onChange,
    },
    ref
) => {
    useImperativeHandle(ref, () => ({
        handleSave,
        handleCreate
    }));

    const { idType } = useParams();

    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const [data, setData] = useState({
        title: widget?.data?.title || "",
    })

    const handleSave = () => {
        const updatedWidget = { ...widget, data };
        WidgetService.updateWidget(widget.id, widget)
            .then(() => {
                onSave(updatedWidget);
            })
            .catch((error) => {
                console.log('Failed to save widget');
                throw error;
            })
    }

    const handleCreate = () => {
        const updatedWidget = {
            portfolio: 2, // fix
            section: 1, // fix
            widget_type: idType,
            order: 27, // fix
            data
        };

        WidgetService.createWidget(updatedWidget)
            .then((newWidget) => {
                onSave(newWidget); // Pass the new widget to the onSave callback
            })
            .catch((error) => {
                console.log('Failed to create widget');
                throw error;
            });
    }

    const handleChanges = (data) => {
        setData(data);
        const updatedWidget = { ...widget, data };
        onChange(updatedWidget);
    }

    useEffect(() => {
        if (widget?.data) {
            setData({
                title: widget?.data?.title || "",
            });
        }
    }, [widget]);

    return (
        <div className="grid w-full gap-7">
            <div className="grid grid-col gap-7">
                <InputGroup
                    name="title"
                    widthInputClass="w-3/6"
                    classGapLabelToField="gap-3.5"
                    label="Instagram Feed Title"
                    value={ data.title }
                    onChange={(e) => handleChanges({ ...data, title: e.target.value })}
                    backgroundClass="bg-[#F6F7F8]"
                    required={true}
                />

                <ButtonBase
                    onClick={() => addPopup(
                        <PopupAssignNewAdminAccess
                            assignNewAdmin={
                                () => addPopup( <PopupConfirmAssignNewAdminAccess /> )
                            }
                        />
                    )}
                    className="w-fit bg-orange-gradient text-white whitespace-nowrap text-sm py-3.5 px-6 rounded-full"
                    iconColor="white"
                    icon={ <Icons.SvgTemplateInstagramWhite color="white" /> }
                >
                    {_("Connect to Instagram")}
                </ButtonBase>
            </div>
        </div>
    )
})

export default WidgetInstagramFeedEdit;